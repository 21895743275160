import handleActions from 'redux-actions/lib/handleActions'
import _forEach from 'lodash/forEach'
import { createAsyncHandlers } from '../actions'
import { subtractTwoArrays } from '../../core/utils'

const initialState = {
  seatData: null,
  seatDataChecked: false,
  statuses: null,
  reservedSeats: [],
  tierId: '',
  ticketTypes: null,
  ttlStart: '',
  eid: ''
}

const UPLOAD_GUESTTICKETS = createAsyncHandlers('UPLOAD_GUESTTICKETS', {
  success(state, { payload: { isSeatMap } }) {
    if (isSeatMap) {
      if (state.statuses) {
        _forEach(state.reservedSeats, el => {
          const [rId, index] = el.split(':')
          state.statuses[rId][index] = 'B'
        })
      }

      state.reservedSeats = []
      state.tierId = ''
      state.ttlStart = localStorage.getItem('ttlStart')
    }
  }
})

const GET_SEAT_MAP_DATA = createAsyncHandlers('GET_SEAT_MAP_DATA', {
  success(state, { payload: { seatData, ticketTypes, eid } }) {
    state.seatData = seatData
    state.seatDataChecked = true
    state.ticketTypes = ticketTypes
    state.eid = eid
  },
  failed(state, { payload: { err, eid } }) {
    state.seatDataChecked = true
    state.eid = eid
    state.seatData = null
    state.statuses = null
  }
})

const SET_SEAT_MAP_DATA = createAsyncHandlers('SET_SEAT_MAP_DATA', {
  success(state, { payload: { seatData } }) {
    state.seatData = seatData
  }
})

const GET_SEAT_MAP_STATUSES = createAsyncHandlers('GET_SEAT_MAP_STATUSES', {
  success(state, { payload }) {
    state.statuses = payload
  }
})

const RESERVE_SEAT = createAsyncHandlers('RESERVE_SEAT', {
  success(state, { payload: { seatId, tierId } }) {
    const [rId, index] = seatId.split(':')
    state.reservedSeats.push(seatId)
    state.tierId = tierId
    state.statuses[rId][index] = 'OR'
    state.ttlStart = localStorage.getItem('ttlStart')
  }
})

const DELETE_RESERVATIONS = createAsyncHandlers('DELETE_RESERVATIONS', {
  success(state, { payload: { deletedReservations = [] } }) {
    if (state.statuses) {
      _forEach(deletedReservations, el => {
        const [rId, index] = el.split(':')
        state.statuses[rId][index] = 'A'
      })
    }
    state.ttlStart = localStorage.getItem('ttlStart')
    state.reservedSeats = subtractTwoArrays(state.reservedSeats, deletedReservations)
    if (state.reservedSeats.length === 0) {
      state.tierId = ''
    }
  }
})

export default handleActions(
  {
    ...UPLOAD_GUESTTICKETS,
    ...GET_SEAT_MAP_DATA,
    ...GET_SEAT_MAP_STATUSES,
    ...RESERVE_SEAT,
    ...DELETE_RESERVATIONS,
    ...SET_SEAT_MAP_DATA
  },
  initialState
)
