/* eslint-disable indent */
import _get from 'lodash/get'
import _map from 'lodash/map'
import _set from 'lodash/set'
import _filter from 'lodash/filter'
import _isNumber from 'lodash/isNumber'
import _clone from 'lodash/clone'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'

import { withFormik } from 'formik'
import React from 'react'
import moment, { weekdaysShort } from 'moment'
import { PublicKey } from '@solana/web3.js'

import DataRow from './ticketFormRows/DataRow'
import DataRowSlidingScale from './ticketFormRows/DataRowSlidingScale'
import DescriptionRow from './ticketFormRows/DescriptionRow'
import CheckBoxesRow from './ticketFormRows/CheckBoxesRow'
import TagsRow from './ticketFormRows/TagsRow'

import ActionsRow from './ticketFormRows/ActionsRow'
import { defaultTimeSlotValues } from './ticketFormRows/TimeSlotsRow/index'
import { isDateRangeInvalid } from '../form/EventForm/utils'
import SaleDatesRows from './ticketFormRows/SaleDatesRows'

const slidingDatesRange = 6 * 24 * 60 * 60 * 1000 // 6 days
export const defaultPriceRange = [
  { from: '0', to: '', price: '' },
  { from: '', to: '', price: '', type: 'info' }
]
export const defaultHolds = [{ quantity: '', description: '' }]

const saleDatesOptions = [
  {
    value: '1',
    displaySaleDates: false,
    label: 'Once the previous ticket type sells out',
    flagAlwaysAvailable: false
  },
  {
    value: '2',
    displaySaleDates: true,
    label: 'At a specific time on a specific date',
    flagAlwaysAvailable: true
  },
  {
    value: '3',
    displaySaleDates: true,
    label:
      'Either when the previous ticket type sells out, or at a specific time on a specific date - whichever comes first',
    flagAlwaysAvailable: false
  },
  { value: '4', displaySaleDates: false, label: 'Always', flagAlwaysAvailable: true }
]
const hidingOptions = [
  {
    value: 'previous_ticket_sold',
    displayHidingDates: false,
    label: 'Once the previous ticket type sells out'
  },
  {
    value: 'specific_datetime',
    displayHidingDates: true,
    label: 'At a specific time on a specific date'
  },
  {
    value: 'specific_datetime_or_previous_ticket_sold',
    displayHidingDates: true,
    label:
      'Either when the previous ticket type sells out, or at a specific time on a specific date - whichever comes first'
  },
  { value: 'always_hidden', displayHidingDates: false, label: 'Always hidden' }
]

let isInventoryMode = false

function validateTicket(data, props) {
  const required = ['displayName']
  const errors = {}
  const slidingScaleStartDate = _get(data, 'slidingScaleStartDate')
  const slidingScaleEndDate = _get(data, 'slidingScaleEndDate')
  const flagSlidingScale = _get(data, 'flagSlidingScale')
  const flagTimeSlotsEnabled = _get(props, 'event.flagTimeSlotsEnabled')
  const salesStartDate = _get(props, 'event.salesStartDate')
  const isEditing = _get(props, 'isEditing')
  const cartsData = _get(props, 'cartsData')
  const formKey = _get(props, 'formKey')
  const tempDirtyStock = _get(props, 'tempDirtyStock')
  const displayMaxQuantity = _get(data, 'displayMaxQuantity')
  const maxQuantity = _get(data, 'maxQuantity')
  const displaySaleDates = _get(data, 'displaySaleDates')
  const displayHidingDates = _get(data, 'displayHidingDates')
  const flagHidden = _get(data, 'flagHidden')
  const flagNftOwnershipRequired = _get(data, 'flagNftOwnershipRequired')
  
  if (flagSlidingScale) {
    required.push('fundraisingGoal')
    // required.push('minPrice')
  }

  required.forEach(f => {
    if (!_get(data, f)) {
      _set(errors, f, 'Required')
    }
  })

  if (_get(data, 'checkInStart') && !_get(data, 'checkInEnd')) {
    _set(errors, 'checkInEnd', 'Required')
  }

  if (!_get(data, 'checkInStart') && _get(data, 'checkInEnd')) {
    _set(errors, 'checkInStart', 'Required')
  }

  if (
    flagSlidingScale &&
    moment(slidingScaleEndDate).diff(moment(slidingScaleStartDate)) > slidingDatesRange
  ) {
    _set(errors, 'slidingScaleEndDate', 'Sliding scale dates range must be less or equal to 6 days')
  }

  if (flagSlidingScale && isDateRangeInvalid(slidingScaleStartDate, slidingScaleEndDate)) {
    _set(errors, 'slidingScaleEndDate', 'Sliding scale end date must be after sliding scale start date')
  }

  if (!flagSlidingScale && !flagTimeSlotsEnabled) {
    const filteredData = _filter(data.ticketHolds, item => (item.id ? 0 : 1))
    let quantityForCompare = 0
    _map(filteredData, item => {
      quantityForCompare += +item.quantity || 0
    })

    const errMsg = 'The quantity of holds is more than available ticket quantity.'

    if (isEditing && moment().isSameOrAfter(salesStartDate)) {
      const foundedItem = _find(cartsData.ticketTypes || [], o => o.id === formKey) || { qty: 0 }
      if (+_get(data, 'stock') + tempDirtyStock - foundedItem.qty < quantityForCompare) {
        _set(errors, 'holdsError', errMsg)
      }
    } else {
      if (+_get(data, 'maxInventory') < quantityForCompare) {
        _set(errors, 'holdsError', errMsg)
      }
    }
  }

  if (displayMaxQuantity && maxQuantity === '') {
    _set(errors, 'maxQuantity', 'Required')
  }

  if (displaySaleDates) {
    const salesStart = _get(data, 'salesStart')
    const salesEnd = _get(data, 'salesEnd')

    if (!salesStart) {
      _set(errors, 'salesStart', 'Required')
    }

    if (!salesEnd) {
      _set(errors, 'salesEnd', 'Required')
    }

    if (salesStart && salesEnd && moment(salesStart).isSameOrAfter(moment(salesEnd))) {
      _set(errors, 'salesEnd', 'Must be a date after sales start date')
    }
  }
  if (flagNftOwnershipRequired) {
    const ethereum = _get(data, 'nftContracts.ethereum')
    const polygon = _get(data, 'nftContracts.polygon')
    const mainnet = _get(data, 'nftLists.solana-mainnet')
    if(ethereum.length === 0 && polygon.length === 0 && mainnet.length === 0 ){
      _set(errors, 'ethereum', 'At least 1 NFT address is required')
      _set(errors, 'polygon', 'At least 1 NFT address is required')
      _set(errors, 'mainnet', 'At least 1 NFT address is required')
    }
    const re = global.NFTRegex
    for (const em of ethereum) {
      if (!re.test(em)) {
        _set(errors, 'ethereum', 'Invalid')
      }
    }
    for (const em of polygon) {
      if (!re.test(em)) {
        _set(errors, 'polygon', 'Invalid')
      }
    }
    for (const em of mainnet) {
      try {
        const address = new PublicKey(em)
        const isValid = PublicKey.isOnCurve(address.toBuffer())
        if(!isValid) _set(errors, 'mainnet', 'Invalid')
      } catch(e){
        _set(errors, 'mainnet', 'Invalid')
      }
    }
    if((ethereum.length || polygon.length) && mainnet.length){
      _set(errors, 'nfts', 'Ethereum/Polygon combination with Solana Mainnet is not allowed')
    }
  } 

  if(flagHidden && displayHidingDates) {
    const hidingOptionDate = _get(data, 'hidingOptionDate')
    if(!hidingOptionDate) {
      _set(errors, 'hidingOptionDate', 'Required')
    }
  }
  return errors
}

class MyForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedDates: [],
      timeSlotsValues: defaultTimeSlotValues,
      selectedWeekdays: weekdaysShort(),
      excludedDates: []
    }
  }

  updateField = (field, value) => {
    const { setFieldValue } = this.props
    setFieldValue(field, value)

    const fieldName = `attributes.${field}`
    const fields = form_helper_get()
    fields[fieldName] = fieldName
    form_helper_set(fields)
  }

  updateTimeSlotsSettings = (fieldKey, value) => {
    this.setState({ [fieldKey]: value })
  }

  onTagsChange = (field, tags) => {
    const { setFieldValue } = this.props
    switch (field) {
      case 'eth':
        setFieldValue('nftContracts.ethereum', tags)
        break
      case 'pol':
        setFieldValue('nftContracts.polygon', tags)
        break
      case 'mainnet':
        setFieldValue('nftLists.solana-mainnet', tags)
        break
      default:
        break
    }
  }

  render() {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      tickets,
      handleSubmit,
      submitCount,
      onCancel,
      inModal,
      isEditing,
      event,
      isSlotTicket,
      timeSlotGroups,
      launchDarklyFlags = {},
      handleHoldRowDelete,
      tableType = false,
      setFieldValue,
      configs,
    } = this.props

    const { selectedDates, selectedChip, timeSlotsValues, selectedWeekdays, excludedDates } = this.state
    const flagTimeSlotsEnabled = event ? !!event.flagTimeSlotsEnabled : false
    const flagSlidingScale = values ? !!values.flagSlidingScale : false
    const flagDifferentTicketingPlatform = (values && values.externalRedirect && values.externalRedirect.length > 0)
    const externalRedirect = flagDifferentTicketingPlatform ? values.externalRedirect : ''

    isInventoryMode = event.inventoryMode === 'maximum'
    const { allowPriceEditing } = event
    let ticketGroupOptions = []
    if (flagTimeSlotsEnabled) {
      ticketGroupOptions = _map(timeSlotGroups, g => ({
        label: g.groupName,
        value: g.id
      }))
    } else {
      // eslint-disable-next-line no-unused-vars
      const [noGroupedTickets, groupedTickets] = ticketTypesSorting(tickets)
      ticketGroupOptions = _map(groupedTickets, g => ({
        label: g.groupName,
        value: g.groupId
      }))
      ticketGroupOptions = _filter(
        ticketGroupOptions,
        groupOption =>
          groupOption.label.toLowerCase() !== (values.slotGroupName || values.displayName).toLowerCase()
      )
    }
    const flagHoldTickets = !flagTimeSlotsEnabled && !flagSlidingScale
    return (
      <form ref="form" method="POST" onSubmit={handleSubmit}>
        <div className="ticket-form card-space">
          <div className="card-block">
            {flagSlidingScale ? (
              <DataRowSlidingScale
                values={values}
                errors={errors}
                touched={touched}
                isEditing={isEditing}
                updateField={this.updateField}
              />
            ) : (
              <DataRow
                values={values}
                isEditing={isEditing}
                isSlotTicket={isSlotTicket}
                isInventoryMode={isInventoryMode}
                allowPriceEditing={allowPriceEditing}
                ticketGroupOptions={ticketGroupOptions || []}
                flagTicketHasTimeSlots={values.flagTicketHasTimeSlots}
                updateField={this.updateField}
                tableType={tableType}
              />
            )}
            <DescriptionRow
              ref="description"
              values={values}
              updateField={this.updateField}
            />
            <CheckBoxesRow
              values={values}
              errors={errors}
              touched={touched}
              flagDifferentTime={values.flagDifferentTime}
              isSlotTicket={isSlotTicket}
              updateField={this.updateField}
              flagTimeSlotsEnabled={flagTimeSlotsEnabled}
              flagSlidingScale={flagSlidingScale}
              flagHoldTickets={flagHoldTickets}
              submitCount={submitCount}
              isEditing={isEditing}
              launchDarklyFlags={launchDarklyFlags}
              handleHoldRowDelete={handleHoldRowDelete}
              inModal={inModal}
              tableType={tableType}
              hidingOptions={hidingOptions}
              setFieldValue={setFieldValue}
              onTagsChange={this.onTagsChange}
              isInventoryMode={isInventoryMode}
              currency={event.currency}
              updateTimeSlotsSettings={this.updateTimeSlotsSettings}
              selectedDates={selectedDates}
              selectedChip={selectedChip}
              timeSlotsValues={timeSlotsValues}
              selectedWeekdays={selectedWeekdays}
              excludedDates={excludedDates}
              configs={_get(configs, 'children.CheckBoxesRow')}
            />
            <div className="div-spacing-10" />
            <TagsRow ref="tag" values={values} updateField={this.updateField} />
            <div className="div-spacing-10" />
            <SaleDatesRows
              inModal={inModal}
              values={values}
              optionsSaleDates={saleDatesOptions}
              updateField={this.updateField}
            />
            <div className="div-spacing-20" />
            <ActionsRow
              isSubmitting={isSubmitting}
              isSaveButtonDisabled={
                values.flagTicketHasTimeSlots &&
                (!values.timeSlots || (values.timeSlots && !values.timeSlots.length))
              }
              handleSubmit={handleSubmit}
              onCancel={onCancel}
            />
            <div className="div-spacing-20" />
          </div>
        </div>
      </form>
    )
  }
}

const TicketForm = withFormik({
  mapPropsToValues: props => {
    const faceValue = _get(props, 'initialValues.attributes.faceValue', '')
    const price = _get(props, 'initialValues.attributes.price', '')
    const guestPrice = _get(props, 'initialValues.attributes.guestPrice', '')
    const maxInventory = String(_get(props, 'initialValues.attributes.maxInventory')) || ''
    // const minPrice = _get(props, 'initialValues.attributes.minPrice') || ''
    const finalPrice = _get(props, 'initialValues.attributes.slidingScaleDetails.finalPrice', '')
    const fundraisingGoal = _get(props, 'initialValues.attributes.slidingScaleDetails.fundraisingGoal') || ''

    // sliding scale dates
    const salesStartDate = _get(props, 'event.salesStartDate')
    const slidingScaleStartDate =
      _get(props, 'initialValues.attributes.slidingScaleDetails.startDate') || salesStartDate || ''
    let slidingScaleEndDate = ''
    if (slidingScaleStartDate) {
      slidingScaleEndDate =
        _get(props, 'initialValues.attributes.slidingScaleDetails.endDate') ||
        moment.utc(slidingScaleStartDate).add(6, 'days').format()
    }

    // sliding scale price ranges
    const priceRanges = _get(props, 'initialValues.attributes.slidingScaleDetails.priceRanges') || []
    const updatedPriceRanges = _map(priceRanges, item => ({
      ...item,
      price: parseFloat(item.price).toFixed(0),
      to: !item.to ? maxInventory : item.to,
      type: !item.to ? 'info' : null
    }))

    const holds = _get(props, 'initialValues.attributes.ticketHolds') || []
    const flagAlwaysAvailable = !!_get(props, 'initialValues.attributes.flagAlwaysAvailable')
    const displaySaleDates =
      !!_get(props, 'initialValues.attributes.salesStart') ||
      !!_get(props, 'initialValues.attributes.endStart') ||
      false
    const [pickedOption] = _filter(
      saleDatesOptions,
      option =>
        option.displaySaleDates === displaySaleDates && option.flagAlwaysAvailable === flagAlwaysAvailable
    )
    const optionSaleDates = pickedOption.value
    const hidingOption = _get(props, 'initialValues.attributes.hidingOption') || hidingOptions[3].value
    const [hidingPickedOption] = _filter(hidingOptions, option => option.value === hidingOption)
    const flagHidden = !!_get(props, 'initialValues.attributes.flagHidden')
    const hideUnhideDate = flagHidden ? '' : _get(props, 'initialValues.attributes.hideUnhideDate', '')
    const hidingOptionDate = flagHidden ? _get(props, 'initialValues.attributes.hideUnhideDate', '') : ''
    let initialNFTContracts = _get(props, 'initialValues.attributes.nftContracts')
    if(_isEmpty(initialNFTContracts)){
      initialNFTContracts = { ethereum: [], polygon : [] }
    }
    let initialNFTLists = _get(props, 'initialValues.attributes.nftLists')
    if(_isEmpty(initialNFTLists)){
      initialNFTLists = { "solana-mainnet": [] }
    }

    return {
      quantityIncrement: _get(props, 'initialValues.attributes.quantityIncrement') || 1,
      active:
        _get(props, 'initialValues.attributes.active') != null
          ? _get(props, 'initialValues.attributes.active')
          : true,
      displayName: _get(props, 'initialValues.attributes.displayName') || '',
      stock: _get(props, 'initialValues.attributes.stock'),
      maxInventory,
      price: _isNumber(parseFloat(price) / 100) ? parseFloat(price) / 100 : '',
      faceValue: _isNumber(parseFloat(faceValue) / 100) ? parseFloat(faceValue) / 100 : '',
      // minPrice: minPrice,
      finalPrice,
      fundraisingGoal,
      priceRanges: updatedPriceRanges.length ? updatedPriceRanges : defaultPriceRange,
      slidingScaleStartDate,
      slidingScaleEndDate,
      description: _get(props, 'initialValues.attributes.description') || '',
      tags: _get(props, 'initialValues.attributes.tags'),
      flagHidden,
      flagDifferentTime:
        !!_get(props, 'initialValues.attributes.checkInStart') ||
        !!_get(props, 'initialValues.attributes.checkInEnd') ||
        '',
      checkInStart: _get(props, 'initialValues.attributes.checkInStart') || '',
      checkInEnd: _get(props, 'initialValues.attributes.checkInEnd') || '',
      externalRedirect: _get(props, 'initialValues.attributes.externalRedirect') || '',
      flagDifferentTicketingPlatform: _get(props, 'initialValues.attributes.externalRedirect') ? true : false,
      flagTicketHasTimeSlots: _get(props, 'initialValues.attributes.flagTicketHasTimeSlots') ? true : false,
      slotGroupName: _get(props, 'initialValues.attributes.slotGroupName') || '',
      nestedGroups: _get(props, 'initialValues.attributes.nestedGroups') || '',
      flagAvailableAfterRegistration: !!_get(
        props,
        'initialValues.attributes.slidingScaleDetails.finalPrice'
      ),
      flagSlidingScale: _get(props, 'initialValues.attributes.flagSlidingScale'),
      ticketHolds: holds.length ? holds : defaultHolds,
      holdTicketsEnabled: !!holds.length,
      hideUnhideDate,
      hidingOptionDate,
      maxQuantity: _get(props, 'initialValues.attributes.maxQuantity') || '',
      displayMaxQuantity: !!_get(props, 'initialValues.attributes.maxQuantity'),
      salesStart: _get(props, 'initialValues.attributes.salesStart'),
      salesEnd: _get(props, 'initialValues.attributes.salesEnd'),
      optionSaleDates,
      hidingOption,
      flagAlwaysAvailable,
      displaySaleDates,
      displayHidingDates: hidingPickedOption.displayHidingDates,
      minGuests: _get(props, 'initialValues.attributes.minGuests') || '',
      maxGuests: _get(props, 'initialValues.attributes.maxGuests') || '',
      guestPrice: _isNumber(parseFloat(guestPrice) / 100) ? parseFloat(guestPrice) / 100 : '',
      depositPercent: _get(props, 'initialValues.attributes.depositPercent') || '',
      flagNftOwnershipRequired: _get(props, 'initialValues.attributes.flagNftOwnershipRequired') || false,
      maxQuantityPerAccount:  _get(props, 'initialValues.attributes.maxQuantityPerAccount') || '',
      nftContracts: initialNFTContracts,
      nftInput: { ethereum:'', polygon:'', "solana-mainnet":'' },
      nftLists: initialNFTLists,
    }
  },

  // Custom sync validation
  validate: (values, props) => validateTicket(values, props),

  handleSubmit: (values, { props, setSubmitting }) => {
    let updatedValues = _clone(values)

    if (values.flagSlidingScale) {
      const {
        finalPrice,
        slidingScaleStartDate,
        slidingScaleEndDate,
        minPrice,
        fundraisingGoal,
        priceRanges,
        ...rest
      } = values

      updatedValues = {
        ...rest,
        price: parseFloat(priceRanges[0].price) * 100,
        faceValue: parseFloat(priceRanges[0].price) * 100,
        slidingScaleDetails: {
          finalPrice: values.flagAvailableAfterRegistration ? parseFloat(finalPrice) : null,
          startDate: slidingScaleStartDate,
          endDate: slidingScaleEndDate,
          minPrice: minPrice || '',
          fundraisingGoal: parseFloat(fundraisingGoal),
          priceRanges: _map(priceRanges, item => ({
            to: item.type === 'info' ? null : item.to,
            from: item.from,
            price: parseFloat(item.price)
          }))
        }
      }

      if (!finalPrice && finalPrice !== 0) {
        delete updatedValues.finalPrice
        // delete updatedValues.flagAvailableAfterRegistration
      }
    }

    if (!values.flagSlidingScale) {
      updatedValues = {
        ...updatedValues,
        price: parseFloat(values.faceValue) * 100,
        faceValue: parseFloat(values.faceValue) * 100,
        guestPrice: parseFloat(values.guestPrice) * 100
      }
    }

    if (!values.flagDifferentTime) {
      updatedValues = {
        ...updatedValues,
        checkInStart: '',
        checkInEnd: ''
      }
    }

    if (!updatedValues.holdTicketsEnabled) {
      updatedValues.ticketHolds = []
    }

    if (!values.flagDifferentTicketingPlatform) {
      updatedValues = {
        ...updatedValues,
        externalRedirect: ''
      }
    }

    delete updatedValues.flagDifferentTime
    delete updatedValues.holdTicketsEnabled
    if (!props.launchDarklyFlags.flagHoldTicketsEnabled) {
      delete updatedValues.ticketHolds
      delete updatedValues.holdTicketsEnabled
    }

    if (!values.displaySaleDates) {
      updatedValues = {
        ...updatedValues,
        salesStart: '',
        salesEnd: ''
      }
    }

    if(values.flagHidden) {
      updatedValues = {
        ...updatedValues,
        hideUnhideDate: values.displayHidingDates ? values.hidingOptionDate : ''
      }
    } else {
      updatedValues = {
        ...updatedValues,
        hidingOption: null
      }
    }

    delete updatedValues.hidingOptionDate
    delete updatedValues.displaySaleDates
    delete updatedValues.optionSaleDates

    props
      .onSubmit({
        attributes: values.flagTicketHasTimeSlots
          ? {
              timeSlots: [
                {
                  groupName: props.isSlotTicket ? values.slotGroupName : values.displayName,
                  alwaysAvailable: values.flagAlwaysAvailable,
                  nestedGroups: values.nestedGroups ? _map(values.nestedGroups, n => n.value) : [],
                  ticketTypes: _map(values.timeSlots, t => ({
                    startTime: t.checkInStart,
                    endTime: t.checkInEnd,
                    maxQuantity: t.maxInventory,
                    price: parseFloat(t.price) * 100,
                    description: t.description,
                    enabled: t.active,
                    quantityIncrement: t.quantityIncrement,
                    hidden: t.flagHidden,
                    tags: values.tags || []
                  }))
                }
              ]
            }
          : { ...updatedValues }
      })
      .then(v => {
        setSubmitting(false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'TicketForm' // helps with React DevTools
})(MyForm)

export default TicketForm
