import { default as _toInt } from 'validator/lib/toInt'
import moment from 'moment'
import { DAY_DISPLAY_FORMAT, TIME_DISPLAY_FORMAT } from '../../../web/constants/timeFormats'
import _find from 'lodash/find'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _trim from 'lodash/trim'

export const defaultDateFormat = 'YYYY-MM-DD HH:mm:ss'

// normalizers
export const toInt = (value = '') => {
  const int = _toInt(`${value}`)
  return Number.isNaN(int) ? '' : `${int}`
}

export const arrToInt = (arr = []) => _filter(
  _map(arr, item => toNumber(item)),
  Boolean
)

export const toNumber = (value = '') => `${value}`
  .split('')
  .filter((el, index) => {
    if (el === '.') {
      return `${value}`.indexOf('.') === index
    } 
    return /\d/.test(el)
      
  })
  .join('')

export const toAlphabetic = value => value.replace(/[^a-zA-Z]/g, '')

export const toUpperCase = value => value.toUpperCase()

export const toLowerCase = value => value.toLowerCase()

export const formatDate = (date, format, currentFormat) => moment(date, currentFormat || '').format(format || defaultDateFormat)

export const formatDay = date => moment(date).format(DAY_DISPLAY_FORMAT) // 25 Mar 2019

export const formatTime = date => moment(date).format(TIME_DISPLAY_FORMAT) // 02:34 pm

export const formatDateTime = date => moment(date, defaultDateFormat).format('h:[00] a') // 02:34 pm

export const singleSelectNormalizer = value => (value.length === 2 ? (value.shift(), value) : value)

export const toTitleCase = str => str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())

// create normalizers
export const createFormatDateNormalizer = (format = defaultDateFormat, currentFormat = '') => date => moment(date, currentFormat).format(format)

export const createFixedFloatNormalizer = fixedValue => value => (value || value == 0 ? (+value).toFixed(fixedValue) : '')

export const minNormalizerCreator = min => value => (value === '' ? '' : value < min ? min : value)
export const maxNormalizerCreator = max => value => (value === '' ? '' : value > max ? max : value)

export const maxLengthNormalizerCreator = max => value =>
  value === '' ? '' : value.length > max ? value.slice(0, max) : value

export const durationNormalizerCreater = unit => value =>
  !value ? '' : value == 1 ? `${value} ${unit}` : `${value} ${unit}s`

export const currencyNormalizerCreator = currency => value => (!value ? '' : `${currency} ${value}`)

export const createUpperCaseNormalizer = (reg = new RegExp(), options = []) => value =>
  value
    .toLowerCase()
    .replace(reg, (m, m1, p) =>
      _find(options, item => item === m1) ? m1.toUpperCase() : m1.charAt(0).toUpperCase() + m1.slice(1)
    )

export const percentNormalizerCreator = (value) => (value ? `${value} %` : '-')

export const trimNormalizer = value => _trim(value?.replace(/\s+/g, ' '))
