import { ENV } from '../constants/env'

export const MCConfigs = {
  global: {
    appearance: {
      fontFamily: 'Inter',
    },
    CONSTANTS: {
      DOMAINS: ['manacommon', 'manacommon-stg', 'manacommon-dev'],
      MANA_CONSUMERS: [
        'https://tickets.manacommon.com/',
        'https://tickets-staging.manacommon.com/',
        'https://tickets-dev.manacommon.com/',
      ],
    },
    behavior: {
      skipIDVerificationStep: false,
      displayUsersnap: true,
      hideAppcuesSurvey: false,
    },
  },
  '*': {
    domain: 'manacommon',
    messages: {
      documentTitle: 'Mana Common Ticketing – Powered by TheTicketFairy',
      copyright: 'Mana Common',
    },
    appearance: {
      icons: {
        favicon: '/resources/images/mc_favicon.ico',
      },
      assets: {
        headerLogo: '/resources/images/manacommon/MANA_COMMON_DARK_BACKGROUND.png',
        logo: '/resources/images/manacommon/MANA_COMMON_LIGHT_BACKGROUND.svg',
      },
      classes: {
        header: 'res_logo_mc',
        logoLabel: 'mc-powered-text',
      },
    },
  },
  '/': {
    id: 'HomePage',
    messages: {
      documentTitle: 'Home - Mana Common Ticketing - Powered by TheTicketFairy',
    },
    behavior: {
      redirectTo: '/events',
    },
  },
  '/ads': {
    id: 'AdsPage',
    appearance: {
      displaySection: false,
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: false,
      isVisibleForTtfSupport: false,
      isVisibleForAdminOrOwner: false,
    },
    messages: {
      documentTitle: 'Ads - Mana Common Ticketing - Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this page.",
    },
  },
  '/event/:id/*': {
    id: 'EventAllTabs',
    appearance: {
      isBudgetVisible: false,
      isCredentialsVisible: false,
      isPreregistrationVisible: false,
      isFAQVisible: false,
      isMusicVisible: false,
      isPsychographicsVisible: false,
      isGamingVisible: false,
      isDevicesVisible: false,
      isDemographicsVisible: false,
      isGeographicsVisible: false,
      isTalentVisible: false,
      isSupportVisible: false,
    },
  },
  '/event/:id/details': {
    id: 'EventDetails',
    messages: {
      documentTitle: 'Details - {displayName} - Mana Common Ticketing - Powered by TheTicketFairy',
    },
    appearance: {
      hideCancelPostponeFunc: true
    },
    children: {
      BrandForm: {
        appearance: {
          showAdvancedOptions: false,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: true,
          },
          PaymentMethod: {
            displaySection: false,
            appearance: {
              showStripeConnectedAccount: false,
            },
          },
          BrandNameDetails: {
            appearance: {
              showDescriptionBlock: true,
            },
            behavior: {
              baseURL: `${ENV.MC_CONSUMER}/`,
            },
            children: {
              AutoSlugField: {
                behavior: {
                  separator: '',
                },
              },
            },
          },
          BrandImages: {
            children: {
              behavior: {
                fileUploaderId: 'squareImage',
              },
              messages: {
                fileUploaderLabel: 'Square Image',
              },
            },
          },
        },
      },
      EventForm: {
        appearance: {
          showOpenGraphImages: false,
          showEventAlternativeViews: false,
          showShopifyCard: false,
          showEventGenres: true,
          showEventPayoutDetails: true,
          hideShopify: true,
        },
        children: {
          EventDetails: {
            appearance: {
              showAgeRow: false,
            },
            children: {
              EventNameRow: {
                appearance: {
                  showShortNameField: false,
                },
                behavior: {
                  eventDisplayNameLength: 50,
                  eventDisplayNameClassName: 'col-xs-12',
                },
                messages: {
                  eventDisplayNameLabel: 'Event Title',
                },
              },
              EventUrlRow: {
                behavior: {
                  baseURL: `${ENV.MC_CONSUMER}/events/`,
                  slugSeparator: '',
                },
                messages: {
                  autoSlugFieldLabel: 'Custom URL',
                },
              },
              EventDatesRow: {
                behavior: {
                  autoSetDates: true,
                  dateDefaultValues: {
                    startTime: 21,
                    minutes: 0,
                    duration: 4,
                  },
                },
                appearance: {
                  showDateTimePickerNew: true,
                },
                children: {
                  DateTimePickerNew: {
                    behavior: {
                      checkInitialValue: true,
                    },
                  },
                },
              },
            },
          },
          EventVenue: {
            appearance: {
              showRegionField: true,
            },
            behavior: {
              useShortStateValue: true,
            },
            children: {
              EventVenueFlags: {
                hideSendAlternativeInformationForRSVPs: true,
                hideDisabledAccess: false,
                messages: {
                  hideToggleLabel: 'Hide Location on the Event Page',
                },
              },
              EventVenueSearch: {
                behavior: {
                  useAreaShortName: true,
                },
              },
            },
          },
          EventDescription: {
            appearance: {
              showDescriptionLanguageField: false,
              showShortDescriptionField: true,
            },
          },
          EventImages: {
            appearance: {
              showDescriptionLanguageField: false,
              showBackgroundURLField: true,
              showAutoBackgroundField: false,
              showImageAsEventNameSection: false,
              showUseAutomaticallyGeneratedBackgroundToggle: false,
              showUsetransparentLogoInsteadOfEventNameToggle: false,
            },
            messages: {
              imageURLsLabel: 'Square image',
              backgroundURL: 'Banner image',
            },
          },
          EventType: {
            // NOTE: this component must be refactored
            behavior: {
              idString: 'eventCategory_',
              fieldId: 'eventCategory',
              useEventCategoriesArray: true,
              useLabelAsId: true,
            },
            appearance: {
              togglesContainerClassNames: ['rn-event-genres', 'rn-event-categories'],
            },
          },
          EventSettings: {
            appearance: {
              showEventSettingsTogglesSection: false,
              showEventSettingsSection: true,
              showRequireNTFs: false,
              showRefundsAllowed: false,
              showFreeCarerTicketForDisabledGuests: false,
            },
          },
          EventSocialMedia: {
            appearance: {
              showTwitterHashtagField: false,
              showRsvpUrlField: true,
              showExternalUrlField: true,
            },
            messages: {
              cardTitle: 'Additional Information',
              facebookEventFieldLabel: 'Facebook URL',
            },
          },
          EventTags: {
            messages: {
              cardTitle: 'Artists',
              tagsFieldPlaceholder: 'Add an Artist',
            },
          },
        },
      },
      AlternativeViewForm: {
        children: {
          AlternativeViewEventVenue: {
            messages: {
              hideToggleLabel: 'Hide Location on the Event Page',
            },
          },
        },
      },
    },
  },
  '/event/:id/musicad/:audienceItemId': {
    id: 'NewAdPage',
    messages: {
      documentTitle: 'New Facebook AD - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/musicstreamingad/:audienceItemId': {
    id: 'NewAdPage',
    messages: {
      documentTitle: 'New Facebook AD - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/likesad/:audienceItemId': {
    id: 'NewAdPage',
    messages: {
      documentTitle: 'New Facebook AD - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/account': {
    id: 'AccountSettingsPage',
    messages: {
      documentTitle: 'Account Settings - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/team-invitations': {
    id: 'ConfirmTeamInvitationPage',
    messages: {
      documentTitle: 'Team Invitation - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/signin': {
    id: 'LoginPage',
    messages: {
      documentTitle: 'Log In - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showBackgroundVideo: true,
    },
  },
  '/reset-password': {
    id: 'ResetPasswordPage',
    messages: {
      documentTitle: 'Reset Password - Mana Common Ticketing – Powered by TheTicketFairy',
      copyright: 'Mana Common',
    },
    assets: {
      logo: '/resources/images/manacommon/MANA_COMMON_DARK_BACKGROUND.svg',
    },
  },
  '/sign-up': {
    id: 'SignUpPage',
    messages: {
      documentTitle: 'Sign Up - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/brand/:id/demographics': {
    id: 'BrandAudienceDemoGraphics',
    messages: {
      documentTitle: 'Demographics - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
    },
  },
  '/brand/:id/music': {
    id: 'BrandAudienceMusic',
    messages: {
      documentTitle: 'Music - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: false,
      isVisibleForTtfSupport: false,
      isVisibleForAdminOrOwner: false,
    },
  },
  '/brand/:id/musicstreaming': {
    id: 'BrandAudienceMusicStreaming',
    messages: {
      documentTitle: 'Music Streaming - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
    },
  },
  '/brand/:id/details': {
    id: 'BrandDetails',
    messages: {
      documentTitle: 'Details - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    children: {
      BrandForm: {
        appearance: {
          hideShopify: true,
          showAdvancedOptions: false,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: true,
          },
          PaymentMethod: {
            displaySection: false,
            appearance: {
              showStripeConnectedAccount: false,
            },
          },
          BrandNameDetails: {
            appearance: {
              showDescriptionBlock: true,
            },
            behavior: {
              baseURL: `${ENV.MC_CONSUMER}/`,
            },
            children: {
              AutoSlugField: {
                behavior: {
                  separator: '',
                },
              },
            },
          },
          BrandImages: {
            children: {
              behavior: {
                fileUploaderId: 'squareImage',
              },
              messages: {
                fileUploaderLabel: 'Square Image',
              },
            },
          },
        },
      },
    },
  },
  '/events': {
    id: 'EventList',
    messages: {
      documentTitle: 'Events - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      hideDeleteButton: false,
      hideOnlineButton: true,
    },
    behavior: {
      skipIDVerificationStep: false,
    },
  },
  '/brand/:id/*': {
    id: 'BrandAllTabs',
    appearance: {
      showStreamingTab: false,
      showDemographicTab: false,
    },
  },
  '/brand/:id/likes': {
    id: 'BrandLikes',
    messages: {
      documentTitle: 'Likes - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/brand/:id/payments': {
    id: 'BrandPayments',
    messages: {
      documentTitle: 'Payments - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/brands': {
    id: 'BrandsPage',
    messages: {
      documentTitle: 'Brands - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/brands/new': {
    id: 'NewBrandPage',
    messages: {
      documentTitle: 'New Brand - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    children: {
      BrandForm: {
        appearance: {
          showAdvancedOptions: false,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: true,
          },
          PaymentMethod: {
            displaySection: false,
            appearance: {
              showStripeConnectedAccount: false,
            },
          },
          BrandNameDetails: {
            appearance: {
              showDescriptionBlock: true,
            },
            behavior: {
              baseURL: `${ENV.MC_CONSUMER}/`,
            },
            children: {
              AutoSlugField: {
                behavior: {
                  separator: '',
                },
              },
            },
          },
          BrandImages: {
            children: {
              behavior: {
                fileUploaderId: 'squareImage',
              },
              messages: {
                fileUploaderLabel: 'Square Image',
              },
            },
          },
        },
      },
    },
  },
  '/brand/:id/sub-brands/new': {
    id: 'NewSubBrandPage',
    messages: {
      documentTitle: 'New Sub Brand - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    children: {
      BrandForm: {
        appearance: {
          showAdvancedOptions: false,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: true,
          },
          PaymentMethod: {
            displaySection: false,
            appearance: {
              showStripeConnectedAccount: false,
            },
          },
          BrandNameDetails: {
            appearance: {
              showDescriptionBlock: true,
            },
            behavior: {
              baseURL: `${ENV.MC_CONSUMER}/`,
            },
            children: {
              AutoSlugField: {
                behavior: {
                  separator: '',
                },
              },
            },
          },
          BrandImages: {
            children: {
              behavior: {
                fileUploaderId: 'squareImage',
              },
              messages: {
                fileUploaderLabel: 'Square Image',
              },
            },
          },
        },
      },
    },
  },
  '/brand/:id/team': {
    id: 'BrandTeam',
    messages: {
      documentTitle: 'Team - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/brand/:id/messaging': {
    id: 'BrandMessaging',
    messages: {
      documentTitle: 'Messaging - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showMailchimpSection: false,
    },
  },
  '/brand/:id/email-templates': {
    id: 'EmailTemplates',
    messages: {
      documentTitle: 'Email Templates - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    children: {
      NewEmailTemplateForm: {
        appearance: {
          showUploadZippedContent: false,
        },
      },
      EditEmailTemplateForm: {
        appearance: {
          showUploadZippedContent: false,
        },
      },
    },
  },
  '/event/:id/demographics': {
    id: 'EventAudienceDemographics',
    messages: {
      documentTitle: 'Demographics - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: true,
      isVisibleForStatsOrLimitedStats: true,
    },
  },
  '/event/:id/music': {
    id: 'EventAudienceMusic',
    messages: {
      documentTitle: 'Music - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: true,
      isVisibleForStatsOrLimitedStats: true,
    },
  },
  '/event/:id/musicstreaming': {
    id: 'EventAudienceMusicStreaming',
    messages: {
      documentTitle: 'Music Streaming - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
      isVisibleForStatsOrLimitedStats: false,
    },
  },
  '/event/:id/psychographics': {
    id: 'EventAudiencePsychographics',
    messages: {
      documentTitle: 'Psychographics - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
      isVisibleForStatsOrLimitedStats: false,
    },
  },
  '/event/:id/budgets': {
    id: 'EventBudgetManagement',
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
    },
    messages: {
      documentTitle: 'Budget Management - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedHeader: '',
      permissionDeniedSubHeader: "You don't have access to this section.",
    },
  },
  '/event/:id/credentials': {
    id: 'EventCredentials',
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
    },
    messages: {
      documentTitle: 'Credentials - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedHeader: '',
      permissionDeniedSubHeader: "You don't have access to this section.",
    },
  },
  '/event/:id/devices': {
    id: 'EventDevices',
    messages: {
      documentTitle: 'Devices - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
      isVisibleForStatsOrLimitedStats: false,
    },
  },
  '/event/:id/faq': {
    id: 'EventFAQ',
    messages: {
      documentTitle: 'FAQs - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section.",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
    },
  },
  '/event/:id/gaming': {
    id: 'EventGaming',
    messages: {
      documentTitle: 'Gaming - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
      isVisibleForStatsOrLimitedStats: false,
    },
  },
  '/event/:id/gift-certificates-management': {
    id: 'EventGiftCertificatesMangement',
    messages: {
      documentTitle: 'Gift Certificate - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/guest-tickets': {
    id: 'EventGuestTickets',
    messages: {
      documentTitle: 'Guest Tickets - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showGuestTicketDelegation: false,
    },
  },
  '/event/:id/influencers': {
    id: 'EventInfluencers',
    messages: {
      documentTitle: 'Influencers - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/inventory': {
    id: 'EventInventory',
    messages: {
      documentTitle: 'Inventory - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/invitations': {
    id: 'EventInvitation',
    messages: {
      documentTitle: 'Invitations - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/likes': {
    id: 'EventLikes',
    messages: {
      documentTitle: 'Likes - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/messaging': {
    id: 'EventMessaging',
    messages: {
      documentTitle: 'Messaging - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showMailchimpSection: false,
    },
    children: {
      EmailToTicketHolders: {
        appearance: {
          hideRSVPText: true,
          hideAllPreregisteredCustomers: true,
          hideHoldersOfRefundedTickets: true,
          hideHoldersOfSlidingScaleTickets: true,
          hideHoldersOfSpecificAddOns: true,
        },
      },
    },
  },
  '/event/:id/messaging-hidden': {
    id: 'EventMessaging',
    messages: {
      documentTitle: 'Messaging - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/orders': {
    id: 'EventOrders',
    messages: {
      documentTitle: 'Orders - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showSoldOrders: false,
      showPendingPaymentPlanOrders: false,
    },
  },
  '/event/:id/performance/:tabId?': {
    id: 'EventPerformance',
    messages: {
      documentTitle: '{titlePrefix} - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showPaymentPlans: false,
      showAddOns: false,
      showPreregistration: false,
      showBoxOfficeSales: false,
      showRSVP: false,
    },
  },
  '/event/:id/promotions': {
    id: 'EventPromotion',
    messages: {
      documentTitle: 'Promotions - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/contact': {
    id: 'EventSupport',
    messages: {
      documentTitle: 'Contacts - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/talent': {
    id: 'EventTalent',
    messages: {
      documentTitle: 'Talent - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
    },
  },
  '/event/:id/team': {
    id: 'EventTeam',
    messages: {
      documentTitle: 'Team - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/tickets': {
    id: 'EventTickets',
    messages: {
      documentTitle: 'Tickets - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showTables: false,
      showAdvancedOptions: false,
      showAddOnsSection: false,
    },
    children: {
      TicketForm: {
        children: {
          CheckBoxesRow: {
            appearance: {
              hideRequireOwnershipOfNFTsToRegisterForThisTicketTypeToggle: true,
            },
          },
        },
      },
      TicketOptionsForm: {
        appearance: {
          hideWaitingListAfterAddOnSellOutToggle: true,
          hideAllowCustomersToInitiateRefundsToggle: true,
          hideTicketsAreBeingSoldOnADifferentTicketingPlatformToggle: true,
          hideAddOnResaleSystemEnabledToggle: true,
          showRequireBuyerToEnterTheirPhoneNumberToggle: true,
        },
      },
      SalesTracking: {
        children: {
          TrackingForm: {
            appearance: {
              showAddOnIdsField: false,
            },
          },
        },
      },
    },
  },
  '/events/new': {
    id: 'EventNew',
    messages: {
      documentTitle: 'New Event - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    children: {
      BrandForm: {
        appearance: {
          showAdvancedOptions: false,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: true,
          },
          PaymentMethod: {
            displaySection: false,
            appearance: {
              showStripeConnectedAccount: false,
            },
          },
          BrandNameDetails: {
            appearance: {
              showDescriptionBlock: true,
            },
            behavior: {
              baseURL: `${ENV.MC_CONSUMER}/`,
            },
            children: {
              AutoSlugField: {
                behavior: {
                  separator: '',
                },
              },
            },
          },
          BrandImages: {
            children: {
              behavior: {
                fileUploaderId: 'squareImage',
              },
              messages: {
                fileUploaderLabel: 'Square Image',
              },
            },
          },
        },
      },
      EventForm: {
        appearance: {
          showEventPayoutDetails: true,
          showOpenGraphImages: false,
        },
        children: {
          EventImages: {
            appearance: {
              showUseAutomaticallyGeneratedBackgroundToggle: false,
              showUsetransparentLogoInsteadOfEventNameToggle: false,
            },
          },
          EventSettings: {
            appearance: {
              showEventSettingsTogglesSection: false,
              showEventSettingsSection: true,
              showRequireNTFs: false,
              showRefundsAllowed: false,
              showFreeCarerTicketForDisabledGuests: false,
            },
          },
          EventVenue: {
            children: {
              EventVenueFlags: {
                hideSendAlternativeInformationForRSVPs: true,
                hideDisabledAccess: false,
                messages: {
                  hideToggleLabel: 'Hide Location on the Event Page',
                },
              },
            },
          },
        },
      },
    },
  },
  '/events/new-online': {
    id: 'EventNewOnline',
    messages: {
      documentTitle: 'New Online Event - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
    },
    children: {
      EventForm: {
        children: {
          OnlineEventImages: {
            appearance: {
              showUseAutomaticallyGeneratedBackgroundToggle: false,
              showUsetransparentLogoInsteadOfEventNameToggle: false,
            },
          },
        },
      },
      BrandForm: {
        appearance: {
          showAdvancedOptions: false,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: true,
          },
          PaymentMethod: {
            displaySection: false,
            appearance: {
              showStripeConnectedAccount: false,
            },
          },
          BrandNameDetails: {
            appearance: {
              showDescriptionBlock: true,
            },
            behavior: {
              baseURL: `${ENV.MC_CONSUMER}/`,
            },
            children: {
              AutoSlugField: {
                behavior: {
                  separator: '',
                },
              },
            },
          },
          BrandImages: {
            children: {
              behavior: {
                fileUploaderId: 'squareImage',
              },
              messages: {
                fileUploaderLabel: 'Square Image',
              },
            },
          },
        },
      },
    },
  },
  '/event/:id/checkin': {
    id: 'EventCheckIn',
    messages: {
      documentTitle: 'Check-In - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/draft-events': {
    id: 'DraftEventList',
    messages: {
      documentTitle: 'Draft Events - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/geographics': {
    id: 'EventGeographics',
    messages: {
      documentTitle: 'Geographics - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: true,
      isVisibleForStatsOrLimitedStats: true,
    },
  },
  '/event/:id/email-templates': {
    id: 'EmailTemplates',
    messages: {
      documentTitle: 'Email Templates - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    children: {
      NewEmailTemplateForm: {
        appearance: {
          showUploadZippedContent: false,
        },
      },
      EditEmailTemplateForm: {
        appearance: {
          showUploadZippedContent: false,
        },
      },
    },
  },
  '/event/:id/pre-registration': {
    id: 'PreRegistration',
    messages: {
      documentTitle: 'Pre-Registration - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
    },
  },
  '/tours/new': {
    id: 'NewTourPage',
    messages: {
      documentTitle: 'New Tour - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/tour/:id/details': {
    id: 'TourDetails',
    messages: {
      documentTitle: 'Details - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      hideShopify: true,
    },
  },
  '/tours': {
    id: 'ToursPage',
    messages: {
      documentTitle: 'Tours - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/tour/:id/performance': {
    id: 'TourPerformance',
    messages: {
      documentTitle: 'Performance - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/tour/:id/team': {
    id: 'TourTeam',
    messages: {
      documentTitle: 'Team - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/brand/:id/events': {
    id: 'BrandEventList',
    messages: {
      documentTitle: 'Brand Events - Mana Common Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      hideDeleteButton: false,
      hideOnlineButton: true,
    },
    behavior: {
      skipIDVerificationStep: false,
    },
  },
  '/users': {
    id: 'Users',
    messages: {
      documentTitle: 'Users - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/multi-entry-passes': {
    id: 'EventMultiEntryPasses',
    messages: {
      documentTitle: 'Multi-Entry Passes - {displayName} - Mana Common Ticketing – Powered by TheTicketFairy',
    },
  },
  '/support': {
    appearance: {},
  },
}
