import React from 'react'
import Modal from 'react-modal'

import modalStyle from '../../../_common/core/modalStyle'
import AddOnForm from '../addons/AddOnForm'

const AddOnModal = props => {
  const { event, tickets, isEditing, initialValues, handleSubmitEditAddon, handleCancelAddon, tables } = props

  return (
    <Modal
      className="modal-dialog modal-trans"
      style={modalStyle}
      isOpen={isEditing}
      contentLabel="Modal"
      onRequestClose={handleCancelAddon}
      closeTimeoutMS={150}
      ariaHideApp={false}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div>Edit Add-On</div>
          </div>
          <AddOnForm
            event={event}
            tables={tables}
            tickets={tickets}
            isEditing={isEditing}
            initialValues={initialValues}
            handleSubmit={handleSubmitEditAddon}
            onCancel={handleCancelAddon}
          />
        </div>
      </div>
    </Modal>
  )
}

export default AddOnModal