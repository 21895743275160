/* eslint-disable react/no-multi-comp */
// Re-render Optimization 13.05.2020
import React from 'react'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Slider from 'react-slick'

import {
  MenuItem,
  EVENT_STATISTICS,
  EVENT_EDIT,
  EVENT_INTERACT,
  EVENT_SUPPORT,
  FUNC_CHECKPAGE
} from './_Library.jsx'
import EventPublish from '../events/_library/EventPublish'
import { HEADER_ITEM_COUNT } from '../constants/header'

import { get_event } from '../../_common/core/selectors'
import {
  getEventEditMenuItems,
  getEventInteractMenuItems,
  getEventSupportMenuItems,
  getEventStatisticsMenuItems,
  getPageInfoData,
  getBrandMenuItems,
  getTourMenuItems
} from './utils/index'

@withRouter
@connect((state, props) => {
  const dynamicConfigs = state.launchDarklyInfo.launchDarklyFlags
  const event = get_event(state)
  const brand = state.brands.selected
  const tour = state.tours.selected
  const path = _get(props, 'location.pathname')
  const { isRnDomain, isMcDomain } = state.shared
  const { user } = state.auth

  return {
    dynamicConfigs,
    event,
    brand,
    tour,
    path,
    isRnDomain,
    isMcDomain,
    user
  }
}, {})
class HeaderNavigation extends React.PureComponent {
  constructor(props) {
    super(props)
    this.activeSlideNumber = -1
    this.previousSlideNumber = 0
    this.totalSlideNumber = 0
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  componentWillReceiveProps(nextProps) {
    this.autoSlide()
  }

  updateDimensions = () => {
    this.autoSlide()
  }

  getMenuGroup() {
    const { user, event, path, brand, tour, dynamicConfigs, isRnDomain, isMcDomain, configFile, routePath } =
      this.props

    const userTfRole = _get(user, 'tfRole')
    const permission = _get(event, 'self.role', '') || null
    const enablePromotions = _get(event, 'enablePromotions') || false
    const enableGiftCertificates = _get(event, 'flagGiftCertificatesEnabled') || false
    const flagDisableReferral = _get(event, 'flagDisableReferral') || false
    const flagTimeSlotsEnabled = _get(event, 'flagTimeSlotsEnabled') || false
    const showEventCredentials = _get(dynamicConfigs, 'show_event_credentials') || false
    const flagMultiEntryPasses = _get(event, 'multientryPass') || false
    const flagRequireEthereumWallet = _get(event, 'flagRequireEthereumWallet') || false
    const flagMultiEntryPassesBrand = _get(brand, 'allowMultipassEntry', false)

    const routePathArr = routePath.split('/')
    const toBeSpliced = routePathArr.length - 3
    routePathArr.splice(routePathArr.length - toBeSpliced, toBeSpliced, '*')
    const configs = _get(configFile, routePathArr.join('/'))

    const pageInfoData = getPageInfoData({ path, event, brand, tour })
    const { itemId, page, isEventPage, isBrandPage, isTourPage } = pageInfoData

    let menuGroup = []
    if (isEventPage && page !== '') {
      if (FUNC_CHECKPAGE(EVENT_STATISTICS, `|${page}|`)) {
        menuGroup = getEventStatisticsMenuItems({
          itemId,
          permission,
          path,
          userTfRole,
          flagDisableReferral,
          configs
        })
      } else if (FUNC_CHECKPAGE(EVENT_EDIT, `|${page}|`)) {
        menuGroup = getEventEditMenuItems({
          itemId,
          permission,
          path,
          dynamicConfigs,
          enableGiftCertificates,
          userTfRole,
          showEventCredentials,
          flagMultiEntryPasses,
          flagRequireEthereumWallet,
          configs
        })
      } else if (FUNC_CHECKPAGE(EVENT_INTERACT, `|${page}|`)) {
        menuGroup = getEventInteractMenuItems({
          itemId,
          permission,
          path,
          enablePromotions,
          enableGiftCertificates,
          flagTimeSlotsEnabled,
          userTfRole,
          flagMultiEntryPasses
        })
      } else if (FUNC_CHECKPAGE(EVENT_SUPPORT, `|${page}|`)) {
        menuGroup = getEventSupportMenuItems({
          itemId,
          permission,
          path,
          isRnDomain,
          isMcDomain,
          userTfRole,
          configs
        })
      }
    }
    if (isBrandPage && page !== '') {
      menuGroup = getBrandMenuItems({ path, itemId, flagMultiEntryPassesBrand, userTfRole, configs })
    }
    if (isTourPage && page !== '') {
      menuGroup = getTourMenuItems({ path, itemId })
    }

    this.totalSlideNumber = _filter(menuGroup, menu => menu.visible).length
    return menuGroup
  }

  getVisibleSlideCount = () => {
    const width = window.innerWidth
    if (width < 376) return 2
    else if (width < 641) return 2
    else if (width < 769) return 3
    else if (width < 961) return 4
    else if (width < 1025) return 5
    else if (width < 1121) return 6
    else if (width < 1201) return 7
    else if (width < 1401) return 9
    else if (width < 10001) return 11
    return 0
  }

  autoSlide() {
    if (this.activeSlideNumber !== -1 && this.refs.slider) {
      setTimeout(() => {
        const visibleCount = this.getVisibleSlideCount()
        const old = this.previousSlideNumber
        if (this.previousSlideNumber > this.activeSlideNumber)
          this.previousSlideNumber = this.activeSlideNumber
        if (this.previousSlideNumber + visibleCount <= this.activeSlideNumber)
          this.previousSlideNumber = this.activeSlideNumber - visibleCount + 1
        if (
          this.previousSlideNumber >= this.totalSlideNumber - visibleCount &&
          this.totalSlideNumber >= visibleCount
        )
          this.previousSlideNumber = this.totalSlideNumber - visibleCount
        if (old !== this.previousSlideNumber && this.refs.slider) {
          // save current position and move to it
          this.refs.slider.slickGoTo(this.previousSlideNumber)
        }
      }, 500)
    } else {
      this.previousSlideNumber = 0
      this.activeSlideNumber = -1
    }
  }

  onClickSlide = slideNumber => {
    this.previousSlideNumber = slideNumber
  }

  render() {
    const { isRnDomain } = this.props
    const menuGroup = this.getMenuGroup()
    let headerBar = _map(menuGroup, (menu, index) => {
      if (menu.active) {
        this.activeSlideNumber = index
      }
      return menu.visible ? (
        <div key={index} className="header-bar-menuitem">
          <MenuItem
            title={menu.title}
            img={menu.img}
            href={menu.href}
            active={menu.active}
            visible={menu.visible}
          />
        </div>
      ) : null
    })

    if (!menuGroup.length) {
      headerBar = <div />
    }

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      draggable: false,
      swipeToSlide: false,
      slidesToShow: this.getVisibleSlideCount(),
      afterChange: this.onClickSlide
    }

    return (
      <div
        className={`header-bar-center ${
          isRnDomain
            ? headerBar.length >= HEADER_ITEM_COUNT
              ? 'header-bar-long-center-rn'
              : 'header-bar-center-rn'
            : ''
        }`}
      >
        <div className="header-bar-slide">
          {!!menuGroup.length && (
            <Slider ref="slider" {...settings}>
              {headerBar}
            </Slider>
          )}
        </div>
      </div>
    )
  }
}

export default React.memo(props => {
  const { hideMenuButton, enableHomeButton, configFile, routePath } = props

  const handleSidebarStateChange = e => {
    e.stopPropagation()
    const bodyEl = $('body')
    if (bodyEl.hasClass('sidebar-open')) {
      bodyEl.removeClass('sidebar-open')
    } else {
      bodyEl.addClass('sidebar-open')
    }
  }
  const res_logo = _get(configFile, '*.appearance.classes.header', '')
  const logo = _get(configFile, '*.appearance.assets.headerLogo', '/resources/images/ttf-logo.png')
  const logoLabel = _get(configFile, '*.appearance.classes.logoLabel', 'ttf-powered-text')

  return (
    <div className="header-bar">
      <div className="header-bar-left">
        <Link to="/">
          <div className={`res_logo ${!enableHomeButton ? 'disabled' : ''}${res_logo} `}>
            <img alt="nodata" src={asset(logo)} />
            <span className={logoLabel}>
              POWERED BY THE<strong>TICKET</strong>FAIRY
            </span>
          </div>
        </Link>

        {!hideMenuButton && (
          <div aria-hidden={true} onClick={handleSidebarStateChange} className="sidebar-btn">
            <i className="fa fa-bars" />
          </div>
        )}
      </div>
      <HeaderNavigation configFile={configFile} routePath={routePath} />
      <div className="header-bar-right">
        <EventPublish />
      </div>
    </div>
  )
})
