import { createAsyncAction } from '../actions'
import { createAddOn, getAddOns, updateAddOn, updateAddOnsSorting } from '../../core/http_services/addons'
import { showAxiosError } from '../../../web/utils/messenger'


const { FETCH_EVENT_ADDONS } = createAsyncAction('FETCH_EVENT_ADDONS', function (eventId) {
  return async dispatch => {
    try {
      const res = await getAddOns(eventId)
      dispatch(this.success({ addons: res, eventId }))
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { CREATE_ADDON } = createAsyncAction('CREATE_ADDON', function (eventId, form) {
  return async dispatch => {
    try {
      const res = await createAddOn(eventId, form)
      dispatch(this.success({ res, eventId }))
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { UPDATE_ADDON } = createAsyncAction('UPDATE_ADDON', function (addonId, form, eventId) {
  return async dispatch => {
    try {
      const updateData = {
        attributes: form,
        id: addonId,
        type: "add_on"
      }

      const res = await updateAddOn(addonId, updateData)
      dispatch(this.success({ res, eventId }))
      return res
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { UPDATE_ADDONS_SORTING } = createAsyncAction('UPDATE_ADDONS_SORTING', function (eventId, sortingAddons, addons) {
  return async dispatch => {
    try {
      await updateAddOnsSorting(eventId, sortingAddons)
      dispatch(this.success({ eventId, addons }))
    } catch (err) {
      showAxiosError(err)
    }
  }
})

export {
  FETCH_EVENT_ADDONS,
  CREATE_ADDON,
  UPDATE_ADDON,
  UPDATE_ADDONS_SORTING
}
