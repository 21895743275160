import React, { useState, useEffect } from 'react'
import modalStyle from '../../_common/core/modalStyle'
import Modal from 'react-modal'
import EventSwitcher from './_library/EventSwitcher'
import Button from '../_library/Button'
import Card from '../_library/Card'
import LoadingBar from '../_library/LoadingBar'
import { getEvent } from '../../_common/core/http_services'
import EventUrlRow from './form/EventForm/EventUrlRow'
import { validateDupEvent } from './form/EventForm/validation'
import EventNameRow from './form/EventForm/EventNameRow'
import { withFormik, Field } from 'formik'
import { CheckboxField } from '../formik/Fields/CheckboxField'

const toggles = [
  { fieldName: 'team', title: 'Team', fields: [], collapsible: false },
  {
    fieldName: 'credentials',
    title: 'Credentials',
    fields: [],
    collapsible: false
  },
  {
    fieldName: 'preRegistration',
    title: 'Pre-Registration',
    fields: ['Pre-Registration Settings', 'Pre-Registration Actions', 'Pre-Registration Prizes'],
    collapsible: true
  },
  {
    fieldName: 'promotions',
    title: 'Promotions',
    fields: ['Promotion Settings', 'Promotion Codes'],
    collapsible: true
  },
  {
    fieldName: 'emailTemplates',
    title: 'Email Templates',
    fields: [],
    collapsible: false
  },
  {
    fieldName: 'contacts',
    title: 'Contacts',
    fields: [],
    collapsible: false
  },
  {
    fieldName: 'faqs',
    title: 'FAQs',
    fields: [],
    collapsible: false
  }
]

const eventDetails = [
  'Event Details',
  'Venue Details',
  'Description & Media',
  'Event Settings',
  'Ticket Settings',
  'Ticket Types',
  'External/3rd-Party Sales Tracking',
  'Advanced Options'
]

const EventDuplicateForm = props => {
  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    showDuplicateEventModal,
    handleCloseDuplicateEvent,
    selectedEvent,
    events,
    isRnDomain,
    resetForm,
    switchEvent
  } = props

  const [isSlotsEnabled, setIsSlotsEnabled] = useState(false)

  useEffect(() => {
    if (selectedEvent) {
      async function fetchEvent() {
        const event = await getEvent(selectedEvent.id)
        const timeSlotsEnabled = event.flagTimeSlotsEnabled
        setIsSlotsEnabled(timeSlotsEnabled)
      }
      fetchEvent()
    }
  }, [selectedEvent])

  useEffect(() => {
    document.addEventListener('duplicate-error', handleCancelButton)
    return () => {
      document.removeEventListener('duplicate-error', handleCancelButton)
    }
  }, [])

  const handleCancelButton = () => {
    resetForm()
    handleCloseDuplicateEvent()
  }

  return (
    <Modal
      className="event-details modal-trans duplicate-event"
      style={modalStyle}
      isOpen={showDuplicateEventModal}
      contentLabel="Modal"
      onRequestClose={handleCancelButton}
      closeTimeoutMS={150}
      ariaHideApp={false}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="duplicate-event-header">Duplicate Event</div>
          <div className="row duplicate-event-subheader">
            <div className="col-xs-6 col-12 text-holders event-description">
              <p className="duplicate-event-text">
                The following information will be copied to a new event, after which you will be able to edit
                that information before publishing it.
              </p>
            </div>
            <div className="col-xs-6 col-12 text-holders event-switcher">
              <div className="body-panel-header">
                <div className="left">
                  <div className="description">
                    <EventSwitcher
                      currentEvent={selectedEvent}
                      events={events}
                      fullReady={true}
                      active={true}
                      onClickEvent={e => switchEvent(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form autoComplete="off" method="POST" onSubmit={handleSubmit}>
            <div className="modal-body">
              {isSubmitting ? (
                <LoadingBar key="loadingbar" title={"Hold tight! We're duplicating your event..."} />
              ) : (
                <>
                  <div className="duplicate-event-fields">
                    <div className="text-holders">
                      <EventNameRow
                        isCurator={false}
                        values={values}
                        errors={errors}
                        touched={touched}
                        updateField={setFieldValue}
                        isRnDomain={isRnDomain}
                      />
                      <EventUrlRow
                        isNew={true}
                        values={values}
                        errors={errors}
                        touched={touched}
                        updateField={setFieldValue}
                        isRnDomain={isRnDomain}
                      />
                    </div>
                  </div>
                  <Card
                    title={<span className="duplicate-event-title">Event Details</span>}
                    className="create-event"
                  >
                    {eventDetails.map((field, index) => (
                      <div key={index} className="duplicate-event-checkbox-container">
                        <label className="duplicate-event-checkbox-label">
                          {field}
                          {field === 'Ticket Types' && isSlotsEnabled && (
                            <>
                              <Field
                                field={{ fieldName: 'ticketTypes' }}
                                squareCheckbox={true}
                                component={CheckboxField}
                                checked={props.values.ticketTypes}
                              />
                            </>
                          )}
                        </label>
                      </div>
                    ))}
                  </Card>
                  {toggles.map(toggle => {
                    if (toggle.collapsible)
                      return (
                        <Card
                          key={toggle.fieldName}
                          closed={true}
                          title={
                            <>
                              <Field field={toggle} squareCheckbox={true} component={CheckboxField} />
                            </>
                          }
                          className="create-event"
                        >
                          {toggle.fields.map(field => (
                            <div key={field} className="duplicate-event-checkbox-container">
                              <label className="duplicate-event-checkbox-label">{field}</label>
                            </div>
                          ))}
                        </Card>
                      )
                    return (
                      <div className="Collapsible card create-event" key={toggle.fieldName}>
                        <div className="card-title card-unchanged ">
                          <Field field={toggle} squareCheckbox={true} component={CheckboxField} />
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
            <div className="modal-footer">
              <div className="btn-toolbar btn-toolbar-right">
                <Button
                  className="btn btn-cancel"
                  type="button"
                  onClick={handleCancelButton}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button className="btn btn-success btn-shadow" type="submit" disabled={isSubmitting}>
                  Duplicate Event
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

const duplicateEventsForm = withFormik({
  mapPropsToValues: props => ({
    displayName: '',
    shortName: '',
    slug: '',
    team: false,
    credentials: false,
    preRegistration: false,
    promotions: false,
    emailTemplates: false,
    contacts: false,
    faqs: false,
    ticketTypes: true,
    isRnDomain: props.isRnDomain
  }),

  // Custom validation
  validate: values => validateDupEvent(values),

  handleSubmit: (values, { props, setSubmitting }) => {
    const customFields = {
      isRnDomain: props.isRnDomain
    }
    const updatedValues = { ...values, ...customFields }
    const eventId = props.selectedEvent.id
    props
      .onSubmit(eventId, updatedValues)
      ?.then(v => {
        setSubmitting(false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'duplicateEventsForm'
})(EventDuplicateForm)

export default duplicateEventsForm
