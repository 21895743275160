import React, { Component } from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'

import { DisableSectionByPermissionWrapper } from '../../hoc'

import Card from '../../_library/Card'
import LoadingBar from '../../_library/LoadingBar'
import CredentialsTable from './CredentialsTable'
import Button from '../../_library/Button'
import CredentialModal from './CredentialModal'

import {
  FETCH_CREDENTIALS,
  CREATE_CREDENTIALS,
  UPDATE_CREDENTIALS,
  ACTIVATE_DEACTIVATE_CREDENTIALS,
  DELETE_CREDENTIALS
} from '../../../_common/redux/credentials/actions'

import { FETCH_EVENT_TICKETS } from '../../../_common/redux/tickets/actions'

import { FETCH_EVENT_ADDONS } from '../../../_common/redux/addons/actions'
import PermissionDenied from '../../_library/PermissionDeniedComponent'

import {
  get_event,
  get_event_tickets,
  get_event_addons,
  get_credentials_data
} from '../../../_common/core/selectors'

@connect(
  state => {
    const event = get_event(state)
    const ticketTypes = get_event_tickets(state)
    const ticketAddOns = get_event_addons(state)
    const dynamicConfigs = state.launchDarklyInfo.launchDarklyFlags

    return {
      event,
      credentialsData: get_credentials_data(state),
      credenialsChecked: state.credentials.credentialsData.checked,
      credentialsLoading:
        state.loading.has('FETCH_CREDENTIALS') ||
        state.loading.has('FETCH_CREDENTIALS_RULES') ||
        state.loading.has('FETCH_EVENT_TICKETS') ||
        state.loading.has('FETCH_EVENT_ADDONS') ||
        state.loading.has('FETCH_LAUNCH_DARKLY_INFO_REQUEST'),
      ticketAddOns,
      ticketTypes,
      dynamicConfigs
    }
  },
  {
    FETCH_CREDENTIALS,
    CREATE_CREDENTIALS,
    UPDATE_CREDENTIALS,
    ACTIVATE_DEACTIVATE_CREDENTIALS,
    DELETE_CREDENTIALS,
    FETCH_EVENT_TICKETS,
    FETCH_EVENT_ADDONS
  }
)
class Credentials extends Component {
  constructor(props) {
    super(props)
    this.handleActivateDeactivate = this.handleActivateDeactivate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleCredentialSubmit = this.handleCredentialSubmit.bind(this)
    this.handleCredentialModalVisible = this.handleCredentialModalVisible.bind(this)

    this.state = {
      isOpen: false,
      typesOptions: null,
      addOnsOptions: null,
      //
      formInitialValues: {},
      fromEdit: false,
      idForEdit: null,
      editRuleId: null
    }
  }

  componentDidMount() {
    const { event, FETCH_CREDENTIALS, FETCH_EVENT_TICKETS, FETCH_EVENT_ADDONS } = this.props
    const eventId = event.id

    FETCH_EVENT_TICKETS(eventId)
    FETCH_EVENT_ADDONS(eventId)
    FETCH_CREDENTIALS(eventId)
  }

  componentDidUpdate(prevProps) {
    const { ticketAddOns, ticketTypes, dynamicConfigs } = this.props
    const { typesOptions, addOnsOptions } = this.state

    if (!_get(dynamicConfigs, 'show_event_credentials', false)) {
      return
    }

    if ((!prevProps.ticketAddOns.length && ticketAddOns.length) || !addOnsOptions) {
      const options = this.makeOptionsFromObj(ticketAddOns, 'name')
      this.setState({ addOnsOptions: options })
    }
    if ((!prevProps.ticketTypes.length && ticketTypes.length) || !typesOptions) {
      const options = this.makeOptionsFromObj(ticketTypes, 'name')
      this.setState({ typesOptions: options })
    }
  }

  makeOptionsFromObj(obj, item) {
    const optionsArr = []
    Object.entries(obj).forEach(([key, value]) => {
      optionsArr.push({
        value: value.id,
        label: value[item]
      })
    })
    return optionsArr
  }
  handleActivateDeactivate(id, activateDeactivateParams, allData, callBack) {
    const { ACTIVATE_DEACTIVATE_CREDENTIALS } = this.props
    return ACTIVATE_DEACTIVATE_CREDENTIALS(id, activateDeactivateParams, allData).finally(() => {
      // callBack for close modal
      callBack()
    })
  }
  handleDelete(id, hasRules, data, ruleId, callBack) {
    const { DELETE_CREDENTIALS } = this.props
    return DELETE_CREDENTIALS(id, hasRules, data, ruleId).finally(() => {
      // callBack for close modal
      callBack()
    })
  }
  handleCredentialSubmit(paramData) {
    const { CREATE_CREDENTIALS, UPDATE_CREDENTIALS, credentialsData, event, FETCH_CREDENTIALS } = this.props
    const { fromEdit, idForEdit, editRuleId } = this.state
    const eventId = event.id
    let hasRules = false

    const credParams = {
      attributes: {
        code: paramData.code,
        image: paramData.image,
        name: paramData.name,
        type: paramData.type,
        enabled: paramData.enabled
      },
      relationships: [],
      type: 'event_credentials'
    }
    const ruleParams = {
      attributes: {
        badgeId: idForEdit,
        rule: []
      },
      relationships: [],
      type: 'event_credentials_rules'
    }
    if (paramData.addon.length) {
      ruleParams.attributes.rule.push({
        type: 'addon',
        metaData: paramData.addon
      })
      hasRules = true
    }
    // if(paramData.excludedAddon.length) {
    //     ruleParams.attributes.rule.push({
    //         type: 'excludedAddon',
    //         metaData: paramData.excludedAddon
    //     })
    //     hasRules = true
    // }
    if (paramData.ticketType.length) {
      ruleParams.attributes.rule.push({
        type: 'ticketType',
        metaData: paramData.ticketType
      })
      hasRules = true
    }
    if (fromEdit) {
      credParams.attributes.eventId = eventId
      return UPDATE_CREDENTIALS(
        idForEdit,
        credParams,
        ruleParams,
        hasRules,
        _cloneDeep(credentialsData),
        editRuleId
      )
        .catch(err => {
          throw err
        })
        .then(() => {
          FETCH_CREDENTIALS(eventId)
        })
        .finally(() => {
          this.handleCredentialModalVisible()
        })
    }
    delete credParams.attributes.enabled
    return CREATE_CREDENTIALS(eventId, credParams, ruleParams, hasRules, _cloneDeep(credentialsData)).finally(
      () => {
        this.handleCredentialModalVisible()
      }
    )
  }
  handleCredentialModalVisible(formInitialValues = {}, fromEdit = false, idForEdit = null) {
    const { isOpen } = this.state
    if (formInitialValues.code === null) {
      formInitialValues.code = ''
    }
    this.setState({
      isOpen: !isOpen,
      formInitialValues,
      fromEdit,
      idForEdit,
      editRuleId: formInitialValues.ruleId
    })
  }
  render() {
    const {
      credenialsChecked,
      credentialsLoading,
      credentialsData = [],
      ticketAddOns,
      ticketTypes,
      dynamicConfigs
    } = this.props
    const { isOpen, typesOptions, addOnsOptions, formInitialValues, fromEdit } = this.state
    return credentialsLoading ? (
      <LoadingBar />
    ) : _get(dynamicConfigs, 'show_event_credentials', false) ? (
      <DisableSectionByPermissionWrapper>
        <div className="credentials_page">
          <Button
            className="btn btn-success"
            type="button"
            onClick={() => this.handleCredentialModalVisible()}
          >
            <i className="fa fa-plus fa-fw" />
            Add Credential
          </Button>
          <Card title="Credentials">
            {credentialsLoading && <LoadingBar />}
            {!credentialsLoading && credenialsChecked && (
              <CredentialsTable
                data={credentialsData}
                handleActivateDeactivate={this.handleActivateDeactivate}
                handleDelete={this.handleDelete}
                ticketAddOns={ticketAddOns}
                ticketTypes={ticketTypes}
                handleCredentialModalVisible={this.handleCredentialModalVisible}
              />
            )}
          </Card>
          <CredentialModal
            isOpen={isOpen}
            header={fromEdit ? 'Edit Credential' : 'Create Credential'}
            handleSubmit={this.handleCredentialSubmit}
            handleCancel={this.handleCredentialModalVisible}
            addOnsOptions={addOnsOptions || []}
            ticketTypesOptions={typesOptions || []}
            formInitialValues={formInitialValues}
          />
        </div>
      </DisableSectionByPermissionWrapper>
    ) : (
      <PermissionDenied subHeader={`You don't have access to this feature.`} />
    )
  }
}

export default Credentials
