import React from 'react'
import _map from 'lodash/map'
import _sortBy from 'lodash/sortBy'
import moment from 'moment'
import EmptyBar from '../../_library/EmptyBar'
import { JSONDatatable, DATATABLE, TYPE_FROM_URL } from '../../_library/JSONDatatable'

const FILTER_TYPE_NAME = 'Name'
const FILTER_TYPE_DATE = 'Date'
const AUTO_REFRESH = 10 * 1000


export default class PerformanceBoxOfficeSales extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      filter_type: FILTER_TYPE_NAME
    }
    this.getSortedRows = this.getSortedRows.bind(this)
    this.getTableData = this.getTableData.bind(this)
    this.getClipboardText = this.getClipboardText.bind(this)
  }

  getTableContent(datatable, sort, obj, key, type){
    const { event } = this.props
    const currency = getCurrencySymbol(event)

    let cashes = []; let cards = []
    cashes = obj[key].cash
    cards = obj[key].card

    let card_total_sales = 0; let card_total_revenue = 0.0; let card_total_revenue_incl = 0.0
    let cash_total_sales = 0; let cash_total_revenue = 0.0; let cash_total_revenue_incl = 0.0
    _map(cards, (card, cardIndex)=>{
      const revenue = parseInt(card.num_sales) * (card.cost ? parseFloat(card.cost) : 0) 
      const revenue2 = parseInt(card.num_sales) * (card.price ? parseFloat(card.price) : 0) 
      card_total_sales += parseInt(card.num_sales)
      card_total_revenue += revenue
      card_total_revenue_incl += revenue2
    })

    _map(cashes, (cash, cashIndex)=>{
      const revenue = parseInt(cash.num_sales) * (cash.cost ? parseFloat(cash.cost) : 0) 
      const revenue2 = parseInt(cash.num_sales) * (cash.price ? parseFloat(cash.price) : 0) 
      cash_total_sales += parseInt(cash.num_sales)
      cash_total_revenue += revenue
      cash_total_revenue_incl += revenue2
    })

    const clipboard_text_cards = this.getClipboardText(cards)
    const clipboard_cards = datatable.getClipboardColumn(datatable, clipboard_text_cards)
    const content_header_cards = datatable.getHeaderRow(datatable, [
      { title: 'Ticket Type', sort: false },
      { title: 'No of Sales', sort: false },
      { title: 'Price (incl. Fees)', sort: false },
      { title: 'Revenue (incl. Fees)', sort: false },
      { title: 'Price (excl. Fees)', sort: false },
      { title: 'Revenue (excl. Fees)', sort: false },
      { title: clipboard_cards, sort: false, className: 'column-clipboard' },
    ], sort)
    const clipboard_text_cashes = this.getClipboardText(cashes)
    const clipboard_cashes = datatable.getClipboardColumn(datatable, clipboard_text_cashes)
    const content_header_cashes = datatable.getHeaderRow(datatable, [
      { title: 'Ticket Type', sort: false },
      { title: 'No of Sales', sort: false },
      { title: 'Price (incl. Fees)', sort: false },
      { title: 'Revenue (incl. Fees)', sort: false },
      { title: 'Price (excl. Fees)', sort: false },
      { title: 'Revenue (excl. Fees)', sort: false },
      { title: clipboard_cashes, sort: false, className: 'column-clipboard' },
    ], sort)

    return (
      <div key={key} className='box-office-info dark-box table-responsive'>
        {type == FILTER_TYPE_DATE &&				
					<h4 className="box-office-date">
					  <img src={asset('/resources/images/system_icons/cal.svg')}  className="ico-show"/>
					  {moment(key, 'YYYY-MM-DD').format('D MMM YYYY')}
					</h4>
        }
        {type != FILTER_TYPE_DATE &&				
					<h4 className="box-office-date">
					  <img src={asset('/resources/images/system_icons/user-ico.svg')}  className="ico-show"/>
					  {key}
					</h4>
        }
        <h5 className="box-office-payment">
          <img src={asset('/resources/images/system_icons/credit-card.svg')}/>Card
        </h5>
        <table className="table tickets-table">
          <thead>
            {content_header_cards}
          </thead>
          <tbody>
            {
              _map(cards, (card, cardIndex) => {
                const revenue = parseInt(card.num_sales) * (card.cost ? parseFloat(card.cost) : 0)
                const revenue2 = parseInt(card.num_sales) * (card.price ? parseFloat(card.price) : 0)
                return (
                  <tr key={cardIndex} className={cardIndex % 2== 0 ? 'row-stale' : ''}>
                    <td>{card.release_type}</td>
                    <td>{card.num_sales}</td>
                    <td>{currency}{parseFloat(card.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td>{currency}{parseFloat(revenue2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td>{currency}{parseFloat(card.cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>											
                    <td>{currency}{parseFloat(revenue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td />
                  </tr>
                )
              })
            }
          </tbody>
          <tfoot>
            <tr>
              <td><span className="tickets_counts_total">Total</span></td>
              <td>{card_total_sales}</td>
              <td />
              <td>{currency}{card_total_revenue_incl.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td />								
              <td>{currency}{card_total_revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td />
            </tr>
          </tfoot>
        </table>
        <div className="div-spacing-20"/>
        <h5 className="box-office-payment">
          <img src={asset('/resources/images/system_icons/cash-money.svg')}/>Cash
        </h5>
        <table className="table tickets-table">
          <thead>
            {content_header_cashes}
          </thead>
          <tbody>
            {
              _map(cashes, (cash, cashIndex) => {
                const revenue = parseInt(cash.num_sales) * (cash.cost ? parseFloat(cash.cost) : 0)
                const revenue2 = parseInt(cash.num_sales) * (cash.price ? parseFloat(cash.price) : 0)
                return (
                  <tr key={cashIndex} className={cashIndex % 2== 0 ? 'row-stale' : ''}>
                    <td>{cash.release_type}</td>
                    <td>{cash.num_sales}</td>
                    <td>{currency}{parseFloat(cash.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td>{currency}{parseFloat(revenue2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td>{currency}{parseFloat(cash.cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td>{currency}{parseFloat(revenue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td />
                  </tr>
                )
              })
            }
          </tbody>
          <tfoot>
            <tr>
              <td><span className="tickets_counts_total">Total</span></td>
              <td>{cash_total_sales}</td>
              <td />								
              <td>{currency}{cash_total_revenue_incl.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td />
              <td>{currency}{cash_total_revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td />
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }

  getSortedRows(rows_filtered, sort){
    return rows_filtered
  }

  getTableData(datatable, rows_filtered, sort){
    let content = null
    const self = this
    const { filter_type } = this.state
    if(filter_type == FILTER_TYPE_NAME){
      content = _map(rows_filtered, (row, index) => {
        const user = Object.assign({}, row)
        const name = row._key
	
        delete user.id
        delete user.isDetailRow
        delete user.isExpanded
        delete user._key
	
        const dates = Object.keys(user)
        const sortedDates = _sortBy(dates)
	
        const content_date = _map(sortedDates, (date)=>self.getTableContent(datatable, sort, user, date, FILTER_TYPE_DATE))
	
        return (
          <div key={index} className="">
            <div className="">						
              <div className="inner_avatar" />
              <h4 className="inline"><img src={asset('/resources/images/system_icons/user-ico.svg')} className="ico-show-small"/>{name}</h4>
              {content_date}
            </div>
          </div>
        )
      })
    }else{
      const date_rows = {}
      let dates; let sortedDates

      // reconstruct array
      _map(rows_filtered, (row, index) => {
        const user = Object.assign({}, row)
        const name = row._key

        delete user.id
        delete user.isDetailRow
        delete user.isExpanded
        delete user._key

        dates = Object.keys(user)
        sortedDates = dates.sort()

        const content_date = _map(sortedDates, (date)=>{
          if(!date_rows[date]){
            date_rows[date] = {}
          }
          date_rows[date][name] = user[date]
        })
      })

      dates = Object.keys(date_rows)
      sortedDates = dates.sort()

      content = _map(sortedDates, (date, index) => {
        const users = Object.keys(date_rows[date])
        const sortedUsers = users.sort()
        const content_user = _map(sortedUsers, (user)=>self.getTableContent(datatable, sort, date_rows[date], user, FILTER_TYPE_NAME))
        return (
          <div key={index} className="">
            <div className="">						
              <div className="inner_avatar" />
              <h4 className="inline"><img src={asset('/resources/images/system_icons/cal.svg')} className="ico-show-small"/>{moment(date, 'YYYY-MM-DD').format('D MMM YYYY')}</h4>
              {content_user}
            </div>
          </div>
        )
      })
    }		

    return (rows_filtered.length != 0) ? (
      <div>
        {content}
      </div>
    ): (
      <EmptyBar/>
    )
  }

  getClipboardText(rows){
    let ret = ''
    ret += 'Ticket Type' + '\t' + 'No of Sales' + '\t' + 'Price (incl. Fees)' + '\t' + 'Revenue (incl. Fees)' + '\t' + 'Price (excl. Fees)' + '\t' + 'Revenue (excl. Fees)' + '\n'
    _map(rows, (r, index)=>{
      ret += r.release_type + '\t' + 
				r.num_sales + '\t' +
				parseFloat(r.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '\t' +
				parseFloat(parseInt(r.num_sales) * (r.price ? parseFloat(r.price) : 0)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '\t' +
				parseFloat(r.cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '\t' +
				parseFloat(parseInt(r.num_sales) * (r.cost ? parseFloat(r.cost) : 0)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    })
    return ret
  }

  onClickTab(type){		
    this.setState({ filter_type: type })
  }

  render() {
    const { event } = this.props
    const { filter_type } = this.state

    const cs_tab_name = filter_type == FILTER_TYPE_NAME ? 'tab-title selected' :'tab-title'
    const cs_tab_date = filter_type != FILTER_TYPE_NAME ? 'tab-title selected' :'tab-title'
    return (
      <div className="table-background">
        <div className="table-caption"><img className="section-main-heading" src={asset('/resources/images/event/performance/box-office-sales-ico.svg')} />Box Office Sales</div>
        <div className="tab-view">
          <div className="tab-header">
            <div className="text-center">
							Group by: 
              <div className={cs_tab_name} onClick={this.onClickTab.bind(this, FILTER_TYPE_NAME)}>
								Name
              </div>
              <div className={cs_tab_date} onClick={this.onClickTab.bind(this, FILTER_TYPE_DATE)}>
								Date
              </div>
            </div>
          </div>
        </div>
        <div className="table-background">
          <JSONDatatable
            type={TYPE_FROM_URL}
            data={{ url: `/api/events/${event.id}/relationships/performance/`, param:{ 'section': 'box_office_sales' }, node: 'data.box_office_sales.users.*', path: 3 }}
            sort={{ index: 0, asc: true }}
            getSortedRows={this.getSortedRows}
            getTableData={this.getTableData}
            getClipboardText={this.getClipboardText}
            usePagination={false}
            autoRefresh={AUTO_REFRESH}
            loadingBarTitle={'Hold tight! We\'re getting your event\'s box office sales...'}
          >
            <div ref={DATATABLE}/> 
          </JSONDatatable>
        </div>
      </div>				
    )
  }
}
