// Re-render Optimization 14.05.2020

import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import _get from 'lodash/get'

import DateLabel from '../../_library/DateLabel'
import Address from '../../_library/Address'
import { ROLES } from '../../constants/roles'

import EventTicketStatistics from './EventTicketStatistics'
import EventImagePlaceholder from './EventImagePlaceholder'

const EventRow = props => {
  const { event, autoRefresh } = props
  const address = !!event ? event.venue : null
  const organization = !!event ? event.owner.displayName : null
  const { isOnlineEvent } = event
  const eventId = event.id
  const eventIsCancelled = _get(event, 'flagCancelled', false)
  const eventIsPostponed = _get(event, 'flagPostponed', false)
  const eventIsPostponedWithHiddenDate = _get(event, 'flagHideDate', false)

  const permission = !!event ? event.self.role : ''
  const organization_link = !!event ? '/brand/' + event.owner.id + '/details' : '/brands'
  let redirectPath = '/performance'

  if (permission === 'onsite') {
    redirectPath = '/details'
  }
  if (permission === 'curator') {
    redirectPath = '/invitations'
  }

  if (ROLES.is_support_or_partial_support(permission)) {
    redirectPath = '/orders'
  }

  const startDate = moment(new Date(event.startDate))
  const startDate_utc = moment.utc(new Date(event.startDate))
  const now = new Date()
  const cs = [
    startDate.isBefore(now) ? 'row-stale eventslist-row ' : 'eventslist-row',
    event.pinned ? 'event-pinned' : 'event-unpinned'
  ].join(' ')

  return (
    <tr className={cs}>
      <td className="eventslist-details">
        <div className="react-table">
          <div className="eventslist-thumbs">
            <div className="LazyLoad">
              <LazyLoad width={150} height={150} once>
                <div
                  className={`${
                    eventIsCancelled || eventIsPostponed || eventIsPostponedWithHiddenDate
                      ? 'banner-container'
                      : ''
                  }`}
                >
                  {!!event.imageURL ? (
                    <Link to={'/event/' + eventId + redirectPath}>
                      <img alt="nodata" className="LazyLoadImg" src={event.imageURL} />
                    </Link>
                  ) : (
                    <EventImagePlaceholder />
                  )}
                  {eventIsCancelled ? (
                    <div className={`cancelled-banner  ${!!event.imageURL ? 'cancelled-banner-lazy' : ''}`}>
                      Canceled
                    </div>
                  ) : eventIsPostponedWithHiddenDate ? (
                    <div
                      className={`postponed-hidden-banner ${!!event.imageURL ? 'postponed-banner-lazy' : ''}`}
                    >
                      <p>POSTPONED</p>
                      <p>HIDDEN</p>
                    </div>
                  ) : eventIsPostponed ? (
                    <div className={`postponed-banner  ${!!event.imageURL ? 'postponed-banner-lazy' : ''}`}>
                      <span>POSTPONED</span>
                    </div>
                  ) : null}
                </div>
              </LazyLoad>
            </div>
            <div className="event-details">
              <div className="event-brand">
                <Link to={organization_link}>{organization}</Link>
              </div>
              <div className="event-name">
                <Link to={'/event/' + eventId + redirectPath}>{event.displayName}</Link>
              </div>
              <div className="event-time">
                <img alt="nodata" src={asset('/resources/images/cal-ico.svg')} />
                <DateLabel className="starttime" value={startDate_utc} format="LLL" />
              </div>
              {isOnlineEvent ? (
                <div className="event-address">
                  <img alt="nodata" src={asset('/resources/images/location-ico.svg')} />
                  <div className="address-notdefined">Online Event</div>
                </div>
              ) : (
                <div className="event-address">
                  <img alt="nodata" src={asset('/resources/images/location-ico.svg')} />
                  {!!address && <Address className="address-form" type="simple" {...address} />}
                  {!address && <div className="address-notdefined">Not Defined</div>}
                </div>
              )}
            </div>
          </div>
          <div className="table-cell">
            <div className="eventslist-actions top_position">
              <div className="event-actions-container">
                {permission !== 'curator' && !(permission === ROLES.PARTIAL_SUPPORT) && (
                  <EventTicketStatistics event={event} autoRefresh={autoRefresh} />
                )}
                <div className="eventslist-status">{props.children}</div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
}

export default EventRow
