import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useUpdateCheck } from 'react-update-notification'
import { toastNotify } from '../_common/core/ui_services'

export const MouseflowWrapper = ({ children }) => {
  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 10000,
  })
  const history = useHistory()

  useEffect(() => {
    if (!(status === 'checking' || status === 'current')) {
      toastNotify.success(
        "A new version is available. Please reload the page.",
        {
          toastId: 'appUpdateAvailable', // Prevent duplicate toasts
          onClick: reloadPage,
          autoClose: false, // Prevents toast from auto closing
          icon: <img src={asset('/resources/images/information-icon.svg')} />
        }
      )
    }
  }, [status, reloadPage])


  useEffect(() => {
    if (history.location?.pathname?.includes('/welcome')) {
      window._mfq.push(['newPageView', history.location.pathname])
    }
  }, [])

  useEffect(() => history.listen(location => {
    if (location?.pathname?.includes('/welcome')) {
      window._mfq.push(['newPageView', location.pathname])
    }
  }), [history])

  return (
    <>
      {children}
    </>
  )
} 