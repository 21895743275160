import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { ENV } from '../constants/env'
import axios from 'axios'
import { getSignedRequest } from '../../_common/core/http_services/uploads'

const RichTextArea = React.forwardRef(
  (
    {
      label,
      height = 250,
      touched,
      error,
      id,
      placeholder,
      value,
      onChange,
      editorStyle,
      onBlur,
      disableEmbeds,
      disablePlugin,
      limit,
      smsField,
      charLimit,
      customCounter
    },
    editorRef
  ) => {
    const onChangeLimit = (newValue, editor) => {
      if (limit) {
        const matched = newValue.match(/<p>(.*?)<\/p>/g)
        const lineNumbers = matched ? matched.length : 1
        if (lineNumbers > limit) {
          return
        }
      }
      if (charLimit) {
        const simpleText = editor.getContent({ format: 'text' })
        const tagText = editor.getContent()
        const chars = newValue ? simpleText.length : 0
        onChange(simpleText, tagText)
        customCounter(chars)
      } else {
        onChange(newValue)
      }
    }
    return (
      <div className="form-group">
        {!!label && (
          <label className="control-label" htmlFor={id}>
            {label}
          </label>
        )}
        {touched && error && <div style={{ color: '#D9534F', fontSize: '12px' }}>{error}</div>}
        <div style={editorStyle}>
          <Editor
            apiKey={ENV.TINY_MCE_EDITOR_KEY}
            onInit={(evt, editor) => {
              if (editorRef) {
                editorRef.current = editor
              }
            }}
            value={value ?? ''}
            onEditorChange={onChangeLimit}
            onBlur={onBlur}
            init={{
              images_upload_handler: async (blobInfo, success, failure) => {
                try {
                  const res = await getSignedRequest(blobInfo.filename())
                  const formData = new FormData()
                  formData.append('AWSAccessKeyId', res.aws)
                  formData.append('acl', 'public-read')
                  formData.append('success_action_status', 200)
                  formData.append('key', res.key)
                  formData.append('policy', res.policy)
                  formData.append('signature', res.signature)
                  if (res.contentType) {
                    formData.append('Content-Type', res.contentType)
                  }
                  formData.append('file', blobInfo.blob(), blobInfo.filename())
                  await axios.post(res.url, formData, {
                    method: 'POST',
                    withCredentials: false,
                    headers: {
                      'Access-Control-Allow-Origin': '*',
                      origin: 'x-requested-with'
                    }
                  })
                  success((ENV.CDN_URL || '') + res.dest)
                } catch (error) {
                  if (error.response) {
                    failure('HTTP Error: ' + error.response.status)
                  }
                }
              },
              media_live_embeds: !disableEmbeds,
              media_dimensions: false,
              media_alt_source: false,
              media_poster: false,
              image_title: !disablePlugin,
              placeholder: placeholder?.text,
              menubar: false,
              height,
              automatic_uploads: !disablePlugin,
              file_picker_types: disablePlugin ? 'image' : undefined,
              statusbar: smsField ? true : false,
              plugins: [
                `advlist autolink lists link${disablePlugin ? '' : ' image'} charmap print preview anchor`,
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help',
                'image code',
                'media',
                'textcolor',
                'emoticons'
              ],
              toolbar: `undo redo | ${!smsField && 'link'}${disablePlugin ? '' : ' image'} ${
                smsField
                  ? ' emoticons |'
                  : '| media | formatselect | forecolor backcolor | `' +
                    'bold italic | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help'
              }`,
              content_style: `body {
                font-family:Helvetica,Arial,sans-serif;
                font-size:14px;
              }
              .medium-insert-embeds {
                width: 100%;
              }
              .medium-insert-embeds figure {
                width: 100%;
              }
              .medium-insert-embeds figure .medium-insert-embed {
                width: 100%;
              }
              `,
              media_url_resolver: async (data, resolve) => {
                try {
                  const res = await axios.get('https://iframe.ly/api/oembed', {
                    params: {
                      url: data.url,
                      iframe: 1,
                      api_key: ENV.IFRAME_API_KEY
                    }
                  })
                  resolve({ 
                    html: `<p>
                      <div class="medium-insert-embeds">
                        <figure>
                          <div class="medium-insert-embed">
                            ${res.data.html}
                          </div>
                        </figure>
                      </div>
                    </p>` 
                  })
                } catch(e) {
                  resolve({ html: '' })
                }
              },
            }}
          />
        </div>
      </div>
    )
  }
)

export default RichTextArea

