import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'
import React from 'react'

import { JSONDatatable, SEARCHBAR, DATATABLE, TYPE_FROM_URL, IS_FOUND } from '../../_library/JSONDatatable'
import EmptyBar from '../../_library/EmptyBar'
import { formatDate } from '../../../_common/core/validation'
import { DAY_DISPLAY_FORMAT } from '../../constants/timeFormats'

export default class GuestTicketsIssued extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }

    this.getFilteredRows = this.getFilteredRows.bind(this)
    this.getSortedRows = this.getSortedRows.bind(this)
    this.getTableData = this.getTableData.bind(this)
    this.getClipboardText = this.getClipboardText.bind(this)
  }

  componentDidMount() {

  }

  getFilteredRows(rows, search){
    let rows_filtered = rows
		
    // filter by search
    const keyword = search.join('').trim().toLowerCase()
    if(keyword != ''){
      rows_filtered = _filter(rows_filtered, (item) => {
        let found = 0
        found += IS_FOUND(item.first_name + ' ' + item.last_name, keyword)
        found += IS_FOUND(item.email, keyword)
        found += IS_FOUND(item.ticketType, keyword)
        found += IS_FOUND(item.status)
        found += IS_FOUND(item.notes)
        found += IS_FOUND(item.order_datetime, keyword)
        found += IS_FOUND(item.issued_by_first_name + ' ' + item.issued_by_last_name, keyword)
        return found > 0
      })
    }
    return rows_filtered
  }

  getSortedRows(rows_filtered, sort){
    if(sort){
      const dir = sort.asc ? 'asc' : 'desc'
      switch(sort.index){
      case 0:
        rows_filtered = _orderBy(rows_filtered, (t)=>t.first_name + ' ' + t.last_name, dir)
        break
      case 1:
        rows_filtered = _orderBy(rows_filtered, (t)=>t.email, dir)
        break
      case 2:
        rows_filtered = _orderBy(rows_filtered, (t)=>t.ticketType, dir)
        break
      case 5:
        rows_filtered = _orderBy(rows_filtered, (t)=>t.order_datetime, dir)
        break
      case 6:
        rows_filtered = _orderBy(rows_filtered, (t)=>t.issued_by_first_name + ' ' + t.issued_by_last_name, dir)
        break
				
      }
    }
    return rows_filtered
  }

  getTableData(datatable, rows_filtered, sort){
    const clipboard_text = this.getClipboardText(rows_filtered)
    const clipboard = datatable.getClipboardColumn(datatable, clipboard_text)

    const content_header = datatable.getHeaderRow(datatable, [
      { title: 'Name', sort: true },
      { title: 'Email', sort: true },
      { title: 'Ticket Type', sort: true, className: 'mw-140' },
      { title: 'Status', sort: false, className: 'mw-140' },
      { title: 'Notes', sort: false },
      { title: 'Issued On', sort: true, className: 'mw-140' },
      { title: 'Issued By', sort: true, className: 'mw-140' },
      { title: clipboard, sort: false, className: 'column-clipboard' },
    ], sort)

    const camalize = (str) => {
      const ret = str ? str.toLowerCase() : ''
      return ret.replace(/\b[a-z]/g,(f)=> f.toUpperCase())
    }

    return (rows_filtered.length != 0) ? (
      <table className="table guest-tickets-issued-table">
        <thead>
          {content_header}
        </thead>
        <tbody>
          {
            rows_filtered.map((row, index) => (
              <tr key={index}>
                <td>{row.first_name + ' ' + row.last_name}</td>
                <td>{row.email}</td>
                <td>{row.ticketType}</td>
                <td>{camalize(row.status)}</td>
                <td>{row.notes}</td>
                <td>{formatDate(row.order_datetime, DAY_DISPLAY_FORMAT)}</td>
                <td>{row.issued_by_first_name + ' ' + row.issued_by_last_name}</td>
                <td />
              </tr>
            ))
          }
        </tbody>
      </table>
    ): (
      <EmptyBar/>
    )
  }

  getClipboardText(rows_filtered){
    const camalize = (str) => {
      const ret = str ? str.toLowerCase() : ''
      return ret.replace(/\b[a-z]/g,(f)=> f.toUpperCase())
    }

    let ret = ''
    ret += 'Name' + '\t' + 'Email' + '\t' + 'Ticket Type' + '\t' + 'Status' + '\t' + 'Notes' + '\t' + 'Issued On' + '\t' + 'Issued By' + '\n'
    _map(rows_filtered, (t)=>{
      ret += (t.first_name + ' ' + t.last_name) + '\t' + 
				t.email + '\t' + 
				t.ticketType + '\t' + 
				camalize(t.status) + 
				t.notes + '\t' + 
				t.order_datetime + '\t' +
				(t.issued_by_first_name + ' ' + t.issued_by_last_name) + '\n'
    })
    return ret
  }

  render() {
    const { event } = this.props

    return (
      <div className="guest-tickets-issued">
        <JSONDatatable
          ref={this.props.refIssued}
          type={TYPE_FROM_URL}
          data={{ url: `/api/events/${event.id}/relationships/guest_tickets/`, node: 'data.guest_tickets.*' }}
          sort={{ index: 5, asc: false }}
          getFilteredRows={this.getFilteredRows}
          getSortedRows={this.getSortedRows}
          getTableData={this.getTableData}
          getClipboardText={this.getClipboardText}
          usePagination={false}
          autoRefresh={20 * 1000}
          loadingBarTitle={'Hold tight! We\'re getting your event\'s guest tickets...'}
        >
          <div ref={SEARCHBAR} hasSearch labelTotalCount="Number of Matching Tickets" />
          <div ref={DATATABLE}/> 
        </JSONDatatable>
      </div>
    )
  }
}
