import React from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'

import Card from '../../_library/Card'

import {
  FETCH_EVENT_ADDONS,
  CREATE_ADDON,
  UPDATE_ADDON,
  UPDATE_ADDONS_SORTING
} from '../../../_common/redux/addons/actions'
import { get_event_addons } from '../../../_common/core/selectors'
import { showAxiosError } from '../../utils/messenger'

import AddOnForm from './AddOnForm'
import AddOnTable from './AddOnTable'
import AddOnDeactivateModal from './AddOnDeactivateModal'
import AddOnModal from './AddOnModal'

@connect(
  state => {
    const addons = get_event_addons(state)
    const isLoadingAddOns = state.loading.has('FETCH_EVENT_ADDONS')

    return {
      addons,
      isLoadingAddOns
    }
  },
  {
    FETCH_EVENT_ADDONS,
    CREATE_ADDON,
    UPDATE_ADDON,
    UPDATE_ADDONS_SORTING
  }
)
export default class AddOnSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addingAddon: false,
      editingAddon: null,
      deactivatingAddon: null,
      saveSortDialogOpen: false
    }
  }

  componentDidMount() {
    const { event, FETCH_EVENT_ADDONS } = this.props
    FETCH_EVENT_ADDONS(event.id)
  }

  handleAddAddon = () => {
    this.setState({ addingAddon: true })
  }

  handleCancelAddon = () => {
    this.setState({ addingAddon: false, editingAddon: null })
  }

  handleEditAddon = selectedAddOn => {
    this.setState({ editingAddon: selectedAddOn, addingAddon: false })
  }

  handleDeactivateAddonOpenModal = selectedAddOn => {
    this.setState({ deactivatingAddon: selectedAddOn })
  }

  handleDeactivateAddonCancel = () => {
    this.setState({ deactivatingAddon: null })
  }

  handleActivateDeactivateAddon = async (selectedAddOn, active) => {
    const { UPDATE_ADDON, event } = this.props
    this.setState(prevState => ({
      ...prevState,
      loadingAddOnUpdate: {
        ...prevState.loadingAddOnUpdate,
        [selectedAddOn.id]: true
      }
    }))
    try {
      await UPDATE_ADDON(selectedAddOn.id, { active }, event.id)
    } catch (err) {
    } finally {
      this.setState(prevState => ({
        ...prevState,
        deactivatingAddon: null,
        loadingAddOnUpdate: {
          ...prevState.loadingAddOnUpdate,
          [selectedAddOn.id]: false
        }
      }))
    }
  }

  handleSubmitNewAddon = async form => {
    const { event, CREATE_ADDON, addons } = this.props

    if (!form.ticketTypeNeeded) {
      form.prerequisiteTicketTypeIds = null
    }
    delete form.ticketTypeNeeded
    if (form.stockUnlimited) {
      form.stock = null
    }
    delete form.stockUnlimited

    form.price = parseInt(form.price) * 100
    form.sortOrder = addons.length + 1

    // submit
    try {
      await CREATE_ADDON(event.id, form)
    } catch (err) {
      showAxiosError(err)
    } finally {
      this.setState({ addingAddon: false })
    }
  }

  handleSubmitEditAddon = async form => {
    const { UPDATE_ADDON, event } = this.props
    const { editingAddon } = this.state

    if (!form.ticketTypeNeeded) {
      form.prerequisiteTicketTypeIds = null
    }
    delete form.ticketTypeNeeded
    if (form.stockUnlimited) {
      form.stock = null
    }
    delete form.stockUnlimited

    // submit
    try {
      await UPDATE_ADDON(_get(editingAddon, 'id'), form, event.id)
    } catch (err) {
      showAxiosError(err)
    } finally {
      this.setState({ editingAddon: false })
    }
  }

  handleAddonRefresh = async () => {
    const { event, FETCH_EVENT_ADDONS } = this.props
    try {
      await FETCH_EVENT_ADDONS(event.id)
    } catch (error) {
      showAxiosError(error)
    }
  }

  handleSaveSort = async (sorting, data) => {
    const { event, UPDATE_ADDONS_SORTING } = this.props
    await UPDATE_ADDONS_SORTING(event.id, sorting, data)
  }

  render() {
    const { event, tickets, addons, isLoadingAddOns, tables } = this.props
    const { addingAddon, editingAddon, deactivatingAddon, loadingAddOnUpdate } = this.state

    return (
      <div className="row">
        <div className="col-xs-12">
          <Card icon={'fa-book'} title={'Add-Ons'}>
            {addingAddon && (
              <AddOnForm
                event={event}
                tickets={tickets}
                tables={tables}
                handleSubmit={this.handleSubmitNewAddon}
                onCancel={this.handleCancelAddon}
              />
            )}
            {editingAddon && (
              <AddOnModal
                event={event}
                tickets={tickets}
                tables={tables}
                isEditing={true}
                initialValues={editingAddon}
                handleSubmitEditAddon={this.handleSubmitEditAddon}
                handleCancelAddon={this.handleCancelAddon}
              />
            )}
            <AddOnTable
              event={event}
              addons={addons}
              isLoading={isLoadingAddOns}
              loadingAddOnUpdate={loadingAddOnUpdate}
              handleEditAddon={this.handleEditAddon}
              handleActivateAddon={this.handleActivateDeactivateAddon}
              handleDeactivateAddonOpenModal={this.handleDeactivateAddonOpenModal}
              handleAddAddon={this.handleAddAddon}
              addingAddon={addingAddon}
              handleAddonRefresh={this.handleAddonRefresh}
              handleSaveSort={this.handleSaveSort}
            />
            <AddOnDeactivateModal
              deactivatingAddon={deactivatingAddon}
              loadingAddOnUpdate={loadingAddOnUpdate}
              handleDeactivateAddon={this.handleActivateDeactivateAddon}
              handleDeactivateAddonCancel={this.handleDeactivateAddonCancel}
            />
          </Card>
        </div>
      </div>
    )
  }
}