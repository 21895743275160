import _find from 'lodash/find'
import _result from 'lodash/result'
import _map from 'lodash/map'
import _get from 'lodash/get'
import React, { createRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DisableSectionByPermissionWrapper } from '../hoc'

import { RouteLeavingGuard } from '../_library/RouteLeavingGuard'
import Card from '../_library/Card'
import TicketGuestForm from './ticket/TicketGuestFormSection/TicketGuestForm'
import ImportGuestsForm from './guesttickets/ImportGuestsForm'
import GuestTicketDelegations from './guesttickets/Delegations'
import GuestTicketsIssued from './guesttickets/GuestTicketsIssued'
import TimeSlotGuestsForm from './guesttickets/TimeSlotGuestsForm'

// actions
import { FETCH_EVENT_TICKETS, FETCH_EVENT_TABLES } from '../../_common/redux/tickets/actions'
import { UPLOAD_GUESTTICKETS, GET_SEAT_MAP_DATA } from '../../_common/redux/guesttickets/actions'
import { FETCH_EVENT_ADDONS } from '../../_common/redux/addons/actions'
import {
  FETCH_INVENTORY_DELEGATIONS,
  CREATE_INVENTORY_DELEGATION,
  DELETE_INVENTORY_DELEGATION,
  UPDATE_INVENTORY_DELEGATION
} from '../../_common/redux/inventorydelegations/actions'
import { CARD_STATUS_UNCHANGED, CARD_STATUS_CHANGED, CARD_STATUS_SAVED } from '../../_common/core/form_helper'
import { getGroupedTickets } from '../utils/ticketUtils'

import {
  get_event,
  get_event_tickets,
  get_event_tables,
  get_event_addons,
  get_event_inventorydelegations
} from '../../_common/core/selectors'
import { showAxiosError, showMessage } from '../utils/messenger'
import { getTitle } from '../utils/getTitle'

function getTicketTypeID(ticketTypeName, tickets) {
  const ticketType = _find(
    tickets,
    ticket => ticket.displayName.toLowerCase() === ticketTypeName.toLowerCase()
  )
  return ticketType.id
}

@withRouter
@connect(
  state => {
    const event = get_event(state)
    const tickets = get_event_tickets(state)
    const tables = get_event_tables(state)
    const addons = get_event_addons(state)
    const inventoryDelegations = get_event_inventorydelegations(state)

    let seatData = null
    const _eid = state.guesttickets.eid
    const eid = event.id
    const { seatDataChecked } = state.guesttickets
    const { statuses } = state.guesttickets
    const reservation_eid = state.guesttickets.eid

    if (_eid === eid) {
      seatData = state.guesttickets.seatData
    }

    return {
      event,
      reservation_eid,
      tickets,
      tables,
      addons,
      inventoryDelegations,
      inventoryDelegationsLoading: state.loading.has('FETCH_INVENTORY_DELEGATIONS'),
      seatData,
      seatDataChecked,
      getSeatDataLoading: state.loading.has('GET_SEAT_MAP_DATA'),
      statuses,
      getSeatDataStatusesLoading: state.loading.has('GET_SEAT_MAP_STATUSES'),
    }
  },
  {
    FETCH_EVENT_TICKETS,
    FETCH_EVENT_TABLES,
    GET_SEAT_MAP_DATA,
    UPLOAD_GUESTTICKETS,
    FETCH_EVENT_ADDONS,
    FETCH_INVENTORY_DELEGATIONS,
    CREATE_INVENTORY_DELEGATION,
    DELETE_INVENTORY_DELEGATION,
    UPDATE_INVENTORY_DELEGATION
  }
)
export default class EventGuestTickets extends React.Component {
  constructor(props) {
    super(props)
    form_helper_reset()
    this.mounted = false
    this.cardsStatusForm = {
      form: CARD_STATUS_UNCHANGED
    }
    this.cardsStatusCSV = {
      form: CARD_STATUS_UNCHANGED
    }
    this.createNewDelegation = this.createNewDelegation.bind(this)
    this.deleteDelgation = this.deleteDelgation.bind(this)
    this.updateDelegation = this.updateDelegation.bind(this)
    this.uploadGuestsFromForm = this.uploadGuestsFromForm.bind(this)
    this.uploadGuestsFromCSV = this.uploadGuestsFromCSV.bind(this)
    this.GuestTicketsIssued = createRef()
  }
  componentDidMount() {
    Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
      theme: 'future'
    }

    const {
      event: { id, flagSeatMapAllowed, displayName },
      reservation_eid,
      FETCH_EVENT_TICKETS,
      FETCH_EVENT_TABLES,
      FETCH_EVENT_ADDONS,
      FETCH_INVENTORY_DELEGATIONS,
      GET_SEAT_MAP_DATA,
      configs,
    } = this.props

    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    Promise.resolve(FETCH_EVENT_TICKETS(id)).catch()
    Promise.resolve(FETCH_EVENT_ADDONS(id)).catch()
    Promise.resolve(FETCH_EVENT_TABLES(id)).catch()

    this.mounted = true

    FETCH_INVENTORY_DELEGATIONS(id)
    if (flagSeatMapAllowed) {
      if (+id !== +reservation_eid) {
        GET_SEAT_MAP_DATA(id).catch(() => {})
      }
    }
  }

  componentWillUnmount() {
    form_helper_reset()
    this.mounted = false
  }

  routerWillLeave = () => !form_helper_isEditted()

  uploadGuestsFromForm(form, isSeatMap) {
    const { event, UPLOAD_GUESTTICKETS } = this.props
    const recipients = [
      {
        first_name: form.ticketHolders[0].first_name,
        last_name: form.ticketHolders[0].last_name,
        email: form.email,
        ticketTypeID: form.ticketTypeID,
        quantity: form.quantity,
        ticketHolders: form.ticketHolders,
        notes: form.notes,
        tags: form.tags,
        reservation_id: localStorage.getItem('reservationId')
      }
    ]
    return Promise.resolve(UPLOAD_GUESTTICKETS(event.id, recipients, isSeatMap))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Successfully Uploaded!',
          hideAfter: 10,
          showCloseButton: true
        })
        form_helper_reset()
        this.changeFormCardStatusToNormal()
        setTimeout(() => {
          this.GuestTicketsIssued?.current?.manualRefresh()
        }, 4000)
        return v
      })
  }

  uploadGuestsFromCSV(data) {
    const { event, UPLOAD_GUESTTICKETS, tickets, tables } = this.props
    const recipients = _map(data.recipients, recipient => ({
      first_name: recipient.first_name,
      last_name: recipient.last_name,
      email: recipient.email,
      ticketTypeID: recipient.time || getTicketTypeID(recipient.ticketTypeName, tickets.concat(tables)),
      quantity: parseInt(recipient.quantity),
      notes: recipient.notes,
      ticketHolders: [
        {
          first_name: recipient.first_name,
          last_name: recipient.last_name,
          email: recipient.email
        }
      ],
      tags: recipient.tags
    }))
    return Promise.resolve(UPLOAD_GUESTTICKETS(event.id, recipients))
      .catch(err => {
        showAxiosError(err, err)
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(v => {
        showMessage('success', 'Successfully Uploaded!', 10, true)
        form_helper_reset()
        this.changeCSVCardStatusToNormal()
        setTimeout(() => {
          this.GuestTicketsIssued?.current?.manualRefresh()
        }, 4000)
        return v
      })
  }

  changeFormCardStatusToNormal() {
    const that = this
    if (this.cardsStatusForm.form !== CARD_STATUS_UNCHANGED) {
      this.cardsStatusForm.form = CARD_STATUS_SAVED
      setTimeout(() => {
        that.cardsStatusForm.form = CARD_STATUS_UNCHANGED
        that.forceUpdate()
      }, 3000)
    }
    this.forceUpdate()
  }

  changeCSVCardStatusToNormal() {
    const that = this
    if (this.cardsStatusCSV.form !== CARD_STATUS_UNCHANGED) {
      this.cardsStatusCSV.form = CARD_STATUS_SAVED
      setTimeout(() => {
        that.cardsStatusCSV.form = CARD_STATUS_UNCHANGED
        that.forceUpdate()
      }, 3000)
    }
    this.forceUpdate()
  }

  createNewDelegation(data) {
    const { event, CREATE_INVENTORY_DELEGATION } = this.props
    return CREATE_INVENTORY_DELEGATION(event.id, data)
  }

  deleteDelgation(did) {
    const { event, DELETE_INVENTORY_DELEGATION } = this.props
    return DELETE_INVENTORY_DELEGATION(did, event.id)
  }

  updateDelegation(did, data) {
    const { event, UPDATE_INVENTORY_DELEGATION } = this.props
    return UPDATE_INVENTORY_DELEGATION(did, event.id, data)
  }

  render() {
    const {
      event,
      tickets,
      tables,
      addons,
      inventoryDelegations,
      inventoryDelegationsLoading,
      seatData,
      seatDataChecked,
      statuses,
      getSeatDataLoading,
      getSeatDataStatusesLoading,
      configs,
    } = this.props
    const { flagTimeSlotsEnabled, flagSeatMapAllowed } = event
    const showGuestTicketDelegation = _get(configs, "appearance.showGuestTicketDelegation", true)

    const [normalTickets, groupedTickets] = ticketTypesSorting(tickets)

    const contentEdittedFields = []

    let ticketHolders = false
    let recipients = false
    const tags = false
    Object.keys(form_helper_get()).forEach((field, index) => {
      if (field === 'ticketTypeID') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> Ticket Type
          </div>
        )
        this.cardsStatusForm.form = CARD_STATUS_CHANGED
      } else if (field === 'quantity') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> Quantity
          </div>
        )
        this.cardsStatusForm.form = CARD_STATUS_CHANGED
      } else if (field === 'email') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> Email
          </div>
        )
        this.cardsStatusForm.form = CARD_STATUS_CHANGED
      } else if (field === 'first_name') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> First Name
          </div>
        )
        this.cardsStatusForm.form = CARD_STATUS_CHANGED
      } else if (field === 'last_name') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> Last Name
          </div>
        )
        this.cardsStatusForm.form = CARD_STATUS_CHANGED
      } else if (field === 'notes') {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> Notes
          </div>
        )
        this.cardsStatusForm.form = CARD_STATUS_CHANGED
      } else if (field.startsWith('ticketHolders')) {
        // let fieldS = field.split('[')[1].split('].')
        // contentEdittedFields.push(<div key={field}> - Ticket Holder #{parseInt(fieldS[0])+1}
        // {fieldS[1]=='first_name' ? 'First Name':'Last Name'}</div>)
        if (!ticketHolders) {
          contentEdittedFields.push(
            <div key={field}>
              <i className="fa fa-info-circle" aria-hidden="true" /> Ticket Holders
            </div>
          )
          ticketHolders = true
          this.cardsStatusForm.form = CARD_STATUS_CHANGED
        }
      } else if (field.startsWith('tags')) {
        if (!tags) {
          contentEdittedFields.push(
            <div key={field}>
              <i className="fa fa-info-circle" aria-hidden="true" /> Tags
            </div>
          )
          this.cardsStatusCSV.form = CARD_STATUS_CHANGED
        }
      } else if (field.startsWith('recipients[')) {
        if (!recipients) {
          contentEdittedFields.push(
            <div key={field}>
              <i className="fa fa-info-circle" aria-hidden="true" /> Recipients from CSV
            </div>
          )
          recipients = true
          this.cardsStatusCSV.form = CARD_STATUS_CHANGED
        }
      } else {
        contentEdittedFields.push(
          <div key={field}>
            <i className="fa fa-info-circle" aria-hidden="true" /> {field}
          </div>
        )
      }
    })

    return (
      <DisableSectionByPermissionWrapper>
        <div className="EventGuestTickets">
          <RouteLeavingGuard routerWillLeave={this.routerWillLeave} />
          <TicketGuestForm
            event={event}
            seatData={seatData}
            statuses={statuses}
            seatDataChecked={flagSeatMapAllowed ? seatDataChecked : true}
            getSeatDataLoading={flagSeatMapAllowed ? getSeatDataLoading : false}
            getSeatDataStatusesLoading={getSeatDataStatusesLoading}
            tickets={getGroupedTickets(tickets)}
            tables={tables}
            onSubmit={this.uploadGuestsFromForm}
            cardsStatus={this.cardsStatusForm}
            addons={addons}
          />
          {(!!normalTickets.length || !!tables.length) && (
            <ImportGuestsForm
              event={event}
              tickets={normalTickets}
              tables={tables}
              onSubmit={this.uploadGuestsFromCSV}
              cardsStatus={this.cardsStatusCSV}
            />
          )}
          {flagTimeSlotsEnabled && !!groupedTickets.length && (
            <TimeSlotGuestsForm
              event={event}
              tickets={groupedTickets}
              onSubmit={this.uploadGuestsFromCSV}
              cardsStatus={this.cardsStatusCSV}
            />
          )}

          {showGuestTicketDelegation ? <Card title="Guest Ticket Delegations">
            <p>
              Delegations allow you to give permissions to 3rd parties (such as sponsors, exhibitors and
              agents) to issue their own limited number of guest tickets.
            </p>
            <GuestTicketDelegations
              event={event}
              tickets={tickets}
              tables={tables}
              delegations={inventoryDelegations}
              loading={inventoryDelegationsLoading}
              onCreateNew={this.createNewDelegation}
              onDelete={this.deleteDelgation}
              onUpdate={this.updateDelegation}
            />
          </Card> : null}
          <Card title={'Issued Guest Tickets'}>
            <GuestTicketsIssued refIssued={this.GuestTicketsIssued} event={event} />
          </Card>
        </div>
      </DisableSectionByPermissionWrapper>
    )
  }
}
