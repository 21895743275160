import React from 'react'
import { Field } from 'formik'
import { RadioGroupField } from '../../../formik/Fields'
import { PickerRow } from './SaleDatesRows/PickerRow'
import _map from 'lodash/map'
import _filter from 'lodash/filter'

export default class SaleDatesRows extends React.Component {
  setFlags = value => {
    const { optionsSaleDates, updateField } = this.props
    const [pickedOption] = _filter(optionsSaleDates, option => option.value === value)
    const { displaySaleDates, flagAlwaysAvailable } = pickedOption
    updateField('displaySaleDates', displaySaleDates)
    updateField('flagAlwaysAvailable', flagAlwaysAvailable)
  }

  render() {
    const { inModal, optionsSaleDates } = this.props
    return (
      <>
        <div className='row'>
          <div className='col-sm-12 col-xs-12' style={{ position: 'static' }}>
            <div>This ticket type should be available:</div>
            <div className='div-spacing-10' />
            <Field
              name='optionSaleDates'
              id='optionSaleDates'
              classNames={{
                container: 'radio-group-container display-block',
                container_item: 'radio-group-item position-static',
                component: ''
              }}
              groups={_map(optionsSaleDates, optionSaleDates => {
                let option = optionSaleDates
                if (optionSaleDates.displaySaleDates) {
                  option = {
                    component: <PickerRow inModal={inModal} />,
                    ...optionSaleDates
                  }
                }
                return option
              })}
              component={RadioGroupField}
              removeSpacing={true}
              onChange={this.setFlags}
            />
          </div>
        </div>
      </>
    )
  }
}
