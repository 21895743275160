import React from 'react'
import { OverlayTrigger as OverlayTriggerBase } from 'react-bootstrap'
import { Tooltip } from 'react-bootstrap'

export const OverlayTrigger = props => {
  const defaultTrigger = ['hover']
  const { 
    id,
    tooltip,
    placement, 
    hideTooltip = false, 
    delayHide = 0,
    delayShow = 0,
    trigger = []
  } = props

  return (
    <div>
      <OverlayTriggerBase
        placement={placement}
        overlay={!hideTooltip ? <Tooltip id={id}> {tooltip} </Tooltip> : <></>}
        trigger={[...defaultTrigger, ...trigger]}
        delayHide={delayHide}
        delayShow={delayShow}
      >
        {props.children}
      </OverlayTriggerBase>
    </div>
  )
}
