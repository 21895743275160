import React from 'react'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'

import Field from '../../../../_library/Field'

import { parsePlaceResult } from '../utils'

class Job {
  constructor(callback) {
    this.callback = callback
  }
  cancel() {
    this.cancelled = true
  }
  callback() {
    if (this.cancelled) return
    this.callback.call(null, arguments)
  }
}

export default class EventVenueSearch extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      suggestions: [],
      searching: false,
      position: null,
      error: null,
      autocomplete: global.google ? new google.maps.places.AutocompleteService() : null
    }
  }

  componentWillMount() {
    this.lookupAddress = _debounce(this.lookupAddress, 300)
  }

  handleChangeAddressQuery = e => {
    const { updateField } = this.props
    this.lookupAddress(e.target.value)
    updateField('searchValue', e.target.value)
  }

  lookupAddress = value => {
    const { google } = global
    if (!google || value.length < 3) {
      this.setState({ suggestions: [] })
      return
    }

    this.setState({ searching: true })

    Promise.resolve(this.userGeo).then(() => {
      const { position, autocomplete } = this.state
      const bounds = position
        ? new google.maps.Circle({
            center: { lat: position.coords.latitude, lng: position.coords.longitude },
            radius: position.coords.accuracy
          }).getBounds()
        : null
      if (this.predictionJob) {
        this.predictionJob.cancel()
      }
      this.predictionJob = new Job((suggestions, status) => {
        this.setState({ searching: false })
        this.handleSuggestions(suggestions, status)
      })

      autocomplete.getPlacePredictions({ bounds, input: value }, this.predictionJob.callback)
    })
  }

  handleSuggestions = (suggestions, status) => {
    const statuses = google.maps.places.PlacesServiceStatus
    if (status !== statuses.OK && status !== statuses.ZERO_RESULTS) {
      this.setState({ error: 'Unexpected error occurred while searching for venue' })
      return
    }
    this.setState({ searching: false })
    const cleaned = (suggestions || []).map(s => ({
      id: s.place_id,
      label: s.terms[0].value,
      sub: s.terms
        .slice(1)
        .map(t => t.value)
        .join(', '),
      details: s
    }))
    this.setState({ suggestions: cleaned || [] })
  }

  handleAddressSelected = loc => {
    const { refs, updateVenue, updateField, isRnDomain, values } = this.props
    const statuses = google.maps.places.PlacesServiceStatus

    this.setState({ error: null })
    const service = new google.maps.places.PlacesService(refs.gattr)

    service.getDetails({ placeId: loc.id }, (res, status) => {
      let err
      if (status === statuses.ZERO_RESULTS) {
        err = 'No address found for venue'
      } else if (status !== statuses.OK) {
        err = 'Unexpected error occurred while looking up venue address'
      }
      if (err) {
        return this.setState({ error: err })
      }

      const venue = parsePlaceResult(res, { isRnDomain })

      const hasSales = _get(values, 'hasSales')
      if (!hasSales || venue.country === values.country) {
        updateVenue({ ...venue, id: venue.googlePlaceId })
        updateField('searchValue', venue.venueName)
        Object.keys(venue).forEach(k => {
          updateField(k, venue[k])
        })
      } else {
        this.setState({ error: 'The country can not be modified if the event has sales' })
      }
    })
  }

  render() {
    const { values, event } = this.props
    const { searching, suggestions, error } = this.state
    const hasSales = _get(values, 'hasSales')
    const flagSeatMapAllowed = _get(event, 'flagSeatMapAllowed', false)

    return (
      <div>
        <Field
          id="location"
          label="Search for an Address, a Venue or a City"
          className={'searchbox'}
          loading={searching}
          onChange={this.handleChangeAddressQuery}
          value={values.searchValue}
          options={suggestions}
          onSelected={this.handleAddressSelected}
          disabled={hasSales && flagSeatMapAllowed}
        />
        {error && <div className="alert alert-danger">{error}</div>}
        <br />
        <input type="hidden" id="googlePlaceId" value={values.googlePlaceId || ''} />
        <input type="hidden" id="latitude" value={values.latitude || ''} />
        <input type="hidden" id="longitude" value={values.longitude || ''} />
      </div>
    )
  }
}
