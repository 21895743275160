import _map from 'lodash/map'
import _get from 'lodash/get'
import _head from 'lodash/head'
import _last from 'lodash/last'
import _filter from 'lodash/filter'
import _sortBy from 'lodash/sortBy'

import Levenshtein from 'levenshtein'
import React from 'react'

import countries from '../../_common/core/countries'
import Field from './Field'

const countryOptions = _map(countries, 'name.common').map((c) => ({ id: c, label: c }))

export default class CountryField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      countries: countryOptions,
    }
    this.handleCountryChanged = this.handleCountryChanged.bind(this)
  }
  handleCountryChanged(e) {
    const { onChange } = this.props
    if (onChange) { onChange(e) }
    const { value } = e.target
    if (!_get(value, 'length', 0)) {
      this.setState({ countries: countryOptions })
      return
    }

    const n = value.toLowerCase()
    let countries = _map(countryOptions, (o) => {
      const { label } = o
      const m = label.toLowerCase()
      const d = m.slice(0, value.length) === n ? 0 : new Levenshtein(m, n).distance
      return [d, o]
    })
    countries = _filter(countries, (x) => x[0] < 3)
    countries = _sortBy(countries, _head)
    countries = _map(countries, _last)
    this.setState({ countries })
  }
  render() {
    const { countries } = this.state
    const onChange = this.props.onChange || (() => {})
    return (
      <Field
        {...this.props}
        itemStyle={this.props.itemStyle}
        listStyle={this.props.listStyle}
        options={countries}
        onChange={this.handleCountryChanged}
        onSelected={(o) => onChange(o.id)} />
    )
  }
}


