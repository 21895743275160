import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { getBrands, getBrandPixelData, getPaymentConnectData, applyPaymentMethod } from '../../core/http_services'
import { ERROR } from '../notification/actions'
import { showAxiosError } from '../../../web/utils/messenger'

const { CREATE_BRAND } = createAsyncAction('CREATE_BRAND', function (form) {
  return dispatch =>
    fetchAPI('/api/organizations', {
      method: 'POST',
      body: JSON.stringify({ data: { ...form, type: 'organization' } })
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { UPDATE_BRAND } = createAsyncAction('UPDATE_BRAND', function (id, form) {
  const body = {
    data: {
      ...form,
      id,
      type: 'organization'
    }
  }
  return dispatch =>
    fetchAPI(`/api/organizations/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(body)
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { UPDATE_BRAND_PAYMENT } = createAsyncAction('UPDATE_BRAND_PAYMENT', function (id, form) {
  const body = {
    data: {
      ...form,
      id,
      type: 'organization'
    }
  }
  return dispatch =>
    fetchAPI(`/api/organizations/${id}`, {
      method: 'POST',
      body: JSON.stringify(body)
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { FETCH_BRAND } = createAsyncAction('FETCH_BRAND', function (id) {
  return dispatch =>
    fetchAPI(`/api/organizations/${id}`)
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { FETCH_BRANDS } = createAsyncAction('FETCH_BRANDS', function (params = {}) {
  return async dispatch => {
    try {
      const brands = await getBrands({ compact: 1, ...params })
      dispatch(this.success({ brands }))
      return brands
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { SET_HAS_BRANDS } = createAsyncAction('SET_HAS_BRANDS', function(flag) {
  return dispatch => {
    dispatch(this.success(flag))
  }
})

const { SET_HAS_PAYOUT_MODE } = createAsyncAction('SET_HAS_PAYOUT_MODE', function(flag) {
  return dispatch => {
    dispatch(this.success(flag))
  }
})

const { FETCH_BRAND_PIXEL_DATA } = createAsyncAction('FETCH_BRAND_PIXEL_DATA', function (eventId, brandId) {
  return async dispatch => {
    try {
      const brandPixelData = await getBrandPixelData(eventId, brandId)
      dispatch(this.success(brandPixelData))
      return brandPixelData
    } catch (err) {
      showAxiosError(err)
    }
  }
})

const { FETCH_BRAND_PAYMENT_DATA } = createAsyncAction('FETCH_BRAND_PAYMENT_DATA', function (id) {
  return async dispatch => {
    try {
      const brandPaymentData = await getPaymentConnectData(id)
      dispatch(this.success({ id, brandPaymentData }))
      return brandPaymentData
    } catch (error) {
      showAxiosError(error)
    }
  }
})

const { APPLY_PAYMENT_METHOD } = createAsyncAction('APPLY_PAYMENT_METHOD', function (brandId, paymentMethodId) {
  return async dispatch => {
    try {
      await applyPaymentMethod(brandId, paymentMethodId)
      dispatch(this.success())
      return
    } catch (error) {
      showAxiosError(error)
    }
  }
})

const { EMAIL_BRAND_USERS } = createAsyncAction('EMAIL_BRAND_USERS', function (eid, form) {
  const body = {
    data: {
      ...form,
      relationships: {
        brand: {
          data: { type: 'brand', id: eid }
        }
      }
    }
  }

  return dispatch =>
    fetchAPI(`/api/messaging/brand/send_email`, {
      method: 'POST',
      body: JSON.stringify(body)
    })
      .catch(err => {
        const errMsg = err.errors[0].details
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

const { UPDATE_BRAND_MAILCHIMP } = createAsyncAction('UPDATE_BRAND_MAILCHIMP', function (id, form) {
  const body = {
    data: {
      ...form,
    }
  }

  return dispatch =>
    fetchAPI(`/api/brand/${id}/add_mailchimp_list_id`, {
      method: 'PATCH',
      body: JSON.stringify(body)
    })
      .catch(err => {
        dispatch(ERROR(...err.errors))
        throw err
      })
      .then(res => {
        dispatch(this.success(res))
        return res
      })
})

export {
  CREATE_BRAND,
  UPDATE_BRAND,
  UPDATE_BRAND_PAYMENT,
  FETCH_BRAND,
  FETCH_BRANDS,
  FETCH_BRAND_PIXEL_DATA,
  FETCH_BRAND_PAYMENT_DATA,
  SET_HAS_BRANDS,
  APPLY_PAYMENT_METHOD,
  SET_HAS_PAYOUT_MODE,
  EMAIL_BRAND_USERS,
  UPDATE_BRAND_MAILCHIMP
}
