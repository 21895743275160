import React from 'react'
import DateTimePickerReact from '../../../_library/DateTimePickerReact'

const PostponmentBanner = props => {
  const { values, errors, touched, updateField } = props

  const handleChangeDate = val => {
    updateField('postponedUntil', val)
  }

  return (
    <div className="row" style={{ marginTop: 8, marginBottom: 16 }}>
      <div className={'col-xs-6 col-12'} style={{ position: 'static' }}>
        <DateTimePickerReact
          id="postponedUntil"
          label="Postponement banner will be shown on the flyer image until:"
          placeholder="D MMM YYYY H:M AM"
          error={errors.postponedUntil}
          touched={touched.postponedUntil}
          value={values.postponedUntil || ''}
          onChange={handleChangeDate}
          suggestion={values.postponedUntil || ''}
        />
      </div>
    </div>
  )
}

export default PostponmentBanner
