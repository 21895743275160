import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isBoolean from 'lodash/isBoolean'
import _uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import getSlug from 'speakingurl'
import queryString from 'query-string'

import { RN_ROLES } from '../../../../constants/roles'

export const getPayoutDetailsFlagValue = props => {
  const accountName = _get(props, 'initialValues.bankingDetails.accountName')
  const accountNumber = _get(props, 'initialValues.bankingDetails.accountNumber')
  const routingNumber = _get(props, 'initialValues.bankingDetails.routingNumber')
  const bsb = _get(props, 'initialValues.bankingDetails.bsb')
  const sortCode = _get(props, 'initialValues.bankingDetails.sortCode')
  const ifscNumber = _get(props, 'initialValues.bankingDetails.ifscNumber')
  const bankName = _get(props, 'initialValues.bankingDetails.bankName')

  if (!accountName && !accountNumber && !routingNumber && !bsb && !sortCode && !ifscNumber && !bankName) {
    return false
  }
  return true
}

const getOwner = (props, id) => {
  let filteredOrg = _uniqBy(props.organizations, 'id')
  filteredOrg = filteredOrg.sort((a, b) => a.displayName.trim().localeCompare(b.displayName))
  const owner = filteredOrg.find(brand => brand.id === id)
  return owner ? owner : _get(props, 'initialValues.owner')
}

export const getDataFromProps = props => {
  const params = queryString.parse(window.location.search)
  const owner = params.brandId ? getOwner(props, params.brandId) : _get(props, 'initialValues.owner')
  const { user } = props
  let initialNFTContracts = _get(props, 'initialValues.nftContracts')
  if(_isEmpty(initialNFTContracts)){
    initialNFTContracts = { ethereum: [], polygon : [] }
  } else if(!initialNFTContracts.polygon){
    initialNFTContracts = { ...initialNFTContracts, polygon : [] }
  } else if(!initialNFTContracts.ethereum){
    initialNFTContracts = { ...initialNFTContracts, ethereum : [] }
  }

  let initialNFTLists = _get(props, 'initialValues.nftLists')
  if (_isEmpty(initialNFTLists)) {
    initialNFTLists = { 'solana-mainnet': [] }
  }

  let data = {
    owner,
    descriptions: _get(props, 'initialValues.descriptions'),
    displayName: _get(props, 'initialValues.displayName') || '',
    slug: _get(props, 'initialValues.slug') || '',
    bannerURL: _get(props, 'initialValues.bannerURL'),
    imageURLs: _get(props, 'initialValues.imageURLs'),
    backgroundURL: _get(props, 'initialValues.backgroundURL'),
    useImageInsteadOfTitle: _isBoolean(_get(props, 'initialValues.useImageInsteadOfTitle'))
      ? _get(props, 'initialValues.useImageInsteadOfTitle')
      : !!_get(props, 'initialValues.titleReplacementImage'),
    titleReplacementImage: _get(props, 'initialValues.titleReplacementImage') || null,
    //titleReplacementImageSvg: _get(props, 'initialValues.titleReplacementImageSvg') || null,
    startDate: _get(props, 'initialValues.startDate'),
    endDate: _get(props, 'initialValues.endDate'),
    salesStartDate: _get(props, 'initialValues.salesStartDate'),
    hasSoldOrders: _get(props, 'initialValues.hasSoldOrders'),
    hasSales: _get(props, 'initialValues.hasSales'),
    salesEndDate: _get(props, 'initialValues.salesEndDate'),
    checkInStart: _get(props, 'initialValues.checkInStart'),
    checkInEnd: _get(props, 'initialValues.checkInEnd'),
    postponedUntil: _get(props, 'initialValues.postponedUntil') || '',
    externalRedirect: _get(props, 'initialValues.externalRedirect'),
    flagDifferentTicketingPlatform: _get(props, 'initialValues.externalRedirect') ? true : false,
    flagCarer: _get(props, 'initialValues.flagCarer'),
    flagInvitationsOnly: _get(props, 'initialValues.flagInvitationsOnly'),
    flagRefunds: _get(props, 'initialValues.flagRefunds'),
    flagPasswordProtected: _get(props, 'initialValues.flagPasswordProtected'),
    flagValidateAge: _get(props, 'initialValues.flagValidateAge'),
    minimumAge: _get(props, 'initialValues.minimumAge'),
    facebookEvent: _get(props, 'initialValues.facebookEvent') || '',
    twitterHashtag: _get(props, 'initialValues.twitterHashtag') || '',
    eventType: _get(props, 'initialValues.eventType'),
    tags: _get(props, 'initialValues.tags'),
    blurredBackgroundUrl: _get(props, 'initialValues.blurredBackgroundUrl'),
    ogImage: _get(props, 'initialValues.ogImage'),
    twitterImage: _get(props, 'initialValues.twitterImage'),
    currency: _get(props, 'initialValues.currency') || { currency: 'USD', symbol: 'US$', decimal_places: 2 },
    // Venue
    venueName: _get(props, 'initialValues.venue.displayName') || '',
    streetNumber: _get(props, 'initialValues.venue.streetNumber') || '',
    street: _get(props, 'initialValues.venue.street') || '',
    city: _get(props, 'initialValues.venue.city') || '',
    state: _get(props, 'initialValues.venue.state') || '',
    postalCode: _get(props, 'initialValues.venue.postalCode') || '',
    country: _get(props, 'initialValues.venue.country') || '',
    flagHidden: _get(props, 'initialValues.venue.flagHidden'),
    flagDisabled: _get(props, 'initialValues.venue.flagDisabled'),
    googlePlaceId: _get(props, 'initialValues.venue.googlePlaceId'),
    latitude: _get(props, 'initialValues.venue.latitude'),
    longitude: _get(props, 'initialValues.venue.longitude'),
    searchValue: '',
    hideUntil: _get(props, 'initialValues.venue.hideUntil'),
    rsvpAlternativeInformation: _get(props, 'initialValues.rsvpAlternativeInformation'),
    locationInformation: _get(props, 'initialValues.locationInformation'),
    // payment details
    accountName: _get(props, 'initialValues.bankingDetails.accountName') || '',
    accountNumber: _get(props, 'initialValues.bankingDetails.accountNumber') || '',
    routingNumber: _get(props, 'initialValues.bankingDetails.routingNumber') || '',
    fullAddress: _get(props, 'initialValues.bankingDetails.fullAddress') || '',
    clabe: _get(props, 'initialValues.bankingDetails.clabe') || '',
    accountType: _get(props, 'initialValues.bankingDetails.accountType') || 'checking',
    swiftCode: _get(props, 'initialValues.bankingDetails.swiftCode') || '',
    countryCode: _get(props, 'initialValues.bankingDetails.countryCode') || '',
    bsb: _get(props, 'initialValues.bankingDetails.bsb') || '',
    sortCode: _get(props, 'initialValues.bankingDetails.sortCode') || '',
    ifscNumber: _get(props, 'initialValues.bankingDetails.ifscNumber') || '',
    bankName: _get(props, 'initialValues.bankingDetails.bankName') || '',
    transitNumber: _get(props, 'initialValues.bankingDetails.transitNumber'),
    institutionNumber: _get(props, 'initialValues.bankingDetails.institutionNumber'),
    seatMapId: _get(props, 'initialValues.seatMapId'),
    flagPaymentDetails: getPayoutDetailsFlagValue(props),
    // hosting details
    streamEmbedCode: _get(props, 'initialValues.streamEmbedCode') || '',
    flagSlidingScale: _get(props, 'initialValues.flagSlidingScale') || false,
    flagNftOwnershipRequired: _get(props, 'initialValues.flagNftOwnershipRequired') || false,
    nftContracts: initialNFTContracts,
    nftInput: { ethereum: '', polygon: '', 'solana-mainnet': '' },
    nftLists: initialNFTLists,
    ageRestriction: _get(props, 'initialValues.ageRestriction'),
  }
  if (!props.isRnDomain) {
    data = {
      ...data,
      shortName: _get(props, 'initialValues.shortName') || '',
      autoBackground: !_get(props, 'initialValues.backgroundURL'),
    }
  }
  if (props.isRnDomain) {
    data = {
      ...data,
      isRnDomain: props.isRnDomain,
      eventType: { clubShow: true },
      country: 'United States',
      isAccessFeatured: _get(props, 'rnEventMetadataObj.isAccessFeatured'),
      eventGenres: _get(props, 'rnEventMetadataObj.eventGenres'),
      eventCategory: _get(props, 'rnEventMetadataObj.eventCategory'),
      rsvpUrl: _get(props, 'rnEventMetadataObj.rsvpUrl') || '',
      noneTicketingEvent: _get(props, 'event.noneTicketingEvent', false),
      flagPrivate: _get(props, 'rnEventMetadataObj.flagPrivate'),
      flagRecommended: _get(props, 'rnEventMetadataObj.flagRecommended'),
      brief: _get(props, 'rnEventMetadataObj.brief') || '',
      region: _get(props, 'rnEventMetadataObj.region') || '',
      flagRnRsvp: _get(props, 'initialValues.flagRnRsvp'),
      recommendedBrands: (_get(props, 'initialValues.recommendedBrands') || []).filter(String),
      externalUrl: _get(props, 'initialValues.externalUrl') || '',
    }

    if (user && RN_ROLES.hasSomeRoles(user.rnRoles, [RN_ROLES.ADMIN, RN_ROLES.CURATOR])) {
      data = {
        ...data,
        eventGrade: _get(props, 'rnEventMetadataObj.eventGrade'),
        accessSubGroup: _get(props, 'rnEventMetadataObj.accessSubGroup') || '',
      }
    }

    const draftEventData = localStorage.hasOwnProperty('draftEvent')
      ? JSON.parse(localStorage.getItem('draftEvent'))
      : {}

    if (draftEventData && !_isEmpty(draftEventData)) {
      const venue = draftEventData.venueData ? draftEventData.venueData.venue : {}

      data = {
        ...data,
        displayName: draftEventData.title || '',
        shortName: (draftEventData.title || '').slice(0, 17),
        imageURLs: { en: draftEventData.image },
        startDate: draftEventData.startDate || '',
        endDate: draftEventData.endDate || '',
        descriptions: { en: draftEventData.details || null },
        facebookEvent: draftEventData.url,
        draftEventId: draftEventData.id,
        // timezone: draftEventData.timezone || '',

        // venue
        venueName: draftEventData.venue,
        streetNumber: _get(venue, 'streetNumber', ''),
        street: _get(venue, 'street', ''),
        city: _get(venue, 'city', ''),
        state: _get(venue, 'state', ''),
        postalCode: _get(venue, 'postalCode', ''),
        country: _get(venue, 'country', ''),
        googlePlaceId: _get(venue, 'googlePlaceId', ''),
        latitude: _get(venue, 'latitude', ''),
        longitude: _get(venue, 'longitude', ''),
      }
      const slug = !data.startDate
        ? data.displayName
        : data.displayName + moment.utc(data.startDate).format('DMMMYYYY')
      data.slug = getSlug(slug, {
        symbols: false,
        separator: ' ',
      }).replace(/\s/g, '')
    }
  }
  if (props.alternativeview) {
    data = {
      ...data,
      descriptions: _get(props, 'initialValues.descriptions') || null,
      title: _get(props, 'initialValues.displayName'),
      viewId: _get(props, 'initialValues.viewId') || null,
    }
  }
  return data
}
