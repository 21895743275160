import _filter from 'lodash/filter'
import _some from 'lodash/some'
import _includes from 'lodash/includes'
import _keys from 'lodash/keys'
import _find from 'lodash/find'
import countries from 'world-countries'
import { toUpperCase } from './validation'

export default _filter(countries, c => _some(
  ['AUD', 'EUR', 'USD', 'NZD', 'GBP', 'INR', 'KES', 'CAD', 'IDR', 'ISK', 'MXN', 'THB', 'MXN','HRK', 'JMD', 'COP', 'DOP', 'BBD', 'JOD', 'ARS'],
  cur => _includes(_keys(c.currencies), cur)
))

export const countryName = countryCode => {
  const found = _find(countries, c => c.cca2 === toUpperCase(countryCode))
  return found ? found.name.common : countryCode
}
