import React, { useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'
import _some from 'lodash/some'
import _every from 'lodash/every'

import Modal from 'react-modal'
import modalStyle from '../../../_common/core/modalStyle'
import Button from '../../_library/Button'
import Card from '../../_library/Card'
import LoadingBar from '../../_library/LoadingBar'
import ConfirmModal from '../../../web/_library/ConfirmModal'

import { showSuccessMessage, showAxiosError } from '../../utils/messenger'

// http service
import {
  fetchSeatMapData,
  getSeatMapConfiguration,
  saveSeatMapConfiguration,
} from '../../../_common/core/http_services'
import { IS_MOBILE_DEVICE, makeId } from '../../../_common/core/utils'
import { asyncComponent } from '../../hoc'
import ButtonWithTooltip from '../form/EventForm/SeatsCard/ButtonWithTooltip'

const SeatMapView = asyncComponent(() => import('tf-seat-map-view'))

const SeatMapConfiguration = ({ afterSave, tickets = [], event = {} }) => {
  const { id: eventId, seatMapId, hasSales } = event
  const isMobile = IS_MOBILE_DEVICE()
  const [showSeatMapModal, setShowSeatMapModal] = useState(false)
  const [saveDataLoading, setSaveDataLoading] = useState(false)
  const [seatMapLoading, setSeatMapLoading] = useState(false)
  const [seatMapData, setSeatMapData] = useState(null)
  const [tiersList, setTiersList] = useState([])
  const [activeSection, setActiveSection] = useState(null)
  const [selectTierLoading, setSelectTierLoading] = useState({
    loading: false,
    tierId: null,
  })
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const handleOpenModal = async () => {
    try {
      setSeatMapLoading(true)
      setSeatMapData(null)
      setShowSeatMapModal(true)
      const res = await fetchSeatMapData(seatMapId)
      const data = _get(res, 'data.data.rawData')

      setSeatMapData(data)
    } catch (error) {
      showAxiosError(error)
    }
  }

  const handleSubmit = () => {
    const tierConnected = _every(tiersList, ({ tickets }) => _some(tickets, ticket => ticket.checked))
    if (tierConnected) {
      onSave()
    } else {
      setShowConfirmModal(true)
    }
  }

  const onSave = async () => {
    try {
      setSaveDataLoading(true)
      const seats = []
      _forEach(tiersList, ({ tierId, tickets }) => {
        _forEach(tickets, ticket => {
          if (ticket.checked) {
            seats.push({ tier_id: tierId, ticket_type_id: ticket.id })
          }
        })
      })
      const data = {
        data: {
          attributes: {
            seats,
          },
        },
      }

      const res = await saveSeatMapConfiguration(eventId, data)
      const message = _get(res, 'data.data.attributes')
      showSuccessMessage(message)
      if (!_isEmpty(seats)) {
        localStorage.setItem('reservationId', makeId(50))
      } else {
        localStorage.setItem('reservationId', '')
      }
      setShowSeatMapModal(false)
      setActiveSection(null)
    } catch (error) {
      showAxiosError(error)
    } finally {
      afterSave()
      setSaveDataLoading(false)
    }
  }

  const handleTiersColors = colors => {
    const ticketsList = tickets.map(ticket => ({ ...ticket, checked: false }))
    const tiersList = _map(colors, (color, tierId) => ({ tierId, color, tickets: ticketsList }))
    setTiersList(tiersList)
    setSeatMapLoading(false)
    handleSeatMapConfiguration(tiersList)
  }

  const onSelectTicketType = e => {
    const { id, checked } = e.target
    const [checkboxTierId, checkboxTicketId] = id.split('-')

    const updatedData = tiersList.map(tier => {
      const { tierId, tickets } = tier
      if (tierId === checkboxTierId) {
        const ticketsList = tickets.map(ticket => {
          if (ticket.id === checkboxTicketId) return { ...ticket, checked }
          return ticket
        })
        return { ...tier, tickets: ticketsList }
      }
      return tier
    })
    setTiersList(updatedData)
  }

  const handleActiveSection = tierId => {
    if (activeSection === tierId) {
      setActiveSection(null)
    } else {
      setSelectTierLoading({
        loading: true,
        tierId,
      })
      setTimeout(() => {
        setActiveSection(tierId)
        setTimeout(() => {
          setSelectTierLoading({
            loading: false,
            tierId: null,
          })
        })
      })
    }
  }

  const handleSeatMapConfiguration = async tiersList => {
    try {
      const seatMapConfigurationRes = await getSeatMapConfiguration(eventId)
      const seatMapConfiguration = _get(seatMapConfigurationRes, 'data.data.attributes')

      const updatedTiersList = _map(tiersList, tier => {
        const { tierId, tickets } = tier
        if (seatMapConfiguration[tierId]) {
          const updatedTickets = _map(tickets, ticket => {
            const { ticket_types } = seatMapConfiguration[tierId]
            if (ticket_types.includes(ticket.id)) return { ...ticket, checked: true }
            return ticket
          })
          return { ...tier, tickets: updatedTickets }
        }
        return tier
      })

      setTiersList(updatedTiersList)
    } catch (error) {
      showAxiosError(error)
    }
  }

  const onConfirmResponse = async response => {
    if (response === 'yes') {
      onSave()
    }
    setShowConfirmModal(false)
  }

  return (
    <>
      <Button
        className="btn btn-primary "
        type="button"
        disabled={ _isEmpty(tickets) || hasSales}
        onClick={handleOpenModal}
      >
        Configure Ticket Types on Seat Map
      </Button>
      <Modal
        className="modal-dialog modal-trans p5 configuration-seat-map-modal"
        style={modalStyle}
        isOpen={showSeatMapModal}
        ariaHideApp={false}
        contentLabel="Modal"
        onRequestClose={() => setShowSeatMapModal(false)}
        closeTimeoutMS={10}
      >
        <div style={{ backgroundColor: '#292d32', justifyContent: 'center', textAlign: 'center' }}>
          {seatMapLoading && (
            <div className="loading-layer">
              <LoadingBar />
            </div>
          )}
          <div className="body">
            <p>Please select a seat group from the seat map to connect ticket types:</p>
            <div>
              <div className="sections-block">
                {tiersList.map(({ color, tierId, tickets }, index) => {
                  const selectedCount = _filter(tickets, ticket => ticket.checked).length
                  return (
                    <Card
                      key={tierId}
                      title={
                        selectTierLoading.loading && selectTierLoading.tierId === tierId ? (
                          <i className="fa fa-circle-o-notch fa-fw fa-spin" />
                        ) : (
                          `Section ${index + 1} (${selectedCount})`
                        )
                      }
                      closed={activeSection === tierId ? false : true}
                      cardTitleStyles={{
                        backgroundColor:
                          selectTierLoading.loading && selectTierLoading.tierId !== tierId
                            ? color + '80'
                            : color,
                      }}
                      accordionPosition={tierId}
                      handleTriggerClick={selectTierLoading.loading ? () => {} : handleActiveSection}
                    >
                      <p>Select Ticket Types:</p>
                      <div className="tickets-list">
                        {tickets.map(ticket => (
                          <div key={ticket.id} className="checkbox-group-square">
                            <label>
                              <div className="checkbox-square">
                                <input
                                  id={`${tierId}-${ticket.id}`}
                                  type="checkbox"
                                  checked={ticket.checked}
                                  onChange={onSelectTicketType}
                                />
                                <label htmlFor={`${tierId}-${ticket.id}`} />
                              </div>
                              <div className="checkbox-description">{ticket.displayName}</div>
                            </label>
                          </div>
                        ))}
                      </div>
                    </Card>
                  )
                })}
              </div>
              <div className="seat-map-block">
                {seatMapData && (
                  <SeatMapView
                    disabled={true}
                    enableTicketTypeAssign={true}
                    events={{}}
                    seatData={seatMapData}
                    width={380}
                    height={380}
                    isSelectionOn={true}
                    selectedTierId={+activeSection}
                    exportTiersColors={handleTiersColors}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="footer">
            <Button
              className="btn btn-success btn-lg btn-shadow"
              type="submit"
              loading={saveDataLoading}
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              className="btn btn-cancel btn-lg btn-shadow"
              type="button"
              onClick={() => setShowSeatMapModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <ConfirmModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        header="Warning"
        content={
          <div>
            <p>Please notice that not all the seat groups are connected to the ticket types</p>
          </div>
        }
        actions={[
          { value: 'yes', label: 'OK', className: 'btn btn-success btn-shadow' },
          { value: 'no', label: 'Cancel', className: 'btn btn-cancel btn-shadow' },
        ]}
        onAction={onConfirmResponse}
        classNames={{
          modal: 'modal-dialog modal-trans modal-medium',
          container: 'modal-dialog modal-medium',
        }}
        style={{ overlay: { zIndex: 99999 } }}
      />
    </>
  )
}

export default SeatMapConfiguration
