import React from 'react'
import _identity from 'lodash/identity'
import _merge from 'lodash/merge'
import Modal from 'react-modal'
import modalStyle from '../../_common/core/modalStyle'
import Button from '../_library/Button'

const defaultClassNames = {
  modal: 'modal-dialog modal-trans',
  container: 'modal-dialog',
  modal_content: 'modal-content',
  modal_header: 'modal-header',
  modal_body: 'modal-body',
  modal_footer: 'modal-footer',
  modal_footer_toolbar: 'btn-toolbar btn-toolbar-right',
}

export default (props) => {
  let {
    isOpen = false,
    header = 'Are you sure?',
    content = '',
    actions = [
      { value: 'delete', label: 'Delete', className: 'btn btn-danger', loading: false, disabled: false },
      { value: 'cancel', label: 'Cancel', className: 'btn btn-default', loading: false, disabled: false }
    ],
    closeTimeoutMS = 150,
    style = {},
    classNames: _classNames,

    onAction = _identity,
    onClose = _identity,
  } = props

  const classNames = _merge(defaultClassNames, _classNames)
  style = _merge(modalStyle, style)

  return (
    <Modal
      className={classNames.modal}
      style={style}
      isOpen={isOpen}
      contentLabel="Modal"
      onRequestClose={onClose}
      closeTimeoutMS={closeTimeoutMS}
      ariaHideApp={false}
    >
      <div className={classNames.container}>
        <div className={classNames.modal_content}>
          <div>
            <div className={classNames.modal_header}>
              <p className="h4 text-compact">{header}</p>
            </div>
            <div className={classNames.modal_body}>
              {typeof content === 'string' ? <p>{content}</p> : content}
            </div>
            {actions && actions.length ? (
              <div className={classNames.modal_footer}>
                <div className={classNames.modal_footer_toolbar}>
                  {actions.map(action => {
                    const { value, label, className = 'btn btn-default', loading, disabled } = action
                    return (
                      <Button
                        key={value}
                        className={className}
                        loading={loading}
                        type="button"
                        onClick={() => onAction(value)}
                        loading={loading}
                        disabled={disabled}
                      >
                        {label}
                      </Button>
                    )
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  )
}
