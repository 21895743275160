import React from 'react'
import LoadingBar from '../../_library/LoadingBar'
import EmptyBar from '../../_library/EmptyBar'
import CardHeader from '../../_library/CardHeader'
import NumberAnimation from '../../_library/NumberAnimation'
import { TabView } from '../../_library/TabView'
import { Tab } from 'bootstrap'
import { convertFileName } from '../../../_common/core/utils'
import { DATATABLE, JSONDatatable, TYPE_FROM_ARRAY } from '../../_library/JSONDatatable'
import { HTTP_INIT } from '../../../_common/core/http'
import { connect } from 'react-redux'
import { FETCH_EVENT_TRAFFIC } from '../../../_common/redux/performance/actions'
import _groupBy from 'lodash/groupBy'
import _isEqual from 'lodash/isEqual'
import _reduce from 'lodash/reduce'
import { showAxiosError } from '../../utils/messenger'
import { defaultDateFormat } from '../../../_common/core/validation/normalizers'
import moment from 'moment'
import { DATE_TRAFFIC_SUPPORT } from '../performance/constants/constants'

const tabTitles = [
  {
    img: 'icon_tab_browser',
    title: 'Browsers'
  },
  {
    img: 'icon_tab_device_type',
    title: 'Device Types'
  },
  {
    img: 'icon_tab_os',
    title: 'Platforms'
  },
  {
    img: 'icon_tab_devices_brands',
    title: 'Device Brands'
  },
  {
    img: 'icon_tab_device_name',
    title: 'Device Names'
  },
]

@connect(null, { FETCH_EVENT_TRAFFIC })
export default class TrafficViews extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      http_status: HTTP_INIT,
      unique: [],
      isLoading: true,
      group_by: 'browser'
    }

    this.mounted = true
    this.trafficInterval = null
  }

  componentDidMount() {
    this.fetchTraffic()
    this.trafficInterval = setInterval(() => {
      this.fetchTraffic()
    }, 600 * 1000)
  }

  componentWillUnmount() {
    this.mounted = false
    clearInterval(this.trafficInterval)
  }

  fetchTraffic = async () => {
    const { FETCH_EVENT_TRAFFIC, event, showWarning, setShowWarning } = this.props
    const { group_by, isLoading, unique: stateUnique } = this.state
    if (!this.mounted) return
    try {
      const unique = (await FETCH_EVENT_TRAFFIC(event.id, { group_by: [group_by, 'unique'] })) ?? []
      if (!showWarning) {
        if (unique.find(row => 
          moment(row.created_at, defaultDateFormat).isBefore(moment(DATE_TRAFFIC_SUPPORT, defaultDateFormat))
        )) {
          setShowWarning()
        }
      }
      if (_isEqual(unique, stateUnique) && !isLoading) {
        return
      }
      this.setState({
        unique,
        isLoading: false
      })
    } catch (err) {
      showAxiosError(err)
    }
  }

  getTableData(datatable, rows_filtered, sort) {
    const htmlDeviceHeader = (
      <div className="header_row">
        <img src={asset('/resources/images/event/devices/devices-names/_devices.svg')} />
        Devices
      </div>
    )
    const htmlNumberHeader = (
      <div className="header_row text-center">
        <img src={asset('/resources/images/event/devices/devices-names/_numberofusers.svg')} />
        No of Users
      </div>
    )
    const content_header = datatable.getHeaderRow(
      datatable,
      [
        { title: htmlDeviceHeader, sort: false },
        { title: htmlNumberHeader, sort: false },
        { title: htmlDeviceHeader, sort: false },
        { title: htmlNumberHeader, sort: false }
      ],
      sort
    )

    const totalCount = rows_filtered.length
    const leftColumn = Math.ceil(totalCount / 2)
    const result = []
    if (totalCount > 0) {
      for (let index = 0; index < leftColumn; index++) {
        result.push(
          <tr key={index} className={index % 2 == 0 ? 'row-stale' : ''}>
            <td>{rows_filtered[index].title}</td>
            <td className="text-center">{rows_filtered[index].value.toLocaleString()}</td>
            <td>{rows_filtered[index + leftColumn] ? rows_filtered[index + leftColumn].title : ''}</td>
            <td className="text-center">
              {rows_filtered[index + leftColumn]
                ? rows_filtered[index + leftColumn].value.toLocaleString()
                : ''}
            </td>
          </tr>
        )
      }
    }

    return totalCount != 0 ? (
      <table className="table tickets-table">
        <thead>{content_header}</thead>
        <tbody>{result}</tbody>
      </table>
    ) : null
  }

  handleImageError(obj){
    obj.target.src = asset('/resources/images/event/devices/_unknown.svg')
  }

  render() {
    const { http_status, isLoading, unique, group_by } = this.state
    const rows_browser = _reduce(
      _groupBy(
        unique,
        "device.browser",
      ), 
      (result, value, key) => {
        result.push({ title: key, value: value.length })
        return result
      },
      []
    ).sort((a, b) => Number(b.value) - Number(a.value))

    const browser_top5 = rows_browser.slice(0, 5)
    const browser_rest = rows_browser.slice(5, rows_browser.length)
    const getTiles_browser = (items, showRank) =>
      items.map((item, index) => {
        const imgName = convertFileName(item.title)
        const imgSrc = asset('/resources/images/event/devices/browser/' + imgName + '.svg')
        return (
          <div key={index} className="tile">
            <div className="content">
              {showRank && <div className="rank">{index + 1}</div>}
              <img alt="" onError={this.handleImageError} className="icon" src={imgSrc} />
              <div className="value">
                <div className="numbers">{item.value.toLocaleString()}</div>
                <div className="title">{item.title}</div>
              </div>
            </div>
          </div>
        )
      })

    const tab_content1 = (
      <div className="tab_content_browsers">
        <div className="top5">{getTiles_browser(browser_top5, true)}</div>
        <div className="tickets">
          <div className="decoration-icon">
            <div className="decoration" />
            <img alt="" src={asset('/resources/images/event/devices/icon_tickets.svg')} />
            <div className="decoration" />
          </div>
          <div className="decoration-text">Other Browsers</div>
        </div>
        <div className="lists">{getTiles_browser(browser_rest, false)}</div>
      </div>
    )

    const getTiles_device_types = items =>
      items.map((item, index) => {
        const imgName = convertFileName(item.title)
        const imgSrc = asset('/resources/images/event/devices/devices-types/' + imgName + '.svg')
        return (
          <div key={index} className="tile">
            <div className="content">
              <img alt="" onError={this.handleImageError} className="icon" src={imgSrc} />
              <div className="numbers">{item.value.toLocaleString()}</div>
              <div className="decoration" />
              <div className="title">{item.title}</div>
            </div>
          </div>
        )
      })

    const rows_device_type = _reduce(
      _groupBy(
        unique,
        "device.device_type"
      ),
      (result, value, key) => {
        result.push({ title: key, value: value.length })
        return result
      },
      []
    ).sort((a, b) => Number(b.value) - Number(a.value))

    const tab_content2 = (
      <div className="tab_content_device_types">
        <div className="top5">{getTiles_device_types(rows_device_type)}</div>
      </div>
    )

    const rows_platform_description = _reduce(
      _groupBy(
        unique, 
        "device.platform_description"
      ), 
      (result, value, key) => {
        result.push({ title: key, value: value.length })
        return result
      },
      []
    ).sort((a, b) => Number(b.value) - Number(a.value))

    const getTiles_platform = items =>
      items.map((item, index) => {
        const imgName = convertFileName(item.title)
        const imgSrc = asset('/resources/images/event/devices/os-platforms/' + imgName + '.svg')
        return (
          <div key={index} className="tile">
            <div className="content">
              <img alt="" onError={this.handleImageError} className="icon" src={imgSrc} />
              <div className="numbers">{item.value.toLocaleString()}</div>
              <div className="title">{item.title}</div>
            </div>
          </div>
        )
      })
    const tab_content3 = (
      <div className="tab_content_device_types">
        <div className="top5">{getTiles_platform(rows_platform_description)}</div>
      </div>
    )

    const rows_device_brand_name = _reduce(
      _groupBy(
        unique, 
        "device.device_brand_name"
      ),
      (result, value, key) => {
        result.push({ title: key, value: value.length })
        return result
      },
      []
    ).sort((a, b) => Number(b.value) - Number(a.value))

    const brands_top5 = rows_device_brand_name.slice(0, 5)
    const brands_rest = rows_device_brand_name.slice(5, rows_device_brand_name.length)
    const getTiles_brands = items =>
      items.map((item, index) => {
        const imgName = convertFileName(item.title)
        const imgSrc = asset('/resources/images/event/devices/devices-brands/' + imgName + '.svg')
        return (
          <div key={index} className="tile-brands">
            <div className="content">
              <div className="content-top">
                <img alt="" onError={this.handleImageError} className="icon" src={imgSrc} />
              </div>
              <div className="value">
                <div className="numbers" style={{ textAlign: 'center' }}>{item.value.toLocaleString()}</div>
                <div className="title">{item.title}</div>
              </div>
            </div>
          </div>
        )
      })
      
    const getTiles_brands_rest = (items, showRank) =>
      items.map((item, index) => {
        const imgName = convertFileName(item.title)
        const imgSrc = asset('/resources/images/event/devices/devices-brands/' + imgName + '.svg')
        return (
          <div key={index} className="tile">
            <div className="content">
              {showRank && <div className="rank">{index + 1}</div>}
              <img alt="" onError={this.handleImageError} className="icon" src={imgSrc} />
              <div className="value">
                <div className="numbers">{item.value.toLocaleString()}</div>
                <div className="title">{item.title}</div>
              </div>
            </div>
          </div>
        )
      })
    const tab_content4 = (
      <div className="tab_content_device_brands">
        <div className="top5">{getTiles_brands(brands_top5)}</div>
        <div className="tickets">
          <div className="decoration-icon">
            <div className="decoration" />
            <img alt="" src={asset('/resources/images/event/devices/icon_tickets.svg')} />
            <div className="decoration" />
          </div>
          <div className="decoration-text">Other Brands</div>
        </div>
        <div className="lists">{getTiles_brands_rest(brands_rest)}</div>
      </div>
    )

    const rows_device_name = _reduce(
      _groupBy(
        unique,
        "device.device_name"
      ), 
      (result, value, key) => {
        result.push({ title: key, value: value.length })
        return result
      },
      []
    ).sort((a, b) => Number(b.value) - Number(a.value))

    const device_name_top5 = rows_device_name.slice(0, 5)
    const device_name_rest = rows_device_name.slice(5, rows_device_name.length)
    const getTiles_device_name = items =>
      items.map((item, index) => {
        const imgName = convertFileName(item.title)
        const imgSrc = asset('/resources/images/event/devices/devices-names/' + imgName + '.svg')
        return (
          <div key={index} className="tile">
            <div className="content">
              <img alt="" onError={this.handleImageError} className="icon" src={imgSrc} />
              <div className="numbers">{item.value.toLocaleString()}</div>
              <div className="title">{item.title}</div>
            </div>
          </div>
        )
      })
    const tab_content5 = (
      <div className="tab_content_device_types">
        <div className="top5">{getTiles_device_name(device_name_top5)}</div>
        <JSONDatatable
          type={TYPE_FROM_ARRAY}
          parent_http_status={http_status}
          data={device_name_rest}
          sort={{ index: 1, asc: false }}
          getTableData={this.getTableData}
          usePagination={false}
        >
          <div ref={DATATABLE} />
        </JSONDatatable>
      </div>
    )

    const total = unique.length

    const index = (() => {
      switch (group_by) {
        case 'browser':
          return 1
        case 'device_type':
          return 2
        case 'platform_description':
          return 3
        case 'device_brand_name':
          return 4
        default:
          return 5
      }
    })()

    const tabContents = [tab_content1, tab_content2, tab_content3, tab_content4, tab_content5]

    return (
      <div ref="cardContainer" className="">
        <CardHeader imageUrl="/resources/images/event/performance/ticket-sales-ico.svg" bodyText="Devices" />
        <div className="table-background">
          {isLoading ? (
            <LoadingBar title={"Hold tight! We're getting your statistics..."} />
          ) : unique.length === 0 ? (
            <EmptyBar />
          ) : (
            <div ref="cardContainer" className="event-devices">
              <div className="total-buyers">
                <img
                  alt=""
                  className="total-image"
                  src={asset('/resources/images/event/devices/icon_top.svg')}
                />
                <div className="total-number">
                  <NumberAnimation
                    localeString={true}
                    isLoading={false}
                    initValue={0}
                    target={total}
                    duration={3000}
                    decimals={0}
                    useGroup={false}
                    animation={'up'}
                  />
                </div>
                <div className="total-description">Total Visitors</div>
              </div>
              <TabView
                all={false}
                selectedIndex={index}
                onSelectTab={index => {
                  const group_by = (() => {
                    switch (index) {
                      case 1:
                        return 'browser'
                      case 2:
                        return 'device_type'
                      case 3:
                        return 'platform_description'
                      case 4:
                        return 'device_brand_name'
                      default:
                        return 'device_name'
                    }
                  })()
                  this.setState({ group_by, isLoading: true }, () => {
                    this.fetchTraffic()
                  })
                }}
              >
                {tabTitles.map((tab, idx) => (
                  <Tab 
                    title={(
                      <div className="tab-title-category">
                        <img src={asset(`/resources/images/event/devices/${tab.img}.svg`)} />
                        {tab.title}
                      </div>
                    )}
                  >
                    {tabContents[idx]}
                  </Tab>
                 
                ))}
              </TabView>
            </div>
          )}
        </div>
      </div>
    )
  }
}
