import _orderBy from 'lodash/orderBy'
import _map from 'lodash/map'
import React from 'react'
import Modal from 'react-modal'
import moment from 'moment-timezone'

import { JSONDatatable, DATATABLE, PAGINATIONBAR, TYPE_FROM_URL, TYPE_FROM_ARRAY } from '../../_library/JSONDatatable'
import EmptyBar from '../../_library/EmptyBar'
import modalStyle from '../../../_common/core/modalStyle'
import Button from '../../_library/Button'

export default class SentEmails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      contentModalOpen: false,
      recipientsModalOpen: false,
      mailoutDisplayed: null
    }

    this.validateData = this.validateData.bind(this)
    this.getFilteredRows = this.getFilteredRows.bind(this)
    this.getSortedRows = this.getSortedRows.bind(this)
    this.getTableData = this.getTableData.bind(this)
    this.getClipboardText = this.getClipboardText.bind(this)
    this.closeContentModal = this.closeContentModal.bind(this)
    this.closeRecipientsModal = this.closeRecipientsModal.bind(this)
    this.validateMailoutRecipientsData = this.validateMailoutRecipientsData.bind(this)
    this.getFilteredMailoutRecipientsRows = this.getFilteredMailoutRecipientsRows.bind(this)
    this.getSortedMailoutRecipientsRows = this.getSortedMailoutRecipientsRows.bind(this)
    this.getMailoutRecipientsTableData = this.getMailoutRecipientsTableData.bind(this)
  }

  componentDidMount() {

  }

  validateData(data, index){
    return data
  }

  getFilteredRows(rows, search){
    return rows
  }

  getSortedRows(rows_filtered, sort){
    if(sort){
      const dir = sort.asc ? 'asc' : 'desc'
      switch(sort.index){
      case 0:
        rows_filtered = _orderBy(rows_filtered, (t)=>t.subject, dir)
        break
      case 1:
        rows_filtered = _orderBy(rows_filtered, (t)=>t.sent_by_first_name + ' ' + t.sent_by_last_name, dir)
        break
      case 2:
        rows_filtered = _orderBy(rows_filtered, (t)=>JSON.parse(t.recipients).length, dir)
        break
      case 3:
        rows_filtered = _orderBy(rows_filtered, (t)=>t.sent_date, dir)
        break
      default:
        break
      }
    }
    return rows_filtered
  }

  getTableData(datatable, rows_filtered, sort){
    const clipboard_text = this.getClipboardText(rows_filtered)
    const clipboard = datatable.getClipboardColumn(datatable, clipboard_text)

    const content_header = datatable.getHeaderRow(datatable, [
      { title: 'Subject', sort: true },
      { title: 'Sent By', sort: true },
      { title: 'No of Recipients', sort: true },
      { title: 'Date & Time', sort: true },
      { title: clipboard, sort: false, className: 'column-clipboard' }
    ], sort)

    return (rows_filtered.length != 0) ? (
      <table className="table sentemails-table">
        <thead>
          {content_header}
        </thead>
        <tbody>
          {
            rows_filtered.map((row) => (
              <tr key={row.id}>
                <td>{row.subject}</td>
                <td>{row.sent_by_first_name} {row.sent_by_last_name}</td>
                <td>{JSON.parse(row.recipients).length}</td>
                <td>{moment.utc(row.sent_date).format('DD MMM YYYY hh:mm a' )}</td>
                <td className="text-right">
                  <div className="btn btn-blue btn-shadow" onClick={this.showContentModal.bind(this, row)}>
                    <i className="fa fa-file-text" aria-hidden="true" />Show Content
                  </div>
                  <div className="btn btn-default btn-shadow" onClick={this.showRecipientsModal.bind(this, row)}>
                    <i className="fa fa-users" aria-hidden="true" />Show Recipients
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    ): (
      <EmptyBar/>
    )
  }

  getClipboardText(rows_filtered){
    let ret = ''
    ret += 'Subject' + '\t' + 'Sent By' + '\t' + 'No of Recipients' + '\t' + 'Date & Time' + '\n'
    _map(rows_filtered, (t)=>{
      ret += t.subject + '\t' + 
				(t.sent_by_first_name + ' ' + t.sent_by_last_name) + '\t' +
				(JSON.parse(t.recipients).length) + '\t' +
				t.sent_date + '\n'
    })
    return ret
  }

  showContentModal(mailout) {
    this.setState({
      contentModalOpen: true,
      mailoutDisplayed: mailout
    })
  }

  closeContentModal() {
    this.setState({
      contentModalOpen: false
    })
  }

  showRecipientsModal(mailout) {
    this.setState({
      recipientsModalOpen: true,
      mailoutDisplayed: mailout
    })
  }

  closeRecipientsModal() {
    this.setState({
      recipientsModalOpen: false
    })
  }

  validateMailoutRecipientsData(data, index){
    return data
  }

  getFilteredMailoutRecipientsRows(rows, search){
    return rows
  }

  getSortedMailoutRecipientsRows(rows_filtered, sort){
    if(sort){
      const dir = sort.asc ? 'asc' : 'desc'
      switch(sort.index){
      case 0:
        rows_filtered = _orderBy(rows_filtered, (t)=>t.billing_first_name, dir)
        break
      case 1:
        rows_filtered = _orderBy(rows_filtered, (t)=>t.billing_last_name, dir)
        break
      case 2:
        rows_filtered = _orderBy(rows_filtered, (t)=>t.email, dir)
        break
      }
    }
    return rows_filtered
  }

  getMailoutRecipientsTableData(datatable, rows_filtered, sort){
    const content_header = datatable.getHeaderRow(datatable, [
      { title: 'First Name', sort: true },
      { title: 'Last Name', sort: true },
      { title: 'Email', sort: true }
    ], sort)

    return (rows_filtered.length != 0) ? (
      <table className="table mailout-recipients-table">
        <thead>
          {content_header}
        </thead>
        <tbody>
          {
            rows_filtered.map((row) => (
              <tr key={row.id}>
                <td>{row.billing_first_name}</td>
                <td>{row.billing_last_name}</td>
                <td>{row.email}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    ): (
      <EmptyBar/>
    )
  }

  render() {
    const { contentModalOpen, recipientsModalOpen, mailoutDisplayed } = this.state
    const { id, originType, isEmailSent } = this.props

    return (
      <div className="SentEmails">
        <JSONDatatable 
          type={TYPE_FROM_URL}
          autoUpdate={isEmailSent}
          data={{ url: `/api/${originType}/${id}/relationships/mailouts`, node: 'data.mailouts.*' }}
          sort={{ index: 0, asc: false }}
          validateData={this.validateData}
          getFilteredRows={this.getFilteredRows}
          getSortedRows={this.getSortedRows}
          getTableData={this.getTableData}
          getClipboardText={this.getClipboardText}
          usePagination={false}
          loadingBarTitle={`Hold tight! We\'re getting your ${originType}\'s sent emails...`}
        >
          <div ref={DATATABLE}/> 
        </JSONDatatable>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={!!contentModalOpen}
          contentLabel="Modal"
          onRequestClose={this.closeContentModal}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Content of Sent Email</p>
                </div>
                <div className="mailout-content-preview">
                  <div dangerouslySetInnerHTML={{ __html: mailoutDisplayed && mailoutDisplayed.body }} />
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button className="btn btn-default" type="button" onClick={this.closeContentModal}>Close</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={!!recipientsModalOpen}
          contentLabel="Modal"
          onRequestClose={this.closeRecipientsModal}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Recipients of Sent Email</p>
                </div>
                <div className="mailout-recipients-preview">
                  {mailoutDisplayed && 
										<JSONDatatable
										  type={TYPE_FROM_ARRAY}
										  data={JSON.parse(mailoutDisplayed.recipients)}
										  sort={{ index: 0, asc: true }}
										  validateData={this.validateMailoutRecipientsData}
										  getFilteredRows={this.getFilteredMailoutRecipientsRows}
										  getSortedRows={this.getSortedMailoutRecipientsRows}
										  getTableData={this.getMailoutRecipientsTableData}
										  usePagination={true}
										  paginationPageSize={10}
										>
										  <div ref={DATATABLE}/> 
										  <div ref={PAGINATIONBAR}/>
										</JSONDatatable>
                  }
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button className="btn btn-default" type="button" onClick={this.closeRecipientsModal}>Close</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
