import React, { useEffect } from 'react'
import _isArray from 'lodash/isArray'
import _get from 'lodash/get'
import _forEach from 'lodash/forEach'
import _find from 'lodash/find'
import { connect } from 'react-redux'
import {
  FETCH_TICKET_TRACKING_DATA,
  DELETE_TICKET_TRACKING_DATA,
  REGENERATE_TRACKING_TOKEN,
  CREATE_TICKET_TRACKING_DATA,
} from '../../../_common/redux/ticketSalesTracking/actions'
import TrackingForm from './TrackingForm'
import TrackingTable from './TrackingTable'
import {
  get_event,
  get_event_addons,
  get_event_tickets,
  get_tracking_data,
} from '../../../_common/core/selectors'

const SalesTracking = props => {
  const {
    trackingData,
    tickets,
    addons,
    selectedEvent,
    FETCH_TICKET_TRACKING_DATA,
    DELETE_TICKET_TRACKING_DATA,
    REGENERATE_TRACKING_TOKEN,
    CREATE_TICKET_TRACKING_DATA,
    configs,
  } = props

  const ticketTypeIdKey = selectedEvent.flagTimeSlotsEnabled ? 'slotGroupId' : 'id'
  const ticketTypeParamKey = selectedEvent.flagTimeSlotsEnabled ? 'ticketGroupIds' : 'ticketTypeIds'

  const handleSubmit = values => {
    const dataForCreate = {
      name: values.name,
      items: {},
      hideDateBreakdowns: values.hideDateBreakdowns,
    }
    const [normalTickets] = ticketTypesSorting(tickets)

    if (values[ticketTypeParamKey]) {
      _forEach(values[ticketTypeParamKey], item => {
        const normalTicketDetected = _find(normalTickets, ticket => ticket.name === item.label)
        const ticketFlagName = normalTicketDetected ? 'ticketTypeIds' : 'ticketGroupIds'

        if (dataForCreate.items[ticketFlagName]) {
          dataForCreate.items[ticketFlagName].push(item.value)
        } else {
          dataForCreate.items[ticketFlagName] = [item.value]
        }
      })
    }

    if (values.addOnIds) {
      dataForCreate.items.addOnIds = values.addOnIds.map(item => item.value)
    }

    return Promise.resolve(CREATE_TICKET_TRACKING_DATA(selectedEvent.id, dataForCreate))
  }

  const buildTicketTypeOptions = () => {
    const [noGroupedTickets, groupedTickets] = ticketTypesSorting(tickets)
    const mergedTickets = [...noGroupedTickets, ...groupedTickets]

    return mergedTickets.map(item => {
      if (!_isArray(item)) {
        return {
          value: item.id,
          label: item.name,
        }
      }

      const { groupName, groupId } = item

      return {
        value: groupId,
        label: `${groupName} - Slots`,
      }
    })
  }

  const buildAddonOptions = () =>
    addons.map(item => ({
      value: item.id,
      label: item.name,
    }))

  const handleDeleteClick = data => {
    const { eventId, id } = data
    return Promise.resolve(DELETE_TICKET_TRACKING_DATA(eventId, id))
  }

  const handleRegenerateClick = data => {
    const { eventId, id } = data
    return Promise.resolve(REGENERATE_TRACKING_TOKEN(eventId, id))
  }

  useEffect(() => {
    if (selectedEvent) {
      FETCH_TICKET_TRACKING_DATA(selectedEvent.id)
    }
  }, [])

  return (
    <div className="sales_tracking_block">
      <TrackingForm
        handleSubmit={handleSubmit}
        addOnOptions={buildAddonOptions()}
        ticketTypeOptions={buildTicketTypeOptions()}
        ticketTypeIdKey={ticketTypeIdKey}
        ticketTypeParamKey={ticketTypeParamKey}
        configs={_get(configs, 'children.TrackingForm')}
      />
      {!!trackingData.length && (
        <div className="tracking_table_section" style={{ marginTop: '15px' }}>
          <div className="row">
            <div className="col-xs-12">
              <TrackingTable
                data={trackingData}
                addons={addons}
                handleDeleteClick={handleDeleteClick}
                handleRegenerateClick={handleRegenerateClick}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const selectedEvent = get_event(state)
  const tickets = get_event_tickets(state)
  const addons = get_event_addons(state)
  const trackingData = get_tracking_data(state)
  const isTimeSlotEvent = _get(selectedEvent, 'flagTimeSlotsEnabled') || false

  return {
    trackingData,
    tickets,
    addons,
    selectedEvent,
    isTimeSlotEvent,
  }
}
const mapDispatchToProps = {
  FETCH_TICKET_TRACKING_DATA,
  DELETE_TICKET_TRACKING_DATA,
  REGENERATE_TRACKING_TOKEN,
  CREATE_TICKET_TRACKING_DATA,
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesTracking)
