import React from 'react'
import _identity from 'lodash/identity'

const RadioGroupField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  touchedOnBlur = false,
  normalizer = _identity,
  removeSpacing = false,
  groups = [], // [{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}, {label: 'Other', value: 'other', component: (<input type='text' />)}]
  classNames = {
    // this is default classNames
    container: '',
    container_item: '',
    component: ''
  },
  extraDiv,
  onClickContainers,
  ...props
}) => {
  const setValue = value => {
    setFieldValue(field.name, normalizer(value))
  }
  const onChange = value => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value)
    }
  }
  return (
    <RadioGroup
      groups={groups}
      value={field.value}
      name={field.name}
      classNames={classNames}
      removeSpacing={removeSpacing}
      onChange={onChange}
      extraDiv={extraDiv}
      onClickContainers={onClickContainers}
    />
  )
}

export { RadioGroupField }

export const RadioGroup = ({
  groups,
  value,
  name,
  classNames = { container_item: '', container: '', component: '' },
  removeSpacing,
  onChange = _identity,
  extraDiv = false,
  onClickContainers
}) => {
  const groupsEls = groups.map((group, idx) => {
    const checked = value === group.value
    if (extraDiv) {
      return (
        <div className={classNames.container_item} key={group.value} onClick={onClickContainers ? onClickContainers[idx] : undefined }>
          <div className='extra-div'>
            <div className='label-check-group'>
              <input
                type="radio"
                id={`rg_${group.value}`}
                name={name}
                value={group.value}
                checked={checked}
                onChange={e => onChange(e.target.value)}
              />
              <div className="check" />
              <label htmlFor={`rg_${group.value}`}>{ !removeSpacing && <span>&ensp;</span>}{group.label}</label>
            </div>
          </div>
          {checked && !!group.component && (
            <div className={classNames.component}>{group.component}</div>
          )}
        </div>
      )
    }
    return (
      <div className={classNames.container_item} key={group.value}>
        <div className='label-check-group'>
          <input
            type="radio"
            id={`rg_${group.value}`}
            name={name}
            value={group.value}
            checked={checked}
            onChange={e => onChange(e.target.value)}
          />
          <div className="check" />
          <label htmlFor={`rg_${group.value}`}>{ !removeSpacing && <span>&ensp;</span>}{group.label}</label>
        </div>
        {checked && !!group.component && (
          <div className={classNames.component}>{group.component}</div>
        )}
      </div>
    )
  })

  return <div className={classNames.container}>{groupsEls}</div>
}
