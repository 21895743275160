import React, { useEffect } from 'react'
import { Field, Form, withFormik } from 'formik'
import _set from 'lodash/set'
import _get from 'lodash/get'
import { withRouter } from 'react-router'
import Button from '../../_library/Button'
import { combineValidators, isEmailValidator, requiredValidator } from '../../../_common/core/validation'
import { PasswordField } from '../../formik/Fields/PasswordField'
import { RadioGroupField, SimpleField } from '../../formik/Fields'
import { movedToCreateBrand } from '../../../_common/core/gtag'

const CreateAccountForm = props => {
  const { isSubmitting, isValid, setFieldValue } = props
  const handleGoBack = () => {
    props.history.goBack()
  }
  useEffect(() => {
    setFieldValue('best_describe', props?.best_describe ?? '')
  }, [setFieldValue, props?.best_describe])
  return (
    <Form className="margin-horizontal-25 account-form-container">
      <div className="title-form">Create your account</div>
      <Field
        type="firstName"
        name="firstName"
        label="First Name"
        component={SimpleField}
        formclassname="field-wrapper first-name-input"
        validate={requiredValidator()}
        touchedOnBlur
      />
      <Field
        type="lastName"
        name="lastName"
        label="Last Name"
        component={SimpleField}
        formclassname="field-wrapper responsive-input"
        validate={requiredValidator()}
        touchedOnBlur
      />
      <Field
        type="email"
        name="email"
        label="Email"
        component={SimpleField}
        formclassname="field-wrapper margin-top-24"
        validate={combineValidators(requiredValidator(), isEmailValidator('Invalid'))}
        touchedOnBlur
      />
      <Field
        type="password"
        name="password"
        label="Password"
        component={PasswordField}
        className="margin-top-24"
        validate={props?.skipCreateAccount ? undefined : requiredValidator()}
      />
      <Field
        type="password"
        name="confirmPassword"
        label="Confirm Password"
        component={PasswordField}
        className="responsive-input"
        validate={props?.skipCreateAccount ? undefined : requiredValidator()}
      />
      <div className='field-wrapper margin-top-24'>
        <label className='label-best-describe'>What best describe you?</label>
        <Field
          type="best_describe"
          name={'best_describe'}
          component={RadioGroupField}
          validate={requiredValidator()}
          groups={[
            {
              label: 'I want to Buy or Resell Tickets',
              value: '3'
            },
            {
              label: 'Event Promoter/Manager/Marketing',
              value: '1'
            },
            {
              label: 'Venue Owner/Manager/Marketing',
              value: '2'
            },
          ]}
          classNames={{
            container: 'radio-group-container single-column',
            container_item: 'radio-group-item',
            component: ''
          }}
        />
      </div>
      <div className="buttons-wrapper">
        <Button type="button" className="back-button" disabled={isSubmitting} onClick={handleGoBack}>
          Back
        </Button>
        <Button
          type="submit"
          className={!isValid ? 'submit-disabled' : 'submit-button-login'}
          disabled={!isValid}
          loading={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </Form>
  )
}

const CreateAccountFormFormik = withFormik({
  mapPropsToValues: props => ({
    email: props.email ?? '',
    password: (props?.skipCreateAccount && !props.goBack) ? '' : (props.password ?? ''),
    lastName: props.lastName ?? '',
    firstName: props.firstName ?? '',
    confirmPassword: (props?.skipCreateAccount && !props.goBack) ? '' : (props.confirmPassword ?? ''),
    best_describe: ''
  }),

  validate: (values, props) => {
    const errors = {}
    const password = _get(values, 'password')
    const confirmPassword = _get(values, 'confirmPassword')
    if (props?.skipCreateAccount && password === '' && confirmPassword === '') {
      return errors
    }
    if (confirmPassword !== password) {
      _set(errors, 'confirmPassword', 'The value must match your password')
    }

    if (password && password.length < 6) {
      _set(errors, 'password', 'Password must be at least 6 characters')
    }

    if (password && password.length > 16) {
      _set(errors, 'password', 'Password must be less than 16 characters')
    }

    return errors
  },

  handleSubmit: async ({
    email,
    password,
    lastName,
    firstName,
    confirmPassword,
    best_describe
  }, { props, setSubmitting }) => {
    try {
      const newPassword = (props?.skipCreateAccount && password === '') ? undefined : password
      const newConfirmPassword = (props?.skipCreateAccount && confirmPassword === '')
        ? undefined
        : confirmPassword
      const user = {
        email,
        password: newPassword,
        lastName,
        firstName,
        confirmPassword: newConfirmPassword,
        best_describe,
      }
      await props.onSubmit(user)
      localStorage.setItem(
        'createAccountState',
        JSON.stringify({
          ...user,
          skipCreateAccount: true,
          goBack: true
        })
      )
      setSubmitting(false)
      props.history.push('/welcome/create-brand', {
        email,
        password,
        lastName,
        firstName,
        confirmPassword,
        best_describe,
        skipCreateAccount: props?.skipCreateAccount
      })
      movedToCreateBrand()
    } catch (e) {
      setSubmitting(false)
    }
  },

  displayName: 'CreateAccountForm'
})(CreateAccountForm)

const CreateAccountFormRouter = withRouter(CreateAccountFormFormik)

export default CreateAccountFormRouter
