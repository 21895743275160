import React from 'react'
import PropTypes from 'prop-types'
import _debounce from 'lodash/debounce'
import _find from 'lodash/find'
import _get from 'lodash/get'
import moment from 'moment'

import Card from '../../../../_library/Card'
import { Map } from '../../../../_library/Map'
import Field from '../../../../_library/Field'
import RichTextArea from '../../../../_library/RichTextArea'

import { isDateRangeInvalid, parsePlaceResult } from '../utils'
import { ENV } from '../../../../constants/env'
import EventVenueSearch from './EventVenueSearch'
import EventVenueFlags from './EventVenueFlags'
import Select from '../../../../_library/Select'
import CountryCurrencyForm from './CountryCurrencyForm'
import { getCurrenciesFromCountry, getCurrencyObj, countryCodeAdapter } from '../../../../utils/coutriesUtils'
import { countryName, default as localCountries } from '../../../../../_common/core/countries'
import { OverlayTrigger } from '../../../../_library/OverlayTrigger'

const cityOptions = [
  { value: '', label: 'Select', state: '' },
  { value: 'los angeles', label: 'Los Angeles', state: 'CA' },
  { value: 'new york', label: 'New York', state: 'NY' },
  { value: 'san francisco', label: 'San Francisco', state: 'CA' },
  { value: 'austin', label: 'Austin', state: 'TX' },
]

const rsvpBlockBreakPointWidth = 991

export default class EventVenue extends React.PureComponent {
  static propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    updateField: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props)
    const {
      city,
      country,
      googlePlaceId,
      id,
      latitude,
      longitude,
      postalCode,
      state,
      street,
      streetNumber,
      venueName,
    } = props.values

    const venue =
      latitude && longitude
        ? {
          city,
          country,
          googlePlaceId,
          id,
          latitude,
          longitude,
          postalCode,
          state,
          street,
          streetNumber,
          venueName,
        }
        : null

    this.state = {
      venue,
      address: null,
      geocoding: false,
      geocoder: global.google ? new google.maps.Geocoder() : null,
      flagRsvpInfo: false,
      rsvpInfoContent: props.values.rsvpAlternativeInformation,
      isMdSize: window.innerWidth <= rsvpBlockBreakPointWidth,
      mapLocations: venue ? [{ ...venue, displayName: venue.venueName }] : [],
      forceUpdate: false,
    }

    this.handleChangeAddress = _debounce(this.handleChangeAddress, 500)
  }

  handleChangeVenueName = e => {
    const { updateField } = this.props
    const str = e.target.value
    updateField('venueName', str)
  }

  handleResetDetailsValues = () => {
    const { updateField } = this.props

    updateField('searchValue', '')
    updateField('venueName', '')
    updateField('streetNumber', '')
    updateField('street', '')
    updateField('city', '')
    updateField('region', '')
    updateField('state', '')
    updateField('postalCode', '')
    updateField('googlePlaceId', undefined)
    updateField('longitude', undefined)
    updateField('latitude', undefined)
  }

  handleChangeCountry = value => {
    const { updateField, globalCountries, values, brand } = this.props
    // Reset Changed Country Venue All Fields
    if (values?.country !== value) {
      this.handleResetDetailsValues()
    }

    const countryValue = value.target ? value.target.value : value
    const selectedCountryObj = _find(localCountries, item => item.name.common === countryValue)
    const selectedCountry = selectedCountryObj ? selectedCountryObj.cca2.toLowerCase() : ''
    const currencies = getCurrenciesFromCountry(globalCountries, countryValue)
    const customCurrency = currencies.length ? currencies[0].toLowerCase() : null
    const brandCountryUpper = countryCodeAdapter(brand?.country, 'uk', 'gb').toUpperCase()

    if (countryName(brand?.country || '') === countryValue && values.flagPaymentDetails) {
      updateField('countryCode', '')
    } else if (values.flagPaymentDetails && brandCountryUpper) {
      updateField('countryCode', brandCountryUpper)
    }
    updateField('country', countryValue)
    if (
      countryValue === 'Mexico' &&
      values.country.includes('Mexico') &&
      values.currency.currency === 'USD'
    ) {
      return
    }
    if (
      countryValue === 'Croatia' &&
      values.country.includes('Croatia') &&
      values.currency.currency === 'GBP'
    ) {
      return
    }
    if (
      countryValue === 'Jamaica' &&
      values.country.includes('Jamaica') &&
      values.currency.currency === 'USD'
    ) {
      return
    }
    if (
      countryValue === 'Colombia' &&
      values.country.includes('Colombia') &&
      values.currency.currency === 'USD'
    ) {
      return
    }
    if (
      countryValue === 'Dominican Republic' &&
      values.country.includes('Dominican Republic') &&
      values.currency.currency === 'USD'
    ) {
      return
    }
    if (
      countryValue === 'Barbados' &&
      values.country.includes('Jamaica') &&
      values.currency.currency === 'USD'
    ) {
      return
    }
    updateField('currency', getCurrencyObj(selectedCountry, customCurrency))
  }

  onCurrencyChange = value => {
    const { updateField, values } = this.props
    const selectedCountryObj = _find(localCountries, item => item.name.common === values.country)
    const selectedCountry = selectedCountryObj ? selectedCountryObj.cca2.toLowerCase() : ''
    updateField('currency', getCurrencyObj(selectedCountry, value))
  }

  handleSelectChange = (e, fieldId) => {
    const { updateField } = this.props
    const { value } = e.target
    const selectedRegion = _find(cityOptions, c => c.value === value)
    updateField('state', selectedRegion.state)
    updateField(fieldId, value)
  }

  updateVenue = venue => {
    this.setState(() => ({
      venue,
      mapLocations: venue ? [{ ...venue, displayName: venue.venueName }] : [],
    }))

    const { values } = this.props
    const hasSales = _get(values, 'hasSales')
    if (!hasSales) {
      this.handleChangeCountry(venue.country)
    }
  }

  handleChangeField = e => {
    const { values, updateField, isRnDomain } = this.props
    const field = e.target.id
    const { value } = e.target

    if (field === 'state' && isRnDomain) {
      updateField(field, value ? value.slice(0, 2).toUpperCase() : value)
    } else {
      updateField(field, value)
    }

    const { geocoder } = this.state
    if (!geocoder) {
      return
    }

    const streetLine = values.street ? [values.streetNumber, values.street].filter(Boolean).join(' ') : ''
    const cityLine = [values.city, values.state].filter(Boolean).join(' ')
    const countryLine = [values.country, values.postalCode].filter(Boolean).join(' ')
    const address = [streetLine, cityLine, countryLine].filter(Boolean).join(', ')
    if (this.state.address === address) {
      return
    }

    updateField('googlePlaceId', undefined)
    updateField('longitude', undefined)
    updateField('latitude', undefined)
    this.setState(() => ({ address }))
    if (!address) {
      return
    }

    this.handleChangeAddress(address, geocoder)
  }

  handleChangeAddress = (address, geocoder) => {
    const { updateField } = this.props
    this.setState(() => ({ geocoding: true }))
    const p = new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        const { OK, ZERO_RESULTS, REQUEST_DENIED } = google.maps.GeocoderStatus
        this.setState(() => ({ geocoding: false }))
        if (status === ZERO_RESULTS || status === REQUEST_DENIED) {
          resolve({ query: address, venue: null })
          return
        }
        if (status !== OK || !results) {
          reject(status)
          return
        }
        resolve({ query: address, venue: parsePlaceResult(results[0]) })
      })
    })

    p.then(res => {
      let { venue } = res
      const { query } = res
      if (!venue) {
        return
      }
      if (query === address) {
        updateField('googlePlaceId', venue.googlePlaceId)
        updateField('longitude', venue.longitude)
        updateField('latitude', venue.latitude)
        venue = {
          ...venue,
          id: venue.googlePlaceId,
        }
        this.setState({
          venue,
          mapLocations: venue ? [{ ...venue, displayName: venue.venueName }] : [],
        })
      }
      return venue
    }).catch(() => {})
  }

  handleChangeLocationInfo = val => {
    const { updateField } = this.props
    updateField('locationInformation', val)
  }

  forceUpdate = () => {
    this.setState({ forceUpdate: true }, () => {
      this.setState({ forceUpdate: false })
    })
  }

  render() {
    const {
      values,
      errors,
      touched,
      cardsStatus,
      updateField,
      isRnDomain,
      closed,
      globalCountries,
      isNew,
      event,
      hasVenueChanged,
      configs,
      seatMapId,
    } = this.props
    const { venue, flagRsvpInfo, isMdSize, rsvpInfoContent, mapLocations, forceUpdate } = this.state
    const salesStartDate = _get(values, 'salesStartDate')
    const hasSales = _get(values, 'hasSales')
    const flagSeatMapAllowed = _get(event, 'flagSeatMapAllowed', false)
    const isCurrencyChangeDisabled =
      isNew || !hasSales ? false : salesStartDate ? isDateRangeInvalid(moment.now(), salesStartDate) : true
    return (
      <Card
        icon={'fa-envelope-open'}
        title={'Venue Details'}
        status={cardsStatus && cardsStatus.venue}
        closed={closed}
      >
        <div className="row">
          <div className="col-md-5 venues-left">
            <OverlayTrigger
              id="venueCountryTooltip"
              tooltip="Notice that any change in venue details will reset the seat map configurations."
              placement="top"
              hideTooltip={!seatMapId}
            >
              <div>
                <EventVenueSearch
                  refs={this.refs}
                  values={values}
                  venue={venue}
                  updateVenue={this.updateVenue}
                  updateField={updateField}
                  isRnDomain={isRnDomain}
                  event={event}
                />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              id="venueCountryTooltip"
              tooltip="Notice that any change in venue details will reset the seat map configurations."
              placement="top"
              hideTooltip={!seatMapId}
            >
              <div>
                <CountryCurrencyForm
                  globalCountries={globalCountries}
                  values={values}
                  errors={errors}
                  touched={touched}
                  isCurrencyChangeDisabled={isCurrencyChangeDisabled}
                  onChange={this.handleChangeCountry}
                  onCurrencyChange={this.onCurrencyChange}
                />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              id="venueNameTooltip"
              tooltip="Notice that any change in venue details will reset the seat map configurations."
              placement="top"
              hideTooltip={!seatMapId}
            >
              <Field
                id="venueName"
                label="Venue name"
                value={values.venueName}
                error={errors.venueName}
                touched={touched.venueName}
                onChange={this.handleChangeVenueName}
                disabled={hasSales && flagSeatMapAllowed}
              />
            </OverlayTrigger>
            <OverlayTrigger
              id="streetNumberhTooltip"
              tooltip="Notice that any change in venue details will reset the seat map configurations."
              placement="top"
              hideTooltip={!seatMapId}
            >
              <Field
                id="streetNumber"
                label="Street Number"
                value={values.streetNumber}
                error={errors.streetNumber}
                touched={touched.streetNumber}
                onChange={this.handleChangeField}
                disabled={hasSales && flagSeatMapAllowed}
              />
            </OverlayTrigger>
            <OverlayTrigger
              id="streetTooltip"
              tooltip="Notice that any change in venue details will reset the seat map configurations."
              placement="top"
              hideTooltip={!seatMapId}
            >
              <Field
                id="street"
                label="Street"
                value={values.street}
                error={errors.street}
                touched={touched.street}
                onChange={this.handleChangeField}
                disabled={hasSales && flagSeatMapAllowed}
              />
            </OverlayTrigger>
            <OverlayTrigger
              id="streetTooltip"
              tooltip="Notice that any change in venue details will reset the seat map configurations."
              placement="top"
              hideTooltip={!seatMapId}
            >
              <Field
                id="city"
                label="City"
                value={values.city}
                error={errors.city}
                touched={touched.city}
                onChange={this.handleChangeField}
                disabled={hasSales && flagSeatMapAllowed}
              />
            </OverlayTrigger>
            {isRnDomain && (
              <Select
                fieldKey="region"
                label="Region"
                style={{ margin: 0 }}
                value={values.region}
                error={errors.region}
                touched={touched.region}
                onChange={this.handleSelectChange}
                options={cityOptions}
                disabled={hasSales && flagSeatMapAllowed}
              />
            )}
            <div className="row">
              <div className="col-xs-6">
                <OverlayTrigger
                  id="streetTooltip"
                  tooltip="Notice that any change in venue details will reset the seat map configurations."
                  placement="top"
                  hideTooltip={!seatMapId}
                >
                  <Field
                    id="state"
                    label="State"
                    value={values.state}
                    error={errors.state}
                    touched={touched.state}
                    onChange={this.handleChangeField}
                    disabled={hasSales && flagSeatMapAllowed}
                  />
                </OverlayTrigger>
              </div>
              <div className="col-xs-6">
                <OverlayTrigger
                  id="streetTooltip"
                  tooltip="Notice that any change in venue details will reset the seat map configurations."
                  placement="top"
                  hideTooltip={!seatMapId}
                >
                  <Field
                    id="postalCode"
                    label="Postal Code"
                    value={values.postalCode}
                    error={errors.postalCode}
                    touched={touched.postalCode}
                    onChange={this.handleChangeField}
                    disabled={hasSales && flagSeatMapAllowed}
                  />
                </OverlayTrigger>
              </div>
            </div>
            <EventVenueFlags
              forceUpdate={this.forceUpdate}
              event={event}
              flagRsvpInfo={flagRsvpInfo}
              handleRsvpInfoCheckbox={this.handleRsvpInfoCheckbox}
              values={values}
              updateField={updateField}
              hasVenueChanged={hasVenueChanged}
              configs={_get(configs, 'children.EventVenueFlags')}
            />
            <div ref="gattr" />
          </div>
          {flagRsvpInfo && isMdSize && (
            <div className="col-xs-12">
              <RichTextArea
                id="rsvpAlternativeInformation"
                baseurl={ENV.CDN_URL}
                value={rsvpInfoContent}
                onChange={this.handleRsvpTextChange}
                placeholder={{ text: 'Enter text here...' }}
              />
            </div>
          )}
          <div className="col-xs-12 location-info-top">
            <RichTextArea
              ref={'locationInformation'}
              id={'locationInformation'}
              label="Additional Location Information"
              baseurl={ENV.CDN_URL}
              value={values.locationInformation}
              onChange={this.handleChangeLocationInfo}
              placeholder={{ text: 'Enter text here...' }}
            />
          </div>
          <div className="col-md-7 venues-right">
            <div className="venue-map">
              <Map locations={mapLocations} />
            </div>
          </div>
          {flagRsvpInfo && !isMdSize && (
            <div className="col-xs-12">
              <RichTextArea
                id="rsvpAlternativeInformation"
                baseurl={ENV.CDN_URL}
                value={rsvpInfoContent}
                onChange={this.handleRsvpTextChange}
                placeholder={{ text: 'Enter text here...' }}
              />
            </div>
          )}
          <div className="col-xs-12 location-info-bottom">
            <RichTextArea
              id={'locationInformation'}
              label="Additional Location Information"
              baseurl={ENV.CDN_URL}
              value={values.locationInformation}
              onChange={this.handleChangeLocationInfo}
              placeholder={{ text: 'Enter text here...' }}
              forceUpdate={forceUpdate}
            />
          </div>
        </div>
      </Card>
    )
  }

  handleRsvpInfoCheckbox = () => {
    const { updateField, values } = this.props
    this.setState(prevState => {
      let rsvpInfoContent = null
      if (prevState.flagRsvpInfo) {
        rsvpInfoContent = values.rsvpAlternativeInformation
        updateField('rsvpAlternativeInformation', null)
      } else {
        updateField('rsvpAlternativeInformation', this.state.rsvpInfoContent)
      }
      const newState = { flagRsvpInfo: !prevState.flagRsvpInfo }
      if (rsvpInfoContent) {
        newState.rsvpInfoContent = rsvpInfoContent
      }
      return newState
    })
  }

  handleRsvpTextChange = value => {
    this.setState({ rsvpInfoContent: value }, () => {
      this.props.updateField('rsvpAlternativeInformation', value)
    })
  }

  handleWindowResize = e => {
    const { isMdSize } = this.state
    const { innerWidth } = e.target
    if (innerWidth <= rsvpBlockBreakPointWidth) {
      !isMdSize &&
        this.setState(() => ({
          isMdSize: true,
        }))
    } else {
      isMdSize &&
        this.setState(() => ({
          isMdSize: false,
        }))
    }
  }

  async componentDidMount() {
    const { values } = this.props
    if (values && values.rsvpAlternativeInformation) {
      this.setState(() => ({ flagRsvpInfo: true }))
    }
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }
}
