import React from 'react'
import _identity from 'lodash/identity'

const CheckboxField = ({
  squareCheckbox,
  field,
  form: { touched, errors, setFieldValue, setFieldTouched, values },
  touchedOnBlur = false,
  normalizer = _identity,
  label,
  labelProps = {},
  containerProps = {},
  ...props
}) => {
  const setValue = value => {
    setFieldValue(field.name, normalizer(value))
  }
  const onChange = ({ target: { checked }, target }) => {
    if (squareCheckbox) {
      const field = target.name
      setFieldValue(field, checked)
    } else {
      setValue(checked)
    }
  }
  const onBlur = ({ target: { checked } }) => {
    setValue(checked)
    touchedOnBlur && setFieldTouched(field.name, checked)
  }
  if (squareCheckbox) {
    return (
      <label
        className="duplicate-event-title"
        onClick={e => e.stopPropagation()}
        onKeyDown={e => e.stopPropagation()}
      >
        {field.title}
        <input
          name={field.fieldName}
          type="checkbox"
          className="duplicate-event-title-input"
          onChange={onChange}
          checked={values[field.fieldName]}
        />
        <span className="checkmark" />
      </label>
    )
  }
  return (
    <div>
      <div className="checkbox-switch" {...containerProps}>
        <input
          {...{ ...field, checked: field.value }}
          {...props}
          type="checkbox"
          id={field.name}
          onChange={onChange}
          onBlur={onBlur}
        />
        <label htmlFor={field.name} />
      </div>
      {label && <label {...labelProps}>{label}</label>}
    </div>
  )
}

export { CheckboxField }
