import _find from 'lodash/find'
import _map from 'lodash/map'
import moment from 'moment'
import {
  FORMAT,
  DAY_FORMAT,
  DAY_DISPLAY_FORMAT,
  TIME_FORMAT,
  TIME_DISPLAY_FORMAT
} from '../constants/timeFormats'

export const getGroupedTickets = tickets => {
  const ticketWithTimeSlot = _find(tickets, t => !!t.slotGroupName)
  if (!ticketWithTimeSlot) {
    return tickets
  }

  let groupedTickets = {}
  _map(tickets, (t, i) => {
    if (t.slotGroupName) {
      const arr = groupedTickets[t.slotGroupName] || []
      arr.push(t)
      groupedTickets = {
        ...groupedTickets,
        [t.slotGroupName]: arr
      }
    } else {
      groupedTickets = { ...groupedTickets, [i]: t }
    }
  })

  return groupedTickets
}

export const getDayOptions = (tickets, ticketTypeID) => {
  const dayOptions = [{ value: '', label: 'Day' }]
  _map(tickets[ticketTypeID], (t, i) => {
    const dateTime = t.slotStartDate
    if (
      i === 0 ||
      getDayFromDate(dateTime) !== getDayFromDate(tickets[ticketTypeID][i - 1].slotStartDate)
    ) {
      dayOptions.push({
        value: getDayFromDate(dateTime),
        label: getDisplayDayFromDate(dateTime),
        timeOptions: [
          { value: '', label: 'Time' },
          {
            value: getTimeFromDate(dateTime),
            label: getDisplayTimeFromDate(dateTime)
          }
        ]
      })
    } else {
      const dayOption = _find(dayOptions, d => d.value === getDayFromDate(dateTime))
      dayOption.timeOptions.push({
        value: getTimeFromDate(dateTime),
        label: getDisplayTimeFromDate(dateTime)
      })
    }
  })
  return dayOptions
}

export const getDayFromDate = date =>  // 11-23-2019
  moment(date, FORMAT).format(DAY_FORMAT)


export const getDisplayDayFromDate = date =>  // 23 Nov 2019
  moment(date, FORMAT).format(DAY_DISPLAY_FORMAT)


export const getTimeFromDate = date =>  // 10:00:00
  moment(date, FORMAT).format(TIME_FORMAT)


export const getDisplayTimeFromDate = date =>  // 1:00 pm
  moment(date, FORMAT).format(TIME_DISPLAY_FORMAT)

