import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { CANCEL_EVENT } from '../../../_common/redux/events/actions'
import Button from '../../_library/Button'
import ConfirmModal from '../../_library/ConfirmModal'
import { toastNotify } from '../../../_common/core/ui_services'
import { get_event } from '../../../_common/core/selectors'
import { OverlayTrigger } from '../../_library/OverlayTrigger'
import { SUPPORT_EMAIL } from '../../constants/events'

const CancelEvent = ({
  event: { id, flagCancelled: eventIsCancelled, flagFundsRemitted: eventHasBeenMarkedAsPaid, endDate },
  CANCEL_EVENT
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)

  const endDateHasPassed = moment.utc().isAfter(endDate)
  const disabled = eventIsCancelled || (eventHasBeenMarkedAsPaid || endDateHasPassed)
  const showPromptText = eventHasBeenMarkedAsPaid || endDateHasPassed

  const handleOnCancel = () => {
    setModalIsOpen(true)
  }

  const handleModalResponse = response => {
    switch (response) {
      case 'confirm':
        setCancelLoading(true)
        // Cancel functional
        CANCEL_EVENT(id)
          .then(() => {
            toastNotify.success('This event has been marked as canceled.')
          })
          .finally(() => {
            setModalIsOpen(false)
            setCancelLoading(false)
          })
        break
      case 'cancel':
        setModalIsOpen(false)
        break
      default:
        break
    }
  }

  return (
    <div className="event-cancel-container">
      <ConfirmModal
        isOpen={modalIsOpen}
        header="Cancel this event"
        content={
          <div>
            <p>
              This event will be marked as canceled and all ticketholders automatically issued full face value
              refunds within 1-2 business days.
            </p>
            <p>Please confirm you would like to cancel and refund all tickets for this event.</p>
          </div>
        }
        actions={[
          {
            value: 'cancel',
            label: 'Cancel',
            className: 'btn btn-cancel-transparent',
            disabled: cancelLoading
          },
          { value: 'confirm', label: 'Confirm', className: 'btn btn-danger', loading: cancelLoading }
        ]}
        classNames={{
          modal: 'modal-dialog modal-trans',
          container: 'modal-dialog modern-modal-dialog cancel-event-modal-dialog'
        }}
        onAction={handleModalResponse}
      />
      <OverlayTrigger
        id="email-info"
        placement="top"
        tooltip={`Please email ${SUPPORT_EMAIL} if you need to cancel your event.`}
        hideTooltip={!showPromptText}
      >
        <div>
          <Button
            className="btn btn-danger"
            type="button"
            onClick={handleOnCancel}
            disabled={disabled}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
          >
            <img className="default-icosize" src={asset('/resources/images/cross-ico.svg')} alt="" />
            Cancel Event
          </Button>
        </div>
      </OverlayTrigger>
    </div>
  )
}

export default connect(
  state => {
    const event = get_event(state)
    return { event }
  },
  { CANCEL_EVENT }
)(CancelEvent)
