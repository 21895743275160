import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import moment from 'moment'
import { POSTPONE_EVENT, DISCLOSE_EVENT } from '../../../_common/redux/events/actions'
import { OverlayTrigger } from '../../_library/OverlayTrigger'
import Button from '../../_library/Button'
import ConfirmModal from '../../_library/ConfirmModal'
import { RadioGroupField, DateTimePickerFieldReact, TextAreaField } from '../../formik/Fields'
import { requiredValidator } from '../../../_common/core/validation'
import { toastNotify } from '../../../_common/core/ui_services'
import { get_event } from '../../../_common/core/selectors'
import { showAxiosError } from '../../utils/messenger'
import { SUPPORT_EMAIL } from '../../constants/events'

const PostponeEvent = ({
  event: {
    id,
    flagCancelled: eventIsCancelled,
    flagFundsRemitted: eventHasBeenMarkedAsPaid,
    endDate,
    flagAllowCustomerRefund,
    flagHideDate
  },
  POSTPONE_EVENT = () => {},
  DISCLOSE_EVENT = () => {}
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [discloseModalIsOpen, setDiscloseModalIsOpen] = useState(false)
  const [showRefundPolicySection, setShowRefundPolicySection] = useState(false)
  const [postponeRequestLoading, setPostponeRequestLoading] = useState(false)
  const [discloseRequestLoading, setDiscloseRequestLoading] = useState(false)
  const [postponeConfirmModalIsOpen, setPostponeConfirmModalIsOpen] = useState(false)
  const formikRenderProps = useRef()

  const endDateHasPassed = moment.utc().isAfter(endDate)
  const disabled = eventIsCancelled || eventHasBeenMarkedAsPaid || endDateHasPassed
  const showPromptText = !eventIsCancelled && (eventHasBeenMarkedAsPaid || endDateHasPassed)

  const handleOnDisclose = () => {
    setDiscloseModalIsOpen(true)
  }

  const handleEventPostponse = async () => {
    const { values } = formikRenderProps.current

    try {
      const {
        refundPolicy,
        dateAction,
        postponedStart,
        postponedEnd,
        postponedUntilField,
        refundPolicyText
      } = values

      setPostponeRequestLoading(true)
      const body = {
        flagPostponed: true,
        flagHideDate: dateAction === 'hideNewDate',
        startDate: postponedStart,
        endDate: postponedEnd,
        postponedUntil: postponedUntilField,
        flagAllowCustomerRefund: refundPolicy === 'flagAllowCustomerRefund',
        flagAllowCustomerRefundBeforePostponed: refundPolicy === 'flagAllowCustomerRefundBeforePostponed',
        refundPolicy: refundPolicyText
      }

      await POSTPONE_EVENT(id, body)
      setPostponeConfirmModalIsOpen(false)
      setPostponeRequestLoading(false)
      setShowRefundPolicySection(false)
      setModalIsOpen(false)
      toastNotify.success('This event has been marked as postponed.')
    } catch (e) {
      setPostponeConfirmModalIsOpen(false)
      setPostponeRequestLoading(false)
      setShowRefundPolicySection(false)
      setModalIsOpen(false)
      showAxiosError(e)
    }
  }

  const handlePostponeModalResponse = response => {
    switch (response) {
      case 'confirm':
        const { handleSubmit } = formikRenderProps.current

        if (showRefundPolicySection) {
          setModalIsOpen(false)
          setPostponeConfirmModalIsOpen(true)
          return
        }
        handleSubmit()
        break
      case 'cancel':
        if (showRefundPolicySection) {
          setShowRefundPolicySection(false)
          return
        }
        setModalIsOpen(false)
        break
      default:
        break
    }
  }

  const handleDiscloseModalResponse = async response => {
    switch (response) {
      case 'ok':
        setDiscloseRequestLoading(true)
        try {
          await DISCLOSE_EVENT(id)
          toastNotify.success('Successfully disclosed')
        } finally {
          setDiscloseRequestLoading(false)
          setDiscloseModalIsOpen(false)
        }
        break
      case 'cancel':
        setDiscloseModalIsOpen(false)
        break
      default:
        break
    }
  }

  const handlePostponeConfirmationResponse = async response => {
    switch (response) {
      case 'confirm':
        handleEventPostponse()
        break
      case 'cancel':
        setPostponeConfirmModalIsOpen(false)
        setModalIsOpen(false)
        setShowRefundPolicySection(false)
        break
      default:
        break
    }
  }

  return (
    <div className="event-postpone-container">
      <ConfirmModal
        isOpen={modalIsOpen}
        header="Postpone this event"
        content={
          <div>
            <Formik
              initialValues={{
                dateAction: 'showNewDate',
                postponedStart: '',
                postponedEnd: '',
                postponedUntilField: '',
                refundPolicy: 'flagAllowCustomerRefund',
                refundPolicyText: ''
              }}
              onSubmit={values => {
                const { refundPolicy } = values

                // Detect if policy content can be opened or Postpone confirmation modal will be opened
                if (refundPolicy === 'disableSelfRefund' && !showRefundPolicySection) {
                  setShowRefundPolicySection(true)
                  return
                }

                // After successful validation open confirmation modal
                setModalIsOpen(false)
                setPostponeConfirmModalIsOpen(true)
              }}
              render={props => {
                formikRenderProps.current = props

                return (
                  <Form autoComplete="off">
                    {!showRefundPolicySection ? (
                      <>
                        <p>Select the new date and time when this event will be re-scheduled</p>
                        <div className="actions-container">
                          <Field
                            name="dateAction"
                            component={RadioGroupField}
                            groups={[
                              { value: 'showNewDate', label: 'Show New Date' },
                              { value: 'hideNewDate', label: 'Hide New Date / TBA' }
                            ]}
                            classNames={{
                              container: 'radio-group-container display-block',
                              container_item: 'radio-group-item position-static'
                            }}
                          />
                        </div>
                        <div className="date-container">
                          <div className="check-in-start-container">
                            <Field
                              name="postponedStart"
                              label="Event Start Date"
                              placeholder="D MMM YYYY H:M AM"
                              component={DateTimePickerFieldReact}
                              validate={requiredValidator()}
                            />
                          </div>
                          <div className="check-in-end-container">
                            <Field
                              name="postponedEnd"
                              label="Event End Date"
                              placeholder="D MMM YYYY H:M AM"
                              component={DateTimePickerFieldReact}
                              validate={requiredValidator()}
                            />
                          </div>
                        </div>
                        <div className="postponment-container">
                          <Field
                            name="postponedUntilField"
                            label="Postponement banner will be shown on the flyer image until:"
                            placeholder="D MMM YYYY H:M AM"
                            component={DateTimePickerFieldReact}
                            validate={requiredValidator()}
                          />
                        </div>
                        <div className="break-line" />
                        <div className="refund-container">
                          <p>Tickets Refund Settings</p>
                          <Field
                            name="refundPolicy"
                            component={RadioGroupField}
                            removeSpacing={true}
                            groups={[
                              {
                                value: 'flagAllowCustomerRefund',
                                label: 'Self-refunds will be enabled  for all customers'
                              },
                              {
                                value: 'flagAllowCustomerRefundBeforePostponed',
                                label:
                                  'Self-refund will be enabled for customers who purchased tickets prior to the postponement'
                              },
                              { value: 'disableSelfRefund', label: 'Self-refunds will not be enabled' }
                            ]}
                            classNames={{
                              container: 'radio-group-container display-block',
                              container_item: 'radio-group-item position-static'
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="policy-container">
                        <p className="policy-text">
                          Please confirm the refund policy for those customers unable to attend the new date.
                        </p>
                        <div>
                          <Field
                            className="policy-textarea"
                            name="refundPolicyText"
                            readOnly={true}
                            label="Write your policy"
                            component={TextAreaField}
                            validate={requiredValidator()}
                          />
                        </div>
                      </div>
                    )}
                  </Form>
                )
              }}
            />
          </div>
        }
        actions={[
          {
            value: 'cancel',
            label: 'Cancel',
            className: 'btn btn-cancel-transparent',
          },
          {
            value: 'confirm',
            label: 'Postpone',
            className: 'btn btn-postpone',
          }
        ]}
        classNames={{
          modal: `modal-dialog modal-trans ${
            flagAllowCustomerRefund ? 'postpone-hidden-refund-section' : ''
          }`,
          container: 'modal-dialog modern-modal-dialog postpone-event-modal-dialog'
        }}
        onAction={handlePostponeModalResponse}
      />
      <ConfirmModal
        isOpen={discloseModalIsOpen}
        header="Disclose Event Date"
        content={<div>Are you sure you want to disclose the event date?</div>}
        actions={[
          {
            value: 'ok',
            label: 'Ok',
            className: 'btn btn-success btn-shadow',
            loading: discloseRequestLoading
          },
          {
            value: 'cancel',
            label: 'Cancel',
            className: 'btn btn-default',
            disabled: discloseRequestLoading
          }
        ]}
        classNames={{
          modal: 'modal-dialog modal-trans',
          container: 'modal-dialog modern-modal-dialog postpone-event-modal-dialog'
        }}
        onAction={handleDiscloseModalResponse}
      />
      <ConfirmModal
        isOpen={postponeConfirmModalIsOpen}
        header="Are you sure you want to postpone the event?"
        content={
          <div>
            Notice that by postponing the event, the entered data in the Ticket Sales Start Date, Doors Open
            Time, and Last Entry Time fields will be reset.
          </div>
        }
        actions={[
          {
            value: 'confirm',
            label: 'Confirm',
            className: 'btn btn-success btn-shadow',
            loading: postponeRequestLoading
          },
          {
            value: 'cancel',
            label: 'Cancel',
            className: 'btn btn-default',
            disabled: postponeRequestLoading
          }
        ]}
        classNames={{
          modal: 'modal-dialog modal-trans',
          container: 'modal-dialog modern-modal-dialog postpone-confirmation-modal-dialog'
        }}
        onAction={handlePostponeConfirmationResponse}
      />
      <OverlayTrigger
        id="email-info"
        placement="top"
        tooltip={`Please email ${SUPPORT_EMAIL} if you need to postpone your event.`}
        hideTooltip={!showPromptText}
      >
        <div>
          {flagHideDate ? (
            <Button
              className="btn btn-disclose"
              type="button"
              onClick={handleOnDisclose}
              disabled={disabled}
              style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            >
              <img className="default-icosize" src={asset('/resources/images/eye.svg')} alt="" />
              Disclose Event Date
            </Button>
          ) : (
            <Button
              className="btn"
              type="button"
              onClick={() => setModalIsOpen(true)}
              disabled={disabled}
              style={{ pointerEvents: disabled ? 'none' : 'auto' }}
            >
              <img className="default-icosize" src={asset('/resources/images/loading.svg')} alt="" />
              Postpone
            </Button>
          )}
        </div>
      </OverlayTrigger>
    </div>
  )
}

export default connect(
  state => {
    const event = get_event(state)
    return { event }
  },
  { POSTPONE_EVENT, DISCLOSE_EVENT }
)(PostponeEvent)
