import React from 'react'
import { toast } from 'react-toastify'

const defaultErrorMessage = 'Something went wrong'
const defaultSuccessMessage = 'Success'
const defaultWarningMessage = 'Warning'
const defaultInfoMessage = 'Info'

const defaultOptions = {
  // eslint-disable-next-line react/jsx-filename-extension
  icon: () => <img className="default-icosize" src={asset('/resources/images/information-line.svg')} alt="" />
}

const toastNotify = {
  ...toast,
  error(errorMessage = defaultErrorMessage, options) {
    return toast.error(errorMessage, { ...defaultOptions, ...options })
  },
  success(successMessage = defaultSuccessMessage, options) {
    return toast.success(successMessage, { ...defaultOptions, ...options, })
  },
  warning(warningMessage = defaultWarningMessage, options) {
    return toast.warning(warningMessage, { ...defaultOptions, ...options, })
  },
  info(infoMessage = defaultInfoMessage, options) {
    return toast.info(infoMessage, { ...defaultOptions, ...options, })
  },
}


export { toastNotify }