import React from 'react'
import _orderBy from 'lodash/orderBy'
import _result from 'lodash/result'
import Modal from 'react-modal'

import modalStyle from '../../../_common/core/modalStyle'
import Button from '../../_library/Button'

import { JSONDatatable, DATATABLE, TYPE_FROM_ARRAY } from '../../_library/JSONDatatable'
import EmptyBar from '../../_library/EmptyBar'

import NewDelegationForm from './NewDelegationForm'
import DelegationManageAccessForm from './DelegationManageAccessForm'
import { getGroupedTickets } from '../../utils/ticketUtils'

export default class GuestTicketDelegations extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      newDelegationModalOpen: false,
      deleteDelegationConfirmModalOpen: false,
      delegationToBeDeleted: null,
      delegationDeleting: false,
      delegationManageAccessModalOpen: false,
      delegationToManageAccess: null,
      delegationManageAccessSkippable: false
    }

    this.openNewDelegationModal = this.openNewDelegationModal.bind(this)
    this.getDelegatesTableSortedRows = this.getDelegatesTableSortedRows.bind(this)
    this.getDelegatesTableData = this.getDelegatesTableData.bind(this)
    this.createNewDelegation = this.createNewDelegation.bind(this)
    this.updateDelegationAccess = this.updateDelegationAccess.bind(this)
    this.deleteDelegation = this.deleteDelegation.bind(this)
  }

  componentDidMount() {}

  componentWillReceiveProps(newProps) {}

  openNewDelegationModal() {
    this.setState({
      newDelegationModalOpen: true
    })
  }

  hideNewDelegationModal() {
    this.setState({
      newDelegationModalOpen: false
    })
  }

  openDelegationManageAccessModal(data, skippable = false) {
    this.setState({
      delegationManageAccessModalOpen: true,
      delegationToManageAccess: data,
      delegationManageAccessSkippable: skippable
    })
  }

  hideDelegationManageAccessModal() {
    this.setState({
      delegationManageAccessModalOpen: false,
      delegationToManageAccess: null
    })
  }

  openDeleteDelegationConfirmModal(data) {
    this.setState({
      deleteDelegationConfirmModalOpen: true,
      delegationToBeDeleted: data
    })
  }

  hideDeleteDelegationConfirmModal() {
    this.setState({
      deleteDelegationConfirmModalOpen: false
    })
  }

  createNewDelegation(data) {
    return Promise.resolve(this.props.onCreateNew(data))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Successfully created guest ticket delegation',
          hideAfter: 3,
          showCloseButton: true
        })
        this.hideNewDelegationModal()
        this.openDelegationManageAccessModal(v.data, true)
        return v
      })
  }

  updateDelegationAccess(data) {
    const delegation = this.state.delegationToManageAccess
    if (!delegation) return
    return Promise.resolve(
      this.props.onUpdate(this.state.delegationToManageAccess.id, {
        attributes: {
          name: delegation.name,
          types: delegation.types,
          access: data.attributes.access
        }
      })
    )
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Successfully updated access',
          hideAfter: 3,
          showCloseButton: true
        })
        this.hideDelegationManageAccessModal()
        return v
      })
  }

  deleteDelegation() {
    this.setState({ delegationDeleting: true })
    const data = this.state.delegationToBeDeleted
    return Promise.resolve(this.props.onDelete(data.id))
      .catch(err => {
        this.setState({ delegationDeleting: false })
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(v => {
        this.setState({ delegationDeleting: false })
        Messenger().post({
          type: 'success',
          message: 'Successfully deleted guest ticket delegation',
          hideAfter: 3,
          showCloseButton: true
        })
        this.hideDeleteDelegationConfirmModal()
        return v
      })
  }

  getDelegatesTableData(datatable, rows_filtered, sort) {
    const content_header = datatable.getHeaderRow(
      datatable,
      [
        { title: '', sort: false },
        { title: 'Name', sort: true },
        { title: '', sort: false }
      ],
      sort
    )

    const { tickets } = this.props

    return rows_filtered.length !== 0 ? (
      <table className="table guest-ticket-delegates-table">
        <thead>{content_header}</thead>
        <tbody>
          {rows_filtered.map((row, index) => {
            let content_row = null
            if (!row.isDetailRow) {
              content_row = (
                <tr
                  key={index}
                  className={
                    (index % 2 === 0 ? 'row-stale' : '') + (row.isExpanded ? '  tr-expanded-row' : '')
                  }
                >
                  <td className="toggleExpand" onClick={datatable.onClickDetail.bind(datatable, row.id)}>
                    <div className={row.isExpanded ? 'expandIcon expanded' : 'expandIcon'}>
                      <i className={row.isExpanded ? 'fa fa-minus-square-o' : 'fa fa-plus-square-o'} />
                    </div>
                  </td>
                  <td>{row.name}</td>
                  <td className="text-right">
                    <Button
                      className="btn btn-primary btn-shadow delegate-manage-access-btn"
                      type="button"
                      onClick={this.openDelegationManageAccessModal.bind(this, row, false)}
                    >
                      Manage Access
                    </Button>
                    <Button
                      className="btn btn-danger btn-shadow delegate-delete-btn"
                      type="button"
                      onClick={this.openDeleteDelegationConfirmModal.bind(this, row)}
                    >
                      <i className="fa fa-trash" />
                    </Button>
                  </td>
                </tr>
              )
            } else {
              content_row = (
                <tr key={index}>
                  <td colSpan={5}>
                    <div className="guest-ticket-delegate-sub">
                      <div className="guest-ticket-delegate-types">
                        <div className="guest-ticket-delegate-types-table-title">Ticket Type Limits</div>
                        <table className="table guest-ticket-delegate-types-table">
                          <thead>
                            <tr>
                              <th>Ticket Type</th>
                              <th>Quantity Limit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {row.types.map((type, index) => {
                              const tt = tickets.find(t => t.id === type.ticketTypeId.toString())
                              return (
                                <tr key={index}>
                                  <td>{tt && tt.name}</td>
                                  <td>{type.maxQuantity}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="guest-ticket-delegate-access">
                        <div className="guest-ticket-delegate-access-table-title">Users</div>
                        <table className="table guest-ticket-delegate-access-table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {row.access.map((access, index) => (
                              <tr key={index}>
                                <td>{access.firstName + ' ' + access.lastName}</td>
                                <td>{access.email}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            }
            return content_row
          })}
        </tbody>
      </table>
    ) : (
      <EmptyBar />
    )
  }

  getDelegatesTableSortedRows(rows_filtered, sort) {
    if (sort) {
      const dir = sort.asc ? 'asc' : 'desc'
      switch (sort.index) {
        case 0:
          rows_filtered = _orderBy(rows_filtered, t => t.name, dir)
          break
        case 1:
          rows_filtered = _orderBy(rows_filtered, t => t.types.length, dir)
          break
        default:
          break
      }
    }
    return rows_filtered
  }

  render() {
    const { tickets, tables, delegations } = this.props
    const {
      newDelegationModalOpen,
      delegationManageAccessModalOpen,
      delegationToManageAccess,
      delegationManageAccessSkippable,
      deleteDelegationConfirmModalOpen,
      delegationToBeDeleted,
      delegationDeleting
    } = this.state

    const groupedTicketTypes = getGroupedTickets(tickets)

    return (
      <div className="guest-ticket-delegates">
        <div className="guest-ticket-delegates-buttons">
          <div className="btn btn-success btn-shadow" onClick={this.openNewDelegationModal}>
            <i className="fa fa-plus" aria-hidden="true" />
            New Delegation
          </div>
        </div>

        <div className="guest-ticket-delegates">
          <JSONDatatable
            type={TYPE_FROM_ARRAY}
            data={delegations}
            sort={{ index: 0, asc: true }}
            getSortedRows={this.getDelegatesTableSortedRows}
            getTableData={this.getDelegatesTableData}
          >
            <div ref={DATATABLE} />
          </JSONDatatable>
        </div>

        <Modal
          className="guest-ticket-new-delegate modal-trans"
          style={modalStyle}
          isOpen={newDelegationModalOpen}
          contentLabel="Modal"
          onRequestClose={this.hideNewDelegationModal.bind(this)}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">Create New Delegation</div>
              <div className="modal-body">
                <NewDelegationForm
                  tickets={groupedTicketTypes}
                  tables={tables}
                  onSubmit={this.createNewDelegation}
                  onCancel={this.hideNewDelegationModal.bind(this)}
                />
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          className="guest-ticket-delegate-manage-users modal-trans"
          style={modalStyle}
          isOpen={delegationManageAccessModalOpen}
          contentLabel="Modal"
          onRequestClose={this.hideDelegationManageAccessModal.bind(this)}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                Manage Permissions for {delegationToManageAccess && delegationToManageAccess.name}
              </div>
              <div className="modal-body">
                <DelegationManageAccessForm
                  delegation={delegationToManageAccess}
                  skippable={delegationManageAccessSkippable}
                  initialValues={{
                    attributes: { access: delegationToManageAccess && delegationToManageAccess.access }
                  }}
                  onSubmit={this.updateDelegationAccess}
                  onCancel={this.hideDelegationManageAccessModal.bind(this)}
                />
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={deleteDelegationConfirmModalOpen}
          contentLabel="Modal"
          onRequestClose={this.hideDeleteDelegationConfirmModal.bind(this)}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-confirm-switch">
                <div className="modal-header">Confirm Delegation Delete</div>
                <div className="modal-body">
                  <div className="msg-confirm">
                    Are you sure you want to delete delegation{' '}
                    {delegationToBeDeleted && delegationToBeDeleted.name}?
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={this.deleteDelegation}
                      loading={delegationDeleting}
                      disabled={delegationDeleting}
                    >
                      Ok
                    </Button>
                    <Button
                      className="btn btn-default btn-shadow"
                      type="button"
                      onClick={this.hideDeleteDelegationConfirmModal.bind(this)}
                      disabled={delegationDeleting}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
