import React from 'react'
import { Element } from 'react-scroll'
import Card from '../../../_library/Card'

import BrandRow from './EventDetailsBrandRow'
import EventNameRow from './EventNameRow'
import EventUrlRow from './EventUrlRow'
import EventDatesRow from './EventDatesRow'
import EventDetailsPostponmentBanner from './EventDetailsPostponmentBanner'
import EventTicketSaleDatesRow from './EventTicketSaleDatesRow'
import EventDoorsDatesRow from './EventDoorsDatesRow'
import AgeRow from './EventDetailsAgeRow'

const EventDetails = props => {
  const {
    values,
    errors,
    touched,
    isNew,
    organizations,
    cardsStatus,
    updateField,
    onClickNewBrand,
    isRnDomain,
    disableWholeSection,
    FETCH_BRAND,
    event,
    isFundsRemmited
  } = props

  return (
    <Card
      icon={'fa-info'}
      title={'Event Details'}
      className={`create-event ${disableWholeSection ? 'disable-section' : ''}`}
      status={cardsStatus && cardsStatus.details}
    >
      <BrandRow
        values={values}
        errors={errors}
        touched={touched}
        organizations={organizations}
        onClickNewBrand={onClickNewBrand}
        updateField={updateField}
        FETCH_BRAND={FETCH_BRAND}
      />
      <EventNameRow
        isCurator={false}
        values={values}
        errors={errors}
        touched={touched}
        updateField={updateField}
        isRnDomain={isRnDomain}
      />
      <EventUrlRow
        isNew={isNew}
        values={values}
        errors={errors}
        touched={touched}
        updateField={updateField}
        isRnDomain={isRnDomain}
      />
      <Element name="scroll-to-date" />
      <div className="div-spacing-20" />
      <EventDatesRow
        values={values}
        errors={errors}
        touched={touched}
        updateField={updateField}
        isRnDomain={isRnDomain}
        isFundsRemmited={isFundsRemmited}
      />
      <EventTicketSaleDatesRow values={values} errors={errors} touched={touched} updateField={updateField} />
      {event?.flagPostponed && (
        <EventDetailsPostponmentBanner
          values={values}
          errors={errors}
          touched={touched}
          updateField={updateField}
        />
      )}
      <EventDoorsDatesRow values={values} errors={errors} touched={touched} updateField={updateField} />
      {!isRnDomain && <AgeRow values={values} updateField={updateField} />}
    </Card>
  )
}

export default EventDetails
