import _get from 'lodash/get'
import { baseRequest } from '.'

export const fetchSeatMapTickets = async eventId => {
  const res = await baseRequest.get(`event/${eventId}/seat_map/tickets/`)
  return _get(res, 'data.data.attributes.tickets')
}

export const bookSeat = async (eventId, data) =>
  await baseRequest.post(`event/${eventId}/seat_map/ticket/book/`, {
    data: {
      attributes: { ...data }
    }
  })

export const deleteBookedSeat = async (eventId, ticketId) =>
  await baseRequest.delete(`event/${eventId}/seat_map/ticket/book/`, {
    data: {
      data: {
        attributes: { ticketId }
      }
    }
  })

export const moveReservedSeat = async (eventId, ticketId) =>
  await baseRequest.post(`event/${eventId}/seat_map/ticket/move/`, {
    data: {
      attributes: {
        ticketId
      }
    }
  })

export const fetchSeatMapList = async eventId => await baseRequest.get(`event/${eventId}/venue-seat-map`)

export const fetchSeatMapData = async seatMapId => await baseRequest.get(`seat-map/${seatMapId}`)

export const attachSeatMap = async (eventId, seatMapId) =>
  await baseRequest.post(`event/${eventId}/seat-map/${seatMapId}`)

export const deleteAttachedSeatMap = async eventId => await baseRequest.delete(`event/${eventId}/seat-map`)

export const getSeatMapConfiguration = async eventId =>
  await baseRequest.get(`event/${eventId}/seat-map/tier_ticket_types`)

export const saveSeatMapConfiguration = async (eventId, data) =>
  await baseRequest.post(`event/${eventId}/event_venue_seats`, data)
