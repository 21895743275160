import React from 'react'
import _get from 'lodash/get'
import _isNumber from 'lodash/isNumber'
import { getTableColumns } from '../../utils/sortableTableUtils'
import { createFixedFloatNormalizer, currencyNormalizerCreator } from '../../../_common/core/validation'
import LoadingBar from '../../_library/LoadingBar'

import SortableTableWithDnDHOC from '../../hoc/SortableTableWithDND'

const SortableTableWithDND = SortableTableWithDnDHOC()

export default class AddOnTable extends React.PureComponent {
  constructor(props) {
    super(props)
    this.tableColumns = getTableColumns([
      { key: 'name', label: 'Name' },
      { key: 'groupName', label: 'Group' },
      {
        key: 'cost',
        label: 'Cost',
        className: 'half minw-100',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) / 100)
          )
      },
      {
        key: 'price',
        label: 'Price',
        className: 'half minw-100',
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(props.event))(
            createFixedFloatNormalizer(2)(parseFloat(value) / 100)
          )
      },
      {
        key: 'stock',
        label: 'Stock',
        className: 'minw-100',
        normalizer: value => {
          if (_isNumber(value)) {
            return value
          }
          return 'Unlimited'
        }
      }
    ])
  }

  render() {
    const {
      addons = [],
      isLoading = true,
      loadingAddOnUpdate = {},
      handleEditAddon,
      handleActivateAddon,
      handleDeactivateAddonOpenModal,
      handleAddAddon,
      addingAddon,
      event,
      handleAddonRefresh,
      loadingAddonsTable,
      handleSaveSort
    } = this.props
    const isAddOnsEmpty = !addons.length

    return (
      <div className="">
        {isLoading && <LoadingBar title="Hold tight! We're getting add-ons data..." />}
        <SortableTableWithDND
          data={addons}
          event={event}
          tableColumns={this.tableColumns}
          enableCopyTable={false}
          enableSort={false}
          className="table addons-table"
          actionsClassName="pos-start mw-240"
          statuses={[
            {
              key: 'active',
              activeLabel: `This add-on is available for sale`,
              inactiveLabel: (
                <span>
                  This add-on is <strong>not</strong> available for sale
                </span>
              )
            }
          ]}
          actions={[
            {
              label: 'Edit',
              className: 'btn btn-primary',
              icon: 'fa fa-pencil fa-fw',
              onClick: data => handleEditAddon(data)
            },
            {
              label: data => (data.active ? 'Deactivate' : 'Activate'),
              className: data => (data.active ? 'btn btn-seablue' : 'btn btn-ok'),
              icon: data => (data.active ? 'fa fa-ban fa-fw' : 'fa fa-cog fa-fw'),
              isLoading: data => !data.active && loadingAddOnUpdate[_get(data, 'id')],
              onClick: data =>
                data.active ? handleDeactivateAddonOpenModal(data) : handleActivateAddon(data, true)
            }
          ]}
          isDndHistoryEnabled={true}
          hideDndActionBtns={addingAddon}
          isEmpty={isAddOnsEmpty}
          showEmptyBar={false}
          isLoading={isLoading}
          tableMainActions={[
            {
              label: 'Add Add-On',
              className: 'btn btn-ok',
              icon: 'fa fa-fw fa-plus',
              onClick: handleAddAddon,
              hideBtn: addingAddon
            },
            {
              label: 'Refresh',
              className: 'btn btn-seablue',
              icon: 'fa fa-fw fa-refresh',
              onClick: handleAddonRefresh,
              loading: loadingAddonsTable,
              hideBtn: addingAddon
            }
          ]}
          handleSaveSort={handleSaveSort}
          disableRefreshIfDraggDropped={true}
        />
      </div>
    )
  }
}
