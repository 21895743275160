import React from 'react'
import _result from 'lodash/result'
import _find from 'lodash/find'
import _get from 'lodash/get'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ConfirmTeamInvitationForm from './ConfirmTeamInvitationForm'
import Notifications from '../_library/notifications/Notifications'
import { CHECK_TEAM_INVITATION_HASH, ACCEPT_TEAM_INVITATION, LOGOUT } from '../../_common/redux/auth/actions'
import countries from '../../_common/core/countries'
import { checkExistsCustomer } from '../../_common/core/http_services'
import ConfirmModal from '../_library/ConfirmModal'
import { getTitle } from '../utils/getTitle'

@withRouter
@connect(
  state => ({
    user: state.auth.user,
  }),
  { CHECK_TEAM_INVITATION_HASH, ACCEPT_TEAM_INVITATION, LOGOUT }
)
export default class ConfirmTeamInvitationPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0,
      height: 0,
      tokenValid: false,
      tokenValidating: true,
      isNewCustomer: true,
      tokenText: 'Token is invalid',
      showModal: false,
      currentEmail: '',
      directEmail: ''
    }
    const configDocTitle = _get(props.configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  componentDidMount() {
    setTimeout(() => {
      this.checkTeamInitiationHash()
    }, 1000)
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  async checkTeamInitiationHash() {
    try {
      const { CHECK_TEAM_INVITATION_HASH, ACCEPT_TEAM_INVITATION, location, user, history: { push }, } = this.props
      const token = queryString.parse(location.search)
      const payload = await CHECK_TEAM_INVITATION_HASH(token)
      const type = token.token?.length < 11 ? 'generic' : 'direct'
      const status = payload?.data?.invitation?.status
      const invitationEmail = payload?.data?.invitation?.email?.toLowerCase()
      const userEmail = user?.email?.toLowerCase()
      if (status === 'accepted') {
        throw new Error('Already accepted')
      }
      if (status === 'disabled') {
        throw new Error('Disabled token')
      }
      const showModalObj =
        userEmail &&
        invitationEmail &&
        userEmail !== invitationEmail &&
        type === 'direct'
          ? {
            showModal: true,
            directEmail: invitationEmail,
            currentEmail: userEmail
          }
          : {}
      const isGeneric = userEmail && type === 'generic'
      if (isGeneric) {
        const body = {
          attributes: {
            firstName: user.firstName,
            lastName: user.lastName,
            country: 1,
            email: userEmail,
            token: token.token
          }
        }
        await ACCEPT_TEAM_INVITATION(body)
        this.setState({ tokenText: 'Invitation accepted', tokenValid: false, tokenValidating: false }, () => {
          setTimeout(() => {
            push("/")
          }, 5000)
        })
        return
      }
      this.setState(
        {
          ...showModalObj,
          tokenValidating: false,
          tokenValid: true,
          isNewCustomer: payload.data.isNewCustomer,
        },
        () => {
          const { directEmail, currentEmail, isNewCustomer } = this.state
          if (directEmail === currentEmail && !isNewCustomer) {
            this.handleSubmit({ attributes: {} })
          }
        }
      )
    } catch (e) {
      const error = _get(e, 'errors[0].details')
      this.setState({
        tokenValidating: false,
        tokenValid: false,
        tokenText: (() => {
          if (error) {
            return error
          }
          switch (e.message) {
            case 'Disabled token':
              return 'Invitation is currently disabled'
            case 'Already accepted':
              return 'Team invitation is already accepted'
            default:
              return 'Token is invalid'
          }
        })()
      })
    }
  }

  async handleSubmit(form, type) {
    const {
      ACCEPT_TEAM_INVITATION,
      history: { push },
      location
    } = this.props
    const { directEmail, currentEmail } = this.state
    const { token } = queryString.parse(location.search)
    if (type === 'generic') {
      try {
        const exists = await checkExistsCustomer(form.attributes.email)
        if (!exists) {
          throw new Error('Email do not exists.')
        }
        push(`/signin?token=${token}`)
      } catch (e) {
        push(`/sign-up?token=${token}&email=${form.attributes.email}`)
      }
    } else {
      form.attributes.token = token
      const selectedCountry = countries.find(c => c.name.common === form.attributes.country)
      const email = currentEmail && directEmail && currentEmail !== directEmail ? { email: currentEmail } : {}
      const updatedForm = {
        attributes: {
          ...form.attributes,
          ...email,
          country: selectedCountry ? selectedCountry.cca2 : null
        }
      }
      return Promise.resolve(ACCEPT_TEAM_INVITATION(updatedForm))
        .catch(err => {
          const errors = _result(err, 'errors')
          if (_find(errors, e => e.details === 'Invitation already accepted')) {
            this.props.history.push('signin')
          }
          const error = _get(err, 'errors[0].details')
          if (error) {
            this.setState({
              tokenValidating: false,
              tokenValid: false,
              tokenText: error
            })
          }
          return Promise.reject(_result(err, 'toFieldErrors'))
        })
        .then(v => {
          push('/signin')
          return v
        })
    }
  }

  onConfirmResponse = response => {
    switch (response) {
      case 'yes':
        this.onContinue()
        break
      case 'logout':
        this.onLogout()
        break
      default:
        break
    }
  }

  onContinue = () => {
    this.setState({ showModal: false }, () => {
      this.handleSubmit({ attributes: {} }, 'direct')
    })
  }

  onLogout = () => {
    const { isNewCustomer } = this.state
    const {
      history: { push },
      LOGOUT
    } = this.props
    LOGOUT()
    if (isNewCustomer) {
      this.setState({
        currentEmail: '',
        directEmail: '',
        showModal: false
      })
    } else {
      setTimeout(() => {
        push('/signin')
      }, 500)
    }
  }

  render() {
    const {
      tokenValid,
      isNewCustomer,
      tokenValidating,
      width,
      height,
      tokenText,
      showModal,
      directEmail,
      currentEmail
    } = this.state
    const { location } = this.props
    const type = queryString.parse(location.search)?.token?.length < 11 ? 'generic' : 'direct'
    const isLandscape = width >= height
    let video = 'landscape/1280x720.mp4'
    let image = 'landscape/landscape-desktop.jpg'
    if (isLandscape) {
      if (width <= 668) {
        video = 'landscape/668x376.mp4'
        image = 'landscape/landscape-iphone.jpg'
      } else if (width <= 1024) {
        video = 'landscape/1024x768.mp4'
        image = 'landscape/landscape-ipad.jpg'
      } else if (width <= 1280) {
        video = 'landscape/1280x720.mp4'
        image = 'landscape/landscape-desktop.jpg'
      } else {
        video = 'landscape/1920x1080.mp4'
        image = 'landscape/landscape-desktop.jpg'
      }
    } else {
      if (width < 768) {
        video = 'portrait/376x668.mp4'
        image = 'portrait/portrait-iphone.jpg'
      } else {
        video = 'portrait/768x1024.mp4'
        image = 'portrait/portrait-ipad.jpg'
      }
    }

    return (
      <div className="loginpage confirm-team-invitation-container">
        <div className="bg_video">
          <video
            poster={asset('/resources/videos/login/' + image)}
            id="bgvid"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src={asset('/resources/videos/login/' + video)} type="video/mp4" />
          </video>
        </div>
        <Notifications />
        <ConfirmModal
          isOpen={showModal}
          header="Warning"
          content={
            <div>
              <p>{`This invitation is for ${directEmail}. You are currently logged in as ${currentEmail}.`}</p>
              <p>Are you sure you would like to accept the invitation as this account?</p>
            </div>
          }
          actions={[
            { value: 'yes', label: 'Yes', className: 'btn btn-success btn-shadow' },
            { value: 'logout', label: 'Logout', className: 'btn btn-cancel btn-shadow' }
          ]}
          onAction={this.onConfirmResponse}
          classNames={{
            modal: 'modal-dialog modal-trans modal-medium modal-small',
            container: 'modal-dialog modal-medium modal-small'
          }}
          style={{ overlay: { zIndex: 9999999999999999999 } }}
        />
        <ConfirmTeamInvitationForm
          onSubmit={this.handleSubmit}
          isNewCustomer={isNewCustomer}
          tokenValidating={tokenValidating}
          tokenValid={tokenValid}
          type={type}
          tokenText={tokenText}
        />
      </div>
    )
  }
}
