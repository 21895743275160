export const ROLES = {
  SUPPORT: 'support',
  PARTIAL_SUPPORT: 'limited_support',
  LIMITED_STATS: 'limited_stats',

  // TTF Support roles
  TF_SUPPORT: 'tf_support',
  TF_SUPPORT_ADMIN: 'tf_support_admin',
  TF_CLIENT_SUPPORT: 'tf_client_support',
  TF_CLIENT_SUPPORT_ADMIN: 'tf_client_support_admin',
  TTF_TEAM: 'ttf_team',
  TTF_SUPPORT: 'ttf_support',
  TTF_SUPPORT_ADVANCED: 'ttf_support_advanced',

  // helpful functions
  is_support_or_partial_support(role) {
    return [this.SUPPORT, this.PARTIAL_SUPPORT].includes(role)
  },

  is_ttf_team(role) {
    return [
      this.TTF_TEAM,
    ].includes(role)
  },

  is_ttf_staff(role) {
    return [
      this.TF_SUPPORT,
      this.TF_SUPPORT_ADMIN,
      this.TF_CLIENT_SUPPORT,
      this.TF_CLIENT_SUPPORT_ADMIN,
      this.TTF_TEAM,
      this.TTF_SUPPORT,
      this.TTF_SUPPORT_ADVANCED
    ].includes(role)
  },

  is_tf_support(role) {
    return [
      this.TF_SUPPORT,
      this.TF_SUPPORT_ADMIN,
      this.TF_CLIENT_SUPPORT,
      this.TF_CLIENT_SUPPORT_ADMIN
    ].includes(role)
  },
  is_tf_support_or_client_support(role) {
    return [this.TF_SUPPORT, this.TF_CLIENT_SUPPORT].includes(role)
  },
  is_tf_support_admin_or_client_admin(role) {
    return [this.TF_SUPPORT_ADMIN, this.TF_CLIENT_SUPPORT_ADMIN].includes(role)
  },
  is_tf_client_support_admin(role) {
    return [this.TF_CLIENT_SUPPORT_ADMIN].includes(role)
  }
}

export const RN_ROLES = {
  PROMOTER: 'promoter',
  CURATOR: 'curator',
  ADMIN: 'admin',

  // helpful functions
  hasRoles(rnRoles = [], hasRoles = []) {
    let rolesArr = rnRoles
    if (!Array.isArray(rnRoles)) {
      rolesArr = []
    }
    if (!Array.isArray(hasRoles)) {
      rolesArr = []
    }
    return hasRoles.every(Array.prototype.includes.bind(rolesArr))
  },
  hasSomeRoles(rnRoles = [], hasSomeRoles = []) {
    let rolesArr = rnRoles
    let hasRolesArr = hasSomeRoles
    if (!Array.isArray(rnRoles)) {
      rolesArr = []
    }
    if (!Array.isArray(hasSomeRoles)) {
      hasRolesArr = []
    }
    return rolesArr.some(Array.prototype.includes.bind(hasRolesArr))
  }
}
