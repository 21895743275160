import React from 'react'
import { DragDropContext } from 'react-dnd'
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch'
import MultiBackend from 'react-dnd-multi-backend'
import moment from 'moment'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _filter from 'lodash/filter'
import _uniqBy from 'lodash/uniqBy'
import _findIndex from 'lodash/findIndex'
import { Element, scroller } from 'react-scroll'
import CustomToggle from '../../_library/CustomToggle'
import Button from '../../_library/Button'
import TagsField from '../../_library/TagsField'
import Field from '../../_library/Field'
import PromotionItem from './PromotionItem'

import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import ClipboardButton from 'react-clipboard.js'
import { fetchAPI } from '../../../_common/core/http'
import SelectTimeSlotTickets from '../_library/SelectMultipleTimeSlots/SelectTimeSlotTickets'
import { isDateRangeValidator } from '../../../_common/core/validation'
import DateTimePickerReact from '../../_library/DateTimePickerReact'

const API_BASE = window.location.origin

const DISCOUNT_OPTIONS = [
  {
    value: '',
    title: 'Select Discount Type'
  },
  {
    value: 'fixed_discount',
    title: 'Fixed Discount'
  },
  {
    value: 'fixed_price',
    title: 'Fixed Price'
  },
  {
    value: 'percentage',
    title: 'Percentage Discount'
  },
  {
    value: 'none',
    title: 'No Discount'
  }
]

@DragDropContext(MultiBackend(HTML5toTouch))
export default class Promotion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      promotion: props.promotion,
      ticketOptions: (() => {
        const ticketNameArr = props.promotion.items.map(item => item.ticketTypeID)
        return props.tickets.filter(item => !ticketNameArr.includes(item.id))
      })(),
      noGroupedTicketsIds: [],
      groupedTicketsIds: '',
      discountOption: '',
      discountValueSymbol: props.currency,
      discountValue: null,
      shareMaxLimitChecked: props.promotion.quantity_limit > 0,
      nameRequired: false,
      dateRequired: props.promotion.startDate || props.promotion.endDate,
      isGeneratingToken: false,
      isRefreshingToken: false,
      maxQuantityLimitValue: props.promotion.quantity_limit || null,
      maximumTicketsVal: null
    }

    this.changeDiscount = this.changeDiscount.bind(this)
    this.changeStartDate = this.changeStartDate.bind(this)
    this.addItem = this.addItem.bind(this)
    this.changeEndDate = this.changeEndDate.bind(this)
    this.changePromoCodes = this.changePromoCodes.bind(this)
    this.copyEventUrlOut = this.copyEventUrlOut.bind(this)
    this.handleChangeSlug = this.handleChangeSlug.bind(this)
    this.handleMaxQuantityLimitChange = this.handleMaxQuantityLimitChange.bind(this)
  }

  componentDidMount() {
    const { promotion } = this.props
    if (promotion && promotion.promoCodeTokens && promotion.promoCodeTokens.length) {
      this.setUniqPromoCodes(promotion, true)
    }
  }

  componentWillReceiveProps(newProps) {
    const { tickets, promotion, refresh } = this.props
    let ticketOptions = []
    const ticketNameArr = promotion.items.map(item => item.ticketName)
    ticketOptions = tickets.filter(item => !ticketNameArr.includes(item.displayName))
    this.setState({ ticketOptions })

    if (!_isEqual(newProps.promotion, promotion) || this.state.deselectCheckboxes || newProps.refresh) {
      this.setUniqPromoCodes(newProps.promotion)
      this.setState({
        promotion: newProps.promotion,
        ticketOptions,
        noGroupedTicketsIds: [],
        groupedTicketsIds: '',
        discountValueSymbol: newProps.currency
      })
      if (!_isEqual(newProps.refresh, refresh)) {
        this.collapse()
      }
    }

    if (this.state.deselectCheckboxes) {
      this.setState({ deselectCheckboxes: false })
    }
  }

  setUniqPromoCodes = (promotion, setCode) => {
    const uniqPromoCodes = _uniqBy(promotion.promoCodeTokens, 'code')
    this.setState(prevState => ({
      ...prevState,
      uniqPromoCodes,
      selectedPromoCode: setCode ? uniqPromoCodes[0].code : prevState.selectedPromoCode
    }))
  }

  deletePromotion() {
    if (this.props.onDelete) {
      this.props.onDelete()
    }
  }

  expand() {
    if (this.props.onExpand) {
      this.props.onExpand()
    }
  }

  collapse() {
    if (this.props.onCollapse) {
      this.props.onCollapse()
    }
  }

  changeName(val) {
    if (val !== '') {
      this.setState({ nameRequired: false })
    } else {
      this.setState({ nameRequired: true })
    }
    if (this.props.onChangeName) {
      this.props.onChangeName(val)
    }
  }

  changePromoCodes(val) {
    if (this.props.onChangePromoCodes) {
      this.props.onChangePromoCodes(val)
    }
  }

  changeStartDate(val) {
    this.setState({ dateRequired: val || this.state.promotion.endDate })
    if (this.props.onChangePromoStartDate) {
      this.props.onChangePromoStartDate(val)
    }
  }

  changeEndDate(val) {
    this.setState({ dateRequired: this.state.promotion.startDate || val })
    if (this.props.onChangePromoEndDate) {
      this.props.onChangePromoEndDate(val)
    }
  }

  onChangeGroupedTickets = e => {
    this.setState({ groupedTicketsIds: e })
  }

  onChangeNoGroupedTickets = e => {
    const { noGroupedTicketsIds } = this.state
    const { id } = e.target
    const { checked } = e.target
    let removed = noGroupedTicketsIds
    if (checked) {
      removed.push(id)
    } else {
      removed = _filter(removed, n => n !== id)
    }
    this.setState({ noGroupedTicketsIds: removed })
  }

  changeDiscount(e) {
    const { currency } = this.props
    const symbol = e.target.value === 'percentage' ? '%' : currency
    this.setState({ discountOption: e.target.value, discountValueSymbol: symbol })
  }

  changeQuantityLimit(v) {
    this.setState({
      maximumTicketsVal: v
    })
  }

  changeDiscountValue(v) {
    this.setState({ discountValue: v })
  }

  addItem() {
    const { tickets } = this.props
    const {
      discountOption,
      discountValue,
      noGroupedTicketsIds,
      groupedTicketsIds,
      maxQuantityLimitValue,
      maximumTicketsVal
    } = this.state

    const ttIds = (noGroupedTicketsIds.join(',') + groupedTicketsIds).split(',')
    const ticketTypes = tickets.filter(t => ttIds.includes(t.id + ''))
    _map(ticketTypes, (tt, i) => {
      const newItem = {
        id: new Date().valueOf() + i * 100,
        ticketTypeID: tt.id,
        ticketName: tt.displayName,
        discountType: discountOption,
        discountValue: discountOption !== 'none' && discountOption !== '' ? discountValue : null,
        quantityLimit: maxQuantityLimitValue ? maxQuantityLimitValue : maximumTicketsVal
      }
      if (this.props.onNewPromotionItem) {
        this.props.onNewPromotionItem(newItem)
      }
    })

    if (discountOption !== 'none' && discountOption !== '') {
      this.setState({
        discountValue: null
      })
    }

    this.setState({
      groupedTicketsIds: '',
      deselectCheckboxes: true,
      maximumTicketsVal: null
    })
  }

  savePromotion() {
    const { promotion, shareMaxLimitChecked, maxQuantityLimitValue } = this.state
    const isDateRangeInvalid = isDateRangeValidator()(promotion.startDate, promotion.endDate)

    if (shareMaxLimitChecked && !maxQuantityLimitValue && promotion.isNew) {
      this.setState({
        maxQuantityError: true
      })
      scroller.scrollTo(`promotion-max-quantitylimit-${promotion.id}`, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -100
      })
      return
    }

    if (promotion.name.trim() === '') {
      this.setState({ nameRequired: true })
      scroller.scrollTo(`scroll-to-name-${promotion.id}`, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -100
      })
    } else if ((promotion.startDate && !promotion.endDate) || (!promotion.startDate && promotion.endDate)) {
      this.setState({ dateRequired: true })
      scroller.scrollTo(`scroll-to-date-${promotion.id}`, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -100
      })
    } else if (isDateRangeInvalid) {
      return
    } else {
      if (this.props.onSavePromotion) {
        this.props.onSavePromotion()
      }
    }
  }

  deleteItem(index) {
    if (this.props.onDeleteItem) {
      this.props.onDeleteItem(index)
    }
  }

  moveItem(dragIndex, hoverIndex) {
    if (this.props.onMoveItemInsideSection) {
      this.props.onMoveItemInsideSection(dragIndex, hoverIndex)
    }
  }

  copyEventUrlOut(e) {
    if (this.state.eventUrlCopied) {
      setTimeout(() => {
        this.setState({
          eventUrlCopied: false
        })
      }, 500)
    }
  }

  copyEventUrlAfter = () => {
    this.setState({
      eventUrlCopied: true
    })
  }

  handleChangeSlug(e) {
    let str = e
    if (e.target) {
      str = e.target.value
    }
    if (this.props.onTokenChange) {
      this.props.onTokenChange(str)
    }
  }

  handleShareMaximumLimit = (id, value) => {
    this.setState(
      prevState => ({
        maxQuantityLimitValue: !value ? null : prevState.shareMaxLimitChecked,
        shareMaxLimitChecked: !prevState.shareMaxLimitChecked
      }),
      () => {
        if (!value) {
          this.props.onChangeMaxQuantityLimit(null)
        }
      }
    )
  }

  generateToken = (e, code) => {
    const { event } = this.props
    const { promotion, selectedPromoCode, uniqPromoCodes } = this.state
    const selectedCode = !!code ? code : selectedPromoCode
    const generateTokenURL = `/api/event/${event.id}/promotion/${promotion.id}/generate-promotion-token/${selectedCode}`

    this.setState({
      isGeneratingToken: !code,
      isRefreshingToken: !!code,
      selectedPromoCode: selectedCode
    })
    fetchAPI(generateTokenURL)
      .catch(err => {
        this.props.onTokenChange(null)
        this.setState({
          isGeneratingToken: false,
          isRefreshingToken: false
        })
      })
      .then(res => {
        this.props.onTokenChange(res.token)
        this.setState(prevState => {
          const updatedUniqPromoCodes = _map(uniqPromoCodes, u => {
            if (u.code === selectedCode) {
              return { code: u.code, token: res.token }
            }
            return u
          })
          return {
            ...prevState,
            isGeneratingToken: false,
            isRefreshingToken: false,
            uniqPromoCodes: updatedUniqPromoCodes
          }
        })
      })
  }

  handleChangePromoCode = e => {
    const selectedPromoCode = e.target.value
    this.setState({
      selectedPromoCode
    })
  }

  handleMaxQuantityLimitChange = e => {
    const { value } = e.target
    const { shareMaxLimitChecked } = this.state

    this.setState(
      {
        maxQuantityLimitValue: value
      },
      () => {
        this.props.onChangeMaxQuantityLimit(shareMaxLimitChecked ? value : null)
      }
    )
  }

  render() {
    const { event, isRnDomain } = this.props
    const eventHasLimit = event.flagPromotionSharedQuantityLimitEnabled
    const {
      promotion,
      discountOption,
      discountValue,
      discountValueSymbol,
      ticketOptions,
      noGroupedTicketsIds,
      groupedTicketsIds,
      nameRequired,
      dateRequired,
      isGeneratingToken,
      isRefreshingToken,
      selectedPromoCode,
      uniqPromoCodes,
      deselectCheckboxes,
      maxQuantityLimitValue,
      shareMaxLimitChecked,
      maxQuantityError,
      maximumTicketsVal
    } = this.state

    const [noGroupedTickets, groupedTickets] = ticketTypesSorting(ticketOptions)

    const isGenerateButtonDisabled =
      !selectedPromoCode || _findIndex(uniqPromoCodes, u => u.code === selectedPromoCode && !u.token) === -1

    const checkDiscountValue = discountOption !== 'none'

    const isAddTicketButtonDisabled =
      discountOption === '' ||
      !(noGroupedTicketsIds.join(',') + groupedTicketsIds) ||
      (maximumTicketsVal && !(maximumTicketsVal > 0)) ||
      (discountValue <= 0 && checkDiscountValue) ||
      (!discountValue && checkDiscountValue)

    const items = _map(promotion.items, (item, j) => (
      <PromotionItem
        id={item.id}
        key={item.id}
        index={j}
        item={item}
        onDelete={() => this.deleteItem(j)}
        onMove={(dragIndex, hoverIndex) => this.moveItem(dragIndex, hoverIndex)}
      />
    ))
    const { items: promotionItems = [] } = promotion

    return (
      <div className="eventpromotion-promotion">
        {!promotion.isNew && !promotion.expanded && (
          <div className="eventpromotion-promotion-top">
            <div aria-hidden className="eventpromotion-promotion-caret" onClick={e => this.expand()}>
              <i className="fa fa-plus-square-o" aria-hidden="true" />
            </div>
            <div className="eventpromotion-promotion-name">{promotion.name}</div>
            {promotion.startDate && (
              <div className="eventpromotion-promotion-startdate">
                <i className="fa fa-clock-o" aria-hidden="true" />{' '}
                {moment(promotion.startDate).format('MM/DD/YYYY')}
              </div>
            )}
          </div>
        )}
        {!promotion.isNew && promotion.expanded && (
          <div className="eventpromotion-promotion-top">
            <div aria-hidden className="eventpromotion-promotion-caret" onClick={e => this.collapse()}>
              <i className="fa fa-minus-square-o" aria-hidden="true" />
            </div>
            <div className="eventpromotion-promotion-name">{promotion.name}</div>
            {promotion.startDate && (
              <div className="eventpromotion-promotion-startdate">
                <i className="fa fa-clock-o" aria-hidden="true" />{' '}
                {moment(promotion.startDate).format('MM/DD/YYYY')}
              </div>
            )}
          </div>
        )}
        <Element name={`scroll-to-name-${promotion.id}`} />
        {((!promotion.isNew && promotion.expanded) || promotion.isNew) && (
          <div className={promotion.isNew ? 'eventpromotion-promotion-new' : 'eventpromotion-promotion-edit'}>
            <div className="row">
              <div className="col-xs-12 responsive-labeling">
                <Field
                  id={'promotion-name' + promotion.id}
                  value={promotion.name}
                  label="Promotion Description (for your reference)"
                  error={nameRequired ? 'Required' : null}
                  touched={true}
                  onChange={e => this.changeName(e.target.value)}
                  autoFocus={true}
                />
              </div>
            </div>
            <div className="eventpromotion-promotion-itemform">
              <div className="row multiselect-tickets col-xl-6">
                <div className="col-xs-8">
                  <label htmlFor={'promotion-ticketType' + promotion.id}>Ticket Type</label>
                  {_map(
                    _filter(noGroupedTickets, item => item.active),
                    (t, index) => (
                      <div key={index}>
                        <input
                          type="checkbox"
                          id={t.id}
                          name={t.id}
                          checked={noGroupedTicketsIds.includes(t.id)}
                          onChange={this.onChangeNoGroupedTickets}
                        />
                        <label style={{ marginLeft: '10px' }} htmlFor={t.id}>
                          {t.displayName}
                        </label>
                      </div>
                    )
                  )}
                  {groupedTickets.length ? (
                    <SelectTimeSlotTickets
                      groupedTickets={groupedTickets}
                      deselectCheckboxes={deselectCheckboxes}
                      onChange={v => this.onChangeGroupedTickets(v)}
                    />
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <label htmlFor={'promotion-discount' + promotion.id}>Pricing Adjustment</label>
                  <select
                    id={'promotion-discount' + promotion.id}
                    className="form-control"
                    onChange={this.changeDiscount}
                    value={discountOption}
                    style={{ background: '#393d44' }}
                  >
                    {_map(DISCOUNT_OPTIONS, (e, i) => (
                      <option key={i} value={e.value}>
                        {e.title}
                      </option>
                    ))}
                  </select>
                </div>
                {!shareMaxLimitChecked && (
                  <div className="col-xs-12 col-sm-6" style={{ marginTop: '17px' }}>
                    <Field
                      id={'promotion-quantitylimit' + promotion.id}
                      disabled={!promotion.isNew && shareMaxLimitChecked}
                      value={maximumTicketsVal}
                      type="number"
                      label="Maximum Tickets (optional)"
                      onChange={e => this.changeQuantityLimit(e.target.value)}
                    />
                  </div>
                )}
                {discountOption !== '' && discountOption !== 'none' && (
                  <div className="col-xs-12 col-sm-6" style={{ marginTop: '17px' }}>
                    <Field
                      id={'promotion-discountvalue' + promotion.id}
                      value={discountValue}
                      type="number"
                      label={
                        (discountOption === 'fixed_price' ? 'New Price in ' : 'Discount Amount in ') +
                        discountValueSymbol
                      }
                      onChange={e => this.changeDiscountValue(e.target.value)}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-xs-12 text-center top-btn-spacing">
                  <Button
                    className="btn btn-primary btn-shadow"
                    disabled={isAddTicketButtonDisabled}
                    onClick={this.addItem}
                  >
                    Add Ticket
                  </Button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <table className="eventpromotion-promotion-items-table table">
                  {promotionItems.length > 0 && (
                    <thead>
                      <tr>
                        <td>Ticket</td>
                        <td>Discount</td>
                        <td>Value</td>
                        <td>Quantity Limit</td>
                        <td className="align-right">Actions</td>
                      </tr>
                    </thead>
                  )}
                  <tbody>{items}</tbody>
                </table>
              </div>
              {eventHasLimit && (
                <div
                  className={`col-xs-12 ${shareMaxLimitChecked ? 'col-sm-6' : 'col-sm-12'}`}
                  style={{ marginBottom: '25px', marginTop: '17px' }}
                >
                  <CustomToggle
                    id="shareMaximumLimit"
                    onText="Share maximum limit between selected ticket types"
                    checked={shareMaxLimitChecked}
                    onChange={this.handleShareMaximumLimit}
                  />
                </div>
              )}
              {eventHasLimit && shareMaxLimitChecked && (
                <div className="col-xs-12 col-sm-6" style={{ marginBottom: '25px', marginTop: '17px' }}>
                  <Field
                    id={'promotion-max-quantitylimit' + promotion.id}
                    value={maxQuantityLimitValue || promotion.quantity_limit}
                    type="number"
                    label="Maximum limit between selected ticket types"
                    onChange={this.handleMaxQuantityLimitChange}
                    touched={true}
                    error={maxQuantityError ? 'Required' : ''}
                  />
                </div>
              )}
              <Element name={`scroll-to-date-${promotion.id}`} />
              <div className="col-xs-12 col-sm-6 row-bottom-spacing" style={{ position: 'static' }}>
                <DateTimePickerReact
                  value={promotion.startDate}
                  label="Start Date (optional)"
                  id={'promotion-startDate' + promotion.id}
                  placeholder="D MMM YYYY H:M AM"
                  onChange={this.changeStartDate}
                  error={dateRequired && !promotion.startDate ? 'Required' : null}
                  touched={true}
                />
              </div>
              <div className="col-xs-12 col-sm-6" style={{ position: 'static' }}>
                <DateTimePickerReact
                  value={promotion.endDate}
                  label="End Date (optional)"
                  id={'promotion-endDate' + promotion.id}
                  placeholder="D MMM YYYY H:M AM"
                  onChange={this.changeEndDate}
                  error={
                    dateRequired && !promotion.endDate
                      ? 'Required'
                      : isDateRangeValidator()(promotion.startDate, promotion.endDate)
                  }
                  touched={true}
                />
              </div>
              <div className="col-xs-12 floating-field ">
                <div className="eventpromotion-promotion-tag-label">
                  Enter valid codes for unlocking this promotion (press <strong>space</strong> or{' '}
                  <strong>enter</strong> after each one):
                </div>
                <TagsField
                  tagsContainerClassName="form-control"
                  isPromotion={true}
                  eventSlug={event.slug}
                  externalEventPartnerDomain={event.externalEventPartnerDomain}
                  value={promotion.codes}
                  onChange={this.changePromoCodes}
                  controlOutside
                  isRnDomain={isRnDomain}
                />
              </div>
              <div className="col-xs-12 col-sm-6" style={{ marginBottom: 10 }}>
                <label htmlFor={'promotion-discount' + promotion.id}>Promo Code</label>
                <select
                  id={'promotion-discount' + promotion.id}
                  className="form-control"
                  onChange={this.handleChangePromoCode}
                  value={selectedPromoCode}
                >
                  {uniqPromoCodes && uniqPromoCodes.length ? (
                    _map(uniqPromoCodes, (e, i) => (
                      <option key={i} value={e.code}>
                        {e.code}
                      </option>
                    ))
                  ) : (
                    <option value={'Select...'}>{'Select...'}</option>
                  )}
                </select>
              </div>
              <div className="col-xs-12" style={{ width: 'auto', marginTop: 39 }}>
                <Button
                  className="btn btn-success btn-shadow"
                  onClick={this.generateToken}
                  loading={isGeneratingToken}
                  disabled={isGenerateButtonDisabled}
                >
                  <i className={'fa fa-pencil'} />
                  <span style={{ paddingLeft: 5 }}>{'Generate Sales Tracking Link'}</span>
                </Button>
              </div>
              {uniqPromoCodes &&
                uniqPromoCodes.length > 0 &&
                _findIndex(uniqPromoCodes, p => !!p.token) !== -1 && (
                  <div className="col-xs-12">
                    <table className="eventpromotion-promotion-items-table table">
                      <thead>
                        <tr>
                          <td>Promo Code</td>
                          <td>Copy Tracking Link</td>
                          <td className="align-right" />
                        </tr>
                      </thead>
                      <tbody>
                        {_map(
                          uniqPromoCodes,
                          p =>
                            !!p.token && (
                              <tr className="eventpromotion-item">
                                <td>{p.code}</td>
                                <td>
                                  <ClipboardButton
                                    component="span"
                                    data-clipboard-text={`${API_BASE}/sales-by-promo-code?token=${
                                      p.token || ''
                                    }`}
                                    onSuccess={this.copyEventUrlAfter}
                                  >
                                    <OverlayTrigger
                                      placement="right"
                                      overlay={
                                        this.state.eventUrlCopied ? (
                                          <Tooltip id="promoCodeUrlCopied">Copied</Tooltip>
                                        ) : (
                                          <Tooltip id="promoCodeUrlCopied">Copy Promo Code URL</Tooltip>
                                        )
                                      }
                                      trigger={['hover']}
                                    >
                                      <i className="fa fa-clipboard event-url-copy" />
                                    </OverlayTrigger>
                                  </ClipboardButton>
                                </td>
                                <td className="align-right">
                                  <Button
                                    className="btn btn-success btn-shadow"
                                    onClick={e => this.generateToken(e, p.code)}
                                    loading={isRefreshingToken && selectedPromoCode === p.code}
                                  >
                                    <i className={'fa fa-undo'} />
                                    <span style={{ paddingLeft: 5 }}>{'Regenerate Link'}</span>
                                  </Button>
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
            </div>
          </div>
        )}
        {((!promotion.isNew && promotion.expanded) || promotion.isNew) && (
          <div className="eventpromotion-promotion-buttons">
            <Button
              className="btn btn-success btn-shadow"
              onClick={e => this.savePromotion()}
              disabled={!promotion.items.length}
            >
              <i className={promotion.isNew ? 'fa fa-floppy-o' : 'fa fa-pencil'} />
              <span>{promotion.isNew ? 'Create' : 'Update'}</span>
              <div className="help-text-container">
                <p className="help-text">At least one ticket type <br/>is required.</p>
              </div>
            </Button>
            {!promotion.isNew && (
              <Button className="btn btn-danger btn-shadow" onClick={e => this.deletePromotion()}>
                <i className="fa fa-trash" />
                <span>Delete</span>
              </Button>
            )}
            <Button className="btn btn-cancel btn-shadow" onClick={e => this.collapse()}>
              <span>Cancel</span>
            </Button>
          </div>
        )}
      </div>
    )
  }
}
