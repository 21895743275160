import _get from 'lodash/get'
import { baseRequest } from '.'

export const getContract = async (eventId) => {
    try {
        const res = await baseRequest.get(`events/${eventId}/contract`)
        return _get(res, 'data')
    } catch(err) {
        if (err?.response?.data?.message === 'Contract not found') {
            return null
        }
        throw err
    }
}

export const createContract = async (eventId, data) => {
    const res = await baseRequest.post(`events/${eventId}/contract`, {data})
    return _get(res, 'data')
}

export const getContractStatus = async (eventId) => {
    const res = await baseRequest.get(`events/${eventId}/contract/interaction`)
    return _get(res, 'data')
}

export const deployContract = async (eventId) => {
    const res = await baseRequest.post(`events/${eventId}/contract/deploy`)
    return _get(res, 'data')
}