import { ENV } from '../../web/constants/env'

const base = (ENV.ASSET_URL || '').replace(/\/$/, '')

global.asset = function asset(pathname) {
  return base + pathname
}

global.databaseTimezone = 'Australia/Sydney'

const SIDEBAR_THRESHOLD = 768
global.isMobileDevice = function () {
  return $(window).width() <= SIDEBAR_THRESHOLD
}

let country_code = 'US'; const locale = 'en_US'
$.getJSON('https://ipapi.co/json/', (data) => {
  country_code = data.country
})

global.getLocale = function getLocale() {
  let locale = 'en_US'
  switch(country_code.toUpperCase()){
  case 'AU':
    locale = 'en_AU'
    break
  case 'CA':
    locale = 'en_CA'
    break
  case 'DE':
    locale = 'de'
    break
  case 'EU':
    locale = 'de'
    break
  case 'FR':
    locale = 'fr'
    break
  case 'GB':
    locale = 'en_GB'
    break
  case 'IN':
    locale = 'en_IND'
    break
  case 'ID':
    locale = 'id_ID'
    break
  case 'IS':
    locale = 'en_IE'
    break
  case 'MX':
    locale = 'es_MX'
    break
  case 'NZ':
    locale = 'en_au_ocker'
    break
  default:
    break
  }
  return locale
}

global.getLocalCurrency = function getCurrency() {
  let currency = {
    currency: 'USD',
    symbol: 'US$',
  }
  switch(country_code.toUpperCase()) {
  case 'AU':
    currency = {
      currency: 'AUD',
      symbol: 'AU$',
    }
    break
  case 'EU':
    currency = {
      currency: 'EUR',
      symbol: '€',
    }
    break
  case 'NZ':
    currency = {
      currency: 'NZD',
      symbol: 'NZ$',
    }
    break
  case 'GB':
    currency = {
      currency: 'GBP',
      symbol: '£',
    }
    break
  case 'IN':
    currency = {
      currency: 'INR',
      symbol: '₹',
    }
    break
  case 'KE':
    currency = {
      currency: 'KES',
      symbol: 'KSh',
    }
    break
  case 'CA':
    currency = {
      currency: 'CAD',
      symbol: 'CA$',
    }
    break
  case 'ID':
    currency = {
      currency: 'IDR',
      symbol: 'Rp',
    }
    break
  case 'IS':
    currency = {
      currency: 'ISK',
      symbol: 'kr',
    }
    break
  case 'TH':
    currency = {
      currency: 'THB',
      symbol: '฿',
    }
    break
  default:
    break
  }
  return currency
}

global.getCurrencySymbol = function getCurrencySymbol(event) {
  return (event && event.currency && event.currency.symbol) ? event.currency.symbol : 'US$'
}

global.isNil = (x) => (x === null) || (typeof x === 'undefined')
global.capitalizeFirstLetter = function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

global.getCurrentYear = function getCurrentYear() {
  return (new Date()).getFullYear()
}

const LANGUAGES = {
  'sq': 'Albanian',
  'hy': 'Armenian',
  'ps': 'Pashto',
  'az': 'Azeri',
  'bs': 'Bosnian',
  'bg': 'Bulgarian',
  'be': 'Belarusian',
  'zh': 'Chinese',
  'cs': 'Czech',
  'de': 'German',
  'da': 'Danish',
  'et': 'Estonian',
  'es': 'Spanish',
  'gl': 'Galician',
  'fi': 'Finnish',
  'fo': 'Faroese',
  'fr': 'French',
  'en': 'English',
  'ka': 'Georgian',
  'el': 'Greek',
  'hr': 'Croatian',
  'hu': 'Hungarian',
  'id': 'Indonesian',
  'he': 'Hebrew',
  'hi': 'Hindi',
  'fa': 'Farsi',
  'is': 'Icelandic',
  'it': 'Italian',
  'ja': 'Japanese',
  'sw': 'Swahili',
  'ky': 'Kyrgyz',
  'ko': 'Korean',
  'kk': 'Kazakh',
  'lt': 'Lithuanian',
  'lv': 'Latvian',
  'mk': 'FYRO Macedonian',
  'mn': 'Mongolian',
  'mt': 'Maltese',
  'dv': 'Divehi',
  'ms': 'Malay',
  'nl': 'Dutch',
  'nb': 'Norwegian',
  'nn': 'Norwegian',
  'mi': 'Maori',
  'tl': 'Tagalog',
  'ur': 'Urdu',
  'pl': 'Polish',
  'pt': 'Portuguese',
  'pt-BR': 'Brazilian Portuguese',
  'ro': 'Romanian',
  'ru': 'Russian',
  'sv': 'Swedish',
  'sl': 'Slovenian',
  'sk': 'Slovak',
  'sr': 'Serbian',
  'sy': 'Syriac',
  'th': 'Thai',
  'tr': 'Turkish',
  'uk': 'Ukrainian',
  'uz': 'Uzbek',
  'vi': 'Vietnamese',
  'zu': 'Zulu'
}
global.getLanguages = function getLanguages() {
  return LANGUAGES
}

/** Medium Editor filter&revert module */
global.mediumEditor_revert = function mediumEditor_revert(htmlContent) {
  if(!htmlContent)
    return htmlContent
  const lineRemoved = htmlContent.replace(/\r?\n|\r/g, '')
  const regex = /(<p class="medium-insert-images[^>]+>)(.*?)(<\/p>)/ig
  return lineRemoved.replace(regex, (match, p1, p2, p3, offset, string) => 
    // console.log('r1 = ', p1)
    // console.log('r2 = ', p2)
    // console.log('r3 = ', p3)
    p1.replace(/<p/ig, '<div') + '<figure>' + p2 + '</figure></div>'
  )
}

global.formatNumberToLocale = function formatNumber(number, decimals, useGroup, localeString) {
  let str = parseFloat(number).toFixed(decimals)
  if (useGroup && decimals >= 1) {
    let array1 = str.split('.')[0].split('').reverse().join('')
    const array2 = str.split('.')[1]
    array1 = array1.replace(/(\d{3})(?=[^$])/g, '$1,')
    array1 = array1.split('').reverse().join('')
    str = `${array1}.${array2}`
  }
  if(localeString){
    str = parseInt(number).toLocaleString()
    if(decimals >= 1 && parseFloat(number) - parseInt(number) != 0){
      const str1 = parseFloat(number).toFixed(decimals)
      const array2 = str1.split('.')[1]
      str = `${str}.${array2}`
    }
  }
  return str
}

global.isProd = () => 
  window?.location?.hostname === 'manage.theticketfairy.com' || window?.location?.hostname === 'manage.ticketfairy.com' 
