/* eslint-disable no-underscore-dangle */
import React from 'react'
import _isFinite from 'lodash/isFinite'
import { Formik, Form, Field } from 'formik'
import Button from '../../_library/Button'
import Disabled from '../../_library/Disabled'
import { MetaMaskIcon } from './MetaMaskIcon'
import { metaMask } from '../../../_common/core/web3/metamask'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'

// form fields
import {
  TextAreaField,
  SimpleField,
  SelectField,
  RadioGroupField,
  FileUploaderField,
  CustomToggleField
} from '../../formik/Fields'

// validators
import {
  // field level validators
  requiredValidator,
  fileSizeLimitValidator,

  // form level validators

  // normalizers

  // utils
  combineValidators
} from '../../../_common/core/validation'

const NFTForm = props => {
  const { 
    initialValues,
    onSubmit,
    disabled=false,
    hideSaveButton,
    loading,
  } = props

  const _initialValues = {
    name: initialValues?.name ?? '',
    symbol: initialValues?.symbol ?? '',
    network: initialValues?.network ?? 'ethereum',
    address: initialValues?.address ?? '',
    mintTime: initialValues?.mintTime ?? 'buy',

    file: initialValues?.file ?? '',
    description: initialValues?.description ?? '',
    tokenName: initialValues?.tokenName ?? '',
    externalUrl: initialValues?.externalUrl ?? '',

    afterScan: initialValues?.afterScan ?? false,

    afterScanFile: initialValues?.afterScanFile ?? '',
    afterScanDescription: initialValues?.afterScanDescription ?? '',
    afterScanTokenName: initialValues?.afterScanTokenName ?? '',
    afterScanExternalUrl: initialValues?.afterScanExternalUrl ?? '',

    secondarySalesRoyalties: initialValues?.secondarySalesRoyalties ?? 5,
    // totalSupply: 10000
  }

  const formValidate = () => {
    const errors = {}
    return errors
  }

  const _onSubmit = (values, actions) => {
    onSubmit(values).finally(() => {
        actions.setSubmitting(false)
    })
  }

  return (
    <Disabled opacity={1} disabled={disabled}>
      <div>
        <Formik initialValues={_initialValues} validate={formValidate} onSubmit={_onSubmit}>
          {props => {
            const { values, isSubmitting, setFieldValue } = props

            const onMetamaskConnect = address => {
              setFieldValue('address', address)
            }

            const onArrowClick = (fieldName, type) => {
              let value = values[fieldName]
              value = _isFinite(Number(value)) ? Number(value) : 0
              value = type === 'inc' ? value + 1 : value - 1
              setFieldValue(fieldName, value)
            }

            return (
              <Form autoComplete="off">
                <div>
                  <h4>General</h4>
                  <small>Enter a name and symbol which represents your project and choase a network.</small>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <label>
                        NFT Collection Name
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>A descriptive name for a collection of NFTs in this contract.</Tooltip>
                          }
                        >
                          <span>?</span>
                        </OverlayTrigger>
                      </label>
                      <Field
                        name="name"
                        component={SimpleField}
                        validate={requiredValidator()}
                        helperText="e.g. Drink Outside The Box"
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <label>
                        NFT Collection Symbol
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>An abbreviated name for NFTs in this contract.</Tooltip>}
                        >
                          <span>?</span>
                        </OverlayTrigger>
                      </label>
                      <Field
                        name="symbol"
                        component={SimpleField}
                        validate={requiredValidator()}
                        helperText="e.g. DOTB"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <label>Network</label>
                      <Field
                        name="network"
                        component={SelectField}
                        options={[
                          { value: 'ethereum', label: 'Ethereum' },
                          { value: 'polygon', label: 'Polygon' },
                          { value: 'solana', label: 'Solana' }
                        ]}
                        validate={requiredValidator()}
                      />
                    </div>
                    <div className="col-sm-6 col-12 metamask-field">
                      <label>
                        Collection Owner Address
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              The address which owns the contract and has the ability to reclaim it on network.
                            </Tooltip>
                          }
                        >
                          <span>?</span>
                        </OverlayTrigger>
                      </label>
                      <Field name="address" component={SimpleField} validate={requiredValidator()} />
                      {metaMask.isInstalled ? <MetaMaskIcon onConnect={onMetamaskConnect} /> : null}
                    </div>
                  </div>
                  <h4>Select when NFT is minted</h4>
                  <div className="minted-radio">
                    <Field
                      name="mintTime"
                      component={RadioGroupField}
                      groups={[
                        {
                          label: 'When a customer buys a ticket',
                          value: 'buy'
                        },
                        {
                          label: `When a customer's ticket is scanned`,
                          value: 'scan'
                        }
                      ]}
                      classNames={{
                        container: 'radio-group-container single-column',
                        container_item: 'radio-group-item',
                        component: ''
                      }}
                    />
                  </div>
                  <div className="nft-details">
                    <h4>NFT Details</h4>
                    <div className="row">
                      <div className="col-xs-6 col-12">
                        <label>Photo/Video</label>
                        <Field
                          name="file"
                          filetype="image"
                          label="Upload Photo/Video"
                          validate={combineValidators(requiredValidator(), fileSizeLimitValidator())}
                          component={FileUploaderField}
                          accept="video/*,image/*"
                        />
                      </div>
                      <div className="col-xs-6 col-12 textarea">
                        <label>
                          Description
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>A human readable description of the item.</Tooltip>}
                          >
                            <span>?</span>
                          </OverlayTrigger>
                        </label>
                        <Field
                          name="description"
                          component={TextAreaField}
                          validate={requiredValidator()}
                          rows={7}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-12">
                        <label>
                          Token Name
                          <OverlayTrigger placement="top" overlay={<Tooltip>Name of the item.</Tooltip>}>
                            <span>?</span>
                          </OverlayTrigger>
                        </label>
                        <Field name="tokenName" component={SimpleField} validate={requiredValidator()} />
                      </div>
                      <div className="col-sm-6 col-12">
                        <label>
                          External URL
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>This is the URL that will appear to view the item on your site.</Tooltip>
                            }
                          >
                            <span>?</span>
                          </OverlayTrigger>
                        </label>
                        <Field name="externalUrl" component={SimpleField} validate={requiredValidator()} />
                      </div>
                    </div>
                  </div>
                  {values.mintTime === 'buy' ? (
                    <div className="scan-toggle">
                      <Field
                        name="afterScan"
                        onText="Change token details after the ticket is scanned"
                        component={CustomToggleField}
                      />
                    </div>
                  ) : null}

                  {values.afterScan && values.mintTime === 'buy' ? (
                    <div className="nft-details">
                      <h4>NFT Details after ticket scan</h4>
                      <div className="row">
                        <div className="col-xs-6 col-12">
                          <label>Photo/Video</label>
                          <Field
                            name="afterScanFile"
                            label="Upload Photo/Video"
                            filetype="image"
                            validate={combineValidators(requiredValidator(), fileSizeLimitValidator())}
                            component={FileUploaderField}
                            accept="video/*,image/*"
                          />
                        </div>
                        <div className="col-xs-6 col-12 textarea">
                          <label>
                            Description
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>A human readable description of the item.</Tooltip>}
                            >
                              <span>?</span>
                            </OverlayTrigger>
                          </label>
                          <Field
                            name="afterScanDescription"
                            component={TextAreaField}
                            validate={requiredValidator()}
                            rows={7}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <label>
                            Token Name
                            <OverlayTrigger placement="top" overlay={<Tooltip>Name of the item.</Tooltip>}>
                              <span>?</span>
                            </OverlayTrigger>
                          </label>
                          <Field
                            name="afterScanTokenName"
                            component={SimpleField}
                            validate={requiredValidator()}
                          />
                        </div>
                        <div className="col-sm-6 col-12">
                          <label>
                            External URL
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  This is the URL that will appear to view the item on your site.
                                </Tooltip>
                              }
                            >
                              <span>?</span>
                            </OverlayTrigger>
                          </label>
                          <Field
                            name="afterScanExternalUrl"
                            component={SimpleField}
                            validate={requiredValidator()}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <h4>Minting</h4>
                  <div className="row minting">
                    <div className="col-sm-6 col-12">
                      <label>
                        Secondary Sales Royalties, %
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>The percentage you wish to charge as secondary fees.</Tooltip>}
                        >
                          <span>?</span>
                        </OverlayTrigger>
                      </label>
                      <Field
                        name="secondarySalesRoyalties"
                        component={SimpleField}
                        type="number"
                        validate={requiredValidator()}
                        className="remove-number-input-arrows"
                      />
                      <div className="arrows">
                        <button onClick={() => onArrowClick('secondarySalesRoyalties', 'inc')} type="button">
                          <img src="/resources/images/arrow-top.svg" alt="top" />
                        </button>
                        <button onClick={() => onArrowClick('secondarySalesRoyalties', 'dec')} type="button">
                          <img src="/resources/images/arrow-bottom.svg" alt="bottom" />
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-sm-6 col-12">
                      <label>
                        Total Supply
                        <OverlayTrigger placement="top" overlay={<Tooltip>Max number of tokens.</Tooltip>}>
                          <span>?</span>
                        </OverlayTrigger>
                      </label>
                      <Field
                        name="totalSupply"
                        component={SimpleField}
                        type="number"
                        validate={requiredValidator()}
                        className="remove-number-input-arrows"
                      />
                      <div className="arrows">
                        <button onClick={() => onArrowClick('totalSupply', 'inc')} type="button">
                          <img src="/resources/images/arrow-top.svg" alt="top" />
                        </button>
                        <button onClick={() => onArrowClick('totalSupply', 'dec')} type="button">
                          <img src="/resources/images/arrow-bottom.svg" alt="bottom" />
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                {(!hideSaveButton) && (
                  <div className="row">
                    <div className="col-xs-12 text-center save-btn">
                      <Button className="btn btn-success btn-lg btn-shadow" type="submit" loading={isSubmitting || loading}>
                        Save NFT Contract Details
                      </Button>
                    </div>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </div>
    </Disabled>
  )
}

export { NFTForm }
