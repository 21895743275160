import _result from 'lodash/result'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _map from 'lodash/map'
import _orderBy from 'lodash/orderBy'
import _filter from 'lodash/filter'
import _uniq from 'lodash/uniq'
import _startCase from 'lodash/startCase'
import _isEmpty from 'lodash/isEmpty'
import _some from 'lodash/some'
import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import Modal from 'react-modal'
import PerfectScrollbar from 'react-perfect-scrollbar'

import modalStyle from '../../_common/core/modalStyle'
import Card from '../_library/Card'
import Field from '../_library/Field'
import Button from '../_library/Button'
import Filters from '../_library/Filters'
import LoadingBar from '../_library/LoadingBar'
import { SelectFilterTemplate, SaveTemplate } from '../_library/FilterTemplate'

import EventOrderTicketForm from './order/TicketForm'
import TicketsTypeBulkUpdate from './order/TicketsTypeBulkUpdate'
import Scroll from 'react-scroll'

import OrderDateFilter from './order/OrderDateFilter'

import { toPascalCase } from '../../_common/core/utils'
import { formatDate } from '../../_common/core/validation'
import { DISPLAY_FORMAT } from '../constants/timeFormats'
import {
  RESEND_ORDER,
  CANCEL_ORDER,
  UPDATE_ORDER_TICKET,
  TICKETS_TYPE_BULK_UPDATE,
  FETCH_EVENT_ORDERS,
  TOGGLE_ORDER_RESALE,
  MARK_ORDER_AS_PAID,
  REFRESH_ORDERS_DATA
} from '../../_common/redux/orders/actions'
import { FETCH_EVENT_TICKETS } from '../../_common/redux/tickets/actions'
import { ROLES } from '../constants/roles'
import { ENV } from '../constants/env'

import {
  JSONDatatable,
  TYPE_FROM_URL,
  TYPE_FROM_ARRAY,
  SEARCHBAR,
  DATATABLE,
  PAGINATIONBAR,
  IS_FOUND,
  DOWNLOAD_CSV
} from '../_library/JSONDatatable'
import EmptyBar from '../_library/EmptyBar'

import {
  get_event,
  get_event_role,
  get_event_tickets,
  get_event_orders_state
} from '../../_common/core/selectors'
import { NotesRows } from './_library/NotesRows'
import { SocialMediaRow } from './_library/SocialMediaRow'
import { getTitle } from '../utils/getTitle'

import SortableTable from '../_library/SortableTable'
import { getTableColumns } from '../utils/sortableTableUtils'
import { createFixedFloatNormalizer, currencyNormalizerCreator } from '../../_common/core/validation'

const ORDER_STATUS_ALL = 0
const ORDER_STATUS_PAID = 1
const ORDER_STATUS_REFUNDED = 2
const ORDER_STATUS_PENDING = 3
const PENDING_PAYMENT_PLAN = 4
const ORDER_STATUS_SOLD = 5
const ORDER_STATUS_FOR_SALE = 6
const ORDER_STATUS_SALE_IN_PROGRESS = 7
const PENDING_OXXO_ORDER = 8

const TABLE_E2E_TEST_ID = 'event_orders'

export const getTicketRows = ({
  rowIndex,
  tickets,
  status,
  paymentPlanDetails,
  addOns,
  ticketDataCapture,
  orderId,
  discountCode,
  includeTablesData,
  guestCount,
  currency,
  debt,
  total,
  openTicketEditModal,
  setTicketTypesBulkUpdateModalState,
  event,
  eventId,
  timeSlotsEnabled,
  eventsInOrder,
  customerId
}) => {
  const payment_plans_rows =
    status === 'Payment plan'
      ? (paymentPlanDetails || []).map((item, i) => {
        let row_color = 'ticket-yellow'
        if (item.status === 'succeeded' || item.status === 'paid') {
          row_color = 'ticket-green'
        }
        if (item.status === 'failed') {
          row_color = 'ticket-red'
        }
        return (
          <div key={String(i)} className={`row-content row-ticket row ${row_color}`}>
            <div className="col-xs-2">
              <div className="content">
                <div className="value">{item.type}</div>
              </div>
            </div>
            <div className="col-xs-2">
              <div className="content">
                <div className="value">{`${event.currency.symbol}${item.amount}`}</div>
              </div>
            </div>
            <div className="col-xs-3">
              <div className="content">
                <div>{formatDate(item.charge_date || item.scheduled_date, DISPLAY_FORMAT)}</div>
              </div>
            </div>
            <div className="col-xs-2">
              <div className="content">
                <div className="value">{item.status}</div>
              </div>
            </div>
          </div>
        )
      })
      : null
  const addons_rows = _map(addOns, (addons, aid) => (
    <div key={aid} className={'row-content row'}>
      <div className="col-xs-6" data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${rowIndex - 1}-${aid}-name`}>
        <div className="content">
          <div className="value">{addons.name}</div>
        </div>
      </div>
      <div className="col-xs-6" data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${rowIndex - 1}-${aid}-quantity`}>
        <div className="content">
          <div className="value">{addons.quantity}</div>
        </div>
      </div>
    </div>
  ))
  const isSeatNameExist = _some(tickets, ticket => ticket.seatName)
  const ticket_rows = _map(tickets, (ticket, tid) => {
    let row_color = 'ticket-red'
    if (ticket.status === 'valid') row_color = 'ticket-green'
    if (ticket.status === 'checked in') row_color = 'ticket-yellow'
    const ticketDataCapture_rows = []
    _map(ticketDataCapture, (tdcRow, tdcid) => {
      if (tdcRow.ticketHash === ticket.ticketHash) {
        ticketDataCapture_rows.push(
          <div key={tdcid} className={'row-content row-ticket row ticket-gray'}>
            <div className="col-xs-6">
              <div className="content">
                <div className="value">{tdcRow.name}</div>
              </div>
            </div>
            <div className="col-xs-6">
              <div className="content">
                <div className="value">{tdcRow.value}</div>
              </div>
            </div>
          </div>
        )
      }
    })
    return (
      <div key={tid}>
        <div className={'row-content row-ticket row ' + row_color}>
          <div
            className="col-xs-2"
            data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${rowIndex - 1}-${tid}-ticketHash`}
          >
            <div className="content">
              <div className="value">
                <a href={`${ENV.API_BASE}/download-ticket/${ticket.id}/${ticket.ticketHash}/`}>
                  <i className="fa fa-download" aria-hidden="true" />
                </a>
                &nbsp;&nbsp;
                <i
                  className="fa fa-edit"
                  aria-hidden="true"
                  onClick={() => openTicketEditModal(ticket, orderId, ticket.eventId, customerId)}
                />
                &nbsp;&nbsp;
                {ticket?.ticketHash?.toUpperCase()}
              </div>
            </div>
          </div>
          <div className="col-xs-2" data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${rowIndex - 1}-${tid}-fullName`}>
            <div className="content">
              <div className="value">
                {ticket.firstName} {ticket.lastName}
              </div>
            </div>
          </div>
          <div
            className="col-xs-2"
            data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${rowIndex - 1}-${tid}-ticketType`}
          >
            <div className="content">
              <div className="value">{ticket.ticketType}</div>
            </div>
          </div>
          {isSeatNameExist && (
            <div
              className="col-xs-2"
              data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${rowIndex - 1}-${tid}-seatName`}
            >
              <div className="content">
                <div className="value">{ticket.seatName}</div>
              </div>
            </div>
          )}
          <div className="col-xs-2" data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${rowIndex - 1}-${tid}-status`}>
            <div className="content">
              <div className="value">{ticket.status}</div>
            </div>
          </div>
          <div className="col-xs-2" data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${rowIndex - 1}-${tid}-tags`}>
            <div className="content">
              <div className="value" id={'ticket-column-tags-' + ticket.ticketHash} key={ticket.ticketHash}>
                {getTicketTagsColumn(ticket.tags)}
              </div>
            </div>
          </div>
          <div
            className="col-xs-2"
            data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${rowIndex - 1}-${tid}-extraInfo`}
          >
            <div className="content">
              <div className="value">{ticket.extraInfo}</div>
            </div>
          </div>
        </div>
        {ticketDataCapture_rows.length > 0 && (
          <div className="row-ticketDataCapture">
            <div className="row-title row">
              <span>
                <i className="fa fa-table" aria-hidden="true" /> Data Capture
              </span>
            </div>
            <div className="row-content-expanded">
              <div className="row-content row-ticket row">
                <div className="col-xs-6">
                  <div className="content">
                    <div className="field">Field</div>
                  </div>
                </div>
                <div className="col-xs-6">
                  <div className="content">
                    <div className="field">Value</div>
                  </div>
                </div>
              </div>
              {ticketDataCapture_rows}
            </div>
          </div>
        )}
      </div>
    )
  })
  return (
    <div className="ticket-row-box">
      <div className="row-content row-ticket row">
        <div className="row-title row">
          <div className="col-xs-12 withDecorator ticket-type-bulk-update">
            <span>Tickets</span>
            {((!!ticket_rows.length && eventsInOrder === undefined) ||
              (eventsInOrder !== undefined && eventsInOrder === 1 && !!ticket_rows.length)) && (
              <button
                type="button"
                className="btn btn-primary btn-shadow pull-right button-event-order"
                onClick={() => {
                  setTicketTypesBulkUpdateModalState(true, orderId, eventId, timeSlotsEnabled, customerId)()
                }}
              >
                {event?.flagTimeSlotsEnabled || timeSlotsEnabled ? 'Change Time Slot' : 'Change Ticket Type'}
              </button>
            )}
          </div>
          <div className="div-spacing-20" />
        </div>
        <div className="col-xs-2">
          <div className="content">
            <div className="field">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ticket ID
            </div>
          </div>
        </div>
        <div className="col-xs-2">
          <div className="content">
            <div className="field">Ticket Holder</div>
          </div>
        </div>
        <div className="col-xs-2">
          <div className="content">
            <div className="field">Ticket Type</div>
          </div>
        </div>
        {isSeatNameExist && (
          <div className="col-xs-2">
            <div className="content">
              <div className="field">Seat Name</div>
            </div>
          </div>
        )}
        <div className="col-xs-2">
          <div className="content">
            <div className="field">Status</div>
          </div>
        </div>
        <div className="col-xs-2">
          <div className="content">
            <div className="field">Tags</div>
          </div>
        </div>
        <div className="col-xs-2">
          <div className="content">
            <div className="field">Notes</div>
          </div>
        </div>
      </div>
      <div>{ticket_rows}</div>
      <div className="row-separator" />
      {status === 'Payment plan' && (
        <div>
          <div className="row-title row">
            <div className="col-xs-12 withDecorator ticket-type-bulk-update">
              <span>Payment Plan</span>
            </div>
          </div>
          <div className="div-spacing-20" />
          <div className="row-content row-ticket row">
            <div className="col-xs-2">
              <div className="content">
                <div className="field">Type</div>
              </div>
            </div>
            <div className="col-xs-2">
              <div className="content">
                <div className="field">Amount</div>
              </div>
            </div>
            <div className="col-xs-3">
              <div className="content">
                <div className="field">Date</div>
              </div>
            </div>
            <div className="col-xs-2">
              <div className="content">
                <div className="field">Status</div>
              </div>
            </div>
          </div>
          <div>{payment_plans_rows}</div>
          <div className="row-separator" />
        </div>
      )}
      <div className="row-title row">
        <div className="col-xs-12 withDecorator">Add-ons / Upgrades</div>
      </div>
      <div className="div-spacing-20" />
      {addOns.length > 0 ? (
        <div className="row-content row">
          <div className="col-xs-6">
            <div className="content">
              <div className="field">Add-on / Upgrade</div>
            </div>
          </div>
          <div className="col-xs-6">
            <div className="content">
              <div className="field">Quantity</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row-content row">
          <div className="col-xs-12">
            <div className="content">
              <div className="value">Not purchased</div>
            </div>
          </div>
        </div>
      )}
      {addOns.length > 0 && <div>{addons_rows}</div>}
      {discountCode && <div className="row-separator" />}
      {discountCode && (
        <div className="row-title row">
          <div className="col-xs-12 withDecorator">Promo Code</div>
        </div>
      )}
      {discountCode && <div className="div-spacing-20" />}
      {discountCode && (
        <div className="row-content row">
          <div className="col-xs-12">
            <div className="content">
              <div className="value">{discountCode}</div>
            </div>
          </div>
        </div>
      )}
      <div className="row-separator" />
      {includeTablesData && parseInt(guestCount) > 0 && (
        <>
          <div className="row-title row">
            <div className="col-xs-12 withDecorator ticket-type-bulk-update">
              <span>Table data</span>
            </div>
          </div>
          <div className="div-spacing-20" />
          <div className="row-content row">
            <div className="col-xs-3">
              <div className="content">
                <div className="field">
                  <i className="fa fa-users" aria-hidden="true" /> Number of Guests
                </div>
                <div className="value email">{parseInt(guestCount)}</div>
              </div>
            </div>
            <div className="col-xs-3">
              <div className="content">
                <div className="field">Current deposit</div>
                <div className="value">
                  {currency}
                  {total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </div>
              </div>
            </div>
            <div className="col-xs-3">
              <div className="content">
                <div className="field">Pending to Pay</div>
                <div className="value">
                  {currency}
                  {debt.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </div>
              </div>
            </div>
          </div>
          <div className="row-separator" />
        </>
      )}
      <NotesRows orderID={orderId} />
    </div>
  )
}

export const getTicketTagsColumn = tags => (
  <div>
    {tags &&
      tags.map((tag, ii) => (
        <div key={ii} className="row-ticket-tag">
          {tag}
        </div>
      ))}
  </div>
)

export const orderDetailRow = (
  t,
  event,
  index,
  includeTablesData,
  currency,
  total,
  debt,
  openTicketEditModal,
  setTicketTypesBulkUpdateModalState,
  openCustomerNoteEditModal,
  deleteCustomerNote,
  isNotesLoading,
  isCustomerCRM,
  SortableTable
) => {
  let content_row = null
  let ticket_rows = null
  let data_capture_rows = null

  if (!isCustomerCRM) {
    ticket_rows = getTicketRows({
      rowIndex: index,
      tickets: t.tickets,
      status: t.status,
      paymentPlanDetails: t.paymentPlanDetails,
      addOns: t.addOns,
      ticketDataCapture: t.ticketDataCapture,
      orderId: t.order.id,
      discountCode: t.order.discountCode,
      includeTablesData,
      guestCount: t.order.guestCount,
      currency,
      debt,
      total,
      openTicketEditModal,
      setTicketTypesBulkUpdateModalState,
      event
    })
  }

  data_capture_rows = _map(t.dataCapture, (dcRow, dcid) => (
    <div key={dcid} className={'row-content row-ticket row ticket-green'}>
      <div className="col-xs-6">
        <div className="content">
          <div className="value">{dcRow.name}</div>
        </div>
      </div>
      <div className="col-xs-6">
        <div className="content">
          <div className="value">{dcRow.value}</div>
        </div>
      </div>
    </div>
  ))

  content_row = (
    <div className="row-detail">
      <div className="row-title row">
        <div className={`${isCustomerCRM ? 'col-xs-4' : 'col-xs-6'} withDecorator`}>Contact Information</div>
        <div className={`${isCustomerCRM ? 'col-xs-4' : 'col-xs-6'} withDecorator`}>Location</div>
        {isCustomerCRM ? (
          <div className="col-xs-4 withDecorator">
            Notes&nbsp;&nbsp;&nbsp;
            {isNotesLoading && <i className="fa fa-spin fa-spinner" aria-hidden="true" />}
          </div>
        ) : null}
      </div>
      <div className="div-spacing-20" />
      <div className="row-content row">
        <div className={isCustomerCRM ? 'col-xs-2' : 'col-xs-3'}>
          <div className="content">
            <div className="field">
              <i className="fa fa-envelope" aria-hidden="true" /> Email Address
            </div>
            <div className="value email">{t.order.billingEmail}</div>
          </div>
        </div>
        <div className={isCustomerCRM ? 'col-xs-2' : 'col-xs-3'}>
          <div className="content">
            <div className="field">
              <i className="fa fa-phone-square" aria-hidden="true" /> Phone Number
            </div>
            <div className="value">{t.order.billingPhone}</div>
          </div>
        </div>
        <div className={isCustomerCRM ? 'col-xs-2' : 'col-xs-3'}>
          <div className="content">
            <div className="field">
              <i className="fa fa-map-marker" aria-hidden="true" /> City
            </div>
            <div className="value">{t.order.billingCity}</div>
          </div>
        </div>
        <div className={isCustomerCRM ? 'col-xs-2' : 'col-xs-3'}>
          <div className="content">
            <div className="field">
              <i className="fa fa-globe" aria-hidden="true" /> Country
            </div>
            <div className="value">{t.order.billingCountry}</div>
          </div>
        </div>
        {isCustomerCRM ? (
          <div className="col-xs-4">
            {t.notes ? (
              <div className={'row-content row-notes row note-bg'}>
                <div className="col-xs-12">
                  <div className="content">
                    <div className="value" style={{ display: 'flex' }}>
                      <i
                        className="fa fa-edit"
                        aria-hidden="true"
                        onClick={() => {
                          openCustomerNoteEditModal(t.id, t.notes)
                        }}
                      />
                      &nbsp;&nbsp;
                      <i
                        className="fa fa-trash"
                        aria-hidden="true"
                        onClick={() => {
                          deleteCustomerNote(t.id)
                        }}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <div>{t.notes}</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="content">
                <div className="value">
                  <div
                    aria-hidden={true}
                    className="addNote"
                    onClick={() => {
                      openCustomerNoteEditModal(t.id, t.notes)
                    }}
                    style={{ color: 'rgb(182, 197, 207)' }}
                  >
                    <i className="fa fa-plus" aria-hidden="true" />
                    &nbsp;&nbsp;Add Note
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
      {isCustomerCRM ? <SocialMediaRow customerId={t.id} /> : null}
      {(t.order.referrerId || t.order.promoterFirstName) && (
        <>
          <br />
          <div className="row-title row">
            <div className="col-xs-6 withDecorator">{t.order.referrerId ? 'Referred By' : 'Sold By'}</div>
          </div>
          <div className="div-spacing-20" />
          <div className="row-content row">
            <div className="col-xs-3">
              <div className="content">
                <div className="value">
                  {t.order.referrerId
                    ? `${t.order.referrerFirstName} ${t.order.referrerLastName}`
                    : `${t.order.promoterFirstName} ${t.order.promoterLastName}`}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="row-separator" />
      {t.dataCapture && t.dataCapture.length > 0 && (
        <div className="row-title row">
          <div className="col-xs-6 withDecorator">Data Capture</div>
        </div>
      )}
      {t.dataCapture && t.dataCapture.length > 0 && <div className="div-spacing-20" />}
      {t.dataCapture && t.dataCapture.length > 0 && (
        <div className="row-content row-ticket row">
          <div className="col-xs-6">
            <div className="content">
              <div className="field">Question</div>
            </div>
          </div>
          <div className="col-xs-6">
            <div className="content">
              <div className="field">Answer</div>
            </div>
          </div>
        </div>
      )}
      {t.dataCapture && t.dataCapture.length > 0 && data_capture_rows}
      {t.dataCapture && t.dataCapture.length > 0 && <div className="row-separator" />}

      {t.order.twitchChannel && (
        <div className="row-title row">
          <div className="col-xs-6 withDecorator">Twitch Channel Information</div>
        </div>
      )}
      {t.order.twitchChannel && <div className="div-spacing-20" />}
      {t.order.twitchChannel && (
        <div className="row-content row">
          <div className="col-xs-4">
            <div className="content">
              <div className="field">
                <i className="fa fa-twitch" aria-hidden="true" /> User ID
              </div>
              <div className="value">{t.order.twitchUserId}</div>
            </div>
          </div>
          <div className="col-xs-4">
            <div className="content">
              <div className="field">
                <i className="fa fa-twitch" aria-hidden="true" /> Display Name
              </div>
              <div className="value">{t.order.twitchChannel.display_name}</div>
            </div>
          </div>
          <div className="col-xs-4">
            <div className="content">
              <div className="field">
                <i className="fa fa-twitch" aria-hidden="true" /> Broadcaster Type
              </div>
              <div className="value">
                {t.order.twitchChannel.broadcaster_type ? t.order.twitchChannel.broadcaster_type : 'Regular'}
              </div>
            </div>
          </div>
        </div>
      )}
      {t.order.twitchChannel && <div className="row-separator" />}
      {isCustomerCRM ? (
        <div className="tickets-sortable-table-customers">
          <div className="row-title row">
            <div className={'col-xs-12 withDecorator'}>Orders</div>
          </div>
          {SortableTable}
        </div>
      ) : (
        <>
          <div>{ticket_rows}</div>
        </>
      )}
    </div>
  )

  return content_row
}

@connect(
  state => {
    const event = get_event(state)
    const eventRole = get_event_role(state)
    const ordersState = get_event_orders_state(state)
    const tickets = get_event_tickets(state)
    const isTicketsTypeUpdating = _has(state.loading, 'TICKETS_TYPE_BULK_UPDATE')
    const resent = _get(state, 'resent')
    const cancelled = _get(state, 'cancelled')

    return {
      event,
      eventRole,
      ordersState,
      tickets,
      isTicketsTypeUpdating,
      resent,
      cancelled
    }
  },
  {
    RESEND_ORDER,
    CANCEL_ORDER,
    UPDATE_ORDER_TICKET,
    FETCH_EVENT_TICKETS,
    TICKETS_TYPE_BULK_UPDATE,
    FETCH_EVENT_ORDERS,
    TOGGLE_ORDER_RESALE,
    MARK_ORDER_AS_PAID,
    REFRESH_ORDERS_DATA
  }
)
export default class EventOrders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order_status: ORDER_STATUS_ALL,
      csvData: null,
      csvFileName: '',
      filter_types: [],
      filter_addons: [],
      filter_discountcodes: [],
      filter_compOrder: false,
      dateFilterInfo: { range: { start: '', end: '' } },
      filters: [],
      showResend: false,
      resendEmail: '',
      showCancel: false,
      cancelled: false,
      cancelling: false,
      cancelMessage: '',
      orderID: null,
      ticketEditModalShow: false,
      isTicketTypesBulkUpdateModalOpen: false,
      ticketEdited: null,
      expandedID: null,
      filterUpdateVersion: 0,
      loading: {}
    }
    this.noteUID = 1
    this.defaultTicketTypeIDs = []
  }

  componentDidMount() {
    const {
      event: { id, displayName },
      FETCH_EVENT_TICKETS,
      FETCH_EVENT_ORDERS,
      configs
    } = this.props
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
      theme: 'future'
    }
    FETCH_EVENT_TICKETS(id, { filter: 'all' })
    FETCH_EVENT_ORDERS(id)
  }

  onClickTab = order_status => {
    this.setState({ order_status })
  }

  onFilterCompOrder = (e, value) => {
    const filter_compOrder = _get(e, 'target.checked', e)
    this.setState(() => ({ filter_compOrder }))
  }

  openTicketEditModal = ticket => {
    this.setState({
      ticketEditModalShow: true,
      ticketEdited: ticket
    })
  }

  closeTicketEditModal = () => {
    this.setState({
      ticketEditModalShow: false
    })
  }

  setTicketTypesBulkUpdateModalState =
    (isOpen, orderID = null) =>
      () => {
        this.setState({
          orderID,
          isTicketTypesBulkUpdateModalOpen: isOpen
        })
      }

  handleTicketSave = form => {
    const { UPDATE_ORDER_TICKET, FETCH_EVENT_ORDERS, event } = this.props
    const { ticketEdited } = this.state
    return Promise.resolve(UPDATE_ORDER_TICKET(ticketEdited.ticketHash, form))
      .catch(err => {
        Messenger().post({
          type: 'error',
          message: err,
          hideAfter: 5,
          showCloseButton: true
        })
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(v => {
        const tags = JSON.parse(v.data.$original.tickets[0].tags)
        const { changeStatus } = v.data.$original.tickets[0]
        const thash = ticketEdited.ticketHash
        const tagsElement = document.getElementById('ticket-column-tags-' + thash)
        const newTagsRender = this.getTicketTagsColumn(tags)
        this.setState({ ticketEditModalShow: false })
        ReactDOM.render(newTagsRender, tagsElement)

        const message =
          changeStatus === 'pending_user_confirmation'
            ? 'Successfully updated! \n Awaiting for customer confirmation.'
            : 'Successfully updated!'

        FETCH_EVENT_ORDERS(event.id)
        this.ordersTable.manualRefresh()

        Messenger().post({
          type: 'success',
          message,
          hideAfter: 5,
          showCloseButton: true
        })
        return v
      })
  }

  handleTicketsTypeBulkUpdate = data => {
    const { TICKETS_TYPE_BULK_UPDATE, event } = this.props

    TICKETS_TYPE_BULK_UPDATE(event.id, this.state.orderID, data.ticketTypeId)
      .then(() => {
        Messenger().post({
          type: 'success',
          message: 'Successfully updated!',
          hideAfter: 5,
          showCloseButton: true
        })
        this.defaultTicketTypeIDs[this.state.orderID] = data.ticketTypeId
        this.ordersTable.manualRefresh()
      })
      .catch(err => {
        Scroll.animateScroll.scrollToTop({ duration: 400 })
      })
      .finally(() => {
        this.setTicketTypesBulkUpdateModalState(false)()
      })
  }

  onDataChange = rows => {
    const { event } = this.props
    const fileName = 'tickets_' + event.id + '_' + moment().format('YYYYMMDDHHmmss') + '.csv'

    const data = []
    let newRow = {}
    let filter_types = []
    let filter_addons = []
    let filter_discountcodes = []
    // add body
    _map(rows, (t, index) => {
      if (t.tickets) {
        let refundedCount = 0
        _map(t.tickets, (ticket, tid) => {
          if (ticket.status === 'refunded') {
            refundedCount += 1
          }
          filter_types.push(ticket.ticketType)
        })
        if (refundedCount === t.tickets.length && refundedCount !== 0) {
          t.order.status = 'Refunded'
        }
      }
      if (t.addOns) {
        _map(t.addOns, (addon, aid) => {
          filter_addons.push(addon.name)
        })
      }
      if (t.order.discountCode) {
        filter_discountcodes.push(t.order.discountCode)
      }
      if (t.tickets && t.order.status === 'Paid') {
        _map(t.tickets, (ticket, tid) => {
          newRow = {}
          newRow.qr_data = ''
          newRow.ticket_first_name = ticket.firstName
          newRow.ticket_last_name = ticket.lastName
          newRow.ticket_email = ticket.email
          newRow.buyer_email = t.order.billingEmail
          newRow.phone = t.order.billingPhone
          newRow.street_address = ''
          newRow.city = t.order.billingCity
          newRow.state = ''
          newRow.zip_postcode = ''
          newRow.country = t.order.billingCountry
          newRow.ticket_type = ticket.ticketType
          newRow.ticket_price = ''
          newRow.status = ticket.status
          newRow.order_id = t.order.id
          newRow.ticket_id = ticket.ticketHash
          newRow.ordered_by_first_name = t.order.billingFirstName
          newRow.ordered_by_last_name = t.order.billingLastName
          newRow.order_date = t.order.orderDate
          newRow.order_datetime = t.order.orderDateTime
          newRow.checked_in_at = ''
          newRow.discount_code = t.order.discountCode
          newRow.twitch_display_name = ''
          newRow.twitch_partnered = ''
          newRow.upgrades = ''
          newRow.data_capture = ''
          if (ticket.status !== 'cancelled' && ticket.status !== 'refunded') data.push(newRow)
        })
      }
    })

    filter_types = _orderBy(_uniq(filter_types))
    filter_addons = _orderBy(_uniq(filter_addons))
    filter_discountcodes = _orderBy(_uniq(filter_discountcodes))
    this.setState({
      csvData: data,
      csvFileName: fileName,
      filter_types,
      filter_addons,
      filter_discountcodes
    })
  }

  validateData = (data, index) => {
    // validate goes here
    if (!data.order) return null
    // set id
    data.id = data.order.id
    return data
  }

  getFilteredRows = (rows, search) => {
    const { eventRole, event } = this.props
    const { order_status, filter_compOrder, filters, dateFilterInfo } = this.state
    const keyword = search.join('').trim().toLowerCase()
    const { start, end } = dateFilterInfo.range
    let rows_filtered = rows
    if (
      ROLES.is_support_or_partial_support(eventRole) &&
      !filter_compOrder &&
      !filters.length &&
      !keyword &&
      !start &&
      !end
    ) {
      return []
    }

    // filter by order status
    switch (order_status) {
      case ORDER_STATUS_ALL:
        rows_filtered = rows
        break
      case ORDER_STATUS_PAID:
      case ORDER_STATUS_FOR_SALE:
      case ORDER_STATUS_SALE_IN_PROGRESS:
        rows_filtered = _filter(
          rows,
          item =>
            item.order.status === 'Paid' ||
            item.order.status === 'For sale' ||
            item.order.status === 'Sale in progress'
        )
        break
      case ORDER_STATUS_SOLD:
        rows_filtered = _filter(rows, { order: { status: 'Sold' } })
        break
      case ORDER_STATUS_PENDING:
        rows_filtered = _filter(rows, { order: { status: 'Pending' } })
        break
      case PENDING_PAYMENT_PLAN:
        rows_filtered = _filter(rows, { order: { status: 'Payment plan' } })
        break
      case ORDER_STATUS_REFUNDED:
        rows_filtered = _filter(rows, { order: { status: 'Refunded' } })
        break
      case PENDING_OXXO_ORDER:
        rows_filtered = _filter(rows, { order: { isOxxoPayment: '1', status: 'Pending For Oxxo' } })
        break
      default:
        rows_filtered = rows
        break
    }

    // filter by compOrder
    if (filter_compOrder) {
      rows_filtered = _filter(rows_filtered, item => item.order.compOrder)
    }

    // filter by filters
    if (filters.length > 0) {
      rows_filtered = _filter(rows_filtered, item => {
        let found = true
        _map(filters, (filter, index) => {
          let found1 = 0
          switch (filter.type) {
            case 'ticketType':
              _map(item.tickets, t => {
                found1 += t.ticketType === filter.value ? 1 : 0
              })
              break
            case 'addOn':
              _map(item.addOns, a => {
                found1 += a.name === filter.value ? 1 : 0
              })
              break
            case 'discountCode':
              const discount = item.order.discountCode
              if (discount) {
                found1 += discount.toLowerCase() === filter.value.toLowerCase()
              }
              break
            default:
              break
          }
          if (filter.isNot) {
            found1 = found1 > 0 ? 0 : 1
          }
          if (filter.isAnd) {
            found = found && found1 > 0
          } else {
            found = found || found1 > 0
          }
        })
        return found
      })
    }

    // filter by search
    if (keyword !== '') {
      rows_filtered = _filter(rows_filtered, item => {
        let found = 0
        found += IS_FOUND(item.order.id, keyword)
        found += IS_FOUND(item.order.billingFirstName + ' ' + item.order.billingLastName, keyword)
        found += IS_FOUND(item.order.billingEmail, keyword)
        item.order.searchOrderNotes.forEach(item => {
          found += IS_FOUND(item, keyword)
        })

        if (item.order.twitchChannel) {
          found += IS_FOUND(item.order.twitchChannel.display_name, keyword)
        }
        _map(item.tickets, t => {
          found += IS_FOUND(t.firstName + ' ' + t.lastName, keyword)
          found += IS_FOUND(t.email, keyword)
          found += IS_FOUND(t.ticketHash, keyword)
        })
        return found > 0
      })
    }

    if (start && end) {
      rows_filtered = rows_filtered.filter(order => {
        const tickets = order.tickets || []
        return tickets.some(el => {
          const allTickets = this.props.tickets || []
          const { timezone } = event
          const { ticketTypeID } = el
          const ticket = allTickets.find(el => el.id === ticketTypeID)
          if (ticket) {
            const startDate = +moment.tz(ticket.startDate, timezone).utc()
            return startDate >= start && startDate <= end
          }
          return false
        })
      })
    }

    return rows_filtered
  }

  getSortedRows = (rows_filtered, sort, includeTablesData) => {
    if (sort) {
      const dir = sort.asc ? 'asc' : 'desc'
      switch (sort.index) {
        case 1: // Order ID
          rows_filtered = _orderBy(rows_filtered, t => parseInt(t.id), dir)
          break
        case 2: // Date
          rows_filtered = _orderBy(rows_filtered, t => t.order.orderDateTime, dir)
          break
        case 3: // Status
          rows_filtered = _orderBy(rows_filtered, t => t.order.status, dir)
          break
        case 4: // Name
          rows_filtered = _orderBy(
            rows_filtered,
            t => (t.order.billingFirstName + ' ' + t.order.billingLastName).trim().toLowerCase(),
            dir
          )
          break
        case 5: // Email
          rows_filtered = _orderBy(rows_filtered, t => t.order.billingEmail.trim().toLowerCase(), dir)
          break
        case 6: // No. of Tickets
          rows_filtered = _orderBy(
            rows_filtered,
            t => t?.tickets?.length ?? t?.gift?.ticketQuantity ?? 0,
            dir
          )
          break
        case 7:
          if (!includeTablesData) {
            // Total Revenue
            rows_filtered = _orderBy(rows_filtered, t => parseFloat(t.order.totalCost ?? '0.00'), dir)
          } else {
            // Current deposit
            rows_filtered = _orderBy(
              rows_filtered,
              t => parseFloat(t?.order?.currentDeposit ?? t?.order?.totalCost ?? '0.00'),
              dir
            )
          }
          break
        case 8:
          // Pending to Pay
          rows_filtered = _orderBy(rows_filtered, t => parseFloat(t?.order?.pendingToPay ?? 0), dir)
          break
        default:
          break
      }
    }
    return rows_filtered
  }

  getCSVData = (rows_filtered, includeTablesData) => {
    const data = []
    let newRow = []
    // add header
    newRow.push('Order ID')
    newRow.push('Date')
    newRow.push('Status')
    newRow.push('First Name')
    newRow.push('Last Name')
    newRow.push('Email')
    newRow.push('No. of Tickets')
    if (!includeTablesData) {
      newRow.push('Net Revenue')
    } else {
      newRow.push('Current Deposit')
      newRow.push('Pending to Pay')
    }
    data.push(newRow)

    // add body
    _map(rows_filtered, (t, index) => {
      newRow = []
      newRow.push(t.id)
      newRow.push(moment(t.order.orderDateTime, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY hh:mm a'))
      newRow.push(t.order.status)
      newRow.push(t.order.billingFirstName)
      newRow.push(t.order.billingLastName)
      newRow.push(t.order.billingEmail)
      newRow.push(t?.tickets?.length ?? t?.gift?.ticketQuantity ?? 0)
      if (!includeTablesData) {
        newRow.push(t.order.totalCost ?? '0.00')
      } else {
        newRow.push(t?.order?.currentDeposit ?? t?.order?.totalCost ?? '0.00')
        newRow.push(t?.order?.pendingToPay ?? 0)
      }
      data.push(newRow)
    })
    return data
  }

  onDisableToggle = async (data, id, disabled) => {
    const { TOGGLE_ORDER_RESALE, event } = this.props
    const { loading } = this.state
    this.setState({ loading: { [id]: true } })
    await TOGGLE_ORDER_RESALE(event.id, id, { disabled })
    this.setState({ loading: { ...loading, [id]: false } })
  }

  onMarkAsPaid = async (data, id, disabled) => {
    const { MARK_ORDER_AS_PAID, event } = this.props
    const { loading } = this.state
    this.setState({ loading: { [id]: true } })
    await MARK_ORDER_AS_PAID(event.id, id, { disabled })
    this.setState({ loading: { ...loading, [id]: false } })
  }

  getTicketTagsColumn = tags => (
    <div>
      {tags &&
        tags.map((tag, ii) => (
          <div key={ii} className="row-ticket-tag">
            {tag}
          </div>
        ))}
    </div>
  )

  shouldDisplayTablesColumns = data =>
    data.reduce((res, row) => {
      let guestCount = parseInt(row.order.guestCount)
      if (isNaN(guestCount)) {
        guestCount = 0
      }

      return res || guestCount > 0
    }, false)

  getTableHeaderColumns = (includeTablesData, clipboard) => {
    const columns = [
      { title: '', sort: false },
      { title: 'Order ID', sort: true },
      { title: 'Date', sort: true },
      { title: 'Status', sort: true },
      { title: 'Name', sort: true },
      { title: 'Email', sort: true },
      { title: 'No. of Tickets', sort: true }
    ]

    if (!includeTablesData) {
      columns.push({ title: 'Net Revenue', sort: true })
    } else {
      columns.push({ title: 'Current Deposit', sort: true }, { title: 'Pending to Pay', sort: true })
    }

    columns.push(
      { title: 'Action', sort: false },
      { title: clipboard, sort: false, className: 'column-clipboard' }
    )

    return columns
  }

  getOxxoTableHeaderColumns = event =>
    getTableColumns([
      {
        key: 'id',
        label: 'Order Id',
        isSortable: true
      },
      {
        key: 'orderDateTime',
        label: 'Date',
        isSortable: true
      },
      {
        key: 'orderStatus',
        label: 'Status',
        isSortable: true
      },
      {
        key: 'fullName',
        label: 'Name',
        isSortable: true
      },
      {
        key: 'billingEmail',
        label: 'Email',
        isSortable: true
      },
      {
        key: 'quantity',
        label: 'No. of Tickets',
        isSortable: true
      },
      {
        key: 'totalCost',
        label: 'Net Revenue',
        isSortable: true,
        normalizer: value =>
          currencyNormalizerCreator(getCurrencySymbol(event))(
            createFixedFloatNormalizer(2)(parseFloat(value))
          ),
        disableNormalizerOnCopy: true
      }
    ])

  getTableData = (datatable, rows_filtered, sort) => {
    const { eventRole, event } = this.props
    const { loading } = this.state
    const tableData = []
    const detailsData = []

    const includeTablesData = this.shouldDisplayTablesColumns(rows_filtered) > 0

    const clipboard_text = this.getClipboardText(rows_filtered, includeTablesData)
    const clipboard = datatable.getClipboardColumn(datatable, clipboard_text)

    const headerColumns = this.getTableHeaderColumns(includeTablesData, clipboard)
    const tableColumns = this.getOxxoTableHeaderColumns(event)

    const content_header = datatable.getHeaderRow(datatable, headerColumns, sort)

    const self = this
    let number = 0
    const currency = getCurrencySymbol(event)
    const content_table = _map(rows_filtered, (t, index) => {
      if (!this.defaultTicketTypeIDs[t.id] && t.tickets && t.tickets.length) {
        this.defaultTicketTypeIDs[t.id] = t.tickets[0].ticketTypeID
      }

      let total
      let debt
      if (!includeTablesData) {
        total = parseFloat(t.order.totalCost)
      } else {
        total = parseFloat(t.order.currentDeposit)
        total = isNaN(total) ? parseFloat(t.order.totalCost) : total

        debt = parseFloat(t.order.pendingToPay)
        debt = isNaN(debt) ? 0 : debt
      }

      const detailRowContent = orderDetailRow(
        t,
        event,
        index,
        includeTablesData,
        currency,
        total,
        debt,
        this.openTicketEditModal,
        this.setTicketTypesBulkUpdateModalState
      )

      tableData.push({
        ...t.order,
        fullName: t.order.billingFirstName + ' ' + t.order.billingLastName,
        orderStatus: t.order.status,
        quantity: t.tickets.length
      })

      detailsData.push({
        id: t.id,
        type: 'detailRow',
        component: detailRowContent
      })

      let content_row = null

      if (!t.isDetailRow) {
        // normal row
        const isOxxoOrder = _get(t, 'order.isOxxoPayment') === '1' ? true : false
        const resaleDisabled = _get(t, 'order.disableResale', false)
        const toggleButtonLoading = loading[t.id]
        const markAsPaidButtonLoading = loading[t.id]
        const isToggleButtonsVisible = event.flagResaleEnabled && !isOxxoOrder

        content_row = (
          <tr
            key={index}
            className={
              t.isExpanded
                ? ' tr-expanded-row'
                : number++ % 2 === 0
                ? 'table-stripe-row1'
                : 'table-stripe-row2'
            }
          >
            <td
              aria-hidden={true}
              className="toggleExpand"
              onClick={() => this.onClickExpandDetail(datatable, t)}
              data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${index}-expanded`}
            >
              <div className={t.isExpanded ? 'expandIcon expanded' : 'expandIcon'}>
                <i className={t.isExpanded ? 'fa fa-minus-square-o' : 'fa fa-plus-square-o'} />
              </div>
            </td>
            <td data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${index}-id`}>{t.id}</td>
            <td data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${index}-orderDateTime`} className="nowrap-col">
              {moment(t.order.orderDateTime, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY hh:mm a')}
            </td>
            <td data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${index}-status`}>{toPascalCase(t.order.status)}</td>
            <td data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${index}-billingName`}>
              {t.order.billingFirstName} {t.order.billingLastName}
            </td>
            <td data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${index}-billingEmail`}>{t.order.billingEmail}</td>
            <td data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${index}-ticketQuantity`}>
              {t?.tickets?.length ?? t?.gift?.ticketQuantity ?? 0}
            </td>
            <td data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${index}-netRevenue`}>
              {currency}
              {total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
            {includeTablesData && (
              <td data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${index}-debt`}>
                {currency}
                {debt.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </td>
            )}
            <td className="tdAction" data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${index}-actions`}>
              <div
                aria-hidden={true}
                className="btnAction"
                onClick={self.onClickResend.bind(self, t.id, t.order.billingEmail)}
              >
                <img
                  alt="nodata"
                  className="default-icosize"
                  src={asset('/resources/images/resend-ico.svg')}
                />
                <span className="inline pl-5">Resend</span>
              </div>
              {total === 0 && !(ROLES.PARTIAL_SUPPORT === eventRole) && (
                <div aria-hidden={true} className="btnAction" onClick={self.onClickCancel.bind(self, t.id)}>
                  <img
                    alt="nodata"
                    className="default-icosize"
                    src={asset('/resources/images/cross-ico.svg')}
                  />
                  <span className="inline pl-5">Cancel</span>
                </div>
              )}
              {isToggleButtonsVisible && (
                <>
                  {!resaleDisabled ? (
                    toggleButtonLoading ? (
                      <div className="btnAction">
                        <i className="fa fa-spin fa-spinner" aria-hidden="true" />
                      </div>
                    ) : (
                      <div
                        aria-hidden={true}
                        className="btnAction"
                        onClick={() => this.onDisableToggle(t, t.id, true)}
                      >
                        <i className="fa fa-ban" aria-hidden="true" />
                        <span className="inline pl-5 nowrap-col">Block Resale</span>
                      </div>
                    )
                  ) : toggleButtonLoading ? (
                    <div className="btnAction">
                      <i className="fa fa-spin fa-spinner" aria-hidden="true" />
                    </div>
                  ) : (
                    <div
                      aria-hidden={true}
                      className="btnAction"
                      onClick={() => this.onDisableToggle(t, t.id, false)}
                    >
                      <i className="fa fa-fw fa-check" aria-hidden="true" />
                      <span className="inline pl-5">Unblock Resale</span>
                    </div>
                  )}
                </>
              )}
              {t.order.status === 'Pending' &&
                (markAsPaidButtonLoading ? (
                  <div className="btnAction">
                    <i className="fa fa-spin fa-spinner" aria-hidden="true" />
                  </div>
                ) : (
                  <div
                    aria-hidden={true}
                    className="btnAction"
                    onClick={() => this.onMarkAsPaid(t, t.id, true)}
                  >
                    <i className="fa fa-check" aria-hidden="true" />
                    <span className="inline pl-5">Mark as Paid</span>
                  </div>
                ))}
            </td>
            <td data-e2e-test-id={`${TABLE_E2E_TEST_ID}-${index}-clipboard`} />
          </tr>
        )
      } else {
        content_row = (
          <tr className="tr-detail-row" key={index}>
            <td colSpan={12} className={'colspan-customer'}>
              {detailRowContent}
            </td>
          </tr>
        )
      }
      return content_row
    })

    return rows_filtered.length !== 0 ? (
      this.state.order_status === PENDING_OXXO_ORDER ? (
        <SortableTable
          parentTableClass="table"
          tableColumns={tableColumns}
          data={tableData}
          enableCopyTable={true}
          enableSort={true}
          detailsRows={detailsData}
          actionsLabel="Action"
          dataForCopy={tableData}
          rowTableClass="oxxoTableRow"
          actions={[
            {
              label: () => <span className="inline pl-5">Resend</span>,
              className: 'oxxoBtnAction',
              icon: (
                <img
                  alt="nodata"
                  className="default-icosize"
                  src={asset('/resources/images/resend-ico.svg')}
                />
              ),
              onClick: data => this.onClickResend(data.id, data.billingEmail)
            }
          ]}
        />
      ) : (
        <table className="table" data-e2e-test-id={TABLE_E2E_TEST_ID}>
          <thead>{content_header}</thead>
          <tbody>{content_table}</tbody>
        </table>
      )
    ) : (
      <EmptyBar />
    )
  }

  getClipboardText = (rows_filtered, includeTablesData) => {
    let ret = ''
    if (!includeTablesData) {
      ret +=
        'Order ID' +
        '\t' +
        'Date' +
        '\t' +
        'Status' +
        '\t' +
        'Name' +
        '\t' +
        'Email' +
        '\t' +
        'No. of Tickets' +
        '\t' +
        'Net Revenue' +
        '\n'
    } else {
      ret +=
        'Order ID' +
        '\t' +
        'Date' +
        '\t' +
        'Status' +
        '\t' +
        'Name' +
        '\t' +
        'Email' +
        '\t' +
        'No. of Tickets' +
        '\t' +
        'Current Deposit' +
        '\t' +
        'Pending to Pay' +
        '\n'
    }
    _map(rows_filtered, t => {
      if (!includeTablesData) {
        ret +=
          t.id +
          '\t' +
          moment(t.order.orderDateTime, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY hh:mm a') +
          '\t' +
          toPascalCase(t.order.status) +
          '\t' +
          (t.order.billingFirstName + ' ' + t.order.billingLastName) +
          '\t' +
          t.order.billingEmail +
          '\t' +
          String(t?.tickets?.length ?? t?.gift?.ticketQuantity ?? 0) +
          '\t' +
          parseFloat(t.order.totalCost ?? '0.00').toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) +
          '\n'
      } else {
        ret +=
          t.id +
          '\t' +
          moment(t.order.orderDateTime, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY hh:mm a') +
          '\t' +
          toPascalCase(t.order.status) +
          '\t' +
          (t.order.billingFirstName + ' ' + t.order.billingLastName) +
          '\t' +
          t.order.billingEmail +
          '\t' +
          String(t?.tickets?.length ?? t?.gift?.ticketQuantity ?? 0) +
          '\t' +
          parseFloat(t?.order?.currentDeposit ?? t?.order?.totalCost ?? '0.00').toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) +
          '\t' +
          String(t?.order?.pendingToPay ?? 0) +
          '\n'
      }
    })
    return ret
  }

  onClickExportTickets = (csvData, csvFileName) => {
    DOWNLOAD_CSV(csvData, csvFileName)
  }

  refreshTable = () => {
    const { event } = this.props
    this.refs.JSONDatatable.forceRefresh(TYPE_FROM_URL, {
      url: `/api/events/${event.id}/relationships/orders/`,
      node: 'data.*'
    })
  }

  handleResend = () => {
    const { RESEND_ORDER, event } = this.props
    const { orderID, resendEmail } = this.state

    return Promise.resolve(RESEND_ORDER(event.id, { orderID, resendEmail }))
      .catch(err => {
        Messenger().post({
          type: 'error',
          message: err,
          hideAfter: 5,
          showCloseButton: true
        })
        this.setState({ showResend: false })
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Successfully Sent',
          hideAfter: 10,
          showCloseButton: true
        })
        this.setState({ showResend: false })
        return v
      })
  }

  handleCancel = () => {
    const { CANCEL_ORDER, event } = this.props
    const { orderID } = this.state

    this.setState({
      cancelling: true
    })

    return Promise.resolve(CANCEL_ORDER(event.id, { orderID }))
      .catch(err => {
        Messenger().post({
          type: 'error',
          message: err,
          hideAfter: 5,
          showCloseButton: true
        })
        this.setState({
          showCancel: false,
          cancelled: false,
          cancelling: false
        })
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(v => {
        this.setState({
          showCancel: true,
          cancelled: true,
          cancelling: false,
          cancelMessage: 'The order has been successfully cancelled'
        })
        return v
      })
  }

  onClickResend = (orderID, email) => {
    this.setState({ showResend: true, resendEmail: email, orderID })
  }

  onClickCancel = orderID => {
    this.setState({
      showCancel: true,
      orderID,
      cancelMessage: 'Do you really want to cancel the order?'
    })
  }

  closeResendDialog = () => {
    this.setState({ showResend: false })
  }

  closeCancelDialog = () => {
    this.setState({
      showCancel: false,
      cancelled: false
    })
  }

  onResendEmailChanged = e => {
    this.setState({ resendEmail: e.target.value })
  }

  onFiltersChange = filters => {
    this.setState({ filters })
  }

  onDateFiltersChange = dateFilterInfo => {
    this.setState({ dateFilterInfo })
  }

  onClickExpandDetail = (datatable, order) => {
    datatable.onClickDetail(order.id)
    if (order.isExpanded) {
      this.setState({
        expandedID: order.id
      })
    }
  }

  isEmptyChecker = data => {
    const { filters, dateFilterInfo, filter_compOrder } = data
    return (
      _isEmpty(filters) &&
      !filter_compOrder &&
      !_get(dateFilterInfo, 'range.start') &&
      !_get(dateFilterInfo, 'range.end')
    )
  }

  onSelectFilter = template => {
    let { value } = template
    try {
      value = JSON.parse(value)
      const { filters, filter_compOrder, dateFilterInfo } = value
      this.onFiltersChange(filters)
      this.onDateFiltersChange(dateFilterInfo)
      this.onFilterCompOrder(filter_compOrder)
    } catch (e) {}
  }

  onSaveFilter = () => {
    this.setState(s => ({ filterUpdateVersion: s.filterUpdateVersion + 1 }))
  }

  render() {
    const {
      order_status,
      filter_types,
      filter_addons,
      filter_discountcodes,
      filter_compOrder,
      filters,
      dateFilterInfo,
      filterUpdateVersion
    } = this.state
    const {
      showResend,
      resendEmail,
      showCancel,
      cancelMessage,
      cancelled,
      cancelling,
      ticketEditModalShow,
      isTicketTypesBulkUpdateModalOpen,
      ticketEdited
    } = this.state
    const { isTicketsTypeUpdating, ordersState, event, eventRole, tickets, configs } = this.props

    const showSoldOrders = _get(configs, 'appearance.showSoldOrders', true)
    const showPendingPaymentPlanOrders = _get(configs, 'appearance.showPendingPaymentPlanOrders', true)

    const [noGroupedTickets, groupedTickets] = ticketTypesSorting(tickets)
    const tabs = [
      ORDER_STATUS_ALL,
      ORDER_STATUS_PAID,
      ORDER_STATUS_SOLD,
      ORDER_STATUS_REFUNDED,
      ORDER_STATUS_PENDING,
    ].filter(tab => !(tab === ORDER_STATUS_SOLD && !showSoldOrders))

    if (showPendingPaymentPlanOrders) {
      tabs.push(PENDING_PAYMENT_PLAN)
    }

    if (event.flagIsOxxoPaymentEnabled) {
      tabs.push(PENDING_OXXO_ORDER)
    }

    const tab_header = _map(tabs, s => {
      let title = ''
      switch (s) {
        case ORDER_STATUS_ALL:
          title = 'All Orders'
          break
        case ORDER_STATUS_PAID:
          title = 'Paid Orders'
          break
        case ORDER_STATUS_SOLD:
          title = 'Sold Orders'
          break
        case ORDER_STATUS_REFUNDED:
          title = 'Refunded Orders'
          break
        case ORDER_STATUS_PENDING:
          title = 'Pending Orders'
          break
        case PENDING_PAYMENT_PLAN:
          title = 'Pending Payment Plan Orders'
          break
        case PENDING_OXXO_ORDER:
          title = 'Pending OXXO Orders'
          break
        default:
          break
      }
      return (
        <div
          aria-hidden={true}
          key={s}
          className={'tab-title' + (s === order_status ? ' selected' : '')}
          onClick={() => this.onClickTab(s)}
        >
          {title}
        </div>
      )
    })
    const select_filter_types = []
    _map(filter_types, (e, i) => {
      select_filter_types.push({ value: e, label: e })
    })
    const select_filter_addons = []
    _map(filter_addons, (e, i) => {
      select_filter_addons.push({ value: e, label: e })
    })
    const select_filter_discountcodes = []
    _map(filter_discountcodes, (e, i) => {
      select_filter_discountcodes.push({ value: e, label: _startCase(e) })
    })

    const exportURL = `${ENV.API_BASE}/api/event/${event.id}/tickets/export/`
    let tabData = tab_header
    if (!isMobileDevice()) {
      tabData = (
        <div style={{ position: 'relative', width: `calc(100% - 130px)` }}>
          {' '}
          <PerfectScrollbar>{tab_header}</PerfectScrollbar>
        </div>
      )
    }

    return (
      <div className="event-order">
        {!ROLES.is_support_or_partial_support(eventRole) && (
          <div className="btn-toolbar export-tickets">
            {/*csvData && <Button className="btn-primary" onClick=
            {this.onClickExportTickets.bind(this, csvData, csvFileName)}>Export Tickets</Button>*/}
            <a className="btn btn-primary btn-shadow" href={exportURL}>
              {' '}
              <img alt="nodata" src={asset('/resources/images/excel-ico.svg')} />
              Export Tickets
            </a>
            {/*
						<Button className="btn-default" onClick={() => this.refreshTable()}>Refresh</Button>*/}
          </div>
        )}
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={showResend}
          contentLabel="Modal"
          onRequestClose={() => this.closeResendDialog()}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Resend Order</p>
                </div>
                <div className="modal-body">
                  <Field
                    ref="resend_email"
                    id="resend_email"
                    value={resendEmail}
                    label="Email Address"
                    size="large"
                    onChange={evt => this.onResendEmailChanged(evt)}
                  />
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={() => this.handleResend()}
                    >
                      Resend
                    </Button>
                    <Button
                      className="btn btn-cancel btn-shadow"
                      type="button"
                      onClick={() => this.closeResendDialog()}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={showCancel}
          contentLabel="Modal"
          onRequestClose={() => this.closeCancelDialog()}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">Cancel Order</p>
                </div>
                <div className="modal-body">
                  <p>{cancelMessage}</p>
                </div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    {!cancelled && (
                      <Button
                        className="btn btn-danger btn-shadow"
                        type="button"
                        disabled={cancelling}
                        onClick={() => this.handleCancel()}
                      >
                        Confirm Cancellation
                        {cancelling && <i className="fa fa-circle-o-notch fa-spin" />}
                      </Button>
                    )}
                    {cancelled && (
                      <Button
                        className="btn btn-primary btn-shadow"
                        type="button"
                        onClick={() => this.closeCancelDialog()}
                      >
                        OK
                      </Button>
                    )}
                    {!cancelled && (
                      <Button
                        className="btn btn-default btn-shadow"
                        type="button"
                        disabled={cancelling}
                        onClick={() => this.closeCancelDialog()}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={ticketEditModalShow}
          contentLabel="Modal"
          onRequestClose={() => this.closeTicketEditModal()}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog modal-event-order-ticket">
            <div className="modal-content">
              <div className="modal-header">Edit Ticket</div>
              <div className="modal-body">
                <EventOrderTicketForm
                  onSubmit={form => this.handleTicketSave(form)}
                  onCancel={() => this.closeTicketEditModal()}
                  submitLabel="Save"
                  initialValues={ticketEdited}
                  event={event}
                  tickets={tickets}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={isTicketTypesBulkUpdateModalOpen}
          contentLabel="Modal"
          onRequestClose={this.setTicketTypesBulkUpdateModalState(false)}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog modal-event-order-ticket">
            <div className="modal-content">
              <div className="modal-header">
                {event.flagTimeSlotsEnabled ? 'Change Time Slot' : 'Change Ticket Type'}
              </div>
              <div className="modal-body">
                {groupedTickets.length || noGroupedTickets.length ? (
                  <TicketsTypeBulkUpdate
                    defaultTicketTypeId={this.defaultTicketTypeIDs[this.state.orderID]}
                    isLoading={isTicketsTypeUpdating}
                    onSubmit={form => this.handleTicketsTypeBulkUpdate(form)}
                    onCancel={this.setTicketTypesBulkUpdateModalState(false)}
                    groupedTickets={groupedTickets}
                    noGroupedTickets={noGroupedTickets}
                  />
                ) : (
                  <LoadingBar />
                )}
              </div>
            </div>
          </div>
        </Modal>
        <div className="row">
          <div className="col-xs-12 event-orders">
            {ordersState.eventId !== event.id ? (
              <LoadingBar title={"Hold tight! We're getting your event's orders..."} />
            ) : null}
            {ordersState.fullReady && !ordersState.orders.length && ordersState.eventId === event.id && (
              <EmptyBar />
            )}
            {ordersState.eventId === event.id && ordersState.orders.length > 0 && (
              <JSONDatatable
                type={TYPE_FROM_ARRAY}
                data={ordersState.orders}
                sort={{ index: 1, asc: false }}
                onDataChange={this.onDataChange}
                validateData={(data, index) => this.validateData(data, index)}
                getFilteredRows={(rows, search) => this.getFilteredRows(rows, search)}
                getSortedRows={(rows_filtered, sort) => {
                  const includeTablesData = this.shouldDisplayTablesColumns(rows_filtered) > 0
                  return this.getSortedRows(rows_filtered, sort, includeTablesData)
                }}
                getTableData={(datatable, rows_filtered, sort) =>
                  this.getTableData(datatable, rows_filtered, sort)
                }
                getCSVData={rows_filtered => {
                  const includeTablesData = this.shouldDisplayTablesColumns(rows_filtered) > 0
                  return this.getCSVData(rows_filtered, includeTablesData)
                }}
                getClipboardText={rows_filtered => {
                  const includeTablesData = this.shouldDisplayTablesColumns(rows_filtered) > 0
                  this.getClipboardText(rows_filtered, includeTablesData)
                }}
                // autoRefresh={10 * 60 * 1000}
                usePagination={true}
                loadingBarTitle={"Hold tight! We're getting your event's orders..."}
                ref={table => {
                  this.ordersTable = table
                }}
                keepExpanded={this.state.expandedID}
              >
                {/* It can give additional className to SEARCHBAR, DATATABLE, PAGINATIONBAR by specifying className="XXX" */}
                <div
                  ClassName="d"
                  ref={SEARCHBAR}
                  hasSearch
                  autoFocus
                  alwaysShowSearch
                  alwaysShowTotalCount
                  labelTotalCount="Number of Matching Orders"
                />
                <div>
                  {ordersState.eventId === event.id && !ordersState.fullReady && (
                    <p className="loading-dots">Retrieving orders</p>
                  )}
                </div>
                <Card icon={'fa-filter'} title={'Filter'} closed className="filter-card">
                  <div style={{ textAlign: 'end' }}>
                    <SelectFilterTemplate
                      storagePath={`event-${event.id}-orders-filter`}
                      onSelectFilter={this.onSelectFilter}
                      updateVersion={filterUpdateVersion}
                    />
                  </div>
                  <Filters
                    filters={[
                      { label: 'Ticket Type', type: 'ticketType', options: select_filter_types },
                      { label: 'Add On', type: 'addOn', options: select_filter_addons },
                      { label: 'Discount Code', type: 'discountCode', options: select_filter_discountcodes }
                    ]}
                    onFiltersChange={filters => this.onFiltersChange(filters)}
                    value={filters}
                  >
                    {event.flagTimeSlotsEnabled && (
                      <OrderDateFilter
                        tickets={tickets}
                        timezone={event.timezone}
                        onChange={this.onDateFiltersChange}
                      />
                    )}
                    <div className="row">
                      <div className="guest-row guest-ticket-sm col-xs-6">
                        <div className="line pt-10">
                          <div className="line-cell">
                            <div className="checkbox-switch">
                              <input
                                type="checkbox"
                                id="filter-guest-tickets"
                                checked={filter_compOrder}
                                onChange={(e, value) => this.onFilterCompOrder(e, value)}
                              />
                              <label htmlFor="filter-guest-tickets" />
                            </div>
                          </div>
                          <div className="line-cell">
                            <label htmlFor="filter-guest-tickets" className="show-guest-only">
                              Only Show Guest Ticket Orders
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <br />
                      <SaveTemplate
                        data={{
                          filters,
                          dateFilterInfo,
                          filter_compOrder
                        }}
                        isEmptyChecker={this.isEmptyChecker}
                        storagePath={`event-${event.id}-orders-filter`}
                        onSaveFilter={this.onSaveFilter}
                      />
                    </div>
                  </Filters>
                </Card>

                <div className="tab-view">
                  <div
                    className={`tab-header event-orders-tab-header  ${
                      isMobileDevice() ? 'responsive-tab-header' : ''
                    }`}
                  >
                    <div className="tab-view-title">Show Orders:</div>
                    {tabData}
                  </div>
                </div>
                <div ref={DATATABLE} />
                <div ref={PAGINATIONBAR} hasCSVExport csvFileName={`orders_${event.id}.csv`} />
              </JSONDatatable>
            )}
          </div>
        </div>
      </div>
    )
  }
}
