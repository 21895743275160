import _get from 'lodash/get'
import _set from 'lodash/set'
import _map from 'lodash/map'
import _each from 'lodash/each'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import { withFormik } from 'formik'
import React from 'react'
import { Element, scroller } from 'react-scroll'
import { countries } from '../constants/countries'

import Button from '../_library/Button'
import { ShopifyCard } from '../shopify'

import BusinessDetails from './BusinessDetails'
import PayoutDetails from './PayoutDetails'
import PaymentMethod from './PaymentMethod'
import BrandNameDetails from './BrandNameDetails'
import BrandImages from './BrandImages'
import BrandTicketAdvancedOptions from './BrandTicketAdvancedOptions'
import modalStyle from '../../_common/core/modalStyle'
import Modal from 'react-modal'
import * as fb from '../../_common/core/fb'
import Support from './BrandSupport'
import { isValidInteger } from '../../_common/core/validation'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { countryCodeAdapter } from '../utils/coutriesUtils'
import Card from '../_library/Card'

function validateBrand(data, props) {
  const gaIdRegexp = /^ua-\d{4,9}-\d{1,4}$/i
  const gtmRegex = /^gtm-[A-Z0-9]{4,9}$|^aw-[A-Z0-9]{4,}$/i
  const alphaNumericRegex = RegExp('^([0-9a-zA-Z]*)$')

  let required = [
    'displayName',
    'slug',
    'legalEntity',
    'addressLine1',
    'brandCity',
    'brandState',
    'zip',
    'country',
    'email',
    'accountType',
  ]

  const errors = {}
  const legalFirstName = _get(data, 'legalFirstName') || ''
  const legalLastName = _get(data, 'legalLastName') || ''
  const googleAnalyticsKey = _get(data, 'googleAnalyticsKey')
  const googleTagManagerKey = _get(data, 'googleTagManagerKey')
  const accountNumber = _get(data, 'accountNumber') || ''
  const swiftCode = _get(data, 'swiftCode') || ''
  const bsb = _get(data, 'bsb') || ''
  const ifscNumber = _get(data, 'ifscNumber') || ''
  const country = _get(data, 'country') || ''
  const fullAddress = _get(data, 'fullAddress') || ''
  const clabe = _get(data, 'clabe') || ''
  const nzbn = _get(data, 'nzbn') || ''
  const transitNumber = _get(data, 'transitNumber') || null
  const institutionNumber = _get(data, 'institutionNumber') || null
  if (!_get(props, 'isRnDomain')) {
    required = [...required, 'internalSupportEmail', 'externalSupportEmail']
    if (
      _get(data, 'internalSupportEmail') &&
      _get(data, 'internalSupportEmail').length &&
      !validateEmail(_get(data, 'internalSupportEmail'))
    ) {
      _set(errors, 'internalSupportEmail', 'Invalid')
    }

    if (
      _get(data, 'externalSupportEmail') &&
      _get(data, 'externalSupportEmail').length &&
      !validateEmail(_get(data, 'externalSupportEmail'))
    ) {
      _set(errors, 'externalSupportEmail', 'Invalid')
    }
  }
  required.forEach(f => {
    if (!_get(data, f).trim()) {
      _set(errors, f, 'Required')
    }
  })

  if (!validateEmail(_get(data, 'email'))) {
    _set(errors, 'email', 'Invalid')
  }

  if (_get(data, 'shortDescription').length > 150) {
    _set(errors, 'shortDescription', 'Max 150 characters')
  }

  if (!!googleAnalyticsKey && !gaIdRegexp.test(googleAnalyticsKey.toString())) {
    _set(errors, 'googleAnalyticsKey', 'Invalid Tracking ID')
  }

  if (!!googleTagManagerKey && !gtmRegex.test(googleTagManagerKey.toString())) {
    _set(errors, 'googleTagManagerKey', 'Invalid ID')
  }

  if (nzbn && (!isValidInteger(nzbn) || nzbn.length !== 13)) {
    _set(errors, 'nzbn', 'Invalid code: NZBN code must contain 13 digits')
  }
  switch (country) {
    case 'us': {
      if (!legalFirstName) {
        _set(errors, 'legalFirstName', 'First name is required')
      }

      if (!legalLastName) {
        _set(errors, 'legalLastName', 'Last name is required')
      }
      break
    }
    case 'au': {
      if (bsb && (!isValidInteger(bsb) || bsb.length !== 6)) {
        _set(errors, 'bsb', 'BSB should be 6 digits')
      }
      break
    }
    case 'in': {
      if (ifscNumber && (!alphaNumericRegex.test(ifscNumber) || ifscNumber.length !== 11)) {
        _set(errors, 'ifscNumber', 'IFSC Number should be 11 alphanumeric characters')
      }
      break
    }
    case 'mx': {
      if (accountNumber && (!isValidInteger(accountNumber) || accountNumber.length !== 18)) {
        _set(errors, 'accountNumber', 'Account Number must contain 18 digits')
      }

      if (accountNumber && !swiftCode) {
        _set(errors, 'swiftCode', 'SWIFT Code is required')
      }

      if (accountNumber && swiftCode && !alphaNumericRegex.test(swiftCode)) {
        _set(errors, 'swiftCode', 'SWIFT Code can only be alphanumeric')
      }

      if (accountNumber && !clabe) {
        _set(errors, 'clabe', 'CLABE Code is required')
      }

      if (accountNumber && clabe && (!isValidInteger(clabe) || clabe.length !== 18)) {
        _set(errors, 'clabe', 'CLABE must contain 18 digits')
      }

      if (accountNumber && !fullAddress) {
        _set(errors, 'fullAddress', 'Address is required')
      }

      if (accountNumber && swiftCode && (swiftCode.length < 8 || swiftCode.length > 11)) {
        _set(errors, 'swiftCode', 'SWIFT Code must contain 8 to 11 characters')
      }
      break
    }
    case 'ca': {
      if (transitNumber && (!isValidInteger(transitNumber) || String(transitNumber).length !== 5)) {
        _set(errors, 'transitNumber', 'Transit Number should be 5 digits')
      }
      if (institutionNumber && (!isValidInteger(institutionNumber) || String(institutionNumber).length !== 3)) {
        _set(errors, 'institutionNumber', 'Institution Number should be 3 digits')
      }
    }
    default:
      break
  }
  return errors
}

class MyForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      brandUrlCopied: false,
      showImportFbPixelDialog: false,
      fbAdAccounts: [],
      fbAdAccountsLoading: false,
      fbAdAccountsLoadingError: null,
      closedBusinessDetailsSection: this.props.closed,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isSubmitting, containerId } = this.props
    const { errors } = nextProps
    if (!_isEmpty(nextProps.errors) && isSubmitting) {
      if (errors.slug || errors.displayName) {
        scroller.scrollTo('scroll-to-brand-name-details', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          containerId,
        })
      } else if (
        errors.legalEntity ||
        errors.legalFirstName ||
        errors.legalLastName ||
        errors.addressLine1 ||
        errors.brandCity ||
        errors.brandState ||
        errors.brandState ||
        errors.zip ||
        errors.country ||
        errors.email
      ) {
        this.setState(
          {
            closedBusinessDetailsSection: false,
          },
          () => {
            scroller.scrollTo('scroll-to-business-details', {
              duration: 800,
              delay: 0,
              smooth: 'easeInOutQuart',
              containerId,
            })
          },
        )
      } else if (errors.externalSupportEmail || errors.internalSupportEmail) {
        scroller.scrollTo('scroll-to-support', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          containerId,
        })
      } else if (
        errors.accountNumber ||
        errors.fullAddress ||
        errors.clabe ||
        errors.swiftCode ||
        errors.accountType ||
        errors.bsb ||
        errors.ifscNumber || 
        errors.institutionNumber ||
        errors.transitNumber
      ) {
        scroller.scrollTo('scroll-to-payout-details', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          containerId,
        })
      }
    }
  }

  copyBrandUrlAfter = () => {
    this.setState({
      brandUrlCopied: true,
    })
  }

  copyBrandUrlOut = () => {
    if (this.state.brandUrlCopied) {
      setTimeout(() => {
        this.setState({
          brandUrlCopied: false,
        })
      }, 500)
    }
  }

  onChangeCountry = e => {
    const newCountry = e.target.value
    const { setFieldValue } = this.props
    setFieldValue('country', newCountry)
  }

  handleChangeSlug = val => {
    const { setFieldValue } = this.props
    setFieldValue('slug', typeof val === 'string' ? val : val.target.value)
  }

  handleChangeDescription = value => {
    const { setFieldValue } = this.props
    setFieldValue('description', value)
  }

  handleChangeIconURL = (id, image) => {
    const { setFieldValue } = this.props
    setFieldValue(id, image)
  }

  clickImportFbPixelDialog = () => {
    this.openImportFbPixelDialog()
    this.fetchFbPixelsAll()
  }

  openImportFbPixelDialog = () => {
    this.setState({ showImportFbPixelDialog: true })
  }

  closeImportFbPixelDialog = () => {
    this.setState({
      showImportFbPixelDialog: false,
      fbAdAccounts: [],
    })
  }

  fetchFbPixelsAll = () => {
    this.setState({ fbAdAccountsLoading: true, fbAdAccounts: [] }, () => {
      fb.fetchSDK()
        .catch()
        .then(FB => {
          FB.login(
            () => {
              this.fetchFbAdAccounts(FB)
            },
            { scope: 'ads_management,business_management' },
          )
        })
    })
  }

  fetchFbAdAccounts = FB => {
    FB.api('/me/adaccounts?fields=id,name,currency,owner,business', response => {
      if (response.data) {
        const fbAdAccounts = _map(response.data, fbAdAccount => ({
          id: fbAdAccount.id,
          name: fbAdAccount.name,
          currency: fbAdAccount.currency,
          owner_id: fbAdAccount.owner,
          business: fbAdAccount.business,
          pixels: [],
          pixelsLoading: true,
          pixelsLoadingError: null,
          pixelCreating: false,
          pixelCreatingError: null,
          owner: fbAdAccount.business ?? null,
        }))
        this.setState(
          { fbAdAccounts, fbAdAccountsLoading: false, fbAdAccountsLoadingError: null },
          async () => {
            _each(this.state.fbAdAccounts, (fbAdAccount, idx) => {
              this.fetchFbAdAccountOwner(FB, fbAdAccount, idx)
              this.fetchFbPixels(FB, fbAdAccount, idx)
            })
          },
        )
      } else if (response.error) {
        this.setState({ fbAdAccountsLoading: false, fbAdAccountsLoadingError: response.error.message })
      }
    })
  }

  fetchFbAdAccountOwner = (FB, fbAdAccount, idx) => {
    if (!fbAdAccount.owner) {
      FB.api('/' + fbAdAccount.owner_id, response => {
        this.setState(state => {
          const newFbAdAccounts = [...state.fbAdAccounts]
          newFbAdAccounts[idx].owner = response
          return { fbAdAccounts: newFbAdAccounts }
        })
      })
    }
  }

  fetchFbPixels = (FB, fbAdAccount, idx) => {
    this.setState(
      state => {
        const newFbAdAccounts = [...state.fbAdAccounts]
        newFbAdAccounts[idx].pixelsLoading = true
        return { fbAdAccounts: newFbAdAccounts }
      },
      () => {
        const { pixels } = this.props.values
        FB.api('/' + fbAdAccount.id + '/adspixels?fields=id,name,code', response => {
          if (response.data) {
            this.setState(state => {
              const newFbAdAccounts = [...state.fbAdAccounts]
              newFbAdAccounts[idx].pixels = _map(response.data, p => ({
                id: p.id,
                name: p.name,
                code: p.code,
                checked: !!pixels.find(pixel => pixel.pixelId === p.id),
                accessToken: pixels.find(pixel => pixel.pixelId === p.id)?.accessToken ?? '',
                error: '',
              }))
              newFbAdAccounts[idx].pixelsLoading = false
              newFbAdAccounts[idx].pixelsLoadingError = null
              return { fbAdAccounts: newFbAdAccounts }
            })
          } else if (response.error) {
            this.setState(state => {
              const newFbAdAccounts = [...state.fbAdAccounts]
              newFbAdAccounts[idx].pixelsLoading = false
              fbAdAccount.pixelsLoadingError = response.error.message
              return { fbAdAccounts: newFbAdAccounts }
            })
          }
        })
      },
    )
  }

  importFbPixelCode = fbPixel => {
    const { values } = this.props
    const extraCodePage = "<script>\nfbq('track', 'ViewContent');\n</script>"
    const extraCodeCheckout = "<script>\nfbq('track','InitiateCheckout');\n</script>"
    const extraCodePurchase =
      "<script>\nfbq('track','Purchase',{\nvalue:'{VALUE}',\ncurrency: '{CURRENCY}'\n});\n</script>"

    this.updateField(
      'pagePixels',
      (values.pagePixels ? values.pagePixels + '\n' : '') + fbPixel.code + '\n' + extraCodePage,
    )
    this.updateField(
      'checkoutPixels',
      (values.checkoutPixels ? values.checkoutPixels + '\n' : '') + fbPixel.code + '\n' + extraCodeCheckout,
    )
    this.updateField(
      'conversionPixels',
      (values.conversionPixels ? values.conversionPixels + '\n' : '') +
        fbPixel.code +
        '\n' +
        extraCodePurchase,
    )
    this.closeImportFbPixelDialog()
  }

  createFbPixel = (fbAdAccount, idx) => {
    if (fbAdAccount.pixels.length > 0) return
    this.setState(state => {
      const newFbAdAccounts = [...state.fbAdAccounts]
      newFbAdAccounts[idx].pixelCreating = true
      return { fbAdAccounts: newFbAdAccounts }
    })
    fb.fetchSDK()
      .catch()
      .then(FB => {
        FB.login(
          () => {
            FB.api('/' + fbAdAccount.id + '/adspixels', 'post', { name: 'The Ticket Fairy' }, response => {
              if (response.error) {
                this.setState(state => {
                  const newFbAdAccounts = [...state.fbAdAccounts]
                  newFbAdAccounts[idx].pixelCreating = false
                  newFbAdAccounts[idx].pixelCreatingError = response.error.message
                  return { fbAdAccounts: newFbAdAccounts }
                })
              } else {
                this.setState(
                  state => {
                    const newFbAdAccounts = [...state.fbAdAccounts]
                    newFbAdAccounts[idx].pixelCreating = false
                    newFbAdAccounts[idx].pixelCreatingError = null
                    return { fbAdAccounts: newFbAdAccounts }
                  },
                  () => {
                    this.fetchFbPixels(FB, fbAdAccount)
                  },
                )
              }
            })
          },
          { scope: 'ads_management' },
        )
      })
  }

  updateField = (key, value) => {
    const { setFieldValue } = this.props
    setFieldValue(key, value)
  }

  importFacebookPixels = () => {
    const {
      values: { pixels: savedPixels },
    } = this.props
    const { fbAdAccounts } = this.state
    const pixels = fbAdAccounts
      .reduce((curr, account) => {
        const checkedPixels = account.pixels.map(({ id, accessToken, checked }) => {
          const isSaved = savedPixels.find(savedPixel => savedPixel.pixelId === id)
          return !isSaved && !checked
            ? null
            : {
              ...(isSaved ? { id: isSaved.id } : {}),
              pixelId: id,
              accessToken,
              platform: 'facebook',
              ...(isSaved && !checked ? { _delete: true } : {}),
            }
        })
        return curr.concat(checkedPixels)
      }, [])
      .filter(pixel => pixel)

    this.updateField('pixels', pixels)
    this.closeImportFbPixelDialog()
    setTimeout(() => {
      this.props.submitForm()
    }, 100)
  }

  render() {
    const {
      brandUrlCopied,
      fbAdAccounts,
      fbAdAccountsLoading,
      fbAdAccountsLoadingError,
      closedBusinessDetailsSection,
    } = this.state

    const {
      onCancel,
      submitLabel,
      isRnDomain,
      brand,
      shopifyInfo,
      shopifyConnectLoading,
      shopifyDisconnectLoading,
      shopifyRefreshLoading,
      fetchProductsLoading,
      shopifyConnectSubmit,
      onDisconnectShopify,
      onSopifyUpdate,
      onSopifyRefresh,
      isNew,
      dynamicConfigs,
      closed,
      handleSubmit,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      isSubmitting,
      configs,
      user,
    } = this.props
    const displayPaymentSection = _get(configs, 'children.PaymentMethod.displaySection')
    const displayBrandPaymentDetails = _get(configs, 'children.BrandPaymentDetails.displaySection', true)
    const hideShopify = _get(configs, 'appearance.hideShopify', false)
    const showAdvancedOptions = _get(configs, 'appearance.showAdvancedOptions', true)
    const userIsPayoutAdmin = user.payoutAdmin

    const fbAdAccounts_element = _map(this.state.fbAdAccounts, (fbAdAccount, fbAdAccountIndex) => {
      const fbPixels_element_server = _map(fbAdAccount.pixels, (fbPixel, fbPixelIndex) => (
        <div
          key={fbPixelIndex}
          style={{ backgroundColor: fbPixel.checked ? '#363E48' : 'transparent' }}
          className="list-fb-accounts"
        >
          <div className="container-checkbox-wrapper">
            <label className="container-checkbox">
              <input
                data-testid="checked-input"
                type="checkbox"
                defaultChecked={fbPixel.checked}
                onChange={event => {
                  const { checked } = event.target
                  this.setState(state => {
                    const newFbAdAccounts = [...state.fbAdAccounts]
                    newFbAdAccounts[fbAdAccountIndex].pixels[fbPixelIndex].checked = checked
                    return { fbAdAccounts: newFbAdAccounts }
                  })
                }}
              />
              <span class="checkmark" />
            </label>
          </div>
          <div className="fb-pixel-name">{fbPixel.name}</div>
          <div className="id-wrapper">
            <div className="id-title">Pixel ID:</div>
            <div className="id-number">{fbPixel.id}</div>
          </div>
          <div className="token-box">
            <div className="secret-token">
              Secret Access Token
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="fbAccountsInfoToken">
                    This is a long string that identifies a user, app, or page, and can be used by the app to
                    make calls to the API. This provides temporary and secure access to the Facebook APIs. You
                    can get more information{' '}
                    <a
                      style={{ color: '#0645AD' }}
                      href="https://developers.facebook.com/docs/facebook-login/access-tokens"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                  </Tooltip>
                }
                trigger={['click']}
              >
                <i className="fa fa-question-circle" style={{ marginLeft: 5, cursor: 'pointer' }} />
              </OverlayTrigger>
            </div>
            <textarea
              data-testid="textarea-fb-accounts"
              className="textarea-fb-accounts"
              value={fbPixel.accessToken}
              onChange={event => {
                const { value } = event.target
                this.setState(state => {
                  const newFbAdAccounts = [...state.fbAdAccounts]
                  newFbAdAccounts[fbAdAccountIndex].pixels[fbPixelIndex].accessToken = value
                  return { fbAdAccounts: newFbAdAccounts }
                })
              }}
            />
          </div>
        </div>
      ))
      return (
        <div key={fbAdAccountIndex} className="table" style={{ marginTop: '20px' }}>
          <div className="fb-ad-account-title">
            {fbAdAccount.name}
            &nbsp;&#91;{fbAdAccount.currency}&#93;
            {fbAdAccount.owner && <span>&nbsp;owned by {fbAdAccount.owner.name}</span>}
            {fbAdAccount.pixelsLoading && <i className="fa fa-spin fa-circle-o-notch" aria-hidden="true" />}
            {fbAdAccount.pixelsLoadingError && (
              <div className="fbpixels-loading-error text-danger">
                Loading Pixels failed: {fbAdAccount.pixelsLoadingError}
              </div>
            )}
            {fbAdAccount.pixelCreatingError && (
              <div className="fbpixel-creating-error text-danger">
                Creating Pixel failed: {fbAdAccount.pixelCreatingError}
              </div>
            )}
          </div>
          <div className="text-right">
            {fbAdAccount.pixels.length === 0 && (
              <Button
                key={fbAdAccountIndex}
                className="btn btn-blue"
                disabled={fbAdAccount.pixelCreating}
                onClick={this.createFbPixel.bind(this, fbAdAccount, fbAdAccountIndex)}
              >
                Create New Pixel
                {fbAdAccount.pixelCreating && (
                  <i className="fa fa-spin fa-circle-o-notch" aria-hidden="true" />
                )}
              </Button>
            )}
          </div>
          {fbPixels_element_server}
        </div>
      )
    })

    const pixels = fbAdAccounts
      .reduce((curr, account) => {
        const checkedPixels = account.pixels.map(({ id, accessToken, checked }) => {
          const isSaved = values.pixels.find(savedPixel => savedPixel.pixelId === id)
          return !isSaved && !checked
            ? null
            : {
              ...(isSaved ? { id: isSaved.id } : {}),
              pixelId: id,
              accessToken: accessToken ? accessToken : null,
              platform: 'facebook',
              ...(isSaved && !checked ? { _delete: true } : {}),
            }
        })
        return curr.concat(checkedPixels)
      }, [])
      .filter(pixel => pixel)

    const disableImportButton = fbAdAccountsLoading || fbAdAccountsLoadingError || pixels.length === 0

    return (
      <form autoComplete="off" ref="form" method="POST" onSubmit={handleSubmit}>
        <Element name="scroll-to-brand-name-details" />
        <BrandNameDetails
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          onBlur={handleBlur}
          brandUrlCopied={brandUrlCopied}
          copyBrandUrlAfter={this.copyBrandUrlAfter}
          copyBrandUrlOut={this.copyBrandUrlOut}
          handleChangeSlug={this.handleChangeSlug}
          isRnDomain={isRnDomain}
          handleChangeDescription={this.handleChangeDescription}
          isNew={isNew}
        />
        <BrandImages
          closed={closed}
          values={values}
          handleChangeIconURL={this.handleChangeIconURL}
          isRnDomain={isRnDomain}
        />
        <Element name="scroll-to-business-details" />
        <BusinessDetails
          closed={closedBusinessDetailsSection}
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          onBlur={handleBlur}
          onChangeCountry={this.onChangeCountry}
          updateField={this.updateField}
        />
        <Element name="scroll-to-payout-details" />
        {brand && (!brand.flagIsStripeConnected || user.tfStaff) && displayBrandPaymentDetails ? (
          <PayoutDetails
            closed={closed}
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            onBlur={handleBlur}
            userIsPayoutAdmin={userIsPayoutAdmin}
          />
        ) : null}
        {brand && brand.flagIsStripeConnected && (
          <Card icon={'fa-money'} title={'Payout Details'} closed={closed}>
            <div className="card-block card-footer text-center btn-toolbar">
              Connected to external Stripe account for payouts
            </div>
          </Card>
        )}
        <Element name="scroll-to-support" />
        {!isRnDomain && (
          <Support
            closed={closed}
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            onBlur={handleBlur}
            updateField={this.updateField}
          />
        )}
        {!isNew && (values.isOwner || userIsPayoutAdmin) && displayPaymentSection && (
          <PaymentMethod brand={brand} configs={_get(configs, 'children.PaymentMethod')} />
        )}
        {showAdvancedOptions && (
          <BrandTicketAdvancedOptions
            closed={closed}
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            importFbPixelDialog={this.clickImportFbPixelDialog}
          />
        )}
        {user.tfStaff
          ? !isNew &&
            _get(dynamicConfigs, 'show_connect_shopify', false) &&
            !hideShopify && (
            <ShopifyCard
              id={brand.id}
              onSubmit={shopifyConnectSubmit}
              onDisconnectShopify={onDisconnectShopify}
              shopifyInfo={shopifyInfo}
              shopifyConnectLoading={shopifyConnectLoading}
              shopifyDisconnectLoading={shopifyDisconnectLoading}
              shopifyRefreshLoading={shopifyRefreshLoading}
              fetchProductsLoading={fetchProductsLoading}
              onRefresh={onSopifyRefresh}
              onUpdate={onSopifyUpdate}
              title="Shopify"
            />
          )
          : null}
        <div className="card-block card-footer text-center btn-toolbar">
          <Button type="submit" className="btn btn-primary btn-lg btn-shadow" loading={isSubmitting}>
            {submitLabel}
          </Button>
          {onCancel && (
            <Button
              className="btn btn-danger btn-lg btn-shadow"
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
        </div>
        <Modal
          className="modal-dialog modal-trans"
          style={modalStyle}
          isOpen={!!this.state.showImportFbPixelDialog}
          contentLabel="Modal"
          onRequestClose={this.closeImportFbPixelDialog}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content" style={{ backgroundColor: '#2A2F37' }}>
              <div>
                <div className="modal-header">
                  <p className="h4 text-compact">
                    Facebook Pixels
                    {this.state.fbAdAccountsLoading && (
                      <i className="fa fa-spin fa-circle-o-notch" aria-hidden="true" />
                    )}
                  </p>
                </div>
                <div className="modal-body" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                  {this.state.fbAdAccountsLoadingError && (
                    <div className="text-danger">
                      Loading Ad Accounts failed: {this.state.fbAdAccountsLoadingError}
                    </div>
                  )}
                  {fbAdAccounts_element}
                </div>
                <div className="modal-footer no-border">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-cancel btn-shadow"
                      type="button"
                      onClick={this.closeImportFbPixelDialog}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn btn-default btn-shadow"
                      style={{ backgroundColor: '#139679' }}
                      disabled={disableImportButton}
                      type="button"
                      onClick={this.importFacebookPixels}
                    >
                      Import
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </form>
    )
  }
}

const BrandForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    const initialValues = {
      displayName: _get(props, 'initialValues.attributes.displayName') || '',
      slug: _get(props, 'initialValues.attributes.slug') || '',
      legalEntity: _get(props, 'initialValues.attributes.legalEntity') || '',
      legalFirstName: _get(props, 'initialValues.attributes.legalFirstName') || '',
      legalLastName: _get(props, 'initialValues.attributes.legalLastName') || '',
      addressLine1: _get(props, 'initialValues.attributes.addressLine1') || '',
      addressLine2: _get(props, 'initialValues.attributes.addressLine2') || '',
      brandCity: _get(props, 'initialValues.attributes.city') || '',
      brandState: _get(props, 'initialValues.attributes.state') || '',
      zip: _get(props, 'initialValues.attributes.zip') || '',
      taxID: _get(props, 'initialValues.attributes.taxID') || '',
      accountName: _get(props, 'initialValues.attributes.accountName') || '',
      accountNumber: _get(props, 'initialValues.attributes.accountNumber') || '',
      routingNumber: _get(props, 'initialValues.attributes.routingNumber') || '',
      accountType: _get(props, 'initialValues.attributes.accountType') || 'checking',
      fullAddress: _get(props, 'initialValues.attributes.fullAddress') || '',
      clabe: _get(props, 'initialValues.attributes.clabe') || '',
      bsb: _get(props, 'initialValues.attributes.bsb') || '',
      nzbn: _get(props, 'initialValues.attributes.nzbn') || '',
      sortCode: _get(props, 'initialValues.attributes.sortCode') || '',
      swiftCode: _get(props, 'initialValues.attributes.swiftCode') || '',
      email: _get(props, 'initialValues.attributes.email') || '',
      ifscNumber: _get(props, 'initialValues.attributes.ifscNumber') || '',
      bankName: _get(props, 'initialValues.attributes.bankName') || '',
      description: _get(props, 'initialValues.attributes.description') || '',
      shortDescription: _get(props, 'initialValues.attributes.shortDescription') || '',
      logo: _get(props, 'initialValues.attributes.logo'),
      pagePixels: _get(props, 'initialValues.attributes.pagePixels') || null,
      checkoutPixels: _get(props, 'initialValues.attributes.checkoutPixels') || null,
      conversionPixels: _get(props, 'initialValues.attributes.conversionPixels') || null,
      googleAnalyticsKey: _get(props, 'initialValues.attributes.googleAnalyticsKey') || '',
      googleTagManagerKey: _get(props, 'initialValues.attributes.googleTagManagerKey') || '',
      googleAddress: '',
      pixels: _get(props, 'initialValues.attributes.pixels') || [],
      isOwner: _get(props, 'initialValues.attributes.isOwner', false),
      isTTFAccount: _get(props, 'initialValues.attributes.isTTFAccount', false),
      transitNumber: _get(props, 'initialValues.attributes.transitNumber') || null,
      institutionNumber: _get(props, 'initialValues.attributes.institutionNumber') || null
    }

    const selectedCountry = countryCodeAdapter(_get(props, 'initialValues.attributes.country'), 'gb', 'uk')
    initialValues.country = _find(countries, (item, key) => key === selectedCountry) ? selectedCountry : ''

    if (!_get(props, 'isRnDomain')) {
      initialValues.internalSupportEmail = _get(props, 'initialValues.attributes.internalSupportEmail') || ''
      initialValues.externalSupportEmail = _get(props, 'initialValues.attributes.externalSupportEmail') || ''
    }

    return initialValues
  },

  // Custom sync validation
  validate: (values, props) => {
    const errors = validateBrand(values, props)
    return errors
  },

  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    const { brandCity, brandState, logo, ...rest } = values
    if (values.pagePixels == null) {
      delete rest.pagePixels
    }
    if (values.checkoutPixels == null) {
      delete rest.checkoutPixels
    }
    if (values.conversionPixels == null) {
      delete rest.conversionPixels
    }
    if (values.transitNumber) {
      rest.transitNumber = Number(values.transitNumber)
    }
    if (values.institutionNumber) {
      rest.institutionNumber = Number(values.institutionNumber)
    }

    props
      .onSubmit({
        attributes: {
          ...rest,
          city: brandCity,
          state: brandState,
          logos: logo
            ? [
              {
                fileUrl: logo,
                type: 1,
                description: '',
              },
            ]
            : null,
        },
      })
      .then(() => {
        resetForm()
      })
      .catch(() => {})
      .finally(() => {
        setSubmitting(false)
      })
  },
  displayName: 'BrandForm', // helps with React DevTools
})(MyForm)

export default BrandForm
