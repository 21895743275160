import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import ClipboardButton from 'react-clipboard.js'
import AutoSlugField from '../../../_library/AutoSlugField'
import Field from '../../../_library/Field'
import { ENV } from '../../../constants/env'

export default class EventUrlRow extends React.PureComponent {
  static propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    updateField: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      eventUrlCopied: false,
      embedCodeCopied: false
    }
  }

  copyEventUrlEmbedCodeOut = e => {
    if (this.state.eventUrlCopied) {
      setTimeout(() => {
        this.setState({ eventUrlCopied: false })
      }, 500)
    }

    if (this.state.embedCodeCopied) {
      setTimeout(() => {
        this.setState({ embedCodeCopied: false })
      }, 500)
    }
  }

  copyEventUrlAfter = () => {
    this.setState({ eventUrlCopied: true })
  }

  copyEmbedCodeAfter = () => {
    this.setState({ embedCodeCopied: true })
  }

  handleChangeSlug = e => {
    const { updateField } = this.props
    const str = e.target ? e.target.value : e
    updateField('slug', str)
  }

  render() {
    const { values, errors, touched, isNew, isRnDomain } = this.props
    const { eventUrlCopied, embedCodeCopied } = this.state
    const baseURL = isRnDomain ? `${ENV.RN_CONSUMER}/events/` : `${ENV.API_CONSUMER}/event/`
    const slugSuggestion = !isNew ? values.slug : !values.startDate
      ? values.displayName
      : (values.displayName || '') +
        `${isRnDomain ? '' : '-'}` +
        moment.utc(values.startDate).format('DMMMYYYY')

    return (
      <div className="row event-details-url-section">
        <div className="col-xs-12">
          <label className="autoslugfield-label" onMouseLeave={this.copyEventUrlEmbedCodeOut}>
            Event URL
            <ClipboardButton
              component="span"
              data-clipboard-text={baseURL + values.slug}
              onSuccess={this.copyEventUrlAfter}
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="eventUrlCopied">{eventUrlCopied ? 'Copied' : 'Copy Event URL'}</Tooltip>
                }
                trigger={['hover']}
              >
                <i className="fa fa-clipboard event-url-copy" />
              </OverlayTrigger>
            </ClipboardButton>
            <ClipboardButton
              component="span"
              data-clipboard-text={
                `<script src="${ENV.API_CONSUMER}/resources/js/widget.js?` +
                Math.round(Math.random() * 10000000000) +
                `"></script>
                  <div id="ttf-container" style="width: 100%"></div>

                  <script>
                    var widget = new TTFWidget(
                      {
                        event : '` +
                values.slug +
                `',
                        compact : 1
                      }
                    );
                  </script>
              `
              }
              onSuccess={this.copyEmbedCodeAfter}
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="embedCodeCopied">{embedCodeCopied ? 'Copied' : 'Copy Embed Code'}</Tooltip>
                }
                trigger={['hover']}
              >
                <i className="fa fa-code event-url-copy" />
              </OverlayTrigger>
            </ClipboardButton>
          </label>
          <AutoSlugField
            id="eventSlug"
            label={isRnDomain ? 'Custom URL' : 'Link URL'}
            separator={isRnDomain ? '' : '-'}
            hint="Letters, numbers and hyphens only"
            suggestion={slugSuggestion}
            value={values.slug}
            error={errors.slug}
            touched={touched.slug}
            onChange={this.handleChangeSlug}
          >
            <Field.PrefixAddon className="link-prefix hidden-xs">
              <img className="link-prefix-img" src={asset('/resources/images/event-url.png')} />
              <div className="link-prefix-url">{baseURL}</div>
            </Field.PrefixAddon>
          </AutoSlugField>
        </div>
      </div>
    )
  }
}
