import React from 'react'
import Button from '../../../../_library/Button'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'

const ButtonWithTooltip = ({
  children,
  buttonClassName,
  buttonStyle,
  loading,
  text,
  tooltipText,
  tooltipId,
  disabled,
  onClick,
  containerStyle = {}
}) => (
  <OverlayTrigger
    placement="top"
    overlay={disabled && tooltipText ? <Tooltip id={tooltipId}>{tooltipText}</Tooltip> : <></>}
    trigger={['hover']}
  >
    <div style={{ display: 'inline-block', ...containerStyle }}>
      <Button
        style={{ pointerEvents: disabled && tooltipText ? 'none' : 'auto', ...buttonStyle }}
        disabled={disabled}
        loading={loading}
        className={buttonClassName + 'tooltip-disabled-button'}
        type="button"
        onClick={onClick}
      >
        {children || text}
      </Button>
    </div>
  </OverlayTrigger>
)

export default ButtonWithTooltip
