import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import {Redirect} from 'react-router-dom'
import { get_event } from '../../../_common/core/selectors'
import { getContract, createContract, getContractStatus, deployContract } from '../../../_common/core/http_services'
import Card from '../../_library/Card'
import LoadingBar from '../../_library/LoadingBar'
import Button from '../../_library/Button'
import { NFTForm } from './NFTForm'


let NFTs = (props) => {
  const {event} = props

  const [contract, setContract] = useState({
    loading: false,
    deployLoading: false,
    data: null,
    savedContract: false,
    dataFetched: false,
    status: 'not-ready',
  })

  const isNotReady = contract.status === 'not-ready'
  const isDraft = contract.status === 'draft'
  const isPending = contract.status === 'deploying'
  const isDeployed = contract.status === 'deployed'

  useEffect(() => {
    fetchContract()
  }, [])

  useEffect(() => {
      const fetchContractStatus = async () => {
        try {
          const data = await getContract(event.id)
          const status = data?.data?.attributes?.status ?? 'not-ready'
          setContract(state => ({...state, status}))
        } catch(err) {}
      }

    const id = setInterval(() => {
      if (!isDeployed && !isDraft && contract.savedContract) {
        fetchContractStatus()
        getContractStatus(event.id) // for sync
      }
    }, 3000)
    return () => clearInterval(id)
  }, [event, contract, isDeployed, isDraft ])

  const fetchContract = async () => {
    if (!contract.dataFetched && !contract.loading) {
      try {
        setContract(state => ({...state, loading: true}))
        const data = await getContract(event.id)
        const contract = data?.data?.attributes
        const savedContract = !!contract
        const status = contract?.status ?? 'not-ready'
        setContract(state => ({...state, loading: false, dataFetched: true, data: contract, savedContract, status}))
      } catch(err) {}
    }
  }


  const onSubmit = async (values) => {
    try {
      await createContract(event.id, values)
      setContract(state => ({...state, savedContract: true}))
    } catch (err) {}
  }

  const onDeploy = async () => {
    try {
      setContract(state => ({...state, deployLoading: true}))
      await deployContract(event.id)
      setContract(state => ({...state, deployLoading: false, status: 'deploying'}))
    } catch (err) {
      setContract(state => ({...state, deployLoading: false}))
    }
  }

  if (event && !event.flagRequireEthereumWallet) {
    return <Redirect to='/' />
  }

  if (!contract.dataFetched) {
    return <LoadingBar />
  }

  return (
    <div className="nft-contract">
      <h3 className="heading_style">NFT Contract</h3>
      <small>Enhance your attendees' experience with NFT collectibles airdropped directly into their wallets.</small>
      <Card title="Contract Details" status={''}>
        <NFTForm
          onSubmit={onSubmit}
          initialValues={contract.data}
          disabled={contract.savedContract}
          hideSaveButton={contract.savedContract && !isNotReady}
          loading={isNotReady && contract.savedContract}
        />
        <br/>
        {
          (contract.savedContract && !isNotReady) && (
            <div className="row">
              <div className="col-xs-12 text-center">
                <Button 
                  className="btn btn-primary btn-lg btn-shadow"
                  type="submit"
                  loading={isPending || contract.deployLoading}
                  disabled={isDeployed}
                  onClick={onDeploy}
                >
                  {isDeployed ? 'Deployed' : 'Deploy'}
                </Button>
              </div>
            </div>
          )
        }
      </Card>
    </div>
  )
}

const mapStateToProps = state => ({
  event: get_event(state)
})

NFTs = connect(mapStateToProps)(NFTs)

export { NFTs }
