import _get from 'lodash/get'
import { baseRequest } from '.'
import { crmAdapter, crmCustomerAdapter } from './adapters'

export const getCustomers = async (searchValue = '', limit, page, brandId, offset, sortBy, sortOrder) => {
  const res = await baseRequest.get('crm/customers', {
    params: {
      search_query: searchValue,
      limit,
      page,
      brandId,
      offset,
      sortBy,
      sortOrder: sortOrder ? 'asc' : 'desc'
    }
  })
  const data = _get(res, 'data')
  return crmAdapter(data)
}

export const getCustomer = async (brandId, clientId) => {
  const res = await baseRequest.get(`crm/customer?brandId=${brandId}&clientId=${clientId}`)
  const data = _get(res, 'data')
  return crmCustomerAdapter(data)
}

export const createCustomerNote = async (customerId, note) => {
  const res = await baseRequest.post(`crm/customer/${customerId}/notes`, {
    note
  })
  return res
}

export const getCustomerMedias = async customerId => {
  const res = await baseRequest.get(`crm/customer/${customerId}/social-media`)
  const data = _get(res, 'data.data.attributes')
  if (!data) {
    throw new Error('No data.')
  }
  return data
} 

export const createCustomerMedia = async (customerId, name, url) => {
  const res = await baseRequest.post(`crm/customer/${customerId}/social-media`, {
    socialMedia: [
      {
        name,
        url
      }
    ]
  })
  return res
} 

export const updateCustomerMedia = async (customerId, name, url, customerPropertyId) => {
  const res = await baseRequest.put(`crm/customer/${customerId}/social-media`, {
    customerPropertyId,
    socialMedia: {
      name,
      url
    }
  })
  return res
} 

export const removeCustomerMedia = async (customerId, customerPropertyId) => {
  const res = await baseRequest.delete(`crm/customer/${customerId}/social-media`, {
    data: {
      customerPropertyId
    }
  })
  return res
} 