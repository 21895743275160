import { baseRequest } from '.'
import _get from 'lodash/get'
import { addOnAdapter, addOnsAdapter } from './adapters'

export const getAddOns = async eventId => {
  const res = await baseRequest.get(`/events/${eventId}/relationships/add_ons/`)
  return addOnsAdapter(_get(res, 'data.data'))
}

export const createAddOn = async (eventId, data) => {
  const dataWithAttributes = {
    attributes: {
      ...data
    }
  }

  const res = await baseRequest.post(`/add_ons/`, {
    data: dataWithAttributes,
    relationships: {
      event: {
        data: {
          id: eventId
        }
      }
    }
  })
  return addOnAdapter(_get(res, 'data.data'))
}

export const updateAddOn = async (addonId, data) => {
  const res = await baseRequest.patch(`/add_ons/${addonId}/`, { data })
  return addOnAdapter(_get(res, 'data.data'))
}


export const updateAddOnsSorting = async (eId, addOns) => {
  await baseRequest.patch(`event/${eId}/add-ons/sorting/`, {
    data: {
      attributes: {
        eventAddOns: addOns
      }
    }
  })
}