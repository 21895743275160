import React from 'react'
import { Formik, Form, Field } from 'formik'
import Button from '../../_library/Button'
import { SimpleField, ReactSelectField, CustomToggleField } from '../../formik/Fields'
import { requiredValidator } from '../../../_common/core/validation'
import _get from 'lodash/get'

const TrackingForm = props => {
  const {
    formInitialValues = {},
    handleSubmit,
    ticketTypeOptions,
    addOnOptions,
    ticketTypeParamKey,
    configs,
  } = props

  const renderInitialValues = () => {
    const { name = '' } = formInitialValues
    return {
      name,
    }
  }
  const initialValues = renderInitialValues()
  const showAddOnIdsField = _get(configs, 'appearance.showAddOnIdsField', false)
  return (
    <div className="tracking_form">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={(values, props) => {
          let required = []
          const errors = {}
          if (!values[ticketTypeParamKey] && !values.addOnIds) {
            required = [...required, ticketTypeParamKey, 'addOnIds']
          }
          required.forEach(f => {
            if (!_.get(values, f)) {
              _.set(errors, f, 'Required')
            }
          })
          if (values.name.length < 3) {
            errors.name = 'The name must be at least 3 characters.'
          }
          return errors
        }}
        onSubmit={(values, actions) => {
          const { setSubmitting, resetForm } = actions
          handleSubmit(values).finally(() => {
            resetForm()
            setSubmitting(false)
          })
        }}
        render={({ values, isSubmitting, errors, touched, setFieldValue, setFieldTouched }) => {
          const buttonEnabled =
            values.name &&
            ((values[ticketTypeParamKey] && values[ticketTypeParamKey].length) ||
              (values.addOnIds && values.addOnIds.length))

          return (
            <Form autoComplete="off">
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <Field name="name" label="Name" component={SimpleField} validate={requiredValidator()} />
                </div>
                <div className="col-sm-6 col-xs-12" style={{ paddingTop: 20 }}>
                  <Field
                    name="hideDateBreakdowns"
                    onText="Hide Breakdown by Date"
                    component={CustomToggleField}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <Field
                    name={ticketTypeParamKey}
                    id={ticketTypeParamKey}
                    label="Add a Ticket Type"
                    multi={true}
                    clearable={true}
                    options={ticketTypeOptions}
                    component={ReactSelectField}
                  />
                </div>
                {isMobileDevice() ? <div className="div-spacing-20" /> : null}
                {showAddOnIdsField && (
                  <div className="col-sm-6 col-xs-12">
                    <Field
                      name="addOnIds"
                      id="addOnIds"
                      label="Add an Add-On"
                      multi={true}
                      clearable={true}
                      options={addOnOptions}
                      component={ReactSelectField}
                    />
                  </div>
                )}
              </div>
              <div className="div-spacing-10" />
              <div className="row">
                <div className="col-xs-12 text-center">
                  <Button
                    disabled={!buttonEnabled}
                    className="btn btn-success btn-shadow"
                    type="submit"
                    loading={isSubmitting}
                  >
                    Generate Sales Tracking Link
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      />
    </div>
  )
}

export default TrackingForm
