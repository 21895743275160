import React from 'react'
import _map from 'lodash/map'
import _identity from 'lodash/identity'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { shortid } from '../../../_common/core/rand'
import CustomClipboardButton from '../CustomClipboardButton'
import { getRowValue } from './utils'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle
})

export default class DnDTableBody extends React.PureComponent {
  render() {
    const { data, disableMobileView, onDragEnd, isDesktop, columns, handleRowExpand } = this.props
    const isDetailRow = data.type === 'detailRow'

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <tbody {...provided.droppableProps} ref={provided.innerRef}>
              {_map(data, (row, index) => (
                <Draggable key={row.id} draggableId={row.id} index={index}>
                  {(provided, snapshot) =>
                    isDesktop || disableMobileView ? (
                      <tr
                        // key={shortid()}
                        className={isDetailRow ? 'tr-detail-row dnd' : 'row-stale dnd'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        {isDetailRow ? (
                          <td className="td-detail" colSpan={columns.length} style={{ padding: 10 }}>
                            <div className="td-div-container">{data.component}</div>
                          </td>
                        ) : (
                          _map(columns, (column, index) => (
                            <td
                              key={shortid()}
                              aria-hidden={true}
                              onClick={column.key === 'expand' ? () => handleRowExpand(data) : _identity}
                              className={`td-desktop ${column.className || ''} ${
                                column.key === 'message' ? 'custom-width-row' : ''
                              }`}
                            >
                              {column.enableItemCopy ? (
                                <CustomClipboardButton
                                  id={shortid()}
                                  clipboardText={getRowValue(column, { ...this.props, data: row })}
                                  tooltipBeforeCopyText="Copy"
                                  label={getRowValue(column, { ...this.props, data: row })}
                                />
                              ) : (
                                getRowValue(column, { ...this.props, data: row, enableDnd: true })
                              )}
                            </td>
                          ))
                        )}
                      </tr>
                    ) : (
                      <tr
                        className={'row-stale dnd'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <td key={shortid()} colSpan={6} className="td-mobile">
                          <div className="td-mobile-values">
                            {_map(
                              columns,
                              (column, index) =>
                                column.key !== 'clipboard' && (
                                  <div key={shortid()} className={`td-mobile-name ${column.className || ''}`}>
                                    <div className="td-mobile-label">{column.label}</div>
                                    <div className="td-mobile-value">
                                      {column.enableItemCopy ? (
                                        <CustomClipboardButton
                                          id={shortid()}
                                          clipboardText={getRowValue(column, { ...this.props, data: row })}
                                          tooltipBeforeCopyText="Copy"
                                          label={getRowValue(column, { ...this.props, data: row })}
                                        />
                                      ) : (
                                        getRowValue(column, { ...this.props, data: row, enableDnd: true })
                                      )}
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  }
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}