import _map from 'lodash/map'
import _filter from 'lodash/filter'
import React from 'react'
import SelectTimeSlotTickets from '../_library/SelectMultipleTimeSlots/SelectTimeSlotTickets'

export default class TicketsSection extends React.Component {
  constructor(props) {
    super(props)
    const { tickets, tables } = props
    const ticketOptions = []
    const tableOptions = []
    _map(tickets, (t, id) => {
      ticketOptions.push(t)
    })
    _map(tables, (t, id) => {
      tableOptions.push(t)
    })
    this.state = {
      ticketOptions,
      tableOptions,
      noGroupedTicketsIds: this.props.groupedTicketIds || [],
      groupedTicketsIds: [],
      maxQuantityPerTicketType: []
    }
  }

  componentWillReceiveProps() {
    const { tickets, tables } = this.props
    const ticketOptions = []
    const tableOptions = []
    _map(tickets, (t, id) => {
      ticketOptions.push(t)
    })
    _map(tables, (t, id) => {
      tableOptions.push(t)
    })
    this.setState({
      ticketOptions,
      tableOptions
    })
  }

  onChangeNoGroupedTickets = e => {
    const { noGroupedTicketsIds, groupedTicketsIds, maxQuantityPerTicketType } = this.state
    const { setTicketTypeLimit } = this.props
    const { id } = e.target
    const { checked } = e.target
    let removed = noGroupedTicketsIds
    let perTicketValues = [...maxQuantityPerTicketType]
    if (checked) {
      removed.push(id)
    } else {
      perTicketValues = _filter(maxQuantityPerTicketType, item => item.ticket_type !== id)
      removed = _filter(removed, n => n !== id)
    }
    this.setState({ noGroupedTicketsIds: removed, maxQuantityPerTicketType: perTicketValues }, () => {
      setTicketTypeLimit(this.state.maxQuantityPerTicketType)
    })
    this.setTickets(groupedTicketsIds, removed)
  }

  onChangeGroupedTickets = e => {
    const { noGroupedTicketsIds } = this.state
    const newGroupedTickets = _filter(e.split(','), i => !!i)
    this.setState({ groupedTicketsIds: newGroupedTickets })
    this.setTickets(newGroupedTickets, noGroupedTicketsIds)
  }

  setTickets = (groupedTicketsIds = [], noGroupedTicketsIds = []) => {
    const { setTicketTypePreRequired } = this.props
    setTicketTypePreRequired(groupedTicketsIds.concat(noGroupedTicketsIds))
  }

  render() {
    const { ticketOptions, tableOptions, noGroupedTicketsIds } = this.state
    const [noGroupedTickets, groupedTickets] = ticketTypesSorting(ticketOptions)

    return (
      <div
        className="row col-xl-6"
        style={{
          background: '#2f3339',
          margin: '20px 10px',
          padding: ' 0 0px 10px'
        }}
      >
        <div className="col-sm-6 col-xs-12" style={{ margin: '5px 0' }}>
          <label htmlFor={'add-on-ticketType'}>Ticket Type</label>
          {_map(noGroupedTickets, (t, index) => (
            <div key={index} className='d-flex'>
              <input
                type="checkbox"
                id={t.id}
                name={t.id}
                checked={noGroupedTicketsIds.includes(t.id)}
                onChange={this.onChangeNoGroupedTickets}
              />
              <label style={{ marginLeft: '10px' }} htmlFor={t.id}>
                {t.displayName}
              </label>
            </div>
          ))}

          {groupedTickets.length ? (
            <SelectTimeSlotTickets
              groupedTickets={groupedTickets}
              onChange={v => this.onChangeGroupedTickets(v)}
            />
          ) : null}
        </div>
        <div className="col-sm-6 col-xs-12" style={{ margin: '5px 0' }}>
          <label htmlFor={'add-on-ticketType'}>Table Type</label>
          {_map(tableOptions, (t, index) => (
            <div key={index} className='d-flex'>
              <input
                type="checkbox"
                id={t.id}
                name={t.id}
                checked={noGroupedTicketsIds.includes(t.id)}
                onChange={this.onChangeNoGroupedTickets}
              />
              <label style={{ marginLeft: '10px' }} htmlFor={t.id}>
                {t.displayName}
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
