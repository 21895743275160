import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

export default class TextArea extends React.Component {
  static propType = {
    field: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {}
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }
  handleFocus(e) {
    this.setState({ focused: true })
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }
  handleBlur(e) {
    this.setState({ focused: false })
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }
  render() {
    const { field, label, hint, className, value, readOnly, ...input } = this.props
    const hasValue = this.refs.input && this.refs.input.value
    const { error, touched } = input
    const hasError = error && touched
    const groupClass = classNames(className, 'form-group', hasError ? 'has-error' : '')
    const labelClass = classNames('control-label', this.state.focused || hasValue ? 'active' : '')
    const props = {
      className: 'form-control',
      ...input,
      ...field
    }
    const descriptors = []

    let errorNode
    const errorNodeId = input.id ? `${input.id}_error` : ''
    if (hasError) {
      errorNodeId && descriptors.push(errorNodeId)
      errorNode = (
        <span id={errorNodeId} className="help-block text-area">
          {error}
        </span>
      )
    }

    let hintNode
    const hintNodeId = input.id ? `${input.id}_hint` : ''
    if (hint) {
      hintNode = (
        <small id={hintNodeId} className="hint">
          {hint}
        </small>
      )
    }

    delete props.error
    delete props.touched

    if (descriptors.length) {
      props['aria-describedby'] = descriptors.join(' ')
    }

    return (
      <div className={groupClass}>
        <label htmlFor={props.id} className={labelClass}>
          {label} {!readOnly && <span className="fa fa-fw fa-pencil" />} {hintNode}
        </label>
        <textarea
          {...props}
          value={value || ''}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          ref="input"
        />
        {errorNode}
      </div>
    )
  }
}
