import React from 'react'
import _reduce from 'lodash/reduce'
import DownloadQrCodeButton from '../../../_library/DownloadQrCodeButton'
import Button from '../../../_library/Button'
import Clipboard from '../../../_library/Clipboard'
import { resendAllPromoterLinks } from '../../../../_common/core/http_services/performance'
import { showSuccessMessage } from '../../../utils/messenger'
import SortableTable from '../../../_library/SortableTable'
import { getTableColumns } from '../../../utils/sortableTableUtils'
import { createFixedFloatNormalizer, currencyNormalizerCreator } from '../../../../_common/core/validation'
import EmptyBar from '../../../_library/EmptyBar'

const Summary = ({ event, promoterSalesData }) => {
  const promotersTable = getTableColumns([
    {
      key: 'full_name',
      label: 'Name',
      isSortable: true
    },
    {
      key: 'quantity',
      label: 'No. of Tickets Sold',
      isSortable: true
    },
    {
      key: 'revenue',
      label: 'Revenue',
      isSortable: true,
      disableNormalizerOnCopy: true,
      normalizer: value =>
        currencyNormalizerCreator(getCurrencySymbol(event))(createFixedFloatNormalizer(2)(parseFloat(value)))
    },
    {
      key: 'promoter_link',
      label: 'Promoter Link',
      isSortable: true
    },
    {
      key: 'promoter_link_qr_code',
      label: 'Promoter Link QR Code',
      isSortable: false
    }
  ])

  const footbarColumns = [
    { key: 'quantity', sourceKey: 'quantity' },
    {
      key: 'revenue',
      sourceKey: 'revenue',
      normalizer: value =>
        currencyNormalizerCreator(getCurrencySymbol(event))(
          createFixedFloatNormalizer(2)(parseFloat(value))
        )
    },
    {
      key: 'grossRevenue',
      sourceKey: 'grossRevenue',
      normalizer: value =>
        currencyNormalizerCreator(getCurrencySymbol(event))(
          createFixedFloatNormalizer(2)(parseFloat(value))
        )
    }
  ]

  const permission = !!event ? event.self.role : ''
  const allowedResendLinkOwners = ['admin', 'owner', 'tf_support_admin', 'tf_client_support_admin']
  const listPromoters = Object.values(promoterSalesData)

  const resendPromoterLinks = async () => {
    try {
      const data = await resendAllPromoterLinks(event.id)
      showSuccessMessage(data.data.message)
    } catch (error) {
      this.handleResponse(null, error)
    }
  }

  const promotersData = listPromoters
    .filter(
      promoter =>
        !(
          promoter.email.includes('@theticketfairy.com') &&
          _reduce(promoter.salesBreakdown, (sum, n) => sum + n.quantity, 0) === 0
        )
    )
    .map(promoter => {
      const link = promoter.referralLink
      return {
        full_name: promoter.firstName + ' ' + promoter.lastName,
        quantity: _reduce(promoter.salesBreakdown, (sum, n) => sum + n.quantity, 0),
        revenue: _reduce(promoter.salesBreakdown, (sum, n) => sum + n.revenue, 0),
        promoter_link: (
          <div className="show_linkico emailico_table">
            <img alt="" src={asset('/resources/images/system_icons/links.svg')} className="ico-show-small" />
            <span className="link_element">{link}</span>
            <Clipboard text={link} placement="right" />
          </div>
        ),
        promoter_link_qr_code: (
          <DownloadQrCodeButton
            label="Promoter Link QR Code"
            className="btn btn-lightpurple"
            value={link}
            size={192}
            id={`promoter-qr-code-${event.id}-${promoter.id}`}
          />
        )
      }
    })

  return listPromoters.length !== 0 ? (
    <div>
      <div className="display-flex">
        <div className="table-title first-title">Summary</div>
        {(allowedResendLinkOwners.includes(permission)) && (
          <Button
            className="btn btn-primary btn-shadow resend-btn"
            type="button"
            onClick={resendPromoterLinks}
          >
            Resend All Promoter Links
          </Button>
        )}
      </div>
      <SortableTable
        data={promotersData}
        tableColumns={promotersTable}
        enableSort={true}
        enableCopyTable={true}
        disableMobileView={true}
        sortBy={{ column: 'revenue', asc: false }}
        footbar={{
          label: 'Total',
          columns: footbarColumns
        }}
      />
    </div>
  ) : (
    <EmptyBar />
  )
}

export default Summary
