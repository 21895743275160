import React from 'react'
import Card from '../../../_library/Card'
import _map from 'lodash/map'
import modalStyle from '../../../../_common/core/modalStyle'
import Button from '../../../_library/Button'
import Modal from 'react-modal'
import ClipboardButton from 'react-clipboard.js'
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import { ENV } from '../../../constants/env'

export default class EventAlternativeViews extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteView: false,
      selectedView: null,
      submitting: false,
      eventUrlCopied: false,
      eventUrl: `${ENV.API_CONSUMER}/event/`
    }
  }

  onClickEdit = selected => {
    this.props.onEditAlternativeView(selected)
  }

  onClickConfirmDelete = id => {
    this.setState({ deleteView: true, selectedView: id })
  }

  onCancelDelete = () => {
    this.setState({ deleteView: false, selectedView: null })
  }

  onClickDelete = () => {
    this.setState({ submitting: true })
    this.props.deleteView(this.state.selectedView).finally(() => {
      this.setState({ deleteView: false, selectedView: null, submitting: false })
    })
  }

  copyEventUrlOut = e => {
    if (this.state.eventUrlCopied) {
      setTimeout(() => {
        this.setState({ eventUrlCopied: false })
      }, 100)
    }
  }

  copyEventUrlAfter = () => {
    this.setState({ eventUrlCopied: true })
  }

  renderAlternativeView = () => {
    const { alternativeViews } = this.props
    const { eventUrlCopied, eventUrl } = this.state

    return _map(alternativeViews, (item, j) => (
      <tr id={j} key={j}>
        <td>{item.title ? item.title : ''}</td>
        <td>
          <a className="page-link" href={eventUrl + item.slug} target="_blank" rel="noreferrer">
            View Page
          </a>
        </td>
        <td className="table-action-container">
          <label className="autoslugfield-label" onMouseLeave={this.copyEventUrlOut}>
            <ClipboardButton
              component="span"
              data-clipboard-text={eventUrl + item.slug}
              onSuccess={this.copyEventUrlAfter}
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="eventUrlCopied">
                    {eventUrlCopied ? 'Copied' : 'Copy Alternative View URL'}
                  </Tooltip>
                }
                trigger={['hover']}
              >
                <i className="fa fa-clipboard event-url-copy" />
              </OverlayTrigger>
            </ClipboardButton>
          </label>
        </td>
        <td className="table-action-container">
          <span aria-hidden={true} onClick={() => this.onClickEdit(item)}>
            <i className="fa fa-fw fa-pencil" />
          </span>
        </td>
        <td className="table-action-container">
          <span aria-hidden={true} onClick={() => this.onClickConfirmDelete(item.id)}>
            <i className="fa fa-fw fa-trash" />
          </span>
        </td>
      </tr>
    ))
  }

  render() {
    const { deleteView, submitting } = this.state
    const { cardsStatus, alternativeViews, onClickAlternativeView } = this.props

    return (
      <Card
        icon={'fa-info'}
        title={'Alternative Views'}
        className="create-event"
        status={cardsStatus && cardsStatus.alternativeViews}
      >
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="btn-create-brand">
              <Button
                className="btn btn-ok btn-shadow btn-alternative-view"
                type="button"
                onClick={onClickAlternativeView}
              >
                <i className={'fa fa-fw fa-plus'} />
                Create Alternative View
              </Button>
            </div>
          </div>
          {alternativeViews && alternativeViews.length > 0 && (
            <div className="col-12 col-md-12">
              <div className="table-responsive">
                <table className="table alternative-view-table">
                  <thead>
                    <tr>
                      <th>
                        <strong>Title</strong>
                      </th>
                      <th className="alternative-view-link-head">
                        <strong>Link</strong>
                      </th>
                      <th colSpan={3}>
                        <strong>Actions</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.renderAlternativeView()}</tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <Modal
          className="event-details modal-trans delete-view"
          style={modalStyle}
          isOpen={deleteView}
          contentLabel="Modal"
          onRequestClose={this.onCancelDelete}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">Delete Alternative View</div>
              <div className="modal-body">
                Are you sure you want to delete this alternative view?
                <div className="card-block card-footer text-center btn-toolbar">
                  <Button
                    type="submit"
                    className="btn btn-danger btn-lg btn-shadow"
                    loading={submitting}
                    onClick={this.onClickDelete}
                  >
                    Delete
                  </Button>
                  <Button
                    className="btn btn-cancel btn-lg btn-shadow"
                    type="button"
                    disabled={submitting}
                    onClick={this.onCancelDelete}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Card>
    )
  }
}
