import React from 'react'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _isNaN from 'lodash/isNaN'
import _forEach from 'lodash/forEach'
import _isEmpty from 'lodash/isEmpty'
import _isNumber from 'lodash/isNumber'
import { Formik, Form, Field } from 'formik'

import Button from '../../_library/Button'
import TicketsSection from './TicketsSection'
import { CustomToggleField, RichTextAreaField, SimpleField } from '../../formik/Fields'
import {
  createFixedFloatNormalizer,
  requiredValidator,
  toInt,
  toNumber
} from '../../../_common/core/validation'

const intRE = /\d+/

function validateForm(data) {
  const errors = {}

  if (!_get(data, 'stockUnlimited')) {
    const rawstock = _get(data, 'stock')
    const stock = parseInt(rawstock, 10)
    const maxQuantity = _get(data, 'max_quantity')
    const maxQuantityPerTicketType = _get(data, 'maxQuantityPerTicketType')

    if (!intRE.test(rawstock) || _isNaN(stock) || stock < 0) {
      _set(errors, 'stock', 'Value must be greater than or equal to 0')
    }

    if (maxQuantity && maxQuantity > stock) {
      _set(errors, 'max_quantity', 'Value must be less than or equal to stock value')
    }

    _forEach(maxQuantityPerTicketType, item => {
      if (item.quantity > stock) {
        _set(errors, 'maxQuantityPerTicketType', {
          ...errors.maxQuantityPerTicketType,
          [item.ticket_type]: 'Value must be less than or equal to stock value'
        })
      }
    })
  }

  return errors
}

class AddOnForm extends React.PureComponent {
  renderInitialValues = () => {
    const { initialValues, isEditing } = this.props
    const price = _get(initialValues, 'price', '')
    const stockValue = _get(initialValues, 'stock')
    const stockUnlimited = _isNumber(stockValue) ? false : true
    const stock = _isNumber(stockValue) ? stockValue : ''
    const values = {
      name: _get(initialValues, 'name', ''),
      stock,
      stockUnlimited: isEditing ? stockUnlimited : false,
      price: price ? createFixedFloatNormalizer(2)(parseFloat(price) / 100) : price,
      description: _get(initialValues, 'description', ''),
      active: _get(initialValues, 'active', true),
      ticketTypeNeeded: !!_get(initialValues, 'prerequisiteTicketTypeIds', false),
      prerequisiteTicketTypeIds: _get(initialValues, 'prerequisiteTicketTypeIds', []),
      limitToTicketQuantity: _get(initialValues, 'flagLimitToTicketQuantity', true),
      maxQuantity: !isEditing ? _get(initialValues, 'maxQuantity') || '' : initialValues.maxQuantity || '',
      limitPerTicket: !isEditing
        ? _get(initialValues, 'maxQuantity') || ''
        : initialValues.limitPerTicket || '',
      withholdFromResale: _get(initialValues, 'withholdFromResale', false)
    }

    return values
  }

  render() {
    const { tickets, tables, isEditing, onCancel = () => {}, handleSubmit } = this.props

    return (
      <Formik
        initialValues={this.renderInitialValues()}
        onSubmit={values => {
          handleSubmit(values)
        }}
        validate={validateForm}
        render={({ values, isSubmitting, touched, setFieldValue }) => (
          <Form autoComplete="off" className="addon-form">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <Field name="name" label="Name" component={SimpleField} validate={requiredValidator()} />
              </div>
              <div className="col-xs-12 col-sm-6">
                <Field
                  name="price"
                  label="Price"
                  disabled={!!isEditing}
                  component={SimpleField}
                  normalizer={toNumber}
                  onBlurNormalizer={value => createFixedFloatNormalizer(2)(parseFloat(value))}
                  validate={requiredValidator()}
                />
              </div>
              <div className="col-xs-12 col-sm-6" style={{ margin: '20px 0' }}>
                <Field name="stockUnlimited" onText="Unlimited" component={CustomToggleField} />
              </div>
              {!values.stockUnlimited && (
                <div className="col-xs-12 col-sm-6">
                  <Field
                    name="stock"
                    label="Stock"
                    component={SimpleField}
                    normalizer={toInt}
                    validate={requiredValidator()}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-xs-12">
                <Field name="description" label="Description" component={RichTextAreaField} />
              </div>
              <div className="col-xs-12">
                <Field name="active" onText="Enabled" component={CustomToggleField} />
              </div>
              <div className="col-xs-12">
                <Field
                  name="ticketTypeNeeded"
                  onText="Limit to Certain Ticket Types Only"
                  component={CustomToggleField}
                />
                {values.ticketTypeNeeded && (
                  <TicketsSection
                    tickets={tickets}
                    tables={tables}
                    groupedTicketIds={values.prerequisiteTicketTypeIds}
                    setTicketTypePreRequired={value => setFieldValue('prerequisiteTicketTypeIds', value)}
                    setTicketTypeLimit={value => setFieldValue('maxQuantityPerTicketType', value)}
                    stockValue={values.stock}
                    touched={touched.maxQuantityPerTicketType}
                  />
                )}
              </div>
              <div
                className={`col-xs-12 col-sm-12 ${!values.limitToTicketQuantity ? 'spacing_bottom_20' : ''}`}
              >
                <Field
                  name="limitToTicketQuantity"
                  onText="Limit to ticket quantity"
                  component={CustomToggleField}
                />
              </div>
              {!values.limitToTicketQuantity && (
                <>
                  <div className="col-xs-12 col-sm-6">
                    <Field
                      name="maxQuantity"
                      label="Maximum quantity (optional)"
                      component={SimpleField}
                      normalizer={toInt}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <Field
                      name="limitPerTicket"
                      label="Limit per ticket (optional)"
                      component={SimpleField}
                      normalizer={toInt}
                    />
                  </div>
                </>
              )}
              <div className="col-xs-12">
                <Field
                  name="withholdFromResale"
                  onText="Withhold from Resale"
                  component={CustomToggleField}
                />
              </div>
            </div>
            <div className="modal-footer center no-border">
              <Button
                className="btn btn-success btn-shadow"
                type="submit"
                loading={isSubmitting}
                disabled={values.ticketTypeNeeded && _isEmpty(values.prerequisiteTicketTypeIds)}
              >
                Save
              </Button>
              <Button
                className="btn btn-cancel btn-shadow"
                type="button"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      />
    )
  }
}

export default AddOnForm
