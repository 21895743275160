import _findIndex from 'lodash/findIndex'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  addons: {},
}

const FETCH_EVENT_ADDONS = createAsyncHandlers('FETCH_EVENT_ADDONS', {
  success(state, action) {
    const { eventId, addons } = action.payload
    state.addons[eventId] = addons
  }
})

const CREATE_ADDON = createAsyncHandlers('CREATE_ADDON', {
  success(state, action) {
    const { res, eventId } = action.payload

    if (state.addons[eventId]) {
      state.addons[eventId].push(res)
    }
  }
})

const UPDATE_ADDON = createAsyncHandlers('UPDATE_ADDON', {
  success(state, action) {
    const { res, eventId } = action.payload
    const index = _findIndex(state.addons[eventId], el => el.id === res.id)
    if (index !== -1) {
      state.addons[eventId][index] = res
    }
  }
})

const UPDATE_ADDONS_SORTING = createAsyncHandlers('UPDATE_ADDONS_SORTING', {
  success(state, action) {
    const { eventId, addons } = action.payload
    state.addons[eventId] = addons
  }
})

export default handleActions({
  ...FETCH_EVENT_ADDONS,
  ...CREATE_ADDON,
  ...UPDATE_ADDON,
  ...UPDATE_ADDONS_SORTING
}, initialState)
