import React from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _clone from 'lodash/clone'
import _isEqual from 'lodash/isEqual'
import _filter from 'lodash/filter'
import SortableTable from '../_library/SortableTable'
import DnDTableActions from '../_library/SortableTable/DnDTableActions'
import { showAxiosError } from '../utils/messenger'
import EmptyBar from '../_library/EmptyBar'
import ConfirmSaveModal from '../events/addons/ConfirmSaveModal'

const SortableTableWithDnDHOC = (TableComponent = SortableTable) =>
  class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        reorderedData: [],
        dndHistory: [],
        dndHistoryCurrentPos: 0,
        dragged: false,
        isSavingSort: false,
        disableSave: true,
        saveSortDialogOpen: false
      }
    }

    componentDidMount() {
      this.update(this.props.data, true)
    }

    componentDidUpdate(prevProps) {
      const { data } = prevProps
      const { dndHistoryCurrentPos } = this.state

      if (!_isEqual(data, this.props.data)) {
        this.update(this.props.data, !dndHistoryCurrentPos)
      }
    }

    update = (data, initial) => {
      this.setState(prevState => ({
        reorderedData: data,
        dndHistory: initial ? [[...data]] : [...prevState.dndHistory, data],
        dndHistoryCurrentPos: initial ? 0 : prevState.dndHistoryCurrentPos + 1
      }))
    }

    onDragEnd = result => {
      if (!result.destination || this.state.isSavingSort) return

      const startIndex = _get(result, 'source.index')
      const endIndex = _get(result, 'destination.index')

      if (startIndex === endIndex) return

      this.setState(prevState => {
        const prevReorderedData = _clone(prevState.reorderedData)
        const draggedItem = prevReorderedData[startIndex]
        const dataWithoutDraggedItem = _filter(prevReorderedData, item => item.id !== draggedItem?.id)
        dataWithoutDraggedItem.splice(endIndex, 0, draggedItem)

        const updatedData = _map(dataWithoutDraggedItem, (item, index) => ({ ...item, sortOrder: index + 1 }))

        return {
          ...prevState,
          dragged: true,
          disableSave: false,
          reorderedData: updatedData,
          dndHistory: [...prevState.dndHistory, updatedData],
          dndHistoryCurrentPos: prevState.dndHistoryCurrentPos + 1
        }
      })
    }

    handleUndo = () => {
      this.setState({ disableSave: false })
      const { dndHistoryCurrentPos, dndHistory } = this.state

      if (dndHistoryCurrentPos > 0 && dndHistory.length > dndHistoryCurrentPos) {
        this.setState(prevState => ({
          dndHistoryCurrentPos: prevState.dndHistoryCurrentPos - 1
        }))
      }
    }

    handleRedo = () => {
      this.setState({ disableSave: false })
      const { dndHistoryCurrentPos, dndHistory } = this.state
      if (dndHistoryCurrentPos >= 0 && dndHistory.length > dndHistoryCurrentPos + 1) {
        this.setState(prevState => ({
          dndHistoryCurrentPos: prevState.dndHistoryCurrentPos + 1
        }))
      }
    }

    handleSave = async () => {
      const { handleSaveSort } = this.props
      const { dndHistory, dndHistoryCurrentPos } = this.state

      this.setState({ isSavingSort: true, disableSave: true })
      const data = dndHistory[dndHistoryCurrentPos]

      try {
        const updatedData = _map(data, (item, index) => ({ id: item.id, sortOrder: index + 1 }))
        await handleSaveSort(updatedData, data)
        this.setState({ dndHistory: [[...data]], dndHistoryCurrentPos: 0 })
      } catch (e) {
        showAxiosError(e)
      } finally {
        this.setState({ isSavingSort: false, dragged: false, saveSortDialogOpen: false })
      }
    }

    handleRefresh = async rowData => {
      const { disableRefreshIfDraggDropped } = this.props
      const { dndHistoryCurrentPos, dragged } = this.state

      // Open confirmation modal of save
      if (disableRefreshIfDraggDropped && dragged) {
        const tablePositionIsNotSaved = !!dndHistoryCurrentPos
        if (tablePositionIsNotSaved) {
          this.setState({ saveSortDialogOpen: true })
          return
        }
      }

      await rowData.onClick()

      this.setState(prevState => ({
        dndHistory: [[...prevState.dndHistory[0]]],
        dndHistoryCurrentPos: 0,
        reorderedData: prevState.dndHistory[0]
      }))
    }

    handleCancelSave = () => {
      this.setState({ saveSortDialogOpen: false })
    }

    render() {
      const {
        data,
        tableColumns,
        detailsRows = [],
        isDndHistoryEnabled = false,
        tableMainActions = [],
        isEmpty,
        isLoading,
        hideDndActionBtns = false,
        showEmptyBar = true,
        ...rest
      } = this.props

      const { dndHistory, dndHistoryCurrentPos, dragged, isSavingSort, disableSave, saveSortDialogOpen } = this.state
      const dndHistoryCount = dndHistory.length

      return (
        <div>
          <ConfirmSaveModal
            saveSortDialogOpen={saveSortDialogOpen}
            handleCancelSave={this.handleCancelSave}
            handleSaveSort={this.handleSave}
          />
          <DnDTableActions
            isDndHistoryEnabled={isDndHistoryEnabled}
            mainActions={_map(tableMainActions, item => ({
              ...item,
              onClick: () => this.handleRefresh(item)
            }))}
            handleUndo={this.handleUndo}
            handleRedo={this.handleRedo}
            disableUndo={!dragged || !(dndHistoryCurrentPos > 0 && dndHistoryCount > dndHistoryCurrentPos)}
            hideUndoBtn={hideDndActionBtns || !_get(dndHistory[dndHistoryCurrentPos], 'length')}
            hideRedoBtn={hideDndActionBtns || !_get(dndHistory[dndHistoryCurrentPos], 'length')}
            disableRedo={
              !dragged || !(dndHistoryCurrentPos >= 0 && dndHistoryCount > dndHistoryCurrentPos + 1)
            }
            showSave={!hideDndActionBtns && (dragged || dndHistoryCurrentPos > 1)}
            disableSave={disableSave}
            handleSaveSort={this.handleSave}
            isSavingSort={isSavingSort}
            tableState={this.state}
          />
          {isEmpty && !isLoading && showEmptyBar && <EmptyBar />}
          {!isEmpty && !isLoading && (
            <SortableTable
              data={dndHistory[dndHistoryCurrentPos] || []}
              tableColumns={tableColumns}
              enableSort={false}
              detailsRows={detailsRows}
              isDnDEnabled={true}
              onDragEnd={this.onDragEnd}
              {...rest}
            />
          )}
        </div>
      )
    }
  }

export default SortableTableWithDnDHOC