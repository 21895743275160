import React from 'react'
import _map from 'lodash/map'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'

import { HTTP_LOADING, HTTP_LOADING_SUCCESSED, HTTP_LOADING_FAILED } from '../../_common/core/http'

import InfluencerShareRate from './influencers/InfluencerShareRate'
import InfluencerTiers from './influencers/InfluencerTiers'
import InfluencerPerformance from './influencers/InfluencerPerformance'
import InfluencerReferrers, { getCustomerFullName } from './influencers/InfluencerReferrers'

import { get_event } from '../../_common/core/selectors'
import { getInfluencerReferrersData } from '../../_common/core/http_services'
import { getTitle } from '../utils/getTitle'

@connect(state => {
  const event = get_event(state)

  return {
    event
  }
}, {})
export default class EventInfluencers extends React.Component {
  constructor(props) {
    super(props)
    this.unMounted = false
    this.refreshTimer = null
    this.refreshFlag = false
    this.state = {
      http_status: HTTP_LOADING,
      influencers: {},
      referrers: []
    }
  }

  componentDidMount() {
    const {
      event: { displayName },
      configs
    } = this.props

    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    const self = this
    self.init()
    self.refreshTimer = setInterval(() => {
      if (
        (self.state.http_status === HTTP_LOADING_SUCCESSED ||
          self.state.http_status === HTTP_LOADING_FAILED) &&
        !self.refreshFlag
      ) {
        self.refreshFlag = true
        self.init()
      }
    }, 3 * 60 * 1000) // refresh every 3 mins
  }

  componentWillUnmount() {
    this.unMounted = true
    this.refreshFlag = false
    if (this.refreshTimer) clearInterval(this.refreshTimer)
  }

  async init() {
    const { event } = this.props
    try {
      const data = await getInfluencerReferrersData(event.id)
      const { influencers } = this.state
      if (!_isEqual(data, influencers)) {
        !this.unMounted &&
          this.setState({
            influencers: data,
            referrers: _map(data.referrers, item => ({
              ...item,
              id: item.referrer_id,
              fullName: getCustomerFullName(item)
            })),
            http_status: HTTP_LOADING_SUCCESSED
          })
      }
    } catch (error) {
      !this.unMounted &&
        this.setState({
          http_status: HTTP_LOADING_FAILED
        })
    }
  }

  render() {
    const { http_status, influencers, referrers } = this.state
    const { event } = this.props
    const permission = _get(event, 'self.role', '')
    const isLimitedStats = permission === 'limited_stats'

    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <InfluencerShareRate isLoading={false} statistics={influencers.statistics} />
            <InfluencerTiers event={event} statistics={influencers.statistics} />
            {!isLimitedStats && <InfluencerPerformance event={event} influencers={influencers} />}
            <InfluencerReferrers loading={http_status === HTTP_LOADING} event={event} referrers={referrers} />
          </div>
        </div>
      </div>
    )
  }
}
