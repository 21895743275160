import { ROLES } from '../../constants/roles'
import { visibleByConfig } from './visibleByConfig'
import { getPermissions } from './permissions'
import _get from 'lodash/get'

export const getEventStatisticsMenuItems = ({
  permission,
  itemId,
  path,
  userTfRole,
  configs,
}) => {
  const permissions = getPermissions(permission)
  const isAdminOrOwner = permissions.isAdminOrOwner()
  const isStatsOrLimitedStats = permissions.isStatsOrLimitedStats()
  const isTfSupport = ROLES.is_tf_support(userTfRole)
  const isTtfTeam = ROLES.is_ttf_team(userTfRole)
  const isSupportOrPartialSupport = ROLES.is_support_or_partial_support(permission)

  const isDemographicsVisible = _get(configs, 'appearance.isDemographicsVisible', true)
  const isGeographicsVisible = _get(configs, 'appearance.isGeographicsVisible', true)
  const isMusicVisible = _get(configs, 'appearance.isMusicVisible', true)
  const isPsychographicsVisible = _get(configs, 'appearance.isPsychographicsVisible', true)
  const isDevicesVisible = _get(configs, 'appearance.isDevicesVisible', true)
  const isGamingVisible = _get(configs, 'appearance.isGamingVisible', true)

  const isTfSupportOrTtfTeam = isTfSupport || isTtfTeam

  const { PERMISSION_PROMOTER } = permissions
  const childGroup_stat = []

  childGroup_stat.push({
    img: 'performance',
    title: 'Performance',
    href: `/event/${itemId}/performance`,
    active: path.includes(`/event/${itemId}/performance`),
    visible: isAdminOrOwner || isTfSupportOrTtfTeam || isStatsOrLimitedStats || PERMISSION_PROMOTER,
  })
  childGroup_stat.push({
    img: 'orders',
    title: 'Orders',
    href: `/event/${itemId}/orders`,
    active: path === `/event/${itemId}/orders`,
    visible: isAdminOrOwner || isTfSupportOrTtfTeam || isStatsOrLimitedStats || isSupportOrPartialSupport,
  })
  childGroup_stat.push({
    img: 'influencers',
    title: 'Influencers',
    href: `/event/${itemId}/influencers`,
    active: path === `/event/${itemId}/influencers`,
    visible: isAdminOrOwner || isTfSupportOrTtfTeam || isStatsOrLimitedStats,
  })
  childGroup_stat.push({
    img: 'traffic',
    title: 'Traffic',
    href: `/event/${itemId}/traffic`,
    active: path.includes(`/event/${itemId}/traffic`),
    visible: isAdminOrOwner || isTfSupportOrTtfTeam || isStatsOrLimitedStats,
  })
  childGroup_stat.push({
    img: 'check-in',
    title: 'Check-In',
    href: `/event/${itemId}/checkin`,
    active: path === `/event/${itemId}/checkin`,
    visible: isAdminOrOwner || isTfSupportOrTtfTeam || isStatsOrLimitedStats,
  })
  childGroup_stat.push({
    img: 'demographics',
    title: 'Demographics',
    href: `/event/${itemId}/demographics`,
    active: path === `/event/${itemId}/demographics`,
    visible: visibleByConfig(
      isDemographicsVisible,
      isTfSupportOrTtfTeam,
      isAdminOrOwner || isStatsOrLimitedStats,
    ),
  })
  childGroup_stat.push({
    img: 'geographics',
    title: 'Geographics',
    href: `/event/${itemId}/geographics`,
    active: path === `/event/${itemId}/geographics`,
    visible: visibleByConfig(
      isGeographicsVisible,
      isTfSupportOrTtfTeam,
      isAdminOrOwner || isStatsOrLimitedStats,
    ),
  })
  childGroup_stat.push({
    img: 'streaming',
    title: 'Music',
    href: `/event/${itemId}/musicstreaming`,
    active: path === `/event/${itemId}/musicstreaming`,
    visible: visibleByConfig(
      isMusicVisible,
      isTfSupportOrTtfTeam,
      isAdminOrOwner || isStatsOrLimitedStats,
    ),
  })
  childGroup_stat.push({
    img: 'psychographics',
    title: 'Psychographics',
    href: `/event/${itemId}/psychographics`,
    active: path === `/event/${itemId}/psychographics`,
    visible: visibleByConfig(
      isPsychographicsVisible,
      isTfSupportOrTtfTeam,
      isAdminOrOwner || isStatsOrLimitedStats,
    ),
  })
  childGroup_stat.push({
    img: 'devices',
    title: 'Devices',
    href: `/event/${itemId}/devices`,
    active: path === `/event/${itemId}/devices`,
    visible: visibleByConfig(
      isDevicesVisible,
      isTfSupportOrTtfTeam,
      isAdminOrOwner || isStatsOrLimitedStats,
    ),
  })
  childGroup_stat.push({
    img: 'games',
    title: 'Gaming',
    href: `/event/${itemId}/gaming`,
    active: path === `/event/${itemId}/gaming`,
    visible: visibleByConfig(
      isGamingVisible,
      isTfSupportOrTtfTeam,
      isAdminOrOwner || isStatsOrLimitedStats,
    ),
  })

  return childGroup_stat
}
