import _result from 'lodash/result'
import _findIndex from 'lodash/findIndex'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import React from 'react'
import Modal from 'react-modal'
import TagsInput from 'react-tagsinput'

import TeamInvitationForm from './team/TeamInvitationForm'
import modalStyle from '../../_common/core/modalStyle'
import LoadingBar from '../_library/LoadingBar'
import Button from '../_library/Button'
import { JSONDatatable, TYPE_FROM_ARRAY, DATATABLE, SEARCHBAR } from '../_library/JSONDatatable'

import {
  FETCH_EVENT_TEAM_INVITATIONS,
  FETCH_EVENT_PROMOTER_GENERIC_INVITATION,
  UPDATE_EVENT_PROMOTER_GENERIC_INVITATION,
  FETCH_EVENT_TEAM_MEMBERS,
  ADD_EVENT_TEAM_INVITATION,
  DELETE_PERMISSION,
  CANCEL_INVITATION,
  RESEND_INVITATION,
  REGENERATE_PROMOTER_GENERIC_INVITATION_EVENT,
  EDIT_PERMISSION
} from '../../_common/redux/eventteam/actions'
import { DisableSectionByPermissionWrapper } from '../hoc'

import { get_event } from '../../_common/core/selectors'
import Clipboard from '../_library/Clipboard'
import { getTitle } from '../utils/getTitle'
import BrandEditTeamInvitationForm from '../brands/team/BrandEditTeamInvitationForm'
import { ROLES as TFROLES } from '../constants/roles'

const INVITATION_INITIAL_VALUES = {
  attributes: {
    role: 'promoter'
  }
}

const ROLES = [
  'owner',
  'admin',
  'stats',
  'limited_stats',
  'curator',
  'staff',
  'promoter',
  'support',
  'limited_support'
]

const ROLES_MAP = {
  owner: 'Owner',
  admin: 'Administrator',
  stats: 'Stats',
  limited_stats: 'Limited Stats',
  curator: 'Curator',
  staff: 'Door Staff',
  promoter: 'Promoter',
  support: 'Support',
  limited_support: 'Limited Support'
}

const ROLE_COMPARE_FUNC = (r1, r2) => {
  const i1 = ROLES.findIndex(r => r === r1.role)
  const i2 = ROLES.findIndex(r => r === r2.role)
  if (i1 < i2) {
    return -1
  } else if (i1 > i2) {
    return 1
  }
  return 0
}

export const IS_TF_MEMBER = m => {
  if (m?.email || m?.attributes?.email) {
    return (m?.email || m?.attributes?.email).toLowerCase().endsWith('theticketfairy.com')
  }
  return false
}

@connect(
  state => ({
    user: state.auth.user,
    event: get_event(state),
    promoterGenericInvitation: state.eventteam.promoterGenericInvitation,
    invitations: state.eventteam.invitations,
    eventMembers: state.eventteam.eventMembers,
    brandMembers: state.eventteam.brandMembers,
    tourMembers: state.eventteam.tourMembers
  }),
  {
    FETCH_EVENT_TEAM_INVITATIONS,
    FETCH_EVENT_PROMOTER_GENERIC_INVITATION,
    REGENERATE_PROMOTER_GENERIC_INVITATION_EVENT,
    UPDATE_EVENT_PROMOTER_GENERIC_INVITATION,
    FETCH_EVENT_TEAM_MEMBERS,
    ADD_EVENT_TEAM_INVITATION,
    DELETE_PERMISSION,
    CANCEL_INVITATION,
    RESEND_INVITATION,
    EDIT_PERMISSION
  }
)
export default class EventTeam extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingInvitations: false,
      loadingGenericPromoterInvitation: false,
      loadingMembers: false,
      showNewInvitationModal: false,
      showDeleteConfirmModal: false,
      permissionToDelete: null,
      deleting: false,
      showCancelInvitationModal: false,
      invitationToCancel: null,
      cancelingInvitation: false,
      invitationToResend: null,
      resendingInvitation: false,
      search: '',
      eventMembers: [],
      brandMembers: [],
      tourMembers: [],
      showEditConfirmModal: false,
      permissionToEdit: null
    }

    this.onClickNewInvitation = this.onClickNewInvitation.bind(this)
    this.onCancelNewInvitation = this.onCancelNewInvitation.bind(this)
    this.createtNewInvitation = this.createtNewInvitation.bind(this)
    this.onCancelDelete = this.onCancelDelete.bind(this)
    this.deletePermission = this.deletePermission.bind(this)
    this.onCancelInvitationCancel = this.onCancelInvitationCancel.bind(this)
    this.cancelInvitation = this.cancelInvitation.bind(this)
  }

  componentDidMount() {
    const {
      event: { displayName },
      configs
    } = this.props
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
    this.fetchGenericPromoterInvitation()
    this.fetchInvitations()
    this.fetchMembers()
  }

  componentWillReceiveProps(nextProps) {
    const { eventMembers, brandMembers, tourMembers } = this.props
    if (
      eventMembers !== nextProps.eventMembers ||
      brandMembers !== nextProps.brandMembers ||
      tourMembers !== nextProps.tourMembers
    ) {
      this.setState({
        eventMembers: nextProps.eventMembers,
        brandMembers: nextProps.brandMembers,
        tourMembers: nextProps.tourMembers
      })
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  async fetchInvitations(indicator = true) {
    try {
      const { event, FETCH_EVENT_TEAM_INVITATIONS } = this.props
      indicator && !this.isUnmounted && this.setState({ loadingInvitations: true })
      const res = await FETCH_EVENT_TEAM_INVITATIONS(event.id)
      indicator && !this.isUnmounted && this.setState({ loadingInvitations: false })
      return res
    } catch (err) {
      indicator && !this.isUnmounted && this.setState({ loadingInvitations: false })
      throw err
    }
  }

  fetchGenericPromoterInvitation = async () => {
    try {
      const { event, FETCH_EVENT_PROMOTER_GENERIC_INVITATION } = this.props
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: true })
      await FETCH_EVENT_PROMOTER_GENERIC_INVITATION(event.id)
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    } catch (e) {
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    }
  }

  regenerateGenericPromoterInvitation = async () => {
    try {
      const {
        REGENERATE_PROMOTER_GENERIC_INVITATION_EVENT,
        promoterGenericInvitation: { token }
      } = this.props
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: true })
      await REGENERATE_PROMOTER_GENERIC_INVITATION_EVENT(token)
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    } catch (e) {
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    }
  }

  disableOrEnableGenericPromoterInvitation = async () => {
    try {
      const { UPDATE_EVENT_PROMOTER_GENERIC_INVITATION, promoterGenericInvitation } = this.props
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: true })
      await UPDATE_EVENT_PROMOTER_GENERIC_INVITATION(promoterGenericInvitation.id, {
        id: promoterGenericInvitation.id,
        status: promoterGenericInvitation.status === 'disabled' ? 'pending' : 'disabled'
      })
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    } catch (e) {
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    }
  }

  async fetchMembers(indicator = true) {
    try {
      const { event, FETCH_EVENT_TEAM_MEMBERS } = this.props
      indicator && !this.isUnmounted && this.setState({ loadingMembers: true })
      const res = await FETCH_EVENT_TEAM_MEMBERS(event.id)
      indicator && !this.isUnmounted && this.setState({ loadingMembers: false })
      return res
    } catch (err) {
      indicator && !this.isUnmounted && this.setState({ loadingMembers: false })
      throw err
    }
  }

  createtNewInvitation = async form => {
    try {
      const { event, ADD_EVENT_TEAM_INVITATION } = this.props
      const res = await ADD_EVENT_TEAM_INVITATION(event.id, form)
      Messenger().post({
        type: 'success',
        message: 'The invitation is successfully resent',
        hideAfter: 3,
        showCloseButton: true
      })
      this.setState({ showNewInvitationModal: false })
      setTimeout(() => {
        this.fetchInvitations(false)
      }, 2000)
      return res
    } catch (err) {
      this.setState({ showNewInvitationModal: false })
      Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  deletePermission = async () => {
    try {
      const { DELETE_PERMISSION } = this.props
      const { permissionToDelete } = this.state

      this.setState({ deleting: true })
      const res = await DELETE_PERMISSION(permissionToDelete.id)
      this.fetchMembers(false)
      this.setState({
        deleting: false,
        permissionToDelete: null,
        showDeleteConfirmModal: false
      })
      return res
    } catch (err) {
      this.setState({
        deleting: false,
        permissionToDelete: null,
        showDeleteConfirmModal: false
      })
      Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  handleEditInvitationSubmit = async form => {
    try {
      const { permissionToEdit } = this.state
      const { EDIT_PERMISSION } = this.props
      await EDIT_PERMISSION(permissionToEdit.id, form)
      this.fetchMembers(false)
      this.setState({
        showEditConfirmModal: false,
        permissionToEdit: null
      })
      return
    } catch (err) {
      this.setState({
        showEditConfirmModal: false,
        permissionToEdit: null
      })
    }
  }

  cancelInvitation = async () => {
    try {
      const { CANCEL_INVITATION } = this.props
      const { invitationToCancel } = this.state
      this.setState({ cancelingInvitation: true })
      const res = await CANCEL_INVITATION(invitationToCancel.token)
      this.fetchInvitations(false)
      this.setState({
        cancelingInvitation: false,
        invitationToCancel: null,
        showCancelInvitationModal: false
      })
      return res
    } catch (err) {
      this.setState({
        cancelingInvitation: false,
        invitationToCancel: null,
        showCancelInvitationModal: false
      })
      Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  resendInvitation = async invitation => {
    try {
      const { event, RESEND_INVITATION } = this.props
      this.setState({
        invitationToResend: invitation,
        resendingInvitation: true
      })
      const res = await RESEND_INVITATION(event.id, invitation.email)
      this.setState({
        resendingInvitation: false,
        invitationToResend: null
      })
      Messenger().post({
        type: 'success',
        message: 'The invitation is successfully resent',
        hideAfter: 3,
        showCloseButton: true
      })
      return res
    } catch (err) {
      this.setState({
        resendingInvitation: false,
        invitationToResend: null
      })
      Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  onClickNewInvitation() {
    this.setState({
      showNewInvitationModal: true
    })
  }

  onCancelNewInvitation() {
    this.setState({
      showNewInvitationModal: false
    })
  }

  createtNewInvitation = async form => {
    try {
      const { event, ADD_EVENT_TEAM_INVITATION } = this.props
      const res = await ADD_EVENT_TEAM_INVITATION(event.id, form)
      Messenger().post({
        type: 'success',
        message: res.message,
        hideAfter: 3,
        showCloseButton: true
      })
      this.setState({ showNewInvitationModal: false })
      setTimeout(() => {
        this.fetchInvitations(false)
      }, 2000)
      return res
    } catch (err) {
      this.setState({ showNewInvitationModal: false })
      Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  onCancelDelete() {
    this.setState({
      showDeleteConfirmModal: false,
      permissionToDelete: null
    })
  }

  onClickDelete(permission) {
    this.setState({
      permissionToDelete: permission,
      showDeleteConfirmModal: true
    })
  }

  onClickEdit = permission => {
    this.setState({
      permissionToEdit: permission,
      showEditConfirmModal: true
    })
  }

  onCancelEdit = () => {
    this.setState({
      showEditConfirmModal: false,
      permissionToEdit: null
    })
  }

  onClickCancelInvitation(invitation) {
    this.setState({
      invitationToCancel: invitation,
      showCancelInvitationModal: true
    })
  }

  onCancelInvitationCancel() {
    this.setState({
      invitationToCancel: null,
      showCancelInvitationModal: false
    })
  }

  filterExistingMembers = (search, data) => {
    let filteredData = data
    const keyword = search.join('').trim().toLowerCase()
    const isFound = (value, keyword) => {
      if (!value) return 0
      return value.toLowerCase().indexOf(keyword) !== -1 ? 1 : 0
    }
    if (keyword !== '') {
      filteredData = _.filter(filteredData, item => {
        let found = 0
        found += isFound(item.firstName, keyword)
        found += isFound(item.lastName, keyword)
        found += isFound(`${item.firstName} ${item.lastName}`, keyword)
        found += isFound(item.email, keyword)
        found += isFound(item.role, keyword)
        return found > 0
      })
    }
    return filteredData
  }

  searchValue = val => {
    const { eventMembers, brandMembers, tourMembers } = this.props

    if (val.length === 0) {
      this.setState({
        search: val,
        eventMembers,
        brandMembers,
        tourMembers
      })
    } else {
      const filteredeventMembers = this.filterExistingMembers(val, eventMembers)
      const filteredbrandMembers = this.filterExistingMembers(val, brandMembers)
      const filteredtourMembers = this.filterExistingMembers(val, tourMembers)
      this.setState({
        search: val,
        eventMembers: filteredeventMembers,
        brandMembers: filteredbrandMembers,
        tourMembers: filteredtourMembers
      })
    }
  }

  copyToClipboard = () => {
    const { promoterGenericInvitation } = this.props
    const _promoterLink = `${window.location.origin}/team-invitations/${promoterGenericInvitation?.token}`
    navigator.clipboard.writeText(_promoterLink)
  }

  renderSearchCount = count => (
    <div className="search-total-count">Number of Matching Team Members: {count}</div>
  )

  saveTag = () => {
    this.refs.tag.accept()
    if (this.props.autoFocus) this.refs.tag.focus()
  }

  render() {
    const { user, invitations, promoterGenericInvitation, event } = this.props
    const {
      loadingInvitations,
      loadingGenericPromoterInvitation,
      loadingMembers,
      showNewInvitationModal,
      showDeleteConfirmModal,
      permissionToDelete,
      deleting,
      showCancelInvitationModal,
      invitationToCancel,
      cancelingInvitation,
      search,
      eventMembers,
      brandMembers,
      tourMembers,
      permissionToEdit,
      showEditConfirmModal
    } = this.state

    const myRole = event?.self?.role

    const showGenericPromoterInvitationButton =
      promoterGenericInvitation?.status === 'pending' || promoterGenericInvitation?.status === 'disabled'
    const isActivatedGenericPromoterInvitation = promoterGenericInvitation?.status === 'pending'
    const _promoterLink = `${window.location.origin}/team-invitations/${promoterGenericInvitation?.token}`
    const _invitations = invitations.filter(i => true)
    const _eventMembers = eventMembers.filter(m => (user.tfStaff ? true : !IS_TF_MEMBER(m)))
    const _brandMembers = brandMembers.filter(m => (user.tfStaff ? true : !IS_TF_MEMBER(m)))
    const _tourMembers = tourMembers.filter(m => (user.tfStaff ? true : !IS_TF_MEMBER(m)))

    const searchCount = search.length > 0

    _invitations.sort(ROLE_COMPARE_FUNC)
    _eventMembers.sort(ROLE_COMPARE_FUNC)
    _brandMembers.sort(ROLE_COMPARE_FUNC)
    _tourMembers.sort(ROLE_COMPARE_FUNC)

    const deleteConfirmMsg = permissionToDelete
      ? 'Are you sure to delete ' +
        permissionToDelete.firstName +
        ' ' +
        permissionToDelete.lastName +
        "'s " +
        ROLES_MAP[permissionToDelete.role] +
        ' permission?'
      : ''
    const invitationCancelConfirmMsg = invitationToCancel
      ? 'Are you sure you want to cancel the ' +
        ROLES_MAP[invitationToCancel.role] +
        ' team invitation to ' +
        invitationToCancel.email +
        '?'
      : ''

    return (
      <DisableSectionByPermissionWrapper>
        <div className="event-team">
          {loadingMembers && (
            <LoadingBar key="loadingbar" title={"Hold tight! We're getting your event's team members..."} />
          )}
          {!loadingMembers && (
            <div className="team-members-container">
              <div className="team-member-search-field">
                <div className="team-member-search tags-field searchbar find_order">
                  <TagsInput
                    ref="tag"
                    inputProps={{ placeholder: '' }}
                    addKeys={[9, 13]}
                    maxTags={1}
                    onlyUnique
                    value={search || []}
                    onChange={this.searchValue}
                    renderInput={this.autoSizing}
                  />
                  <Button className="search-btn" onClick={this.saveTag}>
                    <img className="" src={asset('/resources/images/search-ico.svg')} alt="" />
                  </Button>
                </div>
              </div>
              <div className="event-team-members">
                <h3 className="heading_style">Team Members at Event Level</h3>
                {searchCount && this.renderSearchCount(_eventMembers.length)}
                <MembersTable
                  isTeamMemberTable
                  user={user}
                  myRole={myRole}
                  data={_eventMembers}
                  actionsNeeded={true}
                  actions={[
                    {
                      buttonIcon: 'fa fa-pencil',
                      buttonClass: 'btn btn-primary',
                      buttonLabel: 'Edit',
                      action: this.onClickEdit
                    },
                    {
                      buttonIcon: 'fa fa-trash',
                      buttonClass: 'btn btn-danger',
                      buttonLabel: 'Delete',
                      action: this.onClickDelete.bind(this)
                    }
                  ]}
                />
                <h3 className="heading_style">Team Members at Brand Level</h3>
                {searchCount && this.renderSearchCount(_brandMembers.length)}
                <MembersTable data={_brandMembers} actionsNeeded={false} />
                <h3 className="heading_style">Team Members at Tour Level</h3>
                {searchCount && this.renderSearchCount(_tourMembers.length)}
                <MembersTable data={_tourMembers} actionsNeeded={false} />
              </div>
            </div>
          )}
          <h3 className="heading_style">Invite Your Promoters</h3>
          {promoterGenericInvitation?.token ? (
            <div className="row">
              <div className="col-xs-12">
                <label className="autoslugfield-label">
                  Copy Promoter Invitation Link
                  <Clipboard text={_promoterLink} />
                </label>
                <div className="wrapper-promoter-link-box">
                  <div onClick={this.copyToClipboard} className="wrapper-promoter-link">
                    <img
                      className="ico-show-small image-promoter-link"
                      src={asset('/resources/images/system_icons/links.svg')}
                      alt=""
                    />
                    <div className="link-prefix-url" style={{ display: 'flex', flexDirection: 'row' }}>
                      {_promoterLink}
                      <Clipboard text={_promoterLink} containerStyle={{ marginLeft: 10 }} />
                    </div>
                  </div>
                  <div className="generic-buttons-box">
                    <Button
                      className="btn btn-default btn-shadow"
                      type="button"
                      disabled={!isActivatedGenericPromoterInvitation}
                      onClick={this.regenerateGenericPromoterInvitation}
                    >
                      <i className="fa fa-undo" /> Regenerate
                    </Button>
                    {showGenericPromoterInvitationButton && (
                      <Button
                        className={`btn ${
                          isActivatedGenericPromoterInvitation ? 'btn-danger' : 'btn-success'
                        } btn-shadow`}
                        type="button"
                        style={{ marginLeft: 10 }}
                        onClick={this.disableOrEnableGenericPromoterInvitation}
                      >
                        <i className="fa fa-minus-circle" aria-hidden="true" />{' '}
                        {isActivatedGenericPromoterInvitation ? 'Deactivate' : 'Enable'}{' '}
                        {loadingGenericPromoterInvitation && (
                          <i className="fa fa-circle-o-notch fa-fw fa-spin" />
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <i className="fa fa-circle-o-notch fa-fw fa-spin" />
          )}
          <h3 className="heading_style">Team Invitations</h3>
          <div className="btn-toolbar text-right">
            <Button className="btn btn-success" onClick={this.onClickNewInvitation}>
              <i className="fa fa-fw fa-plus" /> Invite New Team Member
            </Button>
          </div>
          <div className="event-team-invitations">
            {loadingInvitations && (
              <LoadingBar
                key="loadingbar"
                title={"Hold tight! We're getting your event's team invitations..."}
              />
            )}
            {!loadingInvitations && (
              <MembersTable
                isTeamMemberTable
                user={user}
                data={_invitations}
                actionsNeeded={true}
                statusNeeded={true}
                actions={[
                  {
                    buttonIcon: 'fa fa-undo',
                    buttonClass: 'btn btn-primary',
                    buttonLabel: 'Resend',
                    action: this.resendInvitation.bind(this)
                  },
                  {
                    buttonIcon: 'fa fa-times',
                    buttonClass: 'btn btn-danger',
                    buttonLabel: 'Cancel',
                    action: this.onClickCancelInvitation.bind(this)
                  }
                ]}
                searchBar
              />
            )}
          </div>
          <Modal
            className="event-team-new-invitation modal-trans"
            style={modalStyle}
            isOpen={showNewInvitationModal}
            contentLabel="Modal"
            onRequestClose={this.onCancelNewInvitation}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">New Team Invitation</div>
                <div className="modal-body">
                  <TeamInvitationForm
                    onSubmit={this.createtNewInvitation}
                    onCancel={this.onCancelNewInvitation}
                    submitLabel={'Invite'}
                    initialValues={INVITATION_INITIAL_VALUES}
                    isNew={true}
                  />
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            className="event-team-new-invitation modal-trans"
            style={modalStyle}
            isOpen={showEditConfirmModal}
            contentLabel="Modal"
            onRequestClose={this.onCancelEdit}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">Edit Team Invitation</div>
                <div className="modal-body">
                  <BrandEditTeamInvitationForm
                    onSubmit={this.handleEditInvitationSubmit}
                    onCancel={this.onCancelEdit}
                    submitLabel={'Edit'}
                    initialValues={{
                      attributes: {
                        role: permissionToEdit?.role
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            className="event-team-delete-confirm modal-trans"
            style={modalStyle}
            isOpen={showDeleteConfirmModal}
            contentLabel="Modal"
            onRequestClose={this.onCancelDelete}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">Permission Delete</div>
                <div className="modal-body">{deleteConfirmMsg}</div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={this.deletePermission}
                      disabled={deleting}
                    >
                      Ok
                    </Button>
                    <Button
                      className="btn btn-default btn-shadow"
                      type="button"
                      onClick={this.onCancelDelete}
                      disabled={deleting}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            className="event-team-cancel-invitation-confirm modal-trans"
            style={modalStyle}
            isOpen={showCancelInvitationModal}
            contentLabel="Modal"
            onRequestClose={this.onCancelInvitationCancel}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">Invitation Cancel</div>
                <div className="modal-body">{invitationCancelConfirmMsg}</div>
                <div className="modal-footer">
                  <div className="btn-toolbar btn-toolbar-right">
                    <Button
                      className="btn btn-success btn-shadow"
                      type="button"
                      onClick={this.cancelInvitation}
                      disabled={cancelingInvitation}
                    >
                      Ok
                    </Button>
                    <Button
                      className="btn btn-default btn-shadow"
                      type="button"
                      onClick={this.onCancelInvitationCancel}
                      disabled={cancelingInvitation}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </DisableSectionByPermissionWrapper>
    )
  }
}

class MembersTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.getFilteredRows = this.getFilteredRows.bind(this)
    this.getSortedRows = this.getSortedRows.bind(this)
    this.getTableData = this.getTableData.bind(this)
  }

  getFilteredRows(rows, search) {
    const isFound = (value, keyword) => {
      if (!value) return 0
      return value.toLowerCase().indexOf(keyword) != -1 ? 1 : 0
    }
    let rows_filtered = rows
    const keyword = search.join('').trim().toLowerCase()
    if (keyword != '') {
      rows_filtered = _filter(rows_filtered, item => {
        let found = 0
        found += isFound(item.firstName, keyword)
        found += isFound(item.lastName, keyword)
        found += isFound(item.email, keyword)
        found += isFound(item.role, keyword)
        return found > 0
      })
    }
    return rows_filtered
  }

  getSortedRows(rows_filtered, sort) {
    return rows_filtered
  }

  callAction(permission, action) {
    action(permission)
  }

  getTableData(datatable, rows_filtered, sort) {
    const { actionsNeeded, statusNeeded, actions, myRole, user, isTeamMemberTable } = this.props

    const columns = [
      { title: 'Name', sort: false },
      { title: 'Email', sort: false },
      { title: 'Permission', sort: false }
    ]
    let colSpan = 3
    if (statusNeeded) {
      columns.push({ title: 'Status', sort: false })
      ++colSpan
    }
    if (actionsNeeded) {
      columns.push({ title: 'Actions', sort: false })
      ++colSpan
    }
    const content_header = datatable.getHeaderRow(datatable, columns, sort)

    const isOwner = myRole === 'owner'
    const isAdmin = myRole === 'admin'

    return (
      <table className="table event-team-table">
        <thead>{content_header}</thead>
        <tbody>
          {rows_filtered.length === 0 && (
            <tr>
              <td colSpan={colSpan} className="text-center">
                No Members Found
              </td>
            </tr>
          )}
          {rows_filtered.length > 0 &&
            rows_filtered.map((row, index) => {
              const canModifyAsOwner = row.email !== user?.email
              const canModifyAsAdmin = !(row.role === 'owner' || row.role === 'admin')
              return (
                <tr key={index} className={index % 2 == 0 ? 'row-stale' : ''}>
                  <td>
                    {row.firstName} {row.lastName}
                  </td>
                  <td>{row.email}</td>
                  <td>
                    <div className="role">
                      <span className={`circle ${row.role}`} />
                      <span>{ROLES_MAP[row.role]}</span>
                    </div>
                  </td>
                  {statusNeeded && <td>{row.status}</td>}
                  {actionsNeeded && (
                    <td>
                      {actions &&
                        actions
                          .filter(() =>
                            !TFROLES.is_tf_support_or_client_support(user?.tfRole) || !isTeamMemberTable
                              ? true
                              : myRole
                              ? (isOwner && canModifyAsOwner) || (isAdmin && canModifyAsAdmin)
                              : isTeamMemberTable
                              ? false
                              : true
                          )
                          .map((action, index) => (
                            <Button
                              disabled={row.status === 'accepted'}
                              key={index}
                              className={action.buttonClass}
                              onClick={this.callAction.bind(this, row, action.action)}
                            >
                              <i className={action.buttonIcon} />
                              {action.buttonLabel}
                            </Button>
                          ))}
                    </td>
                  )}
                </tr>
              )
            })}
        </tbody>
      </table>
    )
  }

  copyPromoterLinkAfter = () => {
    this.setState({
      promoterLinkCopied: true
    })
  }

  render() {
    const { data, searchBar } = this.props

    return (
      <JSONDatatable
        type={TYPE_FROM_ARRAY}
        data={data}
        sort={{ index: 0, asc: true }}
        getFilteredRows={this.getFilteredRows}
        getSortedRows={this.getSortedRows}
        getTableData={this.getTableData}
        autoRefresh={20 * 1000}
      >
        {searchBar && (
          <div ref={SEARCHBAR} hasSearch autoFocus labelTotalCount="Number of Matching Invitations" />
        )}
        <div ref={DATATABLE} />
      </JSONDatatable>
    )
  }
}
